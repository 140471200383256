import { React, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { BiLogoGmail } from 'react-icons/bi';
import { FaCentercode, FaPhoneAlt, FaWarehouse } from 'react-icons/fa';
import { MdPayment } from 'react-icons/md';
import { BsCoin } from 'react-icons/bs';

import PersonName from '../Common/ViewPageDetails/PersonName';
import Badges from '../Common/ViewPageDetails/Badges';
import Details from '../Common/ViewPageDetails/Details';
import ShowMemo from '../Common/ViewPageDetails/ShowMemo';
import EntriesPopUpLineItemAccordionView from 'components/entries-pop-up-form/EntriesPopUpLineItemAccordionView/EntriesPopUpLineItemAccordionView';
import { StoreContext } from 'context/Context';

function PurchaseOrderView({ purchaseOrderData }) {
  const { store } = useContext(StoreContext);
  const taxLabel = store?.globalConfiguration?.tax_label || 'Tax';
  return (
    <Container className="mt-3 ps-3 pe-3 voucher-inquiry-view-wrapper">
      <Row>
        <Col lg={6} md={6} sm={12} className="p-0">
          {purchaseOrderData?.supplier_name ? (
            <PersonName value={purchaseOrderData?.supplier_name} />
          ) : null}
          {purchaseOrderData?.reference && (
            <Badges label={purchaseOrderData?.reference} bg={'danger'} />
          )}
          <div className="mt-2">
            {purchaseOrderData?.email && (
              <Details
                label={'Email Address'}
                value={purchaseOrderData?.email}
                icon={<BiLogoGmail className="me-1 text-danger" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
            {purchaseOrderData?.phone && (
              <Details
                label={'Phone Number'}
                value={purchaseOrderData?.phone}
                icon={<FaPhoneAlt className="me-1 text-dark" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
            {purchaseOrderData?.cost_center_name && (
              <Details
                label={'Cost Center'}
                value={purchaseOrderData?.cost_center_name}
                className={'mb-2 fs--1'}
                icon={<FaCentercode className="me-1 text-warning" size={15} />}
              />
            )}
            {purchaseOrderData?.payment_term_name && (
              <Details
                label={'Payment Term'}
                value={purchaseOrderData?.payment_term_name}
                className={'mb-2 fs--1'}
                icon={<MdPayment className="me-1 text-success" size={15} />}
              />
            )}
            {purchaseOrderData?.warehouse_name && (
              <Details
                label={'Delivery Location'}
                value={purchaseOrderData?.warehouse_name}
                className={'mb-2 fs--1'}
                icon={<FaWarehouse className="me-1 text-dark" size={15} />}
              />
            )}
          </div>
        </Col>
        <Col lg={6} md={6} sm={12} className="p-0">
          <div className="mt-sm-2 mt-md-5">
            <Details
              label={
                purchaseOrderData?.tax_included &&
                purchaseOrderData?.tax_included === 1
                  ? `Amount (Inc.${taxLabel})`
                  : 'Amount'
              }
              value={purchaseOrderData?.amount_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={`Total ${taxLabel}`}
              value={purchaseOrderData?.total_tax_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={'Total Discount'}
              value={
                purchaseOrderData?.total_discount_amount_formatted ?? '0.00'
              }
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={12} md={12} sm={12} className="p-0 mt-2">
          <EntriesPopUpLineItemAccordionView
            type="PO"
            entries={purchaseOrderData.details}
          />
        </Col>
      </Row>
      {purchaseOrderData?.memo && <ShowMemo memo={purchaseOrderData?.memo} />}
    </Container>
  );
}

PurchaseOrderView.propTypes = {
  purchaseOrderData: PropTypes.any
};

export default PurchaseOrderView;

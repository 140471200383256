import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { AiFillFileAdd } from 'react-icons/ai';
import PropTypes from 'prop-types';
import WorkOrderMaterialRequestsModalMaterialsEntryTable from './WorkOrderMaterialRequestsModalMaterialsEntryTable';
import axios from 'axios';
import removeRefData from 'helpers/removeRefData';
import setNewFormData from 'helpers/setNewFormData';
import { showToast } from 'module/Common/Toast/toast';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import ListLoading from 'module/Common/Loading/ListLoading';

export default function WorkOrderMaterialRequestsModal({
  onHide,
  workOrderId,
  workOrderDetailId,
  setTabApiCallCompleted,
  WorkOrderDetailsArray,
  workOrderItem
}) {
  const Translate = useAxisproTranslate();
  const formKeys = {
    trans_date: '',
    details: [],
    memo: ''
  };

  const handleFieldChange = (e, action) => {
    let newFormData = generateFormDataHelper(e, action, formData);
    setFormData(newFormData);
  };

  const [formData, setFormData] = useState(formKeys);
  const [formError, setFormError] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [estimationItem, setEstimationItem] = useState({});
  const [loading, setLoading] = useState(false);

  function handleSubmit(event) {
    event.preventDefault();

    const findQuantity = (workOrderDetailItemQuantity, detailItemQuantity) => {
      const totalWorkOrderDetailsArrayQuantitySum =
        WorkOrderDetailsArray?.reduce(
          (sum, item) => (sum += parseFloat(item?.quantity) || 0),
          0
        );

      let quantity =
        ((parseFloat(workOrderDetailItemQuantity) || 0) /
          totalWorkOrderDetailsArrayQuantitySum) *
        (parseFloat(detailItemQuantity) || 0);

      return (Math.floor(quantity * 100) / 100).toFixed(2);
    };

    setFormError({});
    setIsSaving(true);

    let formDataWithoutRefKeys = {
      ...removeRefData(formData, 'object', {
        removeValue: [null, 'null']
      }),
      work_order_id: workOrderId
    };

    let formDataDetailsData;
    if (!workOrderDetailId) {
      formDataDetailsData = WorkOrderDetailsArray?.reduce((result, item) => {
        formData?.details?.forEach((detailItem, index) => {
          result.push({
            ...detailItem,
            material_request_line_item_total_quantity: detailItem?.quantity,
            quantity: findQuantity(item?.quantity, detailItem?.quantity),
            work_order_detail_id: item?.id,
            id_ref: index
          });
        });

        return result;
      }, []);

      formData?.details?.forEach((detailItem, index) => {
        const detailItemQuantity = parseFloat(detailItem?.quantity) || 0;
        const relatedEntries = formDataDetailsData?.filter(
          item => index === item?.id_ref
        );

        const sumOfRelatedEntriesQuantity = relatedEntries.reduce(
          (sum, item) => (sum += parseFloat(item?.quantity) || 0),
          0
        );

        const quantityDiff =
          Math.round((detailItemQuantity - sumOfRelatedEntriesQuantity) * 100) /
          100;

        if (quantityDiff > 0) {
          const firstResultItem = relatedEntries?.[0];
          if (firstResultItem) {
            const updatedQuantityWithQuantityDiff =
              (parseFloat(firstResultItem?.quantity) || 0) + quantityDiff;

            firstResultItem.quantity =
              Math.round(updatedQuantityWithQuantityDiff * 100) / 100;
          }
        }
      });
    } else {
      formDataDetailsData = [...(formData?.details || [])];
      formDataWithoutRefKeys.work_order_detail_id = workOrderDetailId;
    }

    formDataWithoutRefKeys.details = removeRefData(
      formDataDetailsData,
      'array'
    );
    let newFormData = setNewFormData(formDataWithoutRefKeys);
    axios
      .postForm('purchase/material-request', newFormData)
      .then(response => {
        if (response.data.success !== true) {
          showToast(
            'Something went wrong, please refresh the page and try again.',
            'error'
          );
          return;
        }
        showToast(response?.data?.message, 'success');
        setFormData(formKeys);
        typeof onHide === 'function' && onHide();
        typeof setTabApiCallCompleted === 'function' &&
          setTabApiCallCompleted(prev => ({
            ...prev,
            materialRequestes: false
          }));
      })
      .catch(error => {
        setFormError(error?.response?.data?.data?.errors ?? {});
        showToast(
          error?.response?.data?.message ??
            'Something went wrong, please refresh the page and try again.',
          'error'
        );
      })
      .finally(() => {
        setIsSaving(false);
      });
  }

  const compareArrays = (arr1, arr2) =>
    arr1.length === arr2.length && arr1.every((val, i) => val === arr2[i]);

  useEffect(() => {
    if (!workOrderItem?.estimation_id) return;

    const fetchEstimationData = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(
          `crm/estimation/${workOrderItem.estimation_id}`
        );
        if (!data.success) return;

        const matchedItems = data.data.details.filter(
          item =>
            item.stock_id === workOrderItem.stock_id &&
            compareArrays(
              [item.dim_length, item.dim_width, item.dim_height],
              workOrderItem.dimensions
            )
        );

        setEstimationItem(Object.assign({}, ...matchedItems)); // Merge matched items into one object
      } catch (error) {
        console.error('Estimation fetch error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEstimationData();

    return () => {
      setEstimationItem({});
    };
  }, [
    workOrderItem?.estimation_id,
    workOrderItem?.stock_id,
    workOrderItem?.dimensions
  ]);

  return (
    <Modal size="lg" show={true} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className="text-uppercase d-flex flex-row align-items-center gap-1 fw-bold">
          <AiFillFileAdd size={18} className="text-success" />
          Add Material Requests
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <ListLoading height={'46.8vh'} />
        ) : (
          <>
            {typeof formError === 'object' &&
              Object.keys(formError).length > 0 && (
                <ErrorAlert formError={formError} setFormError={setFormError} />
              )}
            <Form onSubmit={handleSubmit}>
              <Form.Group
                className="mb-4 d-flex flex-wrap gap-4"
                controlId="form.date"
              >
                <Form.Label className="require-data">Date</Form.Label>
                <div
                  className="flex-fill d-flex flex-row align-items-center"
                  style={{
                    maxWidth: 300
                  }}
                >
                  <div className="flex-fill">
                    <AppDatePicker
                      name="trans_date"
                      value={formData.trans_date}
                      yearPlaceholder="yyyy"
                      monthPlaceholder="mm"
                      dayPlaceholder="dd"
                      onChange={handleFieldChange}
                      showDefaultDate
                    />
                  </div>
                  <FormErrorPopover
                    id="trans_date"
                    totalErrorCount={Object.keys(formError).length}
                    errorMessage={formError?.trans_date}
                    addSpace
                  />
                </div>
              </Form.Group>

              <WorkOrderMaterialRequestsModalMaterialsEntryTable
                setFormData={setFormData}
                materials={formData.details}
                estimationMaterialCost={estimationItem?.costs}
              />

              <Form.Group className="mb-3" controlId="memo">
                <Form.Label>{Translate('Notes')}</Form.Label>
                <div className="d-flex flex-row align-items-center">
                  <div className="flex-fill">
                    <Form.Control
                      type="text"
                      as="textarea"
                      name="memo"
                      rows={5}
                      onChange={handleFieldChange}
                      value={formData.memo}
                    />
                  </div>
                  <FormErrorPopover
                    id="memo"
                    totalErrorCount={Object.keys(formError).length}
                    errorMessage={formError.memo}
                  />
                </div>
              </Form.Group>
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!isSaving ? (
          <Button variant="danger" size="sm" onClick={onHide}>
            Cancel
          </Button>
        ) : null}
        {!loading ? (
          <Button
            variant="success"
            size="sm"
            onClick={handleSubmit}
            disabled={isSaving}
            className="d-flex flex-row gap-1 align-items-center justify-content-center"
          >
            {isSaving ? (
              <>
                <Spinner
                  style={{
                    width: 15,
                    height: 15
                  }}
                />{' '}
                Saving
              </>
            ) : (
              'Save'
            )}
          </Button>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
}

WorkOrderMaterialRequestsModal.propTypes = {
  onHide: PropTypes.func,
  workOrderId: PropTypes.string,
  workOrderDetailId: PropTypes.string,
  setTabApiCallCompleted: PropTypes.func,
  WorkOrderDetailsArray: PropTypes.array,
  workOrderItem: PropTypes.object
};

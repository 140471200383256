import { React, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { BsCoin } from 'react-icons/bs';
import { FaCentercode, FaWarehouse } from 'react-icons/fa';
import Details from '../Common/ViewPageDetails/Details';
import Badges from '../Common/ViewPageDetails/Badges';
import ShowMemo from '../Common/ViewPageDetails/ShowMemo';
import EntriesPopUpLineItemAccordionView from 'components/entries-pop-up-form/EntriesPopUpLineItemAccordionView/EntriesPopUpLineItemAccordionView';
import { StoreContext } from 'context/Context';

function PurchaseRequestView({ purchaseRequestData }) {
  const { store } = useContext(StoreContext);
  const taxLabel = store?.globalConfiguration?.tax_label || 'Tax';
  return (
    <Container className="mt-3 ps-3 pe-3 voucher-inquiry-view-wrapper">
      <Row>
        <Col lg={6} md={6} sm={12} className="p-0">
          {purchaseRequestData?.reference && (
            <Badges
              label={purchaseRequestData?.reference}
              bg={'danger'}
              className={''}
            />
          )}
          <div className="mt-md-2">
            {purchaseRequestData?.cost_center_name && (
              <Details
                label={'Cost Center'}
                value={purchaseRequestData?.cost_center_name}
                className={'mb-2 fs--1'}
                icon={<FaCentercode className="me-1 text-warning" size={15} />}
              />
            )}
            {purchaseRequestData?.warehouse_name && (
              <Details
                label={'Delivery Location'}
                value={purchaseRequestData?.warehouse_name}
                className={'mb-2 fs--1'}
                icon={<FaWarehouse className="me-1 text-dark" size={15} />}
              />
            )}
          </div>
        </Col>
        <Col lg={6} md={6} sm={12} className="p-0">
          <div className="mt-sm-2 mt-md-5">
            <Details
              label={
                purchaseRequestData?.tax_included &&
                purchaseRequestData?.tax_included === '1'
                  ? `Amount (Inc.${taxLabel})`
                  : 'Amount'
              }
              value={purchaseRequestData?.amount_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={`Total ${taxLabel}`}
              value={purchaseRequestData?.total_tax_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={'Total Discount'}
              value={
                purchaseRequestData?.total_discount_amount_formatted ?? '0.00'
              }
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={12} md={12} sm={12} className="p-0 mt-2">
          <EntriesPopUpLineItemAccordionView
            type="PR"
            entries={purchaseRequestData.details}
          />
        </Col>
      </Row>
      {purchaseRequestData?.memo && (
        <ShowMemo memo={purchaseRequestData?.memo} />
      )}
    </Container>
  );
}

PurchaseRequestView.propTypes = {
  purchaseRequestData: PropTypes.any
};

export default PurchaseRequestView;

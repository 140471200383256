import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import SelectCustomer from 'components/form/SelectCustomer';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import { useEffect } from 'react';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import SelectItem from 'components/form/SelectItem';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

export default function ChatRequiredParamsList({
  requiredParams,
  lastQuestion,
  onSubmit,
  details
}) {
  const [formData, setFormData] = useState({});
  const Translate = useAxisproTranslate();
  function handleFieldChange(e, action) {
    let newFormData = generateFormDataHelper(e, action, formData);
    setFormData(newFormData);
  }

  function handleSubmitForm(e, type) {
    e?.preventDefault();
    let newFormData = { ...formData, confirmation: type };

    if (type === 'No') {
      return onSubmit(newFormData);
    }

    if (type === 'Yes') {
      const isValid =
        requiredParams.filter(
          key =>
            key !== 'confirmation' &&
            (!newFormData[key] || newFormData[key]?.length < 1)
        ).length === 0;

      if (isValid) {
        onSubmit(newFormData);
      }
    }
  }

  useEffect(() => {
    if (Array.isArray(requiredParams)) {
      requiredParams.forEach(key => {
        if (typeof key === 'string') {
          setFormData(prev => ({
            ...prev,
            [key]: undefined
          }));
        }
      });
    }
    return () => {
      setFormData({});
    };
  }, [requiredParams]);

  return Array.isArray(requiredParams) && requiredParams.length > 0 ? (
    <>
      <p className="small-message">
        {Translate('  AcoBot needs this information to help you.')}
      </p>
      <div className="chat-initial-helpers">
        <div className="helper-item text-capitalize">
          <Form>
            {requiredParams.includes('customer_id') ? (
              <Form.Group className="form-item-group">
                <p>{Translate('Customer')}</p>
                {details?.submitted || !lastQuestion ? (
                  <p className="form-data">
                    {details?.params?.customer_id_ref?.label ?? '-'}
                  </p>
                ) : (
                  <p className="mt-2 form-input">
                    <SelectCustomer
                      value={formData.customer_id_ref}
                      name="customer_id"
                      handleFieldChange={handleFieldChange}
                      style={{ borderRadius: 'none' }}
                      classNamePrefix="chatbot-input-item"
                    />
                  </p>
                )}
              </Form.Group>
            ) : null}
            {requiredParams.includes('stock_id') ? (
              <Form.Group className="form-item-group">
                <p>{Translate('Item')}</p>
                {details?.submitted || !lastQuestion ? (
                  <p className="form-data">
                    {details?.params?.stock_id_ref?.label ?? '-'}
                  </p>
                ) : (
                  <p className="mt-2 form-input">
                    <SelectItem
                      name="stock_id"
                      keyName="stock_id"
                      style={{ borderRadius: 'none' }}
                      classNamePrefix="chatbot-input-item"
                      filter_keys={{
                        exclude_kits: true,
                        product_types: 'goods'
                      }}
                      value={formData.stock_id_ref}
                      handleFieldChange={handleFieldChange}
                    />
                  </p>
                )}
              </Form.Group>
            ) : null}
            {requiredParams.includes('unit_price') ? (
              <Form.Group className="form-item-group">
                <p>{Translate('Unit Price')}</p>
                {details?.submitted || !lastQuestion ? (
                  <p className="form-data">
                    {details?.params?.unit_price ?? '-'}
                  </p>
                ) : (
                  <p className="mt-2 form-input">
                    <Form.Control
                      type="number"
                      name="unit_price"
                      value={formData.unit_price}
                      onChange={handleFieldChange}
                    />
                  </p>
                )}
              </Form.Group>
            ) : null}
            {requiredParams.includes('quantity') ? (
              <Form.Group className="form-item-group">
                <p>{Translate('Quantity')}</p>
                {details?.submitted || !lastQuestion ? (
                  <p className="form-data">
                    {details?.params?.quantity ?? '-'}
                  </p>
                ) : (
                  <p className="mt-2 form-input">
                    <Form.Control
                      type="number"
                      name="quantity"
                      value={formData.quantity}
                      onChange={handleFieldChange}
                    />
                  </p>
                )}
              </Form.Group>
            ) : null}
            {requiredParams.includes('trans_date') ? (
              <Form.Group className="form-item-group">
                <p>{Translate('Trans Date')}</p>
                {details?.submitted || !lastQuestion ? (
                  <p className="form-data">
                    {details?.params?.trans_date ?? '-'}
                  </p>
                ) : (
                  <p className="mt-2 form-input">
                    <AppDatePicker
                      name="trans_date"
                      value={formData.trans_date}
                      yearPlaceholder="yyyy"
                      monthPlaceholder="mm"
                      dayPlaceholder="dd"
                      onChange={handleFieldChange}
                    />
                  </p>
                )}
              </Form.Group>
            ) : null}
            {requiredParams.includes('from_date') ? (
              <Form.Group className="form-item-group">
                <p>{Translate('From Date')}</p>
                {details?.submitted || !lastQuestion ? (
                  <p className="form-data">
                    {details?.params?.from_date ?? '-'}
                  </p>
                ) : (
                  <p className="mt-2 form-input">
                    <AppDatePicker
                      name="from_date"
                      value={formData.from_date}
                      yearPlaceholder="yyyy"
                      monthPlaceholder="mm"
                      dayPlaceholder="dd"
                      onChange={handleFieldChange}
                    />
                  </p>
                )}
              </Form.Group>
            ) : null}
            {requiredParams.includes('to_date') ? (
              <Form.Group className="form-item-group">
                <p>{Translate('To Date')}</p>
                {details?.submitted || !lastQuestion ? (
                  <p className="form-data">{details?.params?.to_date ?? '-'}</p>
                ) : (
                  <p className="mt-2 form-input">
                    <AppDatePicker
                      name="to_date"
                      value={formData.to_date}
                      yearPlaceholder="yyyy"
                      monthPlaceholder="mm"
                      dayPlaceholder="dd"
                      onChange={handleFieldChange}
                    />
                  </p>
                )}
              </Form.Group>
            ) : null}
            {requiredParams.includes('confirmation') ? (
              <div className="confirmation-box">
                <div className="description ps-0">
                  {Translate(`To proceed. Confirmation must be 'yes'`)}
                </div>
                <div className="d-flex align-items-center justify-content-start confirm-buttons">
                  {!details?.submitted && lastQuestion && (
                    <>
                      <button
                        className="confirm-button"
                        onClick={e => handleSubmitForm(e, 'No')}
                      >
                        {Translate('No')}
                      </button>
                      <button
                        className="confirm-button active-color"
                        onClick={e => handleSubmitForm(e, 'Yes')}
                      >
                        {Translate('Yes')}
                      </button>
                    </>
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
          </Form>
        </div>
      </div>
    </>
  ) : null;
}

ChatRequiredParamsList.propTypes = {
  requiredParams: PropTypes.array,
  lastQuestion: PropTypes.bool,
  onSubmit: PropTypes.func,
  details: PropTypes.object
};

import ItemCodeAutoComplete from 'components/form/ItemCodeAutoComplete';
import SelectItem from 'components/form/SelectItem';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React, { useState } from 'react';
import { Button, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { MdAddCircle } from 'react-icons/md';
import PropTypes from 'prop-types';
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaTimesCircle
} from 'react-icons/fa';
import { showToast } from 'module/Common/Toast/toast';

export default function WorkOrderMaterialRequestsModalMaterialsEntryTableForm({
  setFormData,
  editItemData,
  ItemIndex,
  estimationMaterialCost = []
}) {
  const Translate = useAxisproTranslate();
  const [show, setShow] = useState(false);
  const tableFormKeys = {
    stock_id: '',
    item_display_name: '',
    item_display_name_ref: null,
    quantity: '',
    unit_price: 0
  };
  const [estimatedQty, setEstimatedQty] = useState(0);

  const [tableFormData, setTableFormData] = useState(
    editItemData ?? tableFormKeys
  );

  const getEstimatedQty = stkId => {
    let estQuantity = 0;
    if (estimationMaterialCost?.length > 0 && stkId) {
      estimationMaterialCost.forEach(item => {
        if (item?.stock_id === stkId) {
          estQuantity += item?.quantity || 0;
        }
      });
    }
    return estQuantity;
  };

  const handlePopover = (quantity, estQuantity) => {
    setShow(!isNaN(quantity) && quantity !== parseFloat(estQuantity));
    setEstimatedQty(estQuantity);
  };

  function handleFieldChange(e, action) {
    let newFormData = generateFormDataHelper(e, action, tableFormData);
    if (action?.name === 'item_display_name') {
      newFormData.quantity =
        newFormData.quantity > 0 ? newFormData.quantity : 1;
      newFormData.stock_id = e?.item?.stock_id ?? '';
      handlePopover(
        parseFloat(newFormData.quantity),
        getEstimatedQty(newFormData.stock_id)
      );
    }

    if (e?.target?.name === 'quantity') {
      handlePopover(
        parseFloat(newFormData.quantity),
        getEstimatedQty(newFormData.stock_id)
      );
    }

    setTableFormData(newFormData);
  }

  function handleCancelEdit(event) {
    event.preventDefault();
    setFormData(prev => ({
      ...prev,
      details: Array.isArray(prev?.details)
        ? prev.details.map(entry => {
            delete entry?.edit_ref;
            return entry;
          })
        : prev?.details
    }));
  }

  function onSubmit(event) {
    event.preventDefault();
    if (!tableFormData?.stock_id?.length) {
      showToast('Invalid item code', 'error');
      return;
    }

    if (!tableFormData?.item_display_name?.length) {
      showToast('Invalid item', 'error');
      return;
    }

    if (
      isNaN(parseFloat(tableFormData?.quantity)) ||
      !(tableFormData.quantity > 0)
    ) {
      showToast(
        Translate('Invalid quantity, value must be greater than 0'),
        'error'
      );
      return;
    }

    setFormData(prev => ({
      ...prev,
      details: editItemData
        ? Array.isArray(prev?.details)
          ? prev.details.map((item, index) => {
              if (index === ItemIndex) {
                delete tableFormData?.edit_ref;
                return tableFormData;
              }
              return item;
            })
          : prev?.details
        : Array.isArray(prev?.details)
        ? [...prev.details, tableFormData]
        : [tableFormData]
    }));
    setTableFormData(tableFormKeys);
    setShow(false);
  }

  const quantityPopover = (
    <Popover id="popover-contained">
      <Popover.Body className="d-flex">
        <FaExclamationCircle size={20} color="#FFAF2D" className="me-1" />
        <span>
          The entered value is different from the estimated quantity of
          {` ${estimatedQty}`}.
        </span>
      </Popover.Body>
    </Popover>
  );

  return (
    <tr className="form-items">
      <td>#</td>
      <td>
        <div className="form-item">
          <ItemCodeAutoComplete
            type="text"
            name="stock_id"
            keyName="item_name"
            SelectItemKeyName="item_display_name"
            value={tableFormData?.stock_id ?? ''}
            onChange={handleFieldChange}
            filter_keys={{
              exclude_kits: true,
              product_types: 'goods'
            }}
          />
        </div>
      </td>
      <td>
        <div className="form-item item-select-cover">
          <SelectItem
            name="item_display_name"
            keyName="item_name"
            advanceSearch
            advanceSearchType="purchase"
            placeholder={Translate('Search...')}
            style={{ borderRadius: 'none' }}
            filter_keys={{
              exclude_kits: true,
              product_types: 'goods'
            }}
            value={tableFormData?.item_display_name_ref}
            handleFieldChange={handleFieldChange}
          />
        </div>
      </td>
      <td>
        <div className="form-item">
          <OverlayTrigger
            show={show && tableFormData?.stock_id && estimatedQty !== 0}
            placement="bottom"
            overlay={quantityPopover}
          >
            <Form.Control
              type="number"
              name="quantity"
              min={1}
              value={tableFormData?.quantity ?? ''}
              onChange={handleFieldChange}
            />
          </OverlayTrigger>
        </div>
      </td>
      <td>
        <div className="d-flex gap-1 align-items-center justify-content-center">
          <Button
            variant="transparent"
            type="button"
            className="p-0 text-success"
            style={{ boxShadow: 'none' }}
            onClick={onSubmit}
          >
            {editItemData ? (
              <FaCheckCircle size={18} />
            ) : (
              <MdAddCircle size={20} />
            )}
          </Button>
          {editItemData ? (
            <Button
              variant="transparent"
              type="button"
              className="p-0 text-danger"
              style={{ boxShadow: 'none' }}
              onClick={handleCancelEdit}
            >
              <FaTimesCircle size={18} />
            </Button>
          ) : null}
        </div>
      </td>
    </tr>
  );
}

WorkOrderMaterialRequestsModalMaterialsEntryTableForm.propTypes = {
  setFormData: PropTypes.func,
  editItemData: PropTypes.object,
  ItemIndex: PropTypes.number,
  estimationMaterialCost: PropTypes.array
};

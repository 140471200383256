import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { FcContacts } from 'react-icons/fc';
import PropTypes from 'prop-types';
import PhoneNumberInput from '../PhoneNumberInput';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import axios from 'axios';
import { showToast } from 'module/Common/Toast/toast';
import useFormTabNavigator from 'hooks/useFormTabNavigator ';

const SupplierContactPopupForm = ({
  showPopupFormData,
  handleShow,
  salesmanId,
  getContactsData,
  onSave,
  setOnSave
}) => {
  const Translate = useAxisproTranslate();
  const defaultFormKeys = useMemo(
    () => ({
      salesman_id: salesmanId,
      name: '',
      email_address: '',
      contact_number: '',
      address: ''
    }),
    [salesmanId]
  );
  const [formData, setFormData] = useState(defaultFormKeys);
  const [formError, setFormError] = useState({});
  const isEdit = showPopupFormData?.editData ? true : false;

  const handleFieldChange = (e, action) => {
    setFormData(generateFormDataHelper(e, action, formData));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);
    const method = isEdit ? 'put' : 'post';
    try {
      const response = await axios[method](
        `sales/salesman-contacts${isEdit ? '/' + formData.id : ''}`,
        formData
      );
      if (response?.data?.success) {
        showToast(response?.data?.message, 'success');
        getContactsData(isEdit ? false : true);
        handleShow(false);
        setFormData(defaultFormKeys);
      } else {
        showToast(
          'Something went wrong, please refresh the page and try again.',
          'error'
        );
      }
    } catch (error) {
      const errorData = error?.response?.data;
      if (errorData?.message) {
        const validation_error = errorData?.data?.validation_error
          ? errorData.data.validation_error
          : null;
        validation_error && setFormError({ ...validation_error });
      } else {
        showToast(
          'Something went wrong, please refresh the page and try again.',
          'error'
        );
      }
    } finally {
      setOnSave(false);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setFormData(prev => ({
        ...prev,
        salesman_id: salesmanId,
        ...showPopupFormData?.editData
      }));
    } else {
      setFormData(defaultFormKeys);
    }
  }, [isEdit, salesmanId, showPopupFormData?.editData, defaultFormKeys]);

  useFormTabNavigator('form-tab-switch-control', []);

  return (
    <Modal
      show={showPopupFormData?.show}
      onHide={handleShow}
      backdrop="static"
      keyboard={false}
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title className="d-flex flex-row align-items-center">
            <FcContacts size={23} className="text-success" />
            <span className="ms-1">
              {Translate(isEdit ? 'EDIT CONTACT' : 'ADD CONTACT')}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-2" controlId="form.name">
            <Form.Label column sm={12} className="require-data p-0">
              {Translate('Name')}
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              className="form-tab-switch-control"
              value={formData?.name}
              onChange={handleFieldChange}
              isInvalid={!!formError?.name}
            />
            <Form.Control.Feedback type="invalid">
              {formError?.name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-2" controlId="form.contact_number">
            <Form.Label column sm={12} className="require-data p-0">
              {Translate('Contact Number')}
            </Form.Label>

            <PhoneNumberInput
              name="contact_number"
              onChange={handleFieldChange}
              className="form-tab-switch-control"
              value={formData?.contact_number}
              isInvalid={formError?.contact_number}
            />
            <Form.Control.Feedback type="invalid">
              {formError.contact_number}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-2" controlId="form.email_address">
            <Form.Label column sm={12} className="require-data p-0">
              {Translate('Email')}
            </Form.Label>
            <Form.Control
              type="email"
              className="form-tab-switch-control"
              name="email_address"
              value={formData?.email_address}
              onChange={handleFieldChange}
              isInvalid={!!formError?.email_address}
            />
            <Form.Control.Feedback type="invalid">
              {formError.email_address}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-2" controlId="form.address">
            <Form.Label column sm={12} className="require-data p-0">
              {Translate('Address')}
            </Form.Label>
            <Form.Control
              as="textarea"
              name="address"
              className="form-tab-switch-control"
              rows={4}
              onChange={handleFieldChange}
              value={formData?.address}
              isInvalid={!!formError?.address}
            />
            <Form.Control.Feedback type="invalid">
              {formError.address}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={`${isEdit ? 'primary' : 'success'}`}
            size="sm"
            className="form-tab-switch-control d-flex align-items-center justify-content-center"
            onClick={handleSubmit}
            disabled={onSave}
          >
            {onSave ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  variant="success"
                  className="me-1"
                />
                <span className="fs--1">
                  {Translate(isEdit ? 'Updating...' : 'Adding...')}
                </span>
              </>
            ) : (
              <span>{Translate(isEdit ? 'Update' : 'Add')}</span>
            )}
          </Button>
          <Button
            variant="danger"
            onClick={() => handleShow(false)}
            size="sm"
            disabled={onSave}
            className="form-tab-switch-control"
          >
            {Translate('Cancel')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

SupplierContactPopupForm.propTypes = {
  showPopupFormData: PropTypes.any,
  handleShow: PropTypes.func,
  salesmanId: PropTypes.string,
  getContactsData: PropTypes.func,
  setOnSave: PropTypes.bool,
  onSave: PropTypes.bool
};

export default SupplierContactPopupForm;

import {
  purchaseDebitNoteFormKeys,
  purchaseDeliveryFormKeys,
  purchaseInvoiceFormKeys,
  purchaseOrderFormKeys,
  purchaseRequestFormKeys
} from 'helpers/formKeys';

const useFormModuleDefaultFromData = {
  PR: purchaseRequestFormKeys,
  PO: purchaseOrderFormKeys,
  PD: purchaseDeliveryFormKeys,
  PI: purchaseInvoiceFormKeys,
  DN: purchaseDebitNoteFormKeys
};

export default useFormModuleDefaultFromData;

import { React, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { RiRefund2Fill } from 'react-icons/ri';

import { AuthWizardContext } from 'context/Context';
import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import SelectBankAccount from 'components/form/SelectBankAccount';
import ErrorAlert from 'module/Common/Error/ErrorAlert';

function ReFundPopup({ show, onHide, fetchData, popupData }) {
  const Translate = useAxisproTranslate();
  const { user } = useContext(AuthWizardContext);
  const [formData, setFormData] = useState({
    trans_date: ''
  });
  const [formError, setFormError] = useState({});
  const [saving, setSaving] = useState(false);

  const stringifiedPopupData = JSON.stringify(popupData);

  useEffect(() => {
    const parsedPopupData = JSON.parse(stringifiedPopupData);
    const fromBankAccount = user?.branch ?? '';
    setFormData(prev => ({
      ...prev,
      person_id: parsedPopupData.personId,
      person_type: parsedPopupData.personType,
      amount: parsedPopupData.refundAmount,
      payment_mode: 'cash',
      trans_type: parsedPopupData.transType,
      trans_no: parsedPopupData.transNo,
      cost_center_id: parsedPopupData?.costCenterID ?? '',
      from_bank_account_id: fromBankAccount?.bank_account_id ?? '',
      from_bank_account_id_ref: {
        label: fromBankAccount?.bank_name ?? '',
        value: fromBankAccount?.bank_account_id ?? ''
      }
    }));
  }, [stringifiedPopupData, user?.branch]);

  const handleFieldChange = e => {
    let value = null;
    if (e.target.name == 'amount') {
      if (parseFloat(e.target.value) > parseFloat(popupData.refundAmount)) {
        showToast(
          'Amount should be less than or equal to balance amount',
          'error'
        );
        value = popupData.refundAmount;
      } else {
        value = e.target.value;
      }
    } else if (e.target.name === 'trans_date') {
      value = e.target.value;
    } else {
      value = e.target.value;
    }
    setFormData({
      ...formData,
      [e.target.name]: value
    });
  };

  const handleBankAccount = (valueObject, action) => {
    let bankAccountId =
      valueObject && valueObject.value ? valueObject.value : null;
    if (bankAccountId && action?.action) {
      setFormData(prev => ({
        ...prev,
        from_bank_account_id: bankAccountId,
        from_bank_account_id_ref: valueObject
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        from_bank_account_id: '',
        from_bank_account_id_ref: ''
      }));
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setFormError({});
    setSaving(true);
    axios({
      method: 'post',
      url: 'finance/refunds',
      data: formData
    })
      .then(response => {
        if (response.data.success === true) {
          fetchData();

          showToast(response.data.message, 'success');
          onHide();
          const fromBankAccount = user?.branch ?? '';
          setFormData({
            trans_date: '',
            person_id: popupData.personId,
            person_type: popupData.personType,
            trans_type: popupData.transType,
            cost_center_id: popupData?.costCenterID ?? '',
            amount:
              parseFloat(popupData.refundAmount) -
              parseFloat(response.data.data.amount),
            payment_mode: 'cash',
            trans_no: popupData.transNo,
            from_bank_account_id: fromBankAccount?.bank_account_id ?? '',
            from_bank_account_id_ref: {
              label: fromBankAccount?.bank_name ?? '',
              value: fromBankAccount?.bank_account_id ?? ''
            }
          });
        } else {
          showToast(
            'Something went wrong, please refresh the page and try again.',
            'error'
          );
        }
        setSaving(false);
      })
      .catch(error => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            'Something went wrong, please refresh the page and try again.',
            'error'
          );
        }
        setSaving(false);
      });
  };

  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      className="modal-with-overlay"
      backdrop="static"
    >
      <Card className="mt-2">
        <Card.Header className="fs-1 p-2 text-dark d-flex align-items-center border-bottom">
          <RiRefund2Fill size={20} className="me-2" />
          {Translate('Refund')} {popupData.reference}
        </Card.Header>
        <Form onSubmit={handleSubmit}>
          <Card.Body className="pt-1">
            <div className="p-2 ps-0 d-flex align-items-center">
              {Translate('Total Refundable Amount')} &nbsp;: &nbsp;
              <span className="fs-1 fw-bold text-warning">
                {popupData?.refundAmountFormatted}
              </span>
            </div>
            {!Object.keys(formError).length == 0 ? (
              <ErrorAlert setFormError={setFormError} formError={formError} />
            ) : (
              ''
            )}
            <Form.Group as={Row} className="mt-2">
              <Col xs={6}>
                <Form.Label className="require-data">
                  {Translate('Refund Date')}
                </Form.Label>
                <AppDatePicker
                  name="trans_date"
                  value={formData.trans_date}
                  yearPlaceholder="yyyy"
                  monthPlaceholder="mm"
                  dayPlaceholder="dd"
                  onChange={handleFieldChange}
                  isInvalid={!!formError.trans_date}
                  showDefaultDate
                />
              </Col>
              <Col xs={6}>
                <Form.Label>{Translate('Person Name')}</Form.Label>
                <Form.Control disabled value={popupData.personName} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mt-2">
              <Col xs={6}>
                <Form.Label className="require-data">
                  {Translate('Refund Amount')}
                </Form.Label>
                <Form.Control
                  type="number"
                  name="amount"
                  value={formData?.amount}
                  onChange={handleFieldChange}
                  isInvalid={!!formError.amount}
                />
              </Col>
              <Col xs={6}>
                <Form.Label>{Translate('Payment Method')}</Form.Label>
                <Form.Select
                  name="payment_mode"
                  value={formData.payment_mode}
                  onChange={handleFieldChange}
                  isInvalid={!!formError.payment_mode}
                >
                  <option value="cash">{Translate('Cash')}</option>
                  <option value="card">{Translate('Card')}</option>
                  <option value="bank">{Translate('Bank Transfer')}</option>
                  <option value="cheque">{Translate('Cheque')}</option>
                </Form.Select>
              </Col>
            </Form.Group>
            {formData.payment_mode == 'cheque' ? (
              <Form.Group as={Row} className="mt-2">
                <Col xs={12}>
                  <Form.Label className="require-data">
                    {Translate('Cheque Number')}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="cheque_number"
                    value={formData.cheque_number}
                    onChange={handleFieldChange}
                    isInvalid={!!formError.cheque_number}
                  />
                </Col>
              </Form.Group>
            ) : (
              ''
            )}
            <Form.Group as={Row} className="mt-2">
              <Col xs={12}>
                <Form.Label className="require-data">
                  {Translate('Pay Form')}
                </Form.Label>
                <SelectBankAccount
                  value={formData.from_bank_account_id_ref}
                  error={formError.from_bank_account_id}
                  handleFieldChange={handleBankAccount}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mt-2">
              <Col xs={12}>
                <Form.Label>{Translate('Notes')}</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  rows={3}
                  name="memo"
                  value={formData.memo}
                  onChange={handleFieldChange}
                />
              </Col>
            </Form.Group>
          </Card.Body>
          <Card.Footer className="border-top d-flex gap-2 p-2">
            <Button
              variant="success"
              style={{ boxShadow: 'none' }}
              type="submit"
              size="sm"
              disabled={saving}
            >
              {saving ? Translate('Refunding...') : Translate('Refund')}
            </Button>
            <Button
              variant="danger"
              style={{ boxShadow: 'none' }}
              type="button"
              size="sm"
              onClick={onHide}
              disabled={saving}
            >
              {Translate('Cancel')}
            </Button>
          </Card.Footer>
        </Form>
      </Card>
    </Modal>
  );
}

ReFundPopup.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  popupData: PropTypes.object,
  fetchData: PropTypes.func
};

export default ReFundPopup;

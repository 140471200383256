import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Accordion, Badge, Button, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { FaEdit } from 'react-icons/fa';
import { FaTrash } from 'react-icons/fa6';
import { DeleteContext } from 'context/Context';
import EstimationLineItemsAccordionViewCostItem from 'module/Production/Estimation/components/estimation-lineItems-accordion-view/EstimationLineItemsAccordionViewCostItem';
import EntriesPopUpLineItemAccordionViewTitleSection from './EntriesPopUpLineItemAccordionViewTitleSection';
import EntriesPopUpLineItemAccordionViewItemDescriptionSection from './EntriesPopUpLineItemAccordionViewItemDescriptionSection';
import ItemDetails from 'components/item-details/ItemDetails';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import VoucherDetail from 'components/voucher-details/VoucherDetail';

export default function EntriesPopUpLineItemAccordionView({
  type,
  entries,
  setFormData,
  handleSaveDraftData,
  taxIncluded,
  additionalCostIncluded,
  enableTaxGroup,
  enableBatchSection,
  batchIsEditable,
  batchEnabledItemFound,
  keyName
}) {
  const Translate = useAxisproTranslate();
  const { dispatch: configConfirm } = useContext(DeleteContext);
  const permissions = {
    show_item: getAcodaxPermissionSlug('IT', 'show'),
    show_batch_number: getAcodaxPermissionSlug('BCH', 'show')
  };

  const showBatchSection = enableBatchSection && batchEnabledItemFound;
  const showQantityReceivedSection = Boolean(type === 'PO');

  let colSpan = [
    showBatchSection,
    enableTaxGroup,
    additionalCostIncluded,
    showQantityReceivedSection
  ].reduce(
    (colSpan, item) => {
      if (item) {
        colSpan += 1;
      }
      return colSpan;
    },
    additionalCostIncluded ? 11 : 10
  );

  const targetKeyName = keyName || 'details';
  const [activeItemId, setActiveItemId] = useState(null);
  const handleItemDetailsOffcanvas = (event, itemId) => {
    event?.preventDefault();
    setActiveItemId(itemId || null);
  };

  const [activeBatchNumber, setActiveBatchNumber] = useState(null);
  const handleBatchNumberDetailsOffcanvas = (event, batchNumber) => {
    event?.preventDefault();
    setActiveBatchNumber(batchNumber || null);
  };

  function handleEdit(event, ItemIndex) {
    event?.preventDefault();
    setFormData(prev => ({
      ...prev,
      [targetKeyName]: Array.isArray(prev?.[targetKeyName])
        ? prev[targetKeyName].map((entry, index) => {
            delete entry?.edit;
            if (index === ItemIndex) {
              entry.edit = true;
              entry.discount_amount = Number(entry.discount_amount) || '';
              entry.discount_percent = Number(entry.discount_percent) || '';
            }
            return entry;
          })
        : prev?.[targetKeyName]
    }));
  }

  function removeSelectedItem(dataArray, index) {
    if (Array.isArray(dataArray) && dataArray?.[index]) {
      dataArray.splice(index, 1);
    }
    return dataArray;
  }

  function handleRemove(event, ItemIndex) {
    event.preventDefault();
    configConfirm({
      type: 'CONFIG',
      payload: {
        show: true,
        title: Translate('Delete selected item'),
        message: Translate(
          'Are you sure you want to delete the selected line item?'
        ),
        submitButtonVariant: 'danger',
        buttonText: Translate('Delete'),
        cancelButtonVariant: 'secondary',
        onSubmit: event => {
          event?.preventDefault();

          setFormData(prev => {
            const newData = {
              ...prev,
              [targetKeyName]: Array.isArray(prev?.[targetKeyName])
                ? removeSelectedItem(prev[targetKeyName], ItemIndex)
                : prev?.[targetKeyName]
            };

            if (typeof handleSaveDraftData === 'function') {
              handleSaveDraftData({ draftableData: newData });
            }

            return newData;
          });

          configConfirm({
            type: 'RESET'
          });
        }
      }
    });
  }

  useEffect(() => {
    const scrollingAreas = document.querySelectorAll(
      '.entries-pop-up-line-item-accordion-view-table'
    );

    scrollingAreas.forEach(table => {
      const closestScrollContainer = table.closest(
        '.simplebar-content-wrapper'
      );
      const thead = table.getElementsByTagName('thead')?.[0];

      if (closestScrollContainer && thead) {
        thead.style.position = 'relative';
        thead.style.zIndex = '1';
        thead.style.transition = 'all .5 ease-in';

        const handleScroll = () => {
          const tableRect = table.getBoundingClientRect();
          const scrollContainerRect =
            closestScrollContainer.getBoundingClientRect();
          const effectPoint = tableRect.top - scrollContainerRect.top;

          if (effectPoint <= 0) {
            thead.style.top = `${Math.abs(effectPoint) - 1}px`;
          } else {
            thead.style.top = '0';
          }
        };

        handleScroll();
        closestScrollContainer.addEventListener('scroll', handleScroll);

        return () => {
          closestScrollContainer.removeEventListener('scroll', handleScroll);
        };
      }
    });
  }, []);

  return (
    <>
      <Accordion>
        <Table
          bordered
          responsive
          size="sm"
          className="simple-table-animation mb-0 entries-pop-up-line-item-accordion-view-table"
        >
          <thead
            className="bg-light text-dark text-uppercase shadow-sm"
            style={{
              fontSize: '.76rem'
            }}
          >
            <EntriesPopUpLineItemAccordionViewTitleSection
              type={type}
              taxIncluded={taxIncluded}
              additionalCostIncluded={additionalCostIncluded}
              enableTaxGroup={enableTaxGroup}
              enableBatchSection={showBatchSection}
              batchIsEditable={batchIsEditable}
              showQantityReceivedSection={showQantityReceivedSection}
            />
          </thead>
          <tbody
            style={{
              fontSize: '.72rem',
              backgroundColor: '#f2f5f7'
            }}
          >
            {entries?.map((entry, index) => (
              <Fragment key={index}>
                <tr className="text-muted fw-bold">
                  <td className="pt-2">
                    <Badge className="rounded">{index + 1}</Badge>
                  </td>
                  <td className="pt-2">
                    {permissions?.show_item && entry?.item_id ? (
                      <a
                        href="#"
                        className="text-muted"
                        style={{
                          fontSize: '.72rem'
                        }}
                        onClick={event =>
                          handleItemDetailsOffcanvas(event, entry.item_id)
                        }
                      >
                        {entry?.stock_id || '-'}
                      </a>
                    ) : (
                      entry?.stock_id || '-'
                    )}
                  </td>
                  <td className="pt-2">
                    {entry?.title_ref?.label || entry?.item_display_name || '-'}
                  </td>
                  <td className="pt-2">{entry?.quantity || '-'}</td>

                  {type === 'ESTIMATION' ? (
                    <>
                      <td className="pt-2">
                        {entry?.dim_length
                          ? `${entry.dim_length} ${entry?.dimension_unit || ''}`
                          : '-'}
                      </td>
                      <td className="pt-2">
                        {entry?.dim_width
                          ? `${entry.dim_width} ${entry?.dimension_unit || ''}`
                          : '-'}
                      </td>
                      <td className="pt-2">
                        {entry?.dim_height
                          ? `${entry.dim_height} ${entry?.dimension_unit || ''}`
                          : '-'}
                      </td>
                      <td className="pt-2">{entry?.weight || '-'}</td>
                    </>
                  ) : (
                    <>
                      {showQantityReceivedSection ? (
                        <td className="pt-2">{entry?.qty_sent || '-'}</td>
                      ) : null}

                      <td className="pt-2">
                        {entry?.unit_name ||
                          entry?.unit_name_ref ||
                          entry?.unit_ref ||
                          ''}
                      </td>
                      <td className="pt-2">
                        {entry?.unit_price_formatted || '-'}
                      </td>
                      {additionalCostIncluded ? (
                        <td className="pt-2">
                          {entry?.additional_cost_amount_formatted || '-'}
                        </td>
                      ) : null}
                      <td className="pt-2">
                        {entry?.unit_tax_formatted || '-'}
                      </td>
                      {additionalCostIncluded ? (
                        <td className="pt-2">
                          {entry?.additional_cost_tax_amount_formatted || '-'}
                        </td>
                      ) : null}
                      <td className="pt-2">
                        {entry?.discount_amount_formatted || '-'}
                      </td>
                      {enableTaxGroup ? (
                        <td className="pt-2">{entry?.tax_group_name || '-'}</td>
                      ) : null}

                      {showBatchSection ? (
                        <td className="pt-2">
                          {permissions?.show_batch_number &&
                          entry?.batch_number ? (
                            <a
                              href="#"
                              className="text-muted"
                              style={{
                                fontSize: '.72rem'
                              }}
                              onClick={event =>
                                handleBatchNumberDetailsOffcanvas(
                                  event,
                                  entry.batch_number
                                )
                              }
                            >
                              {entry?.batch_number_ref?.label ||
                                entry?.batch_number_display ||
                                '-'}
                            </a>
                          ) : (
                            entry?.batch_number_ref?.label ||
                            entry?.batch_number_display ||
                            '-'
                          )}
                        </td>
                      ) : null}
                    </>
                  )}

                  {type !== 'ESTIMATION' ? (
                    <td className="pt-2 border-end-0">
                      {entry?.line_total_formatted || '-'}
                    </td>
                  ) : null}

                  <td className="pt-2 border-start-0">
                    {typeof setFormData === 'function' ? (
                      <div className="d-flex flex-row align-items-center justify-content-end gap-1">
                        <Button
                          variant="transparent"
                          className="p-0 m-0 text-info outline-none shadow-none"
                          size="sm"
                          onClick={event => handleEdit(event, index)}
                        >
                          <FaEdit size={12} />
                        </Button>
                        <Button
                          variant="transparent"
                          className="p-0 m-0 text-danger outline-none shadow-none"
                          size="sm"
                          onClick={event => handleRemove(event, index)}
                        >
                          <FaTrash size={12} />
                        </Button>
                      </div>
                    ) : null}
                  </td>
                </tr>

                <EntriesPopUpLineItemAccordionViewItemDescriptionSection
                  title="ITEM DESCRIPTION"
                  eventKey={'entryLineItemDescEvent' + index}
                  description={entry?.item_description}
                  colSpan={colSpan}
                />

                {['ES', 'ESTIMATION'].includes(type) ? (
                  <>
                    <EstimationLineItemsAccordionViewCostItem
                      colSpan={colSpan}
                      title="MATERIALS / COSTS"
                      eventKey={'entryLineItemMaCostEvent' + index}
                      entries={entry?.costs}
                      subTotalFormatted={entry?.costs_net_amount_formatted}
                    />

                    <EstimationLineItemsAccordionViewCostItem
                      colSpan={colSpan}
                      title="ADDITIONAL COSTS"
                      eventKey={'entryLineItemAddCostEvent' + index}
                      entries={entry?.additional_costs}
                      subTotalFormatted={
                        entry?.additional_costs_net_amount_formatted
                      }
                    />
                  </>
                ) : null}

                <tr className="border-0">
                  <td
                    className="border-0 bg-white p-0 pt-1"
                    colSpan={colSpan}
                  ></td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </Table>
      </Accordion>

      {permissions?.show_item ? (
        <ItemDetails
          show={Boolean(activeItemId)}
          showItemDetailsArea={Boolean(activeItemId)}
          onHide={handleItemDetailsOffcanvas}
          value={{ value: activeItemId }}
        />
      ) : null}

      {permissions?.show_batch_number ? (
        <VoucherDetail
          show={Boolean(activeBatchNumber)}
          setShowVoucherDetailArea={() =>
            handleBatchNumberDetailsOffcanvas(null)
          }
          onHide={handleBatchNumberDetailsOffcanvas}
          type={'BCH'}
          voucherId={activeBatchNumber}
          offCanvas
        />
      ) : null}
    </>
  );
}

EntriesPopUpLineItemAccordionView.propTypes = {
  type: PropTypes.oneOf(['FIXED-ASSET', 'PR', 'PO', 'PI', 'PD']).isRequired,
  entries: PropTypes.array,
  setFormData: PropTypes.func,
  handleSaveDraftData: PropTypes.func,
  taxIncluded: PropTypes.bool,
  additionalCostIncluded: PropTypes.bool,
  enableTaxGroup: PropTypes.bool,
  enableBatchSection: PropTypes.bool,
  batchIsEditable: PropTypes.bool,
  batchEnabledItemFound: PropTypes.bool,
  keyName: PropTypes.string
};

const FilterOptions = {
  rental_contracts: {
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    date: [
      { name: 'contract_from', Heading: 'Contract From' },
      { name: 'contract_to', Heading: 'Contract To' }
    ],
    wareHouse: [
      {
        name: 'warehouse_id',
        Heading: 'Warehouse'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Asset',
        filterKey: {
          product_type: 'fixed_asset',
          include_fixed_asset: 1
        }
      }
    ],
    customSelect: [
      {
        name: 'rental_status',
        Heading: 'Rental Status',
        option: [
          {
            label: 'Returned',
            value: 'returned'
          },
          {
            label: 'Pending',
            value: 'pending'
          },
          {
            label: 'Rented Out',
            value: 'rented_out'
          }
        ]
      },
      {
        name: 'status',
        Heading: 'Status',
        option: [
          {
            label: 'Active',
            value: 'active'
          },
          {
            label: 'Hold',
            value: 'hold'
          },
          {
            label: 'Cancelled',
            value: 'cancelled'
          },
          {
            label: 'Closed',
            value: 'closed'
          }
        ]
      }
    ]
  },
  material_request_report: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  employee_allocation: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    dateAndTimePicker: [
      {
        name: 'issued_at',
        Heading: 'Issued At'
      },
      {
        name: 'returned_at',
        Heading: 'Returned At'
      }
    ],
    textBox: [{ name: 'allocation_id', Heading: 'Reference' }],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items',
        filterKey: {
          product_type: 'fixed_asset',
          include_fixed_asset: 1
        }
      }
    ],
    user: [
      {
        name: 'receiver_id',
        Heading: 'Employee'
      }
    ],
    searchEnd: true
  },
  employee_allocation_report: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items',
        filterKey: {
          product_type: 'fixed_asset',
          include_fixed_asset: 1
        }
      }
    ],
    date: [
      {
        name: 'issued_at',
        Heading: 'Issued At'
      },
      {
        name: 'returned_at',
        Heading: 'Returned At'
      }
    ],
    customSelect: [
      {
        name: 'status',
        Heading: 'Status',
        option: [
          {
            label: 'Returned',
            value: 'returned'
          },
          {
            label: 'Allocated',
            value: 'allocated'
          }
        ]
      }
    ],
    searchEnd: true
  },
  sales_invoice: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    customSelect: [
      {
        name: 'status',
        Heading: 'Status',
        isMulti: true,
        option: [
          { label: 'FULLY PAID', value: 'fully_paid' },
          {
            label: 'PARTIALLY PAID',
            value: 'partially_paid'
          },
          { label: 'UN PAID', value: 'un_paid' }
        ]
      }
    ],
    checkBox: [{ name: 'overdue', Heading: 'Overdue Invoices' }],
    searchEnd: true
  },
  sales_order: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  sales_delivery: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  sales_delivery_inter_branch: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer: [{ name: 'customer_id', Heading: 'Branch', intraBranch: true }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  supplier_list: {
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    textBox: [{ name: 'email', Heading: 'Email' }]
  },
  sales_quotation: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    textBox: [
      { name: 'reference', Heading: 'Reference' },
      { name: 'enquiry_reference', Heading: 'Enquiry Reference' }
    ]
  },
  credit_note: {
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  customer: {
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [
      {
        name: 'customer_id',
        Heading: 'Customer',
        customer_group_name: 'customer_group_id'
      }
    ],
    customSelect: [
      {
        name: 'status',
        Heading: 'Status',
        option: [
          {
            label: 'Active',
            value: '0'
          },
          {
            label: 'Inactive',
            value: '1'
          }
        ]
      },
      {
        name: 'customer_type',
        Heading: 'Customer Type',
        option: [
          {
            label: 'Individual',
            value: 'INDIVIDUAL'
          },
          {
            label: 'Company',
            value: 'COMPANY'
          }
        ]
      }
    ],
    textBox: [
      { name: 'email', Heading: 'Email' },
      { name: 'phone', Heading: 'Phone' }
    ]
  },
  category: {
    customSelect: [
      {
        name: 'product_type',
        Heading: 'Product Type',
        option: [
          { label: 'GOODS', value: 'goods' },
          {
            label: 'SERVICE',
            value: 'service'
          },
          { label: 'MANUFACTURED', value: 'manufactured' },
          { label: 'FIXED ASSET', value: 'fixed_asset' }
        ]
      }
    ],
    category: [
      {
        name: 'category_id',
        Heading: 'Category'
      }
    ],
    subCategory: [
      {
        name: 'sub_category_id',
        Heading: 'Sub Category Name'
      }
    ],
    account_code: [
      { name: 'sales_account_code', Heading: 'Sales Account' },
      { name: 'inventory_account_code', Heading: 'Inventory Account' },
      { name: 'purchase_account_code', Heading: 'Cost Account' }
    ],

    searchEnd: true
  },
  batch_number: {
    date: [
      { name: 'manufacture_date', Heading: 'Manufacture Date' },
      { name: 'expiry_date', Heading: 'Expiry Date' }
    ],
    textBox: [{ name: 'batch_number', Heading: 'Batch Number' }]
  },
  price_list: {
    item: [
      {
        name: 'stock_id',
        Heading: 'Items',
        filterKey: {
          include_fixed_asset: 1
        }
      }
    ],
    sales_type: [{ name: 'sales_type', Heading: 'Sales Type' }]
  },
  unit_list: { textBox: [{ name: 'name', Heading: 'Name' }] },
  document_type: { textBox: [{ name: 'name', Heading: 'Name' }] },
  brand_list: { textBox: [{ name: 'name', Heading: 'Name' }] },
  manufacture_list: { textBox: [{ name: 'name', Heading: 'Name' }] },
  finance_year: {
    date: [
      { name: 'begin_date', Heading: 'Begin Date' },
      { name: 'end_date', Heading: 'End Date' }
    ]
  },
  exchange_list: {
    date: [
      { name: 'date_from', Heading: 'Date From' },
      { name: 'date_to', Heading: 'Date To' }
    ],
    currency_code: [{ name: 'currency_code', Heading: 'Currency Code' }]
  },
  voucher_inquiry: {
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    voucher_trans: [{ name: 'trans_type', Heading: 'Trans Type' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    searchEnd: true
  },
  work_order: {
    dateRange: [
      {
        to: 'date_to',
        from: 'from_date',
        range: 'to_date',
        month: 'month'
      }
    ],
    customer: [{ name: 'customer', Heading: 'Customer' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  estimations: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer: [{ name: 'customer', Heading: 'Customer' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  enquiries: {
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [
      { name: 'reference', Heading: 'Reference' },
      { name: 'name', Heading: 'Enquiry From' },
      { name: 'phone', Heading: 'Phone' },
      { name: 'email', Heading: 'Email' }
    ],
    searchEnd: true
  },
  appointments: {
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month',
        Heading: 'Apointment Date'
      }
    ],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    textBox: [
      { name: 'phone', Heading: 'Customer Phone' },
      { name: 'email', Heading: 'Customer Email' }
    ],
    customSelect: [
      {
        name: 'status',
        Heading: 'Status',
        option: [
          {
            label: 'Completed',
            value: 'completed'
          },
          {
            label: 'Cancelled',
            value: 'cancelled'
          },
          {
            label: 'Pending',
            value: 'pending'
          },
          {
            label: 'Resheduled',
            value: 'resheduled'
          }
        ]
      }
    ]
  },
  sales_man: {
    salesman: [{ name: 'salesman_id', Heading: 'Salesman' }],
    textBox: [
      { name: 'email', Heading: 'Email' },
      { name: 'phone', Heading: 'Phone' }
    ]
  },
  contracts: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    date: [
      { name: 'contract_from', Heading: 'Contract From' },
      { name: 'contract_to', Heading: 'Contract To' }
    ],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    customSelect: [
      {
        name: 'status',
        Heading: 'Status',
        option: [
          { label: 'Active', value: 'active' },
          {
            label: 'Hold',
            value: 'hold'
          },
          { label: 'Cancelled', value: 'cancelled' },
          { label: 'Closed', value: 'closed' }
        ]
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    searchEnd: true
  },
  stock_kit: {
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    textBox: [{ name: 'query', Heading: 'Name/Kit Code' }]
  },
  stock_transfer: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    wareHouse: [
      {
        name: 'from',
        Heading: 'From'
      },
      {
        name: 'to',
        Heading: 'To'
      }
    ]
  },
  item_module: {
    item: [
      {
        name: 'stock_id',
        Heading: 'Items',
        filterKey: {
          include_fixed_asset: 1
        }
      }
    ],
    category: [
      {
        name: 'category_id',
        Heading: 'Category'
      }
    ],
    customSelect: [
      {
        name: 'product_type',
        Heading: 'Product Type',
        option: [
          { label: 'GOODS', value: 'goods' },
          {
            label: 'SERVICE',
            value: 'service'
          },
          { label: 'MANUFACTURED', value: 'manufactured' },
          { label: 'FIXED ASSET', value: 'fixed_asset' }
        ]
      }
    ],
    textBox: [{ name: 'shelf_no', Heading: 'Shelf Number' }]
  },
  item_fixed_module: {
    item: [
      {
        name: 'stock_id',
        Heading: 'Items',
        filterKey: {
          product_type: 'fixed_asset',
          include_fixed_asset: 1
        }
      }
    ],
    category: [
      {
        name: 'category_id',
        Heading: 'Category',
        filterKey: {
          product_type: 'fixed_asset',
          include_fixed_asset: 1
        }
      }
    ],
    customSelect: [
      {
        name: 'product_type',
        Heading: 'Product Type',
        option: [
          { label: 'GOODS', value: 'goods' },
          {
            label: 'SERVICE',
            value: 'service'
          },
          { label: 'MANUFACTURED', value: 'manufactured' },
          { label: 'FIXED ASSET', value: 'fixed_asset' }
        ]
      }
    ],
    textBox: [{ name: 'shelf_no', Heading: 'Shelf Number' }]
  },
  purchase_request: {
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  material_request: {
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  bank_accounts_list: {
    account_code: [{ name: 'account_code', Heading: 'Back Account' }]
  },
  cost_center_list: {
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }]
  },
  purchase_order: {
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }]
  },
  purchase_order_inter_branch: {
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    supplier: [{ name: 'supplier_id', Heading: 'Company', interBranch: true }]
  },
  users_list: {
    textBox: [{ name: 'search', Heading: 'Search' }]
  },
  cheque_list: {
    dateRange: [
      {
        to: 'trans_to_date',
        from: 'trans_from_date',
        range: 'date_range',
        month: 'month',
        Heading: 'Transaction Date'
      }
    ],
    date: [
      {
        name: 'cheque_date',
        Heading: 'Cheque Date'
      }
    ],
    textBox: [
      { name: 'cheque_number', Heading: 'Cheque Number' },
      { name: 'reference', Heading: 'Reference' }
    ],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    customSelect: [
      {
        name: 'status',
        Heading: 'Status',
        option: [
          {
            label: 'Pending',
            value: 'pending'
          },
          {
            label: 'Bounced',
            value: 'bounced'
          },
          {
            label: 'Cleared',
            value: 'cleared'
          }
        ]
      }
    ],
    searchEnd: true
  },
  deferred_transactions: {
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'search', Heading: 'Reference' }],
    customSelect: [
      {
        name: 'status',
        Heading: 'Status',
        option: [
          { label: 'Completed', value: 'completed' },
          {
            label: 'Pending',
            value: 'pending'
          }
        ]
      }
    ]
  },
  assets_list: {
    customSelect: [
      {
        name: 'fixed_asset_type',
        Heading: 'Asset Type',
        option: [
          { label: 'VEHICLE', value: 'vehicle' },
          {
            label: 'MACHINE',
            value: 'machine'
          }
        ]
      },
      {
        name: 'fa_status',
        Heading: 'Asset Status',
        option: [
          {
            label: 'Functional',
            value: 'functional'
          },
          {
            label: 'Non Functional',
            value: 'non_functional'
          },
          {
            label: 'Under Maintenance',
            value: 'under_maintenance'
          },
          {
            label: 'Inactive',
            value: 'inactive'
          },
          {
            label: 'Damaged',
            value: 'damaged'
          }
        ]
      },
      {
        name: 'item_status',
        Heading: 'Stock Status',
        option: [
          {
            label: 'Rented Out',
            value: 'rented_out'
          },
          {
            label: 'In Stock',
            value: 'in_stock'
          },
          {
            label: 'Allocated',
            value: 'allocated'
          },
          {
            label: 'Not Purchased',
            value: 'not_purchased'
          }
        ]
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Asset Name',
        filterKey: {
          product_type: 'fixed_asset',
          include_fixed_asset: 1
        }
      }
    ],
    wareHouse: [
      {
        name: 'warehouse_id',
        Heading: 'Warehouse Name'
      }
    ],
    category: [
      {
        name: 'category_id',
        Heading: 'Category Name',
        fixedAsset: true,
        filterKey: {
          product_type: 'fixed_asset',
          include_fixed_asset: 1
        }
      }
    ],
    subCategory: [
      {
        name: 'sub_category_id',
        Heading: 'Sub Category Name'
      }
    ],
    textBox: [{ name: 'machine_serial_number', Heading: 'Serial Number' }],

    searchEnd: true
  },
  purchase_invoice: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    customSelect: [
      {
        name: 'status',
        Heading: 'Status',
        isMulti: true,
        option: [
          { label: 'FULLY PAID', value: 'fully_paid' },
          {
            label: 'PARTIALLY PAID',
            value: 'partially_paid'
          },
          { label: 'UN PAID', value: 'un_paid' }
        ]
      }
    ]
  },
  purchase_invoice_inter_branch: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Branch', interBranch: true }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    customSelect: [
      {
        name: 'status',
        Heading: 'Status',
        isMulti: true,
        option: [
          { label: 'FULLY PAID', value: 'fully_paid' },
          {
            label: 'PARTIALLY PAID',
            value: 'partially_paid'
          },
          { label: 'UN PAID', value: 'un_paid' }
        ]
      }
    ]
  },
  fixed_asset_purchase_invoice: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    customSelect: [
      {
        name: 'status',
        Heading: 'Status',
        option: []
      }
    ]
  },
  purchase_delivery: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  purchase_delivery_inter_branch: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'to_date',
        from: 'from_Date',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Branch', interBranch: true }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  debit_note_list: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'person_id', Heading: 'Supplier' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  sales_invoice_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [
      {
        name: 'customer_id',
        Heading: 'Customer',
        customer_group_name: 'customer_group_id'
      }
    ],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    checkBox: [
      { name: 'is_unpaid', Heading: 'Is Unpaid' },
      { name: 'is_recurring', Heading: 'Is Recurrent' }
    ],
    searchEnd: true
  },
  inter_branch_sales_invoice_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    customer: [{ name: 'customer_id', Heading: 'Branch', intraBranch: true }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    checkBox: [
      { name: 'is_unpaid', Heading: 'Is Unpaid' },
      { name: 'is_recurring', Heading: 'Is Recurrent' }
    ],
    searchEnd: true
  },
  sales_order_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [
      {
        name: 'customer_id',
        Heading: 'Customer',
        customer_group_name: 'customer_group_id'
      }
    ],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }]
  },
  inter_branch_sales_order_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    customer: [{ name: 'customer_id', Heading: 'Branch', intraBranch: true }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }]
  },
  sales_delivery_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [
      {
        name: 'customer_id',
        Heading: 'Customer',
        customer_group_name: 'customer_group_id'
      }
    ],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  inter_branch_sales_delivery_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer: [{ name: 'customer_id', Heading: 'Branch', intraBranch: true }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  sales_quotation_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [
      {
        name: 'customer_id',
        Heading: 'Customer',
        customer_group_name: 'customer_group_id'
      }
    ],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  credit_note_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [
      {
        name: 'customer_id',
        Heading: 'Customer',
        customer_group_name: 'customer_group_id'
      }
    ],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  category_wise_report: {
    show_multi_branch: true,
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  top_customer_report: {
    show_multi_branch: true,
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [
      {
        name: 'customer_id',
        Heading: 'Customer',
        customer_group_name: 'customer_group_id'
      }
    ],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }]
  },
  customer_balance_summary: {
    show_multi_branch: true,
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [
      {
        name: 'customer_id',
        Heading: 'Customer',
        customer_group_name: 'customer_group_id'
      }
    ]
  },
  customer_balance_statement_report: {
    show_multi_branch: true,
    show_opening_balance: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [
      {
        name: 'customer_id',
        Heading: 'Customer',
        customer_group_name: 'customer_group_id',
        showPerson: true
      }
    ],
    checkBox: [
      {
        name: 'show_opening_balance',
        Heading: 'Show Opening Balance',
        defaultValue: 1
      }
    ]
  },
  customer_balance_statement_report_without_customer: {
    customer: [
      {
        name: 'customer_id',
        Heading: 'Customer',
        customer_group_name: 'customer_group_id',
        showPerson: true
      }
    ],
    checkBox: [
      {
        name: 'show_opening_balance',
        Heading: 'Show Opening Balance',
        defaultValue: 1,
        hidde: true
      }
    ]
  },
  aged_customer_report: {
    show_multi_branch: true,
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [
      {
        name: 'customer_id',
        Heading: 'Customer',
        customer_group_name: 'customer_group_id'
      }
    ]
  },
  unpaid_sales_invoice_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [
      {
        name: 'customer_id',
        Heading: 'Customer',
        customer_group_name: 'customer_group_id'
      }
    ],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  sales_invoice_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [
      {
        name: 'customer_id',
        Heading: 'Customer',
        customer_group_name: 'customer_group_id'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    searchEnd: true
  },
  inter_branch_sales_invoice_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    customer: [{ name: 'customer_id', Heading: 'Branch', intraBranch: true }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    searchEnd: true
  },
  sales_order_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [
      {
        name: 'customer_id',
        Heading: 'Customer',
        customer_group_name: 'customer_group_id'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    searchEnd: true
  },
  inter_branch_sales_order_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    customer: [{ name: 'customer_id', Heading: 'Branch', intraBranch: true }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    searchEnd: true
  },
  sales_quotation_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [
      {
        name: 'customer_id',
        Heading: 'Customer',
        customer_group_name: 'customer_group_id'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    searchEnd: true
  },
  sales_delivery_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [
      {
        name: 'customer_id',
        Heading: 'Customer',
        customer_group_name: 'customer_group_id'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    searchEnd: true
  },
  inter_branch_sales_delivery_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    customer: [{ name: 'customer_id', Heading: 'Branch', intraBranch: true }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    searchEnd: true
  },
  credit_note_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month',
        Heading: 'Trans Date Range'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [
      {
        name: 'customer_id',
        Heading: 'Customer',
        customer_group_name: 'customer_group_id'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    searchEnd: true
  },
  contract_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month',
        Heading: 'Contract Date Range'
      }
    ],
    date: [
      { name: 'from_date', Heading: 'From Date' },
      { name: 'to_date', Heading: 'To Date' }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [
      {
        name: 'customer_id',
        Heading: 'Customer',
        customer_group_name: 'customer_group_id'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    searchEnd: true
  },
  rental_contract_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month',
        Heading: 'Contract Date Range'
      }
    ],
    date: [
      { name: 'from_date', Heading: 'From Date' },
      { name: 'to_date', Heading: 'To Date' }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Asset Name',
        filterKey: {
          product_type: 'fixed_asset',
          include_fixed_asset: 1
        }
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [
      {
        name: 'customer_id',
        Heading: 'Customer',
        customer_group_name: 'customer_group_id'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    searchEnd: true
  },
  contract_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month',
        Heading: 'Contract Date Range'
      }
    ],
    date: [
      { name: 'contract_from_date', Heading: 'From Date' },
      { name: 'contract_to_date', Heading: 'To Date' }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [
      {
        name: 'customer_id',
        Heading: 'Customer',
        customer_group_name: 'customer_group_id'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    searchEnd: true
  },
  sales_by_customer_report: {
    show_multi_branch: true,
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [
      {
        name: 'customer_id',
        Heading: 'Customer',
        customer_group_name: 'customer_group_id'
      }
    ]
  },
  purchase_invoice_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    checkBox: [{ name: 'is_unpaid', Heading: 'Is Unpaid' }]
  },
  inter_branch_purchase_invoice_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Branch', interBranch: true }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    checkBox: [{ name: 'is_unpaid', Heading: 'Is Unpaid' }]
  },
  purchase_order_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  purchase_order_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  inter_branch_purchase_order_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Branch', interBranch: true }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  inter_branch_purchase_order_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Branch', interBranch: true }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  purchase_request_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  purchase_delivery_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  inter_branch_purchase_delivery_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Branch', interBranch: true }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  supplier_balance_report: {
    show_multi_branch: true,
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }]
  },
  aged_supplier_analysis_Report: {
    show_multi_branch: true,
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }]
  },
  top_supplier_purchase_report: {
    show_multi_branch: true,
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }]
  },
  category_wise_purchase_report: {
    show_multi_branch: true,
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  purchase_invoice_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  inter_branch_purchase_invoice_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    supplier: [{ name: 'supplier_id', Heading: 'Branch', interBranch: true }],
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  purchase_delivery_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  inter_branch_purchase_delivery_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    supplier: [{ name: 'supplier_id', Heading: 'Branch', interBranch: true }],
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  unpaid_purchase_invoice_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  debit_note_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  debit_note_details_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'person_id', Heading: 'Supplier' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  ledger_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month',
        selectThisMonth: true
      }
    ],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    ledger: [{ name: 'account_code', Heading: 'Choose Ledger' }],
    sub_ledger: [{ name: 'sub_ledger_code', Heading: 'Choose Sub Ledger' }],
    trans_type: [{ name: 'transaction_type', Heading: 'Transaction Type' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    searchEnd: true
  },
  profit_and_loss_statement_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    checkBox: [
      { name: 'suppress_zero', Heading: 'Suppress Zero', defaultValue: 1 }
    ]
  },
  balance_sheet: {
    show_multi_branch: true,
    show_default_to_date: true,
    date: [
      { name: 'date_to', Heading: 'Till Date', showDefaultTodayDate: true }
    ],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    checkBox: [
      { name: 'suppress_zero', Heading: 'Suppress Zero', defaultValue: 1 }
    ]
  },
  trail_balance_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    checkBox: [
      { name: 'suppress_zero', Heading: 'Suppress Zero', defaultValue: 1 }
    ]
  },
  const_center_wise_trail_balance_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  cost_center_wise_profit_and_loss_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  schedule_maintenance_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  asset_wise_profit_summary_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Asset Name',
        filterKey: {
          product_type: 'fixed_asset',
          include_fixed_asset: 1
        }
      }
    ],
    category: [
      {
        name: 'category_id',
        Heading: 'Category Name',
        fixedAsset: true,
        filterKey: {
          product_type: 'fixed_asset',
          include_fixed_asset: 1
        }
      }
    ]
  },
  asset_transaction_report: {
    show_multi_branch: true,
    item: [
      {
        name: 'stock_id',
        Heading: 'Items',
        filterKey: {
          product_type: 'fixed_asset',
          include_fixed_asset: 1
        }
      }
    ],
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ]
  },
  asset_stock_report: {
    show_multi_branch: true,
    item: [
      {
        name: 'stock_id',
        Heading: 'Asset Name',
        filterKey: {
          product_type: 'fixed_asset',
          include_fixed_asset: 1
        }
      }
    ],
    date: [
      { name: 'rented_date', Heading: 'Rented Date' },
      { name: 'expected_return_date', Heading: 'Expected Return Date' }
    ],
    customSelect: [
      {
        name: 'item_status',
        Heading: 'Stock Status',
        option: [
          {
            label: 'Rented Out',
            value: 'rented_out'
          },
          {
            label: 'In Stock',
            value: 'in_stock'
          },
          {
            label: 'Allocated',
            value: 'allocated'
          },
          {
            label: 'Not Purchased',
            value: 'not_purchased'
          }
        ]
      }
    ]
  },
  email_logs: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    trans_type: [{ name: 'trans_type', Heading: 'Trans Type' }]
  },
  journal_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    ledger: [{ name: 'account_code', Heading: 'Choose Ledger' }]
  },
  customer_payment_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [
      {
        name: 'customer_id',
        Heading: 'Customer',
        customer_group_name: 'customer_group_id'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  supplier_payment_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  bank_balance_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    bankAccount: [{ name: 'bank_id', Heading: 'Bank Name' }]
  },
  void_transaction_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    trans_type: [{ name: 'transaction_type', Heading: 'Trans Type' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  recurrent_profile_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    trans_type: [{ name: 'transaction_type', Heading: 'Transaction Type' }]
  },
  receipt_voucher_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    bankAccount: [{ name: 'to_bank_id', Heading: 'To Bank' }]
  },
  payment_voucher_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    bankAccount: [{ name: 'from_bank_id', Heading: 'From Bank' }]
  },
  bank_transfer_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    bankAccount: [
      { name: 'from_bank_id', Heading: 'From Bank' },
      { name: 'to_bank_id', Heading: 'To Bank' }
    ]
  },
  voucher_inquiry_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer: [{ name: 'customer_id', Heading: 'Customer' }],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    trans_type: [{ name: 'trans_type', Heading: 'Transaction Type' }],
    consumer: [{ name: 'consumer_id', Heading: 'Consumer' }]
  },
  refund_report: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    bankAccount: [{ name: 'from_bank_id', Heading: 'From Bank' }]
  },
  inventory_valuation_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  batch_report: {
    date: [
      { name: 'exp_date_from', Heading: 'Manufacturing Date' },
      { name: 'exp_date_to', Heading: 'Expiration date up to' }
    ],
    checkBox: [
      { name: 'is_expiry', Heading: 'Is Expired' },
      { name: 'is_active', Heading: 'Is Active' }
    ]
  },
  inventory_status_report: {
    show_multi_branch: true,
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  inventory_movement_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Item'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  inventory_movement_report_without_stock: {
    show_multi_branch: true,
    item: [
      {
        name: 'stock_id',
        Heading: 'Item'
      }
    ]
  },
  inventory_summary_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  inventory_purchase_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    category: [{ name: 'category_id', Heading: 'Category Name' }],
    searchEnd: true
  },
  invoice_wise_item_profit_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    customer_group: [{ name: 'customer_group_id', Heading: 'Customer Group' }],
    customer: [
      {
        name: 'customer_id',
        Heading: 'Customer',
        customer_group_name: 'customer_group_id'
      }
    ],
    category: [{ name: 'category_id', Heading: 'Category Name' }],
    searchEnd: true
  },
  item_reorder_report: {
    show_multi_branch: true,
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  item_wise_profit_summary_report: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    show_multi_branch: true,
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  category_wise_profit_summary_report: {
    show_multi_branch: true,
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  supplier_balance_statement_report_has_supplier: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier', showPerson: true }],
    show_multi_branch: true
  },
  supplier_balance_statement_report_without_supplier: {
    supplier: [{ name: 'supplier_id', Heading: 'Supplier', showPerson: true }]
  },
  bank_transaction_report: {
    show_multi_branch: true,
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    bankAccount: [{ name: 'bank_account_id', Heading: 'Bank' }],
    trans_type: [{ name: 'transaction_type', Heading: 'Trans Type' }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  bank_transaction_report_without_bank: {
    bankAccount: [{ name: 'bank_account_id', Heading: 'Bank' }]
  },
  payment_list_term: {
    textBox: [{ name: 'name', Heading: 'Name' }]
  },
  terms_and_conditions: {
    textBox: [{ name: 'name', Heading: 'Name' }]
  },
  shipping_company_list: {
    textBox: [
      { name: 'name', Heading: 'Name' },
      { name: 'contact_number', Heading: 'Contact Number' },
      { name: 'person', Heading: 'Person' }
    ]
  },
  warehouse_list: {
    textBox: [
      { name: 'location_name', Heading: 'Location Name' },
      { name: 'email', Heading: 'Email' },
      { name: 'contact_number', Heading: 'Contact Number' }
    ],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }]
  },
  tax_list: {
    textBox: [{ name: 'name', Heading: 'Name' }]
  },
  tax_group_list: {
    textBox: [{ name: 'name', Heading: 'Name' }]
  },
  tax_group_item_list: {
    textBox: [{ name: 'name', Heading: 'Name' }]
  },
  monthly_trail_balance_report: {
    show_multi_branch: true,
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    months: [{ name: 'months', Heading: 'Select Month' }]
  },
  year_wise_trail_balance_report: {
    show_multi_branch: true,
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    years: [{ name: 'years', Heading: 'Select Year' }]
  },
  year_wise_profit_and_loss_report: {
    show_multi_branch: true,
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    years: [{ name: 'years', Heading: 'Select Year' }]
  },
  warehouse_wise_average_cost: {
    wareHouse: [
      {
        name: 'warehouse_id',
        Heading: 'Warehouse Name'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Items'
      }
    ],
    category: [{ name: 'category_id', Heading: 'Category Name' }]
  },
  notification_filter: {
    customSelect: [
      {
        name: 'priority',
        Heading: 'priority',
        option: [
          {
            label: 'High',
            value: 'high'
          },
          {
            label: 'Medium',
            value: 'medium'
          },
          {
            label: 'Low',
            value: 'low'
          }
        ]
      }
    ]
  },
  work_in_progress_report: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  finished_work_orders_report: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  work_orders_details_report: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  over_due_work_orders_report: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  work_orders_issued_items_report: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    work_order: [{ name: 'work_order_id', Heading: 'Work Order' }]
  },
  with_out_work_orders_issued_items_report: {
    work_order: [{ name: 'work_order_id', Heading: 'Work Order' }]
  },
  work_orders_report: {
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  work_orders_comparison_report: {
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  enquiry_report: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [
      { name: 'reference', Heading: 'Reference' },
      { name: 'email', Heading: 'Email' },
      { name: 'phone', Heading: 'Phone' }
    ]
  },
  estimation_report: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  vat_report: {
    date: [
      { name: 'date_from', Heading: 'From Date' },
      { name: 'date_to', Heading: 'To Date' }
    ]
  },
  sales_order_inter_branch: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer: [{ name: 'customer_id', Heading: 'Company', intraBranch: true }],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  sales_invoice_inter_branch: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    customer: [{ name: 'customer_id', Heading: 'Branch', intraBranch: true }],
    customSelect: [
      {
        name: 'status',
        Heading: 'Status',
        option: [
          { label: 'FULLY PAID', value: 'fully_paid' },
          {
            label: 'PARTIALLY PAID',
            value: 'partially_paid'
          },
          { label: 'UN PAID', value: 'un_paid' }
        ]
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }]
  },
  salesman_by_sales_invoice_report: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    textBox: [{ name: 'reference', Heading: 'Reference' }],
    salesman: [{ name: 'salesman_id', Heading: 'Salesman' }]
  },
  sales_invoice_report_without_salesman: {
    salesman: [{ name: 'salesman_id', Heading: 'Salesman' }]
  },
  asset_purchase_report: {
    dateRange: [
      {
        to: 'to_date',
        from: 'from_date',
        range: 'date_range',
        month: 'month'
      }
    ],
    item: [
      {
        name: 'stock_id',
        Heading: 'Asset Name',
        filterKey: {
          product_type: 'fixed_asset',
          include_fixed_asset: 1
        }
      }
    ],
    textBox: [{ name: 'purchase_reference', Heading: 'Reference' }],
    supplier: [{ name: 'supplier_id', Heading: 'Supplier' }],
    customSelect: [
      {
        name: 'fixed_asset_type',
        Heading: 'Asset Type',
        option: [
          { label: 'VEHICLE', value: 'vehicle' },
          {
            label: 'MACHINE',
            value: 'machine'
          }
        ]
      },
      {
        name: 'payment_status',
        Heading: 'Payment Status',
        option: [
          { label: 'Fully Paid', value: 'paid' },
          {
            label: 'Not Paid',
            value: 'unpaid'
          },
          {
            label: 'Partially Paid',
            value: 'partially_paid'
          }
        ]
      }
    ],
    searchEnd: true
  },
  customer_group: {
    textBox: [
      { name: 'group_code', Heading: 'Group Code' },
      { name: 'name', Heading: 'Group Name' }
    ]
  },
  sales_type: {
    textBox: [{ name: 'name', Heading: 'Name' }],
    customSelect: [
      {
        name: 'tax_included',
        Heading: 'Tax Included',
        option: [
          {
            label: 'Yes',
            value: '1'
          },
          {
            label: 'No',
            value: '0'
          }
        ]
      }
    ]
  },
  sales_area: {
    textBox: [{ name: 'area_code', Heading: 'Area Code' }]
  },
  cash_handover: {
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    user: [
      {
        name: 'requested_by',
        Heading: 'Requeted By'
      }
    ],
    customSelect: [
      {
        name: 'status',
        Heading: 'Status',
        option: [
          {
            label: 'Pending',
            value: 'pending'
          },
          {
            label: 'Approved',
            value: 'approved'
          },
          {
            label: 'Rejected',
            value: 'rejected'
          }
        ]
      }
    ],
    cost_center: [{ name: 'cost_center_id', Heading: 'Cost Center' }],
    textBox: [{ name: 'amount', Heading: 'Amount' }],
    searchEnd: true
  },
  zatca_records: {
    // textBox: [{ name: 'reference', Heading: 'Reference' }],
    dateRange: [
      {
        to: 'date_to',
        from: 'date_from',
        range: 'date_range',
        month: 'month'
      }
    ],
    // customer: [{ name: 'customer_id', Heading: 'Customer' }],
    customSelect: [
      {
        name: 'type',
        Heading: 'Type',
        option: [
          {
            label: 'SI',
            value: 'si'
          }
        ]
      },
      {
        name: 'validation_status',
        Heading: 'Validation Status',
        option: [
          {
            label: 'PASS',
            value: 'pass'
          },
          {
            label: 'ERROR',
            value: 'error'
          },
          {
            label: 'PENDING',
            value: 'pending'
          }
        ]
      },
      {
        name: 'clearance_status',
        Heading: 'Clearance Status',
        option: [
          {
            label: 'CLEARED',
            value: 'cleared'
          },
          {
            label: 'NOT CLEARED',
            value: 'not cleared'
          }
        ]
      },
      {
        name: 'reporting_status',
        Heading: 'Reporting Status',
        option: [
          {
            label: 'REPORTED',
            value: 'reported'
          },
          {
            label: 'NOT REPORTED',
            value: 'not reported'
          }
        ]
      }
    ]
  }
};

export const SelectedFilterFormData = module => {
  return FilterOptions[module];
};

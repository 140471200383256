import axios from 'axios';
import SelectUser from 'components/form/SelectUser';
import removeRefData from 'helpers/removeRefData';
import setNewFormData from 'helpers/setNewFormData';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import { showToast } from 'module/Common/Toast/toast';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import { FaHandHoldingUsd } from 'react-icons/fa';
import { FaHandHoldingDollar } from 'react-icons/fa6';
import PropTypes from 'prop-types';
import AppDateTimePicker from 'components/app-date-time-picker/AppDateTimePicker';
import CommonSelect from 'components/form/CommonSelect';
import { fixedAssetStatus } from 'module/Common/Enum/enum';
import { CancelButton, SaveButton } from 'module/Common/Buttons/CommonButton';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import { AuthWizardContext } from 'context/Context';
import { rentalContractReturnFormKeys } from 'helpers/formKeys';
const RentalContractReturnModal = ({ data, getData, rentalContract }) => {
  const Translate = useAxisproTranslate();
  const { user } = useContext(AuthWizardContext);
  const [onSave, setOnSave] = useState(false);
  const [show, setShow] = useState(false);
  const [validationErrorMsg, setValidationErrorMsg] = useState({});
  const [formData, setFormData] = useState(rentalContractReturnFormKeys);

  const handleSubmit = () => {
    let newFormData = setNewFormData(removeRefData(formData));
    setOnSave(true);
    axios
      .post(
        rentalContract
          ? `/sales/rental-contract/return/${data?.id}?_method=PUT`
          : `/fixedasset/returns`,
        newFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then(response => {
        showToast(response.data.message, 'success');
        getData();
        setFormData(rentalContractReturnFormKeys);
        setValidationErrorMsg({});
        setShow(false);
      })
      .catch(error => {
        const errorData = error?.response?.data;
        showToast(
          errorData?.message ??
            error?.message ??
            'Something went wrong, please refresh the page and try again.',
          'error'
        );
        setValidationErrorMsg({ ...(errorData?.data?.errors ?? null) });
      })
      .finally(() => {
        setOnSave(false);
      });
  };
  const handleFieldChange = useCallback(
    (e, action) => {
      setFormData(prev => generateFormDataHelper(e, action, prev));
    },
    [setFormData]
  );
  const handleCancel = () => {
    setFormData(rentalContractReturnFormKeys);
    setValidationErrorMsg({});
    setShow(false);
  };

  useEffect(() => {
    if (user && show) {
      setFormData(prev => ({
        ...prev,
        acknowledged_by_ref: {
          label: user?.name,
          value: user?.id
        },
        acknowledged_by: user?.id
      }));
    }
  }, [user, show]);

  useEffect(() => {
    if (!rentalContract) {
      setFormData(prev => ({
        ...prev,
        allocation_id: data?.allocation_id ?? '',
        // receiver_id: data?.receiver_id ?? '',
        returned_date: ''
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        return_date: ''
      }));
    }
  }, [data, rentalContract]);

  return (
    <>
      <Button
        className=" ms-1  text-capitalize d-flex align-items-center justify-content-center  rental-contract-return-button"
        variant="warning"
        onClick={() => setShow(true)}
      >
        <FaHandHoldingDollar
          size={18}
          color="white"
          className="me-1 pt-0 pb-1"
        />{' '}
        {Translate('Return')}
      </Button>
      <Modal show={show} className="modal-with-overlay" backdrop="static">
        <Card>
          <Card.Header className="fs--1 text-uppercase text-dark d-flex align-items-center border-bottom">
            <FaHandHoldingUsd size={18} className="me-2" />
            {Translate('Return')}
          </Card.Header>
          <Card.Body>
            {Object.keys(validationErrorMsg).length > 0 && (
              <ErrorAlert
                formError={validationErrorMsg}
                setFormError={setValidationErrorMsg}
              />
            )}
            <Form className="app-form">
              <Form.Group md={12} sm={12} className="mb-3">
                <Form.Label className="require-data">
                  {Translate(rentalContract ? 'Return Date' : 'Returned Date')}
                </Form.Label>
                <AppDateTimePicker
                  name={rentalContract ? 'return_date' : 'returned_date'}
                  value={
                    rentalContract
                      ? formData?.return_date
                      : formData?.returned_date
                  }
                  yearPlaceholder="yyyy"
                  monthPlaceholder="mm"
                  dayPlaceholder="dd"
                  hourPlaceholder="hh"
                  minutePlaceholder="mm"
                  onChange={handleFieldChange}
                  isInvalid={
                    !!validationErrorMsg[
                      rentalContract ? 'return_date' : 'returned_date'
                    ]
                  }
                  showDefaultDate
                />
              </Form.Group>
              <Form.Group md={12} sm={12} className="mb-3">
                <Form.Label>{Translate('Acknowledged By')}</Form.Label>
                <SelectUser
                  value={formData?.acknowledged_by_ref}
                  name={'acknowledged_by'}
                  handleFieldChange={handleFieldChange}
                  error={validationErrorMsg?.acknowledged_by}
                />
              </Form.Group>
              <Form.Group md={12} sm={12} className="mb-3">
                <Form.Label className="require-data">
                  {Translate('Asset Status')}
                </Form.Label>
                <CommonSelect
                  option={fixedAssetStatus}
                  name="asset_status"
                  value={formData?.asset_status_ref}
                  onChange={handleFieldChange}
                  error={validationErrorMsg?.asset_status}
                />
              </Form.Group>
              <Form.Group md={12} sm={12} className="mb-1 mt-3">
                <Form.Label className="require-data">
                  {Translate('Note')}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="note"
                  className="w-100"
                  value={formData?.note}
                  isInvalid={!!validationErrorMsg?.note}
                  onChange={handleFieldChange}
                />
              </Form.Group>
            </Form>
          </Card.Body>
          <Card.Footer className="d-flex gap-1 justify-content-end border-top">
            <CancelButton handleCancel={handleCancel} onSave={onSave} />
            <SaveButton
              buttonTitle={'Return'}
              createPermission={true}
              editPermission={true}
              handleSubmit={handleSubmit}
              onSave={onSave}
            />
          </Card.Footer>
        </Card>
      </Modal>
    </>
  );
};
RentalContractReturnModal.propTypes = {
  data: PropTypes.object,
  getData: PropTypes.func,
  rentalContract: PropTypes.bool
};
export default RentalContractReturnModal;

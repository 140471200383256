import { React, useContext } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { AuthWizardContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';

function BankChargeInputField({ formData, formError, handleFieldChange }) {
  const { user } = useContext(AuthWizardContext);
  const Translate = useAxisproTranslate();
  const baseCurrency = user?.branch?.base_currency ?? '';

  return (
    <Col sm={6}>
      <Form.Group as={Row} className="mb-3" controlId="payFromConrol">
        <Form.Label column md={3}>
          {Translate('Bank Charges')}
        </Form.Label>
        <Col sm={3} className="pe-0">
          <Form.Control
            type="number"
            defaultValue={0}
            name="bank_charges"
            className="form-tab-switch-control"
            onChange={handleFieldChange}
            value={formData.bank_charges}
          />
        </Col>
        <Col sm={2} className="ps-1">
          <Form.Select
            name="bank_charges_type"
            className="form-tab-switch-control"
            value={formData.bank_charges_type}
            onChange={handleFieldChange}
          >
            <option value="percent">{'%'}</option>
            <option value="amount">{baseCurrency ?? 'AED'}</option>
          </Form.Select>
        </Col>
        <Col
          xl={1}
          md={1}
          sm={1}
          className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
        >
          <FormErrorPopover
            id="bank_charges"
            totalErrorCount={Object.keys(formError).length}
            errorMessage={formError.bank_charges}
          />
        </Col>
      </Form.Group>
    </Col>
  );
}

BankChargeInputField.propTypes = {
  formData: PropTypes.any,
  formError: PropTypes.any,
  handleFieldChange: PropTypes.func
};

export default BankChargeInputField;

import axios from 'axios';
import { showToast } from 'module/Common/Toast/toast';
import moment from 'moment';

export default async function setEnquiryToSalesQuotationData({
  enquiryId,
  setLoading,
  setFormData
}) {
  try {
    //get enquiry details
    const enquiryApiResult = await axios.get('crm/enquiries/' + enquiryId);

    if (enquiryApiResult?.data?.data?.id) {
      const enquiryData = enquiryApiResult.data.data;

      setFormData(prev => ({
        ...prev,
        enquiry_id: enquiryId,
        customer_reference: enquiryData?.reference ?? '',
        enquiry_reference_ref: enquiryData?.reference ?? '',
        enquiry_id_ref: enquiryId,
        trans_date: moment().format('YYYY-MM-DD'),
        customer_id: enquiryData?.customer_id ?? '',
        customer_id_ref: enquiryData?.customer_id
          ? {
              value: enquiryData.customer_id,
              label: enquiryData?.customer_name
            }
          : null,
        mobile: enquiryData?.phone,
        email: enquiryData?.email,
        memo: enquiryData?.message ?? '',
        salesman_id: enquiryData?.salesman_id ?? '',
        salesman_id_ref: enquiryData?.salesman_id
          ? {
              value: enquiryData.customer_id,
              label: enquiryData?.salesman_name
            }
          : null,
        sales_type_id: enquiryData?.customer_sales_type_id ?? '',
        sales_type_id_ref: {
          label: enquiryData?.customer_sales_type_name,
          value: enquiryData?.customer_sales_type_id
        },
        payment_term_id: enquiryData?.customer_payment_term_id ?? '',
        payment_term_id_ref: {
          label: enquiryData?.customer_payment_term_name,
          value: enquiryData?.customer_payment_term_id
        }
      }));
    }
  } catch (error) {
    showToast(
      error?.response?.data?.message ??
        'Something went wrong, please refresh the page and try again.',
      'error'
    );
  } finally {
    if (typeof setLoading === 'function') setLoading(false);
  }
}

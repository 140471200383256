import { React, useState, useContext } from 'react';
import axios from 'axios';
import { Button, Card, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { DeleteContext } from 'context/Context';
import { showToast } from '../Toast/toast';

function DeleteWarning({
  show,
  onHide,
  id,
  fetchData,
  data,
  setKey,
  heading,
  message,
  url,
  type,
  setIsDeleted
}) {
  const Translate = useAxisproTranslate();
  const { config, dispatch } = useContext(DeleteContext);
  const [deleting, setDeleting] = useState(false);

  const submitDelete = e => {
    if (config.url) {
      onSubmit(e);
    } else if (typeof config?.onSubmit === 'function') {
      config.onSubmit(e, {
        deleting: deleting,
        setDeleting: setDeleting
      });
    } else {
      handleDelete();
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    if (typeof config.onDelete === 'function') {
      config.onDelete();
    }
    const configData = config;
    dispatch({ type: 'RESET' });
    axios({
      method: config?.reuestMethod
        ? config.reuestMethod
        : config.method
        ? 'put'
        : 'delete',
      url: configData.url
    })
      .then(response => {
        if (response.data.success) {
          dispatch({
            type: 'CONFIG',
            payload: config.method
              ? {
                  last_updated_item: configData.target
                    ? config.target
                    : undefined
                }
              : {
                  last_deleted_item: configData.target
                    ? config.target
                    : undefined
                }
          });

          if (
            typeof config.onSuccess === 'function' &&
            response.data &&
            response.data.data
          ) {
            config.onSuccess(response.data.data);
          }
        } else {
          if (typeof config.onError === 'function') {
            config.onError(response.data);
          }
        }

        showToast(
          response.data.success
            ? response.data.message
            : 'Something went wrong, please refresh the page and try again.',
          response.data.success ? 'success' : 'error'
        );
      })
      .catch(error => {
        if (typeof config.onError === 'function') {
          config.onError(error);
        }

        const errorMessage =
          error?.response?.data?.data?.errors?.[0]?.[0] ||
          error?.response?.data?.message ||
          'Something went wrong, please refresh the page and try again.';

        showToast(errorMessage, 'error');
      });
  };

  const handleDelete = () => {
    setDeleting(true);
    if (id && type === 'ATTACHMENT') {
      axios
        .delete(`${url}/${id}`)
        .then(res => {
          if (res.data.success === true) {
            if (data.length > 1) {
              fetchData();
              onHide();
              if (typeof setKey === 'function') setKey('attachments');
              setDeleting(false);
            } else {
              fetchData();
              onHide();
              if (typeof setKey === 'function') setKey('overview');
              setDeleting(false);
            }
          }
        })
        .catch(error => {
          if (error?.response?.status === 404) {
            showToast(`Attachment not exist.`, 'error');
          } else {
            showToast(
              `Something went wrong, please refresh the page and try again.`,
              'error'
            );
          }
          if (data.length > 1) {
            if (typeof fetchData === 'function') fetchData();
            onHide();
            if (typeof setKey === 'function') setKey('attachments');
            setDeleting(false);
          } else {
            if (typeof fetchData === 'function') fetchData();
            onHide();
            if (typeof setKey === 'function') setKey('overview');
            setDeleting(false);
          }
        });
    } else if (
      (id && type === 'CUSTOMER') ||
      (id && type === 'SALESMAN') ||
      (id && type === 'ITEM') ||
      (id && type === 'BRANCH') ||
      (id && type === 'USER') ||
      (id && type === 'CUSTOM FIELD') ||
      (id && type === 'EA')
    ) {
      axios
        .delete(`${url}/${id}`)
        .then(response => {
          if (response.data.success === true) {
            onHide();
            showToast(response.data.message, 'success');
          } else {
            showToast(response.data.message, 'error');
            onHide();
          }
          if (type === 'BRANCH') {
            setIsDeleted(true);
          }
          setDeleting(false);
        })
        .catch(error => {
          if (error?.response?.data?.data?.errors[0][0]) {
            showToast(error.response.data.data.errors[0][0], 'error');
          } else {
            showToast(
              'Something went wrong, please refresh the page and try again.',
              'error'
            );
          }
          setDeleting(false);
          onHide();
        });
    }
  };

  const cancelModal = () => {
    if (typeof config.onCancel === 'function') {
      config.onCancel();
    }
    if (config?.show || config?.url) {
      dispatch({ type: 'RESET' });
    } else {
      onHide();
    }
  };

  return (
    <Modal
      show={config?.show || config?.url ? true : show ? show : false}
      size="md"
      className="modal-with-overlay"
    >
      <Card>
        <Card.Header className="text-center">
          <h5 className="fs--1 text-uppercase text-dark fw-bold">
            {config.title
              ? Translate(config.title)
              : heading
              ? Translate(heading)
              : Translate('Delete')}
          </h5>
        </Card.Header>
        <Card.Body className="d-flex flex-column align-items-center justify-content-center py-0">
          <p className="m-0 fs--1">
            {config.message
              ? Translate(config.message)
              : message
              ? Translate(message)
              : Translate('Are you sure? You want to delete this Attachment!')}
          </p>
        </Card.Body>
        <Card.Footer className="d-flex gap-2 align-items-center justify-content-end">
          <Button
            variant={
              config?.submitButtonVariant ??
              (config.buttonText === 'Enable' ? 'success' : 'danger')
            }
            style={{ boxShadow: 'none' }}
            onClick={submitDelete}
            size="sm"
            disabled={deleting || config?.disableSubmitButton}
          >
            {config.buttonText
              ? config.buttonText
              : deleting
              ? Translate('Deleting')
              : Translate('Delete')}
          </Button>
          <Button
            variant={
              config?.cancelButtonVariant ??
              (config.buttonText === 'Enable' ? 'danger' : 'success')
            }
            style={{ boxShadow: 'none' }}
            onClick={cancelModal}
            size="sm"
            disabled={deleting || config?.disableCancelButton}
          >
            {config.cancelText ? config.cancelText : Translate('Cancel')}
          </Button>
        </Card.Footer>
      </Card>
    </Modal>
  );
}

DeleteWarning.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  id: PropTypes.any,
  fetchData: PropTypes.func,
  data: PropTypes.any,
  setKey: PropTypes.func,
  heading: PropTypes.string,
  message: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
  setIsDeleted: PropTypes.func
};

export default DeleteWarning;

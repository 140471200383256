export const Layout_1 = {
  xl: [
    {
      i: 'a',
      x: 0,
      y: 0,
      w: 4,
      h: 4,
      minW: 3,
      maxW: 5,
      minH: 4,
      maxH: 6
    },
    {
      i: 'c',
      x: 4,
      y: 0,
      w: 2,
      h: 4,
      minW: 2,
      maxW: 4,
      minH: 4,
      maxH: 6
    },
    {
      i: 'd',
      x: 6,
      y: 0,
      w: 2,
      h: 4,
      minW: 2,
      maxW: 4,
      minH: 4,
      maxH: 6
    },
    {
      i: 'e',
      x: 8,
      y: 0,
      w: 2,
      h: 4,
      minW: 2,
      maxW: 4,
      minH: 4,
      maxH: 6
    },
    {
      i: 'f',
      x: 10,
      y: 0,
      w: 2,
      h: 4,
      minW: 2,
      maxW: 4,
      minH: 4,
      maxH: 6
    },
    {
      i: 'g',
      x: 0,
      y: 4,
      w: 3,
      h: 4,
      minW: 3,
      maxW: 5,
      minH: 4,
      maxH: 8
    },
    {
      i: 'h',
      x: 3,
      y: 4,
      w: 3,
      h: 4,
      minW: 2,
      maxW: 5,
      minH: 4,
      maxH: 8
    },
    {
      i: 'i',
      x: 6,
      y: 4,
      w: 3,
      h: 4,
      minW: 3,
      maxW: 5,
      minH: 4,
      maxH: 8
    },
    {
      i: 'j',
      x: 9,
      y: 4,
      w: 3,
      h: 4,
      minW: 2,
      maxW: 5,
      minH: 4,
      maxH: 8
    },
    {
      i: 'k',
      x: 0,
      y: 8,
      w: 2,
      h: 10,
      minW: 2,
      maxW: 3,
      minH: 9,
      maxH: 15
    },
    {
      i: 'l',
      x: 2,
      y: 8,
      w: 2,
      h: 5,
      minW: 2,
      maxW: 4,
      minH: 4,
      maxH: 10
    },
    {
      i: 'z',
      x: 2,
      y: 13,
      w: 2,
      h: 5,
      minW: 2,
      maxW: 4,
      minH: 4,
      maxH: 10
    },
    {
      i: 'm',
      x: 4,
      y: 8,
      w: 5,
      h: 10,
      minW: 4,
      maxW: 7,
      minH: 9,
      maxH: 12
    },
    {
      i: 'n',
      x: 9,
      y: 8,
      w: 3,
      h: 5,
      minW: 2,
      maxW: 5,
      minH: 4,
      maxH: 6
    },
    {
      i: 'y',
      x: 9,
      y: 13,
      w: 3,
      h: 5,
      minW: 2,
      maxW: 5,
      minH: 4,
      maxH: 6
    },
    {
      i: 'o',
      x: 0,
      y: 18,
      w: 6,
      h: 10,
      minW: 4,
      maxW: 10,
      minH: 9,
      maxH: 12
    },
    {
      i: 'p',
      x: 6,
      y: 18,
      w: 6,
      h: 10,
      minW: 4,
      maxW: 10,
      minH: 9,
      maxH: 12
    },
    {
      i: 'q',
      x: 0,
      y: 28,
      w: 6,
      h: 12,
      minW: 4,
      maxW: 10,
      minH: 9,
      maxH: 12
    },
    {
      i: 'r',
      x: 6,
      y: 28,
      w: 6,
      h: 12,
      minW: 2,
      maxW: 10,
      minH: 9,
      maxH: 12
    },
    {
      i: 's',
      x: 0,
      y: 38,
      w: 6,
      h: 12,
      minW: 2,
      maxW: 10,
      minH: 9,
      maxH: 12
    },
    {
      i: 't',
      x: 6,
      y: 38,
      w: 6,
      h: 12,
      minW: 4,
      maxW: 10,
      minH: 9,
      maxH: 12
    }
  ],
  md: [
    {
      i: 'a',
      x: 0,
      y: 0,
      w: 12,
      h: 4,
      minW: 5,
      maxW: 12,
      maxH: 6,
      minH: 4
    },
    {
      i: 'c',
      x: 0,
      y: 4,
      w: 3,
      h: 4,
      minW: 2,
      maxW: 4,
      minH: 4,
      maxH: 5
    },
    {
      i: 'd',
      x: 3,
      y: 4,
      w: 3,
      h: 4,
      minW: 2,
      maxW: 4,
      minH: 4,
      maxH: 5
    },
    {
      i: 'e',
      x: 6,
      y: 4,
      w: 3,
      h: 4,
      minW: 2,
      maxW: 4,
      minH: 4,
      maxH: 5
    },
    {
      i: 'f',
      x: 9,
      y: 4,
      w: 3,
      h: 4,
      minW: 2,
      maxW: 4,
      minH: 4,
      maxH: 5
    },
    {
      i: 'g',
      x: 0,
      y: 8,
      w: 6,
      h: 4,
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 6
    },
    {
      i: 'h',
      x: 6,
      y: 8,
      w: 6,
      h: 4,
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 6
    },
    {
      i: 'i',
      x: 0,
      y: 12,
      w: 6,
      h: 4,
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 6
    },
    {
      i: 'j',
      x: 6,
      y: 12,
      w: 6,
      h: 4,
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 6
    },
    {
      i: 'k',
      x: 0,
      y: 16,
      w: 6,
      h: 10,
      minW: 4,
      maxW: 12,
      minH: 9,
      maxH: 15
    },
    {
      i: 'l',
      x: 6,
      y: 16,
      w: 6,
      h: 5,
      minW: 3,
      maxW: 12,
      minH: 4,
      maxH: 10
    },
    {
      i: 'z',
      x: 9,
      y: 16,
      w: 6,
      h: 5,
      minW: 3,
      maxW: 12,
      minH: 4,
      maxH: 10
    },
    {
      i: 'm',
      x: 0,
      y: 26,
      w: 8,
      h: 10,
      minW: 5,
      maxW: 12,
      minH: 9,
      maxH: 12
    },
    {
      i: 'n',
      x: 8,
      y: 26,
      w: 4,
      h: 5,
      minW: 3,
      maxW: 6,
      minH: 4,
      maxH: 6
    },
    {
      i: 'y',
      x: 8,
      y: 26,
      w: 4,
      h: 5,
      minW: 3,
      maxW: 6,
      minH: 4,
      maxH: 6
    },
    {
      i: 'o',
      x: 0,
      y: 36,
      w: 6,
      h: 10,
      minW: 4,
      maxW: 12,
      minH: 9,
      maxH: 12
    },
    {
      i: 'p',
      x: 6,
      y: 36,
      w: 6,
      h: 10,
      minW: 4,
      maxW: 12,
      minH: 9,
      maxH: 12
    },
    {
      i: 'q',
      x: 0,
      y: 46,
      w: 6,
      h: 12,
      minW: 4,
      maxW: 12,
      minH: 6,
      maxH: 12
    },
    {
      i: 'r',
      x: 6,
      y: 46,
      w: 6,
      h: 12,
      minW: 4,
      maxW: 12,
      minH: 6,
      maxH: 12
    },
    {
      i: 's',
      x: 0,
      y: 56,
      w: 6,
      h: 12,
      minW: 3,
      maxW: 12,
      minH: 9,
      maxH: 12
    },
    {
      i: 't',
      x: 6,
      y: 56,
      w: 6,
      h: 12,
      minW: 4,
      maxW: 12,
      minH: 9,
      maxH: 12
    }
  ],
  sm: [
    {
      i: 'a',
      x: 0,
      y: 0,
      w: 12,
      h: 4,
      minW: 5,
      maxW: 12,
      maxH: 6,
      minH: 4
    },
    {
      i: 'c',
      x: 0,
      y: 4,
      w: 12,
      h: 4,
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 5
    },
    {
      i: 'd',
      x: 0,
      y: 8,
      w: 12,
      h: 4,
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 5
    },
    {
      i: 'e',
      x: 6,
      y: 12,
      w: 12,
      h: 4,
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 5
    },
    {
      i: 'f',
      x: 9,
      y: 16,
      w: 12,
      h: 4,
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 5
    },
    {
      i: 'g',
      x: 0,
      y: 20,
      w: 12,
      h: 4,
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 6
    },
    {
      i: 'h',
      x: 0,
      y: 24,
      w: 12,
      h: 4,
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 6
    },
    {
      i: 'i',
      x: 0,
      y: 28,
      w: 12,
      h: 4,
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 6
    },
    {
      i: 'j',
      x: 0,
      y: 32,
      w: 12,
      h: 4,
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 6
    },
    {
      i: 'k',
      x: 0,
      y: 36,
      w: 12,
      h: 10,
      minW: 5,
      maxW: 12,
      minH: 9,
      maxH: 15
    },
    {
      i: 'l',
      x: 6,
      y: 46,
      w: 12,
      h: 5,
      minW: 6,
      maxW: 12,
      minH: 4,
      maxH: 10
    },
    {
      i: 'z',
      x: 0,
      y: 51,
      w: 12,
      h: 5,
      minW: 6,
      maxW: 12,
      minH: 4,
      maxH: 10
    },
    {
      i: 'm',
      x: 0,
      y: 56,
      w: 12,
      h: 10,
      minW: 8,
      maxW: 12,
      minH: 9,
      maxH: 12
    },
    {
      i: 'n',
      x: 0,
      y: 66,
      w: 12,
      h: 5,
      minW: 6,
      maxW: 12,
      minH: 4,
      maxH: 6
    },
    {
      i: 'y',
      x: 0,
      y: 71,
      w: 12,
      h: 5,
      minW: 6,
      maxW: 12,
      minH: 4,
      maxH: 6
    },
    {
      i: 'o',
      x: 0,
      y: 76,
      w: 12,
      h: 10,
      minW: 8,
      maxW: 12,
      minH: 10,
      maxH: 12
    },
    {
      i: 'p',
      x: 0,
      y: 86,
      w: 12,
      h: 10,
      minW: 8,
      maxW: 12,
      minH: 10,
      maxH: 12
    },
    {
      i: 'q',
      x: 0,
      y: 96,
      w: 12,
      h: 10,
      minW: 10,
      maxW: 12,
      minH: 6,
      maxH: 12
    },
    {
      i: 'r',
      x: 0,
      y: 106,
      w: 12,
      h: 6,
      minW: 8,
      maxW: 12,
      minH: 6,
      maxH: 12
    },
    {
      i: 's',
      x: 0,
      y: 112,
      w: 12,
      h: 8,
      minW: 6,
      maxW: 12,
      minH: 9,
      maxH: 12
    },
    {
      i: 't',
      x: 0,
      y: 118,
      w: 12,
      h: 8,
      minW: 6,
      maxW: 12,
      minH: 9,
      maxH: 12
    }
  ]
};

export const Layout_2 = {
  xl: [
    {
      i: 'a',
      x: 0,
      y: 0,
      w: 4,
      h: 4,
      minW: 3,
      maxW: 5,
      minH: 4,
      maxH: 6
    },
    {
      i: 'c',
      x: 4,
      y: 0,
      w: 2,
      h: 4,
      minW: 2,
      maxW: 4,
      minH: 4,
      maxH: 6
    },
    {
      i: 'd',
      x: 6,
      y: 0,
      w: 2,
      h: 4,
      minW: 2,
      maxW: 4,
      minH: 4,
      maxH: 6
    },
    {
      i: 'e',
      x: 8,
      y: 0,
      w: 2,
      h: 4,
      minW: 2,
      maxW: 4,
      minH: 4,
      maxH: 6
    },
    {
      i: 'f',
      x: 10,
      y: 0,
      w: 2,
      h: 4,
      minW: 2,
      maxW: 4,
      minH: 4,
      maxH: 6
    },
    {
      i: 'g',
      x: 3,
      y: 14,
      w: 3,
      h: 4,
      minW: 3,
      maxW: 5,
      minH: 4,
      maxH: 8
    },
    {
      i: 'h',
      x: 6,
      y: 14,
      w: 3,
      h: 4,
      minW: 2,
      maxW: 5,
      minH: 4,
      maxH: 8
    },
    {
      i: 'i',
      x: 0,
      y: 14,
      w: 3,
      h: 4,
      minW: 3,
      maxW: 5,
      minH: 4,
      maxH: 8
    },
    {
      i: 'j',
      x: 9,
      y: 4,
      w: 3,
      h: 4,
      minW: 2,
      maxW: 5,
      minH: 4,
      maxH: 8
    },
    {
      i: 'k',
      x: 5,
      y: 4,
      w: 2,
      h: 10,
      minW: 2,
      maxW: 3,
      minH: 9,
      maxH: 15
    },
    {
      i: 'l',
      x: 7,
      y: 4,
      w: 2,
      h: 5,
      minW: 2,
      maxW: 4,
      minH: 4,
      maxH: 10
    },
    {
      i: 'z',
      x: 7,
      y: 9,
      w: 2,
      h: 5,
      minW: 2,
      maxW: 4,
      minH: 4,
      maxH: 10
    },
    {
      i: 'm',
      x: 0,
      y: 4,
      w: 5,
      h: 10,
      minW: 4,
      maxW: 7,
      minH: 9,
      maxH: 12
    },
    {
      i: 'n',
      x: 9,
      y: 8,
      w: 3,
      h: 5,
      minW: 2,
      maxW: 5,
      minH: 4,
      maxH: 6
    },
    {
      i: 'y',
      x: 9,
      y: 13,
      w: 3,
      h: 5,
      minW: 2,
      maxW: 5,
      minH: 4,
      maxH: 6
    },
    {
      i: 'o',
      x: 0,
      y: 18,
      w: 6,
      h: 10,
      minW: 4,
      maxW: 10,
      minH: 9,
      maxH: 12
    },
    {
      i: 'p',
      x: 6,
      y: 18,
      w: 6,
      h: 10,
      minW: 4,
      maxW: 10,
      minH: 9,
      maxH: 12
    },
    {
      i: 'q',
      x: 0,
      y: 28,
      w: 6,
      h: 12,
      minW: 4,
      maxW: 10,
      minH: 9,
      maxH: 12
    },
    {
      i: 'r',
      x: 6,
      y: 28,
      w: 6,
      h: 12,
      minW: 2,
      maxW: 10,
      minH: 9,
      maxH: 12
    },
    {
      i: 's',
      x: 0,
      y: 38,
      w: 6,
      h: 12,
      minW: 2,
      maxW: 10,
      minH: 9,
      maxH: 12
    },
    {
      i: 't',
      x: 6,
      y: 38,
      w: 6,
      h: 12,
      minW: 2,
      maxW: 10,
      minH: 9,
      maxH: 12
    }
  ],
  md: [
    {
      i: 'a',
      x: 0,
      y: 0,
      w: 12,
      h: 4,
      minW: 5,
      maxW: 12,
      maxH: 6,
      minH: 4
    },
    {
      i: 'c',
      x: 0,
      y: 4,
      w: 3,
      h: 4,
      minW: 2,
      maxW: 4,
      minH: 4,
      maxH: 5
    },
    {
      i: 'd',
      x: 3,
      y: 4,
      w: 3,
      h: 4,
      minW: 2,
      maxW: 4,
      minH: 4,
      maxH: 5
    },
    {
      i: 'e',
      x: 6,
      y: 4,
      w: 3,
      h: 4,
      minW: 2,
      maxW: 4,
      minH: 4,
      maxH: 5
    },
    {
      i: 'f',
      x: 9,
      y: 4,
      w: 3,
      h: 4,
      minW: 2,
      maxW: 4,
      minH: 4,
      maxH: 5
    },
    {
      i: 'g',
      x: 0,
      y: 8,
      w: 6,
      h: 4,
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 6
    },
    {
      i: 'h',
      x: 6,
      y: 8,
      w: 6,
      h: 4,
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 6
    },
    {
      i: 'i',
      x: 0,
      y: 12,
      w: 6,
      h: 4,
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 6
    },
    {
      i: 'j',
      x: 6,
      y: 12,
      w: 6,
      h: 4,
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 6
    },
    {
      i: 'k',
      x: 0,
      y: 16,
      w: 6,
      h: 10,
      minW: 4,
      maxW: 12,
      minH: 9,
      maxH: 15
    },
    {
      i: 'l',
      x: 6,
      y: 16,
      w: 6,
      h: 5,
      minW: 3,
      maxW: 12,
      minH: 4,
      maxH: 10
    },
    {
      i: 'z',
      x: 9,
      y: 16,
      w: 6,
      h: 5,
      minW: 3,
      maxW: 12,
      minH: 4,
      maxH: 10
    },
    {
      i: 'm',
      x: 0,
      y: 26,
      w: 8,
      h: 10,
      minW: 5,
      maxW: 12,
      minH: 9,
      maxH: 12
    },
    {
      i: 'n',
      x: 8,
      y: 26,
      w: 4,
      h: 5,
      minW: 3,
      maxW: 6,
      minH: 4,
      maxH: 6
    },
    {
      i: 'y',
      x: 8,
      y: 26,
      w: 4,
      h: 5,
      minW: 3,
      maxW: 6,
      minH: 4,
      maxH: 6
    },
    {
      i: 'o',
      x: 0,
      y: 36,
      w: 6,
      h: 10,
      minW: 4,
      maxW: 12,
      minH: 9,
      maxH: 12
    },
    {
      i: 'p',
      x: 6,
      y: 36,
      w: 6,
      h: 10,
      minW: 4,
      maxW: 12,
      minH: 9,
      maxH: 12
    },
    {
      i: 'q',
      x: 0,
      y: 46,
      w: 4,
      h: 12,
      minW: 4,
      maxW: 12,
      minH: 6,
      maxH: 12
    },
    {
      i: 'r',
      x: 4,
      y: 46,
      w: 5,
      h: 12,
      minW: 4,
      maxW: 12,
      minH: 6,
      maxH: 12
    },
    {
      i: 's',
      x: 0,
      y: 56,
      w: 6,
      h: 12,
      minW: 3,
      maxW: 12,
      minH: 9,
      maxH: 12
    },
    {
      i: 't',
      x: 6,
      y: 56,
      w: 6,
      h: 12,
      minW: 3,
      maxW: 12,
      minH: 9,
      maxH: 12
    }
  ],
  sm: [
    {
      i: 'a',
      x: 0,
      y: 0,
      w: 12,
      h: 4,
      minW: 5,
      maxW: 12,
      maxH: 6,
      minH: 4
    },
    {
      i: 'c',
      x: 0,
      y: 4,
      w: 12,
      h: 4,
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 5
    },
    {
      i: 'd',
      x: 0,
      y: 8,
      w: 12,
      h: 4,
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 5
    },
    {
      i: 'e',
      x: 6,
      y: 12,
      w: 12,
      h: 4,
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 5
    },
    {
      i: 'f',
      x: 9,
      y: 16,
      w: 12,
      h: 4,
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 5
    },
    {
      i: 'g',
      x: 0,
      y: 20,
      w: 12,
      h: 4,
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 6
    },
    {
      i: 'h',
      x: 0,
      y: 24,
      w: 12,
      h: 4,
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 6
    },
    {
      i: 'i',
      x: 0,
      y: 28,
      w: 12,
      h: 4,
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 6
    },
    {
      i: 'j',
      x: 0,
      y: 32,
      w: 12,
      h: 4,
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 6
    },
    {
      i: 'k',
      x: 0,
      y: 36,
      w: 12,
      h: 10,
      minW: 5,
      maxW: 12,
      minH: 9,
      maxH: 15
    },
    {
      i: 'l',
      x: 6,
      y: 46,
      w: 12,
      h: 5,
      minW: 6,
      maxW: 12,
      minH: 4,
      maxH: 10
    },
    {
      i: 'z',
      x: 0,
      y: 51,
      w: 12,
      h: 5,
      minW: 6,
      maxW: 12,
      minH: 4,
      maxH: 10
    },
    {
      i: 'm',
      x: 0,
      y: 56,
      w: 12,
      h: 10,
      minW: 8,
      maxW: 12,
      minH: 9,
      maxH: 12
    },
    {
      i: 'n',
      x: 0,
      y: 66,
      w: 12,
      h: 5,
      minW: 6,
      maxW: 12,
      minH: 4,
      maxH: 6
    },
    {
      i: 'y',
      x: 0,
      y: 71,
      w: 12,
      h: 5,
      minW: 6,
      maxW: 12,
      minH: 4,
      maxH: 6
    },
    {
      i: 'o',
      x: 0,
      y: 76,
      w: 12,
      h: 10,
      minW: 8,
      maxW: 12,
      minH: 10,
      maxH: 12
    },
    {
      i: 'p',
      x: 0,
      y: 86,
      w: 12,
      h: 10,
      minW: 8,
      maxW: 12,
      minH: 10,
      maxH: 12
    },
    {
      i: 'q',
      x: 0,
      y: 96,
      w: 12,
      h: 10,
      minW: 10,
      maxW: 12,
      minH: 6,
      maxH: 12
    },
    {
      i: 'r',
      x: 0,
      y: 106,
      w: 12,
      h: 6,
      minW: 8,
      maxW: 12,
      minH: 6,
      maxH: 12
    },
    {
      i: 's',
      x: 0,
      y: 116,
      w: 12,
      h: 8,
      minW: 6,
      maxW: 12,
      minH: 9,
      maxH: 12
    },
    {
      i: 't',
      x: 0,
      y: 126,
      w: 12,
      h: 8,
      minW: 6,
      maxW: 12,
      minH: 9,
      maxH: 12
    }
  ]
};

export const dashboardCardName = {
  a: 'welcome tile',
  c: 'total item',
  d: 'total order',
  e: 'total customer',
  f: 'total supplier',
  g: 'gross profit summary',
  h: 'cheque summary',
  i: 'weekly sales',
  j: 'weather',
  k: 'contract details',
  l: 'total receivables',
  z: 'total payable',
  m: 'daily based turn over',
  n: 'sales order completion progress',
  y: 'payment received progress',
  o: 'total sales',
  p: 'daily based turn over',
  q: 'best selling products',
  r: 'unpaid invoices',
  s: 'profit vs loss'
};

import React from 'react';
import PropTypes from 'prop-types';
import useAcodaxNumberFormat from 'hooks/useAcodaxNumberFormat';
import { FaEdit, FaTrash } from 'react-icons/fa';
const AdditionalInvoiceLineItem = ({
  items,
  handleEdit,
  handleDeleteLineItem
}) => {
  const acodaxNumberFormat = useAcodaxNumberFormat();
  return (
    <tr className="text-dark">
      <td className="text-center">{items?.stock_id}</td>
      <td className="d-flex flex-column">
        <span>{items?.item_display_name}</span>
        {items?.description ? (
          <span className="ms-3">
            -<span className="text-muted ms-2">{items?.description}</span>
          </span>
        ) : (
          ''
        )}
      </td>
      <td className="text-center">{acodaxNumberFormat(items?.quantity)}</td>
      <td className="text-center">{acodaxNumberFormat(items?.unit_price)}</td>
      <td className="text-center">{acodaxNumberFormat(items?.line_total)}</td>
      <td className=" text-center">
        <FaEdit
          className="me-2 cursor-pointer"
          color="#2c7be5"
          onClick={handleEdit}
        />
        <FaTrash
          className="text-danger cursor-pointer"
          onClick={handleDeleteLineItem}
        />
      </td>
    </tr>
  );
};
AdditionalInvoiceLineItem.propTypes = {
  items: PropTypes.array,
  handleEdit: PropTypes.func,
  handleDeleteLineItem: PropTypes.func
};
export default AdditionalInvoiceLineItem;

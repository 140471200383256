import { React, useContext, useEffect, useRef } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import './style.scss';
import AppContext, { AuthWizardContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import ReportsContainerNav from './ReportsContainerNav';
import Export from 'module/Report/Common/Export';
import SimpleBar from 'simplebar-react';
import AppPagination from 'components/common/app-pagination/AppPagination';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import SortReport from 'module/Report/Common/SortReport';
import Filter from 'module/Common/TableFilter/Filter';
import RowsPerPage from './RowsPerPage';

const ReportsContainer = ({
  title,
  children,
  url,
  initialMessage,
  successMessage,
  data,
  paginationUrl,
  type,
  loading,
  showPagination,
  formData,
  sort,
  sortValue,
  dateFrom,
  dateTo,
  moduleName,
  sowFilterForm,
  hideHeading,
  HideBackBrowser,
  showPerson,
  ledger,
  year,
  newExportMethod,
  showRowsPerPage,
  custom_height,
  fromFixedAsset,
  dropDownData,
  enableDropDown,
  interBranch,
  reportBasedSimpleBarstyle,
  totalAmount,
  validations,
  showTransactionCount
}) => {
  const {
    config: { isNavbarVerticalCollapsed, pinSidebar }
  } = useContext(AppContext);
  const { setConfig } = useContext(AppContext);
  const { user } = useContext(AuthWizardContext);
  const Translate = useAxisproTranslate();
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const navigate = useNavigate();
  const isInitialRender = useRef(true);
  useEffect(() => {
    if (!HideBackBrowser) {
      const handlePopstate = event => {
        if (event.state) {
          navigate('/reports');
        }
      };
      window.addEventListener('popstate', handlePopstate);

      return () => {
        window.removeEventListener('popstate', handlePopstate);
      };
    }
  }, [HideBackBrowser, navigate]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      if (!pinSidebar) {
        let HTML = document.getElementsByTagName('html')[0];
        if (!isNavbarVerticalCollapsed) {
          setConfig('isNavbarVerticalCollapsed', true);

          HTML.classList.add('navbar-vertical-collapsed');
        } else {
          HTML.classList.remove('navbar-vertical-collapsed-hover');
        }
        return () => {
          HTML.classList.remove('navbar-vertical-collapsed');
        };
      }
    }
  }, [isNavbarVerticalCollapsed, pinSidebar, setConfig]);

  return (
    <div className="position-relative report-container">
      <Card className="position-sticky mb-2">
        <Card.Header className="d-flex flex-column p-0 shadow-sm">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex  p-0 align-items-center">
              <div className="hdr-menu-btn border-end  p-2">
                <ReportsContainerNav />
              </div>
              {!hideHeading && (
                <div className="ps-3 ">
                  <h6 className="fs-1 mb-0 text-uppercase text-primary pt-3 p-0">
                    {Translate(title)}
                  </h6>
                  {user && user.branch && user.branch.company_name && (
                    <div className="d-flex ">
                      <div className="mb-1" style={{ fontSize: '10px' }}>
                        {user?.branch?.company_name}
                      </div>
                      {/* {!dateFrom && !dateTo && ledger && (
                      <div
                        className="text-secondary ms-4 "
                        style={{ fontSize: '10px' }}
                      >
                        {Translate('This Month')}
                      </div>
                    )} */}
                      {dateFrom && dateTo && (
                        <div
                          className="text-secondary ms-4 "
                          style={{ fontSize: '10px' }}
                        >
                          {Translate('From')}&nbsp;:&nbsp;{dateFrom}
                          &nbsp;&nbsp;&nbsp;&nbsp;{Translate('To')}
                          &nbsp;:&nbsp;
                          {dateTo}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="hdr-close-btn   d-flex">
              {sort && (
                <div className="d-flex align-items-center me-2 border-start ps-2">
                  <SortReport sortOptions={sortValue} />
                </div>
              )}
              {url && (
                <div className="py-2  h-100 d-flex border-start">
                  <Export
                    url={url}
                    successMessage={successMessage}
                    formData={ledger && formData}
                    initialMessage={initialMessage}
                    newExportMethod={newExportMethod}
                    interBranch={interBranch}
                    validations={validations}
                  />
                </div>
              )}
              <div className="border-start p-0 d-flex align-items-center justify-content-center ">
                <Button
                  variant="transparent"
                  as={Link}
                  to="/reports"
                  style={{ boxShadow: 'none', border: 'none' }}
                >
                  <FaTimes />
                </Button>
              </div>
            </div>
          </div>
          {totalAmount && (
            <div className="border-top py-2 px-3">
              <div className="d-flex justify-content-end align-items-center">
                <span className="me-2 text-dark" style={{ fontSize: 15 }}>
                  {Translate('Total Asset Value')} :
                </span>
                <span className="fw-bold text-primary" style={{ fontSize: 18 }}>
                  {totalAmount}
                </span>
              </div>
            </div>
          )}
        </Card.Header>
        {!sowFilterForm && (
          <div className="pt-1 ">
            <Filter
              moduleName={moduleName}
              showPerson={showPerson}
              year={year}
              report={true}
              fromFixedAsset={fromFixedAsset}
              dropDownData={dropDownData}
              enableDropDown={enableDropDown}
            />
          </div>
        )}
        {(data && !loading) || type ? (
          <SimpleBar
            className={`${
              type
                ? ledger
                  ? 'simplebar-report-for-ledger-report-pagination-style'
                  : 'simplebar-report-for-ledger-pagination-style'
                : paginationUrl
                ? reportBasedSimpleBarstyle
                  ? reportBasedSimpleBarstyle?.withPagination
                  : 'simplebar-report-style'
                : reportBasedSimpleBarstyle
                ? reportBasedSimpleBarstyle?.withOutPagination
                : 'simplebar-report-no-pagination-style new_style'
            } report-table-simple-scroll-bar-color-style`}
            style={{
              height: custom_height && `${custom_height}vh`
            }}
            forceVisible="y"
            autoHide={false}
          >
            <Card.Body className="d-flex flex-column align-items-center p-0">
              {children}
            </Card.Body>
          </SimpleBar>
        ) : (
          <Card
            style={{
              height: type ? '75vh' : custom_height ? custom_height : '75.5vh',
              boxShadow: 'none'
            }}
          >
            <LoadingScreen message="Generating Report!" />
          </Card>
        )}
        {paginationUrl && !showPagination && !loading ? (
          <>
            <Card.Footer className="d-flex align-items-center footer p-2">
              <AppPagination
                data={data}
                url={paginationUrl}
                search={allQueryParams}
                showTransactionCount={showTransactionCount}
              />
              {showRowsPerPage && <RowsPerPage />}
            </Card.Footer>
          </>
        ) : null}
      </Card>
    </div>
  );
};

ReportsContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  dropDownData: PropTypes.any,
  url: PropTypes.string.isRequired,
  initialMessage: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  data: PropTypes.any,
  paginationUrl: PropTypes.any,
  type: PropTypes.bool,
  loading: PropTypes.bool,
  showPagination: PropTypes.bool,
  formData: PropTypes.object,
  sort: PropTypes.bool,
  sortValue: PropTypes.array,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  moduleName: PropTypes.any,
  sowFilterForm: PropTypes.bool,
  hideHeading: PropTypes.bool,
  HideBackBrowser: PropTypes.bool,
  showPerson: PropTypes.bool,
  ledger: PropTypes.bool,
  year: PropTypes.array,
  newExportMethod: PropTypes.bool,
  showRowsPerPage: PropTypes.bool,
  fromFixedAsset: PropTypes.bool,
  custom_height: PropTypes.string,
  enableDropDown: PropTypes.bool,
  interBranch: PropTypes.bool,
  reportBasedSimpleBarstyle: PropTypes.any,
  totalAmount: PropTypes.bool,
  validations: PropTypes.array,
  showTransactionCount: PropTypes.bool
};

export default ReportsContainer;

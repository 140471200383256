import SelectItem from 'components/form/SelectItem';
import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FaCirclePlus } from 'react-icons/fa6';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { AiFillCloseCircle } from 'react-icons/ai';
import useAcodaxNumberFormat from 'hooks/useAcodaxNumberFormat';
function LineItemForm({
  edit,
  handleEditItem,
  itemFromData,
  handleFieldChange,
  handleAddLineItem,
  handleCloseEdit
}) {
  const acodaxNumberFormat = useAcodaxNumberFormat();
  return (
    <tr>
      <td>
        <Form.Control name="stock_id" disabled value={itemFromData?.stock_id} />
      </td>
      <td>
        <SelectItem
          name="item"
          handleFieldChange={handleFieldChange}
          value={itemFromData?.item_ref}
          filter_keys={{
            exclude_kits: '1',
            product_type: 'service'
          }}
        />
        <Form.Control
          type="text"
          as="textarea"
          className="mt-1"
          placeholder="Enter Description"
          name="description"
          rows={1}
          value={itemFromData?.description}
          onChange={handleFieldChange}
        />
      </td>
      <td>
        <Form.Control
          name="quantity"
          onChange={handleFieldChange}
          value={itemFromData?.quantity}
        />
      </td>
      <td>
        <Form.Control
          name="unit_price"
          onChange={handleFieldChange}
          value={itemFromData?.unit_price}
        />
      </td>
      <td className="text-center">
        {acodaxNumberFormat(itemFromData?.line_total)}
      </td>
      <td>
        {' '}
        <div className="d-flex align-items-center justify-content-center">
          {edit ? (
            <div>
              <IoIosCheckmarkCircle
                color="green"
                size={17}
                onClick={handleEditItem}
                className="cursor-pointer"
              />
              <AiFillCloseCircle
                color="red"
                size={16}
                onClick={handleCloseEdit}
                className="cursor-pointer ms-1"
              />
            </div>
          ) : (
            <FaCirclePlus
              color="green"
              size={16}
              onClick={handleAddLineItem}
              className="cursor-pointer"
            />
          )}
        </div>
      </td>
    </tr>
  );
}
LineItemForm.propTypes = {
  edit: PropTypes.bool,
  handleEditItem: PropTypes.func,
  itemFromData: PropTypes.object,
  handleFieldChange: PropTypes.func,
  handleAddLineItem: PropTypes.func,
  handleCloseEdit: PropTypes.func
};
export default LineItemForm;

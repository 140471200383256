import { React, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { AuthWizardContext, StoreContext } from 'context/Context';
import PersonName from '../Common/ViewPageDetails/PersonName';
import Badges from '../Common/ViewPageDetails/Badges';
import Details from '../Common/ViewPageDetails/Details';
import { BiLogoGmail } from 'react-icons/bi';
import { FaCentercode, FaPhoneAlt } from 'react-icons/fa';
import { BsCoin } from 'react-icons/bs';
import ShowMemo from '../Common/ViewPageDetails/ShowMemo';
import { FaWarehouse } from 'react-icons/fa6';
import EntriesPopUpLineItemAccordionView from 'components/entries-pop-up-form/EntriesPopUpLineItemAccordionView/EntriesPopUpLineItemAccordionView';
function PurchaseDeliveryView({ purchaseDeliveryData }) {
  const { user } = useContext(AuthWizardContext);
  const enableBatchSection = !!+user?.branch?.inventory_settings?.enable_batch;
  const { store } = useContext(StoreContext);
  const taxLabel = store?.globalConfiguration?.tax_label || 'Tax';
  return (
    <Container className="mt-3 ps-3 pe-3 voucher-inquiry-view-wrapper">
      <Row>
        <Col lg={6} md={6} sm={12} className="p-0">
          {purchaseDeliveryData?.supplier_name ? (
            <PersonName value={purchaseDeliveryData?.supplier_name} />
          ) : null}
          {purchaseDeliveryData?.reference && (
            <Badges label={purchaseDeliveryData?.reference} bg={'danger'} />
          )}
          <div className="mt-2">
            {purchaseDeliveryData?.cost_center_name && (
              <Details
                label={'Cost Center'}
                value={purchaseDeliveryData?.cost_center_name}
                className={'mb-2 fs--1'}
                icon={<FaCentercode className="me-1 text-warning" size={15} />}
              />
            )}
            {purchaseDeliveryData?.warehouse_name && (
              <Details
                label={'Warehouse'}
                value={purchaseDeliveryData.warehouse_name}
                icon={<FaWarehouse className="me-1 text-dark" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
            {purchaseDeliveryData?.phone && (
              <Details
                label={'Phone Number'}
                value={purchaseDeliveryData?.phone}
                icon={<FaPhoneAlt className="me-1 text-dark" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
            {purchaseDeliveryData?.email && (
              <Details
                label={'Email Address'}
                value={purchaseDeliveryData?.email}
                icon={<BiLogoGmail className="me-1 text-danger" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
          </div>
        </Col>
        <Col lg={6} md={6} sm={12} className="p-0">
          <div className="mt-sm-2 mt-md-5">
            <Details
              label={
                purchaseDeliveryData?.tax_included &&
                purchaseDeliveryData?.tax_included === 1
                  ? `Amount (Inc.${taxLabel})`
                  : 'Amount'
              }
              value={purchaseDeliveryData?.amount_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={`Total ${taxLabel}`}
              value={purchaseDeliveryData?.total_tax_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={'Total Discount'}
              value={
                purchaseDeliveryData?.total_discount_amount_formatted ?? '0.00'
              }
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={12} md={12} sm={12} className="p-0 mt-2">
          <EntriesPopUpLineItemAccordionView
            type="PD"
            entries={purchaseDeliveryData.details}
            enableBatchSection={enableBatchSection}
          />
        </Col>
      </Row>
      {purchaseDeliveryData?.memo && (
        <ShowMemo memo={purchaseDeliveryData?.memo} />
      )}
    </Container>
  );
}

PurchaseDeliveryView.propTypes = {
  purchaseDeliveryData: PropTypes.any
};

export default PurchaseDeliveryView;

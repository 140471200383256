import AppDateTimePicker from 'components/app-date-time-picker/AppDateTimePicker';
import React from 'react';
import { Button } from 'react-bootstrap';
import { FaCalendar } from 'react-icons/fa';
import PropTypes from 'prop-types';

function DateTimePickerFilter({
  item,
  formData,
  handleFieldChange,
  dateTimePickerName,
  setDateTimePickerName
}) {
  return (
    <div className="d-flex flex-row">
      <Button
        variant="transparent"
        size="sm"
        onClick={() => setDateTimePickerName(item.name)}
        className="px-2 align-self-stretch d-flex justify-content-center align-items-center customer-advance-search-button-style"
      >
        <FaCalendar color="#344050" className="zIndex-1" size={10} />
      </Button>
      <AppDateTimePicker
        filter
        name={item.name}
        value={formData[item.name]}
        yearPlaceholder="yyyy"
        monthPlaceholder="mm"
        dayPlaceholder="dd"
        hourPlaceholder="hh"
        minutePlaceholder="mm"
        onChange={handleFieldChange}
        dateTimePickerName={dateTimePickerName}
        setDateTimePickerName={setDateTimePickerName}
      />
    </div>
  );
}
DateTimePickerFilter.propTypes = {
  item: PropTypes.object,
  formData: PropTypes.object,
  handleFieldChange: PropTypes.func,
  dateTimePickerName: PropTypes.string,
  setDateTimePickerName: PropTypes.func
};

export default DateTimePickerFilter;

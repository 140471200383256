export const receivablesAndPayables = [5, 5, 5, 5, 3, 3, 4, 4, 3, 4, 3, 4, 4];
export const receivablesAndPayablesForFinance = [5, 5, 5, 5, 3, 3, 4, 4, 3, 4];
export const contractDetails = [
  3, 3, 4, 3, 3, 4, 6, 5, 6, 5, 4, 3, 4, 5, 5, 5, 5, 3, 3, 4, 4, 3, 4, 4, 3, 4,
  5, 5, 5, 5, 3, 3, 4, 4, 3, 4
];
export const countTile = [5, 3, 4, 5, 6, 3, 5, 6, 4, 3, 4];
export const grossProfit = [5, 3, 4, 5, 6, 3, 5, 6];
export const chequeSummary = [5, 3, 4, 5, 6, 3, 5, 6];
export const weeklySales = [5, 3, 4, 5, 6, 3, 5, 6];
export const dailyBasedTurnOver = [
  3, 3, 4, 3, 3, 4, 6, 5, 6, 5, 4, 3, 4, 5, 5, 5, 5, 3, 3, 4, 4, 3, 4, 4, 3, 4,
  5, 5, 5, 5, 3, 3, 4
];
export const totalSales = [
  3, 3, 4, 3, 3, 4, 6, 5, 6, 5, 4, 3, 4, 5, 5, 5, 5, 3, 3, 4, 4, 3, 4, 4, 3, 4,
  5, 5, 5, 5
];
export const bestSellingProducts = [
  5, 3, 5, 3, 5, 3, 6, 3, 6, 3, 6, 3, 4, 5, 4, 5, 4, 5, 5, 6, 5, 6, 5, 6, 3, 4,
  4, 3, 4, 4, 3, 4, 4
];
export const unpaidInvoice = [
  5, 3, 5, 3, 5, 3, 6, 3, 6, 3, 6, 3, 4, 5, 4, 5, 4, 5, 5, 6, 5, 6, 5, 6, 3, 4,
  4, 3, 4, 4, 3, 4, 4
];

export const IncomeVsExpense = [
  3, 3, 4, 3, 3, 4, 6, 5, 6, 5, 4, 3, 4, 5, 5, 5, 5, 3, 3, 4, 4, 3, 4, 4, 3, 4,
  5, 5, 5, 5
];

export const grossProfitSummary = [
  3, 3, 4, 3, 3, 4, 6, 5, 6, 5, 4, 3, 4, 5, 5, 5, 5, 3, 3, 4, 4, 3, 4, 4, 3, 4,
  5, 5, 5, 5
];

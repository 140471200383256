import { React, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { BiLogoGmail } from 'react-icons/bi';
import { FaCentercode, FaPhoneAlt, FaWarehouse } from 'react-icons/fa';
import {
  MdOutlineLocalShipping,
  MdOutlineMan3,
  MdPayment
} from 'react-icons/md';
import { BsCoin } from 'react-icons/bs';

import LineItemTable from '../Common/Tables/LineItemTable';
import ShowCustomFieldValues from '../Common/ViewPageDetails/ShowCustomFieldValues';
import PersonName from '../Common/ViewPageDetails/PersonName';
import Badges from '../Common/ViewPageDetails/Badges';
import Details from '../Common/ViewPageDetails/Details';
import QuillPreviewContainer from 'components/QuillPreviewContainer/QuillPreviewContainer';
import { IoDocumentTextSharp } from 'react-icons/io5';
import { StoreContext } from 'context/Context';

function SalesOrderView({ salesOrderData }) {
  const { store } = useContext(StoreContext);
  const taxLabel = store?.globalConfiguration?.tax_label || 'Tax';
  return (
    <Container className="mt-3 ps-3 pe-3 voucher-inquiry-view-wrapper">
      <Row>
        <Col lg={6} md={6} sm={12} className="p-0">
          {salesOrderData?.customer_name && (
            <PersonName value={salesOrderData?.customer_name} />
          )}
          {salesOrderData?.reference && (
            <Badges label={salesOrderData?.reference} bg={'danger'} />
          )}
          <div className="mt-2">
            {salesOrderData?.customer_lpo_number && (
              <Details
                label={'Customer LPO Number'}
                value={salesOrderData?.customer_lpo_number}
                className={'mb-2 fs--1'}
                iconColor={'text-info'}
              />
            )}
            {salesOrderData?.customer_lpo_date_formatted && (
              <Details
                label={'Customer LPO Date'}
                value={salesOrderData?.customer_lpo_date_formatted}
                className={'mb-2 fs--1'}
                iconColor={'text-warning'}
              />
            )}
            {salesOrderData?.email && (
              <Details
                label={'Email Address'}
                value={salesOrderData?.email}
                icon={<BiLogoGmail className="me-1 text-danger" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
            {salesOrderData?.mobile && (
              <Details
                label={'Phone Number'}
                value={salesOrderData?.mobile}
                icon={<FaPhoneAlt className="me-1 text-dark" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
            {salesOrderData?.sales_type_name && (
              <Details
                label={'Sales Type'}
                value={salesOrderData?.sales_type_name}
                className={'mb-2 fs--1'}
                iconColor={'text-info'}
              />
            )}
            {salesOrderData?.warehouse_name && (
              <Details
                label={'Delivery Location'}
                value={salesOrderData?.warehouse_name}
                className={'mb-2 fs--1'}
                icon={<FaWarehouse className="me-1 text-dark" size={15} />}
              />
            )}
            {salesOrderData?.customer_shipping_address && (
              <Details
                label={'Shipping Address'}
                value={[
                  salesOrderData?.customer_shipping_address?.address1,
                  salesOrderData?.customer_shipping_address?.city,
                  salesOrderData?.customer_shipping_address?.state,
                  salesOrderData?.customer_shipping_address?.zip_code,
                  salesOrderData?.customer_shipping_address?.country
                ]
                  .filter(Boolean)
                  .join(', ')}
                className={'mb-2 fs--1'}
                icon={
                  <MdOutlineLocalShipping
                    className="me-1 text-dark"
                    size={16}
                  />
                }
              />
            )}
            {salesOrderData?.cost_center_name && (
              <Details
                label={'Cost Center'}
                value={salesOrderData?.cost_center_name}
                className={'mb-2 fs--1'}
                icon={<FaCentercode className="me-1 text-warning" size={15} />}
              />
            )}
            {salesOrderData?.payment_term_name && (
              <Details
                label={'Payment Term'}
                value={salesOrderData?.payment_term_name}
                className={'mb-2 fs--1'}
                icon={<MdPayment className="me-1 text-success" size={15} />}
              />
            )}
            {salesOrderData?.salesman_name && (
              <Details
                label={'Salesman Name'}
                value={salesOrderData?.salesman_name}
                className={'mb-2 fs--1'}
                icon={<MdOutlineMan3 className="me-1 text-dark" size={15} />}
              />
            )}
            {salesOrderData?.fa_stock_id && (
              <Details
                label={'Asset'}
                value={salesOrderData?.fa_stock_name}
                className={'mb-2 fs--1 text-capitalize'}
                icon={
                  <IoDocumentTextSharp
                    className="me-1  text-primary"
                    size={15}
                  />
                }
              />
            )}
          </div>
        </Col>
        <Col lg={6} md={6} sm={12} className="p-0">
          <div className="mt-sm-2 mt-md-5">
            <Details
              label={
                salesOrderData?.tax_included &&
                salesOrderData?.tax_included === '1'
                  ? `Amount (Inc.${taxLabel})`
                  : 'Amount'
              }
              value={salesOrderData?.amount_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={`Total ${taxLabel}`}
              value={salesOrderData?.total_tax_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={'Total Discount'}
              value={salesOrderData?.total_discount_amount_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
          </div>
        </Col>
      </Row>
      {salesOrderData?.custom_field_values &&
        salesOrderData?.custom_field_values.length > 0 && (
          <Row className="mt-3">
            {salesOrderData?.custom_field_values.map((item, index) => (
              <ShowCustomFieldValues key={index} data={item} />
            ))}
          </Row>
        )}
      <Row>
        <Col xl={12} md={12} sm={12} className="p-0 mt-2">
          <LineItemTable data={salesOrderData} so />
        </Col>
      </Row>
      {salesOrderData?.memo && (
        <QuillPreviewContainer
          title="Notes :"
          value={salesOrderData.memo}
          dashedBorder
        />
      )}
    </Container>
  );
}

SalesOrderView.propTypes = {
  salesOrderData: PropTypes.any
};

export default SalesOrderView;

import React, { useCallback, useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import axios from 'axios';
import { Card, Dropdown } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import CardDropdown from 'components/common/CardDropdown';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import CalendarRangePickerModal from 'components/CalendarRangePickerModal/CalendarRangePickerModal';
import Loader from '../Common/Loader';
import { grossProfit, grossProfitSummary } from '../Common/LoaderData';
import PropTypes from 'prop-types';

const ResponsiveBarChart = ({ draggable, isFromDashboard }) => {
  const [showDateRangePicker, setshowDateRangePicker] = useState(false);
  const Translate = useAxisproTranslate();
  const [dateRange, setDateRange] = useState(null);
  const isDateRangeActive = Array.isArray(dateRange) && dateRange.length > 0;
  const [loading, setLoading] = useState(false);
  const loaderArray = isFromDashboard
    ? [...grossProfitSummary, ...grossProfit]
    : grossProfitSummary;

  const [option, setOption] = useState({
    title: { show: false },
    tooltip: {},
    grid: {
      top: '5%',
      left: '5%',
      right: '5%',
      bottom: '10%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: ['Income', 'Cost', 'Expense', 'Gross Profit', 'Net Profit']
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: value => {
          if (value >= 1000000) return (value / 1000000).toFixed(1) + 'M';
          if (value >= 1000) return (value / 1000).toFixed(1) + 'K';
          return value;
        }
      }
    },
    series: [
      {
        name: '',
        type: 'bar',
        barWidth: '25%',
        label: {
          show: true,
          position: 'top',
          color: '#000',
          fontSize: 12,
          formatter: params => {
            const value = params.value;
            if (value >= 1000000) return (value / 1000000).toFixed(2) + 'M';
            if (value >= 1000) return (value / 1000).toFixed(2) + 'K';
            return value;
          }
        },
        data: [0, 0, 0, 0, 0]
      }
    ]
  });

  const fetchData = useCallback(
    (reset = false) => {
      setLoading(true);
      let params = {};

      if (reset) {
        setDateRange(null);
      } else {
        params = isDateRangeActive
          ? {
              from_date: dateRange[0],
              to_date: dateRange[1]
            }
          : null;
      }

      axios
        .get('sales/get-gross-profit-summary', { params })
        .then(res => {
          if (res?.data?.success) {
            const grossProfitAmount = res.data?.data?.gross_profit_amount ?? 0;
            const netProfitAmount = res.data?.data?.net_profit_amount ?? 0;
            const totalExpenseAmount =
              res.data?.data?.total_expense_amount ?? 0;
            const purchaseAmount = res.data?.data?.total_purchase_amount ?? 0;
            const salesAmount = res.data?.data?.total_sales_amount ?? 0;

            setOption(prevOption => ({
              ...prevOption,
              series: [
                {
                  ...prevOption.series[0],
                  data: [
                    {
                      value: salesAmount,
                      itemStyle: { color: '#66B7DD' }
                    },
                    {
                      value: purchaseAmount,
                      itemStyle: { color: '#6771DC' }
                    },
                    {
                      value: totalExpenseAmount,
                      itemStyle: { color: '#A367DC' }
                    },
                    {
                      value: grossProfitAmount,
                      itemStyle: { color: '#DC67AB' }
                    },
                    {
                      value: netProfitAmount,
                      itemStyle: { color: '#DC6967' }
                    }
                  ]
                }
              ]
            }));

            setshowDateRangePicker(false);
            setLoading(false);
          }
        })
        .catch(error => {
          console.error('Error fetching gross profit summary:', error);
          setLoading(false);
        });
    },
    [dateRange, isDateRangeActive]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSetDateRange = event => {
    setDateRange(event?.target?.value ?? null);
  };

  return (
    <>
      {showDateRangePicker ? (
        <CalendarRangePickerModal
          show={showDateRangePicker}
          value={dateRange}
          onChange={handleSetDateRange}
          onBack={() => setshowDateRangePicker(false)}
          onReset={() => fetchData(true)}
        />
      ) : null}
      <Card className={`h-100 ${draggable && 'dashboard_shake'}`}>
        <FalconCardHeader
          title={`Gross Profit Summary ${
            isDateRangeActive ? `(${dateRange.join(' - ')})` : ''
          }`}
          light={false}
          titleTag="h6"
          className="pb-0"
          endEl={
            <CardDropdown>
              <div className="py-2">
                <Dropdown.Item onClick={() => setshowDateRangePicker(true)}>
                  {Translate('Change Date')}
                </Dropdown.Item>
              </div>
            </CardDropdown>
          }
        />
        {!loading ? (
          <div className="d-flex flex-fill align-items-end justify-content-center">
            <ReactECharts
              option={option}
              style={{ width: '100%', height: '100%', minHeight: '300px' }}
            />
          </div>
        ) : (
          <div>
            <Loader loader={loaderArray} />
          </div>
        )}
      </Card>
    </>
  );
};

ResponsiveBarChart.propTypes = {
  draggable: PropTypes.bool,
  isFromDashboard: PropTypes.bool
};

export default ResponsiveBarChart;

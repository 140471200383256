import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Table } from 'react-bootstrap';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import SelectBankAccount from 'components/form/SelectBankAccount';
import { AuthWizardContext } from 'context/Context';
import SimpleBar from 'simplebar-react';

function PaymentChequeTable({
  data,
  handleFieldChange,
  formError,
  handleChange,
  amountToBeSplit = 0
}) {
  const Translate = useAxisproTranslate();
  const { user } = useContext(AuthWizardContext);
  const baseCurrency = user?.branch?.base_currency ?? '';
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    let total = 0;
    data?.map(items => {
      total = total + parseFloat(items?.allocs?.amount) || 0;
    });
    setTotalAmount(total);
  }, [data]);

  return (
    <>
      <Col md={3} sm={12} className={'m-0'}>
        <div className="p-2 ps-0 d-flex align-items-center">
          {Translate('Amount Received')}({baseCurrency}) &nbsp;: &nbsp;
          <span className="fw-bold text-primary">
            {amountToBeSplit
              ? `${baseCurrency} ` + amountToBeSplit
              : `${baseCurrency} 0.00`}
          </span>
        </div>
      </Col>
      <SimpleBar
        style={{
          maxHeight: '500px',
          overflowY: 'auto'
        }}
      >
        <div className="table-responsive">
          <Table striped bordered responsive className="simple-table-animation">
            <thead className="text-white bg-dark">
              <tr>
                <th className="text-center px-2">{Translate('S/L')}</th>
                <th
                  className="text-center px-2"
                  style={{ width: 'max-content' }}
                >
                  {Translate('Is PDC')}
                </th>
                <th className="text-center">{Translate('Date')}</th>
                <th className="text-center">{Translate('Deposit To')}</th>
                <th className="text-center">{Translate('Bank Name')}</th>
                <th className="text-center">{Translate('Trans Ref')}</th>
                <th className="text-center">{Translate('Cheque Date')}</th>
                <th className="text-center">{Translate('Cheque No')}</th>
                <th className="text-center">{Translate('Amount')}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <td className="p-1 text-center text-dark">{index + 1}</td>
                  <td className="p-1 text-dark h-100 text-center">
                    <Form.Check
                      type="checkbox"
                      value={row?.is_pdc}
                      checked={row?.is_pdc === 1 ? true : false}
                      onChange={e => handleFieldChange(e, index)}
                    />
                  </td>
                  <td className="p-1 text-dark">
                    <AppDatePicker
                      name="trans_date"
                      value={row.trans_date}
                      yearPlaceholder="yyyy"
                      monthPlaceholder="mm"
                      dayPlaceholder="dd"
                      onChange={e => {
                        handleFieldChange(e, index);
                      }}
                      showDefaultDate
                      calendarClassName={
                        (index && index < 4) || index === 0
                          ? 'position-fixed'
                          : 'position-relative'
                      }
                    />
                  </td>
                  <td className="p-1 text-dark" style={{ width: '230px' }}>
                    <SelectBankAccount
                      value={row.deposit_to_ref}
                      error={formError.deposit_to}
                      handleFieldChange={(e, action) => {
                        handleChange(e, action, row, index);
                      }}
                      menuPosition="fixed"
                    />
                  </td>
                  <td className="p-1 text-dark" style={{ width: '180px' }}>
                    <Form.Control
                      type="text"
                      name="cheque_bank"
                      value={row.cheque_bank}
                      onChange={e => {
                        handleFieldChange(e, index);
                      }}
                    />
                  </td>
                  <td className="p-1 text-dark">
                    <Form.Control
                      type="text"
                      name="trans_ref"
                      value={row.trans_ref}
                      onChange={e => {
                        handleFieldChange(e, index);
                      }}
                    />
                  </td>
                  <td className="p-1 text-dark">
                    <AppDatePicker
                      name="cheque_date"
                      value={row.cheque_date}
                      yearPlaceholder="yyyy"
                      monthPlaceholder="mm"
                      dayPlaceholder="dd"
                      onChange={e => {
                        handleFieldChange(e, index);
                      }}
                      showDefaultDate
                      calendarClassName={
                        (index && index < 4) || index === 0
                          ? 'position-fixed'
                          : 'position-relative'
                      }
                    />
                  </td>
                  <td className="p-1 text-dark">
                    <Form.Control
                      type="number"
                      name="cheque_number"
                      value={row.cheque_number}
                      onChange={e => {
                        handleFieldChange(e, index);
                      }}
                      min={0}
                      onKeyDown={e =>
                        ['e', 'E', '+', '-'].includes(e.key) &&
                        e.preventDefault()
                      }
                    />
                  </td>
                  <td className="p-1 text-dark">
                    <Form.Control
                      type="number"
                      name="amount"
                      id={`amount-${index}`}
                      value={row.amount}
                      onChange={e => {
                        handleFieldChange(e, index);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </SimpleBar>
      <div className="d-flex align-items-center justify-content-end">
        <div className="p-2 ps-0 pb-0 d-flex align-items-center">
          {Translate('Total Amount')}({baseCurrency}) &nbsp;: &nbsp;
          <span
            className={`fw-bold text-${
              parseFloat(totalAmount?.toFixed(2)) > parseFloat(amountToBeSplit)
                ? 'danger'
                : 'success'
            } `}
          >
            {totalAmount
              ? `${baseCurrency} ` + parseFloat(totalAmount?.toFixed(2))
              : `${baseCurrency} 0.00`}
          </span>
        </div>
      </div>
    </>
  );
}

export default PaymentChequeTable;

PaymentChequeTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      trans_date: PropTypes.string.isRequired,
      deposit_to_ref: PropTypes.object.isRequired,
      cheque_bank: PropTypes.string.isRequired,
      trans_ref: PropTypes.object.isRequired,
      cheque_date: PropTypes.string.isRequired,
      cheque_number: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired
    })
  ).isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  formError: PropTypes.object,
  handleChange: PropTypes.func,
  amountToBeSplit: PropTypes.number
};

import { React } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';

function DateInputField({
  title,
  name,
  value,
  error,
  formError,
  handleFieldChange,
  setDraft,
  draft,
  enableDraft,
  required,
  SI,
  showDefaultDate,
  disable,
  calendarClassName,
  clearable = true
}) {
  const Translate = useAxisproTranslate();

  return (
    <Form.Group
      as={Col}
      xxl={SI ? 6 : 4}
      xl={6}
      md={6}
      sm={12}
      className={SI ? 'mb-1' : 'mb-2'}
      controlId={'date'}
    >
      <Row>
        <Col xl={SI ? 12 : 4} md={SI ? 12 : 4} sm={12} className="pe-0">
          <Form.Label
            id="date"
            className={
              required
                ? 'require-data m-0 d-flex align-items-center justify-content-start h-100'
                : 'm-0 d-flex align-items-center justify-content-start h-100'
            }
          >
            {Translate(title)}
          </Form.Label>
        </Col>
        <Col
          xl={SI ? 12 : 8}
          md={SI ? 12 : 8}
          sm={12}
          className={SI ? '' : 'ps-0'}
        >
          <Row className="d-flex flex-row">
            <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
              <AppDatePicker
                name={name}
                value={value}
                yearPlaceholder="yyyy"
                monthPlaceholder="mm"
                dayPlaceholder="dd"
                onChange={handleFieldChange}
                onBlur={() => {
                  enableDraft ? setDraft(!draft) : '';
                }}
                calendarClassName={calendarClassName}
                showDefaultDate={showDefaultDate}
                disabled={disable}
                clearable={clearable}
              />
            </Col>
            <Col
              xl={1}
              md={1}
              sm={1}
              className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
            >
              <FormErrorPopover
                id={name}
                totalErrorCount={Object.keys(formError).length}
                errorMessage={error}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form.Group>
  );
}

DateInputField.propTypes = {
  formError: PropTypes.any,
  draft: PropTypes.bool,
  setDraft: PropTypes.func,
  handleFieldChange: PropTypes.func,
  enableDraft: PropTypes.bool,
  title: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.any,
  SI: PropTypes.bool,
  showDefaultDate: PropTypes.bool,
  disable: PropTypes.bool,
  calendarClassName: PropTypes.string,
  clearable: PropTypes.bool
};

export default DateInputField;

import {
  React,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  Row
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FaHandHoldingUsd } from 'react-icons/fa';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { CloseButton } from '../Buttons/ViewPageButtons';
import { AuthWizardContext } from 'context/Context';
import { showToast } from 'module/Common/Toast/toast';
import { CheckZeroOrLessThanZeroValidator } from '../Validators/CheckZeroOrLessThanZero';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import SelectBankAccount from 'components/form/SelectBankAccount';
import UnpaidTransactionsPopUp from './UnpaidTransactionsPopUp';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';

function ReceivePayment({ show, onHide }) {
  const Translate = useAxisproTranslate();
  const { user } = useContext(AuthWizardContext);
  const myRef = useRef(null);
  const navigate = useNavigate();
  let url = '';
  const [formData, setFormData] = useState({
    amount: '',
    discount_amount: 0,
    cheque_number: '',
    trans_ref: '',
    trans_date: ''
  });
  const [formError, setFormError] = useState({});
  const [saving, setSaving] = useState(false);
  const [givenAmount, setGivenAmount] = useState('');
  const [change, setChange] = useState('');
  const [referenceOrBarcode, setReferenceOrBarcode] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showUnpaidInvoices, setShowUnpaidInvoices] = useState(false);
  const [isSelectedReference, setIsSelectedReference] = useState(false);
  const baseCurrency = user?.branch?.base_currency ?? '';

  const searchingForInvoice = useCallback(() => {
    setLoading(true);
    setIsSelectedReference(false);
    axios
      .get('/sales/get-sales-invoice-details-by-reference', {
        params: {
          reference_or_barcode: referenceOrBarcode
        }
      })
      .then(response => {
        if (response.data.success) {
          setFormData(prevData => ({
            ...prevData,
            amount: response.data.data.balance ?? '',
            balance: response.data.data.balance ?? '',
            balance_formatted: response.data.data.balance_formatted ?? '',
            customer_name: response.data.data.customer_name ?? '',
            reference: response.data.data.reference ?? '',
            customer_id: response.data.data.customer_id ?? '',
            voucher_id: response.data.data.voucher_id ?? '',
            id: response.data.data.id ?? '',
            cost_center_id: response.data.data.cost_center_id ?? ''
          }));
          setGivenAmount('');
          setShowForm(true);
        } else {
          setShowForm(false);
        }
        setLoading(false);
      })
      .catch(errors => {
        setFormData(prevData => ({
          ...prevData,
          amount: '',
          balance: '',
          balance_formatted: '',
          customer_name: '',
          reference: '',
          customer_id: '',
          voucher_id: '',
          id: '',
          cost_center_id: ''
        }));
        setShowForm(false);
        setLoading(false);
        if (errors?.response?.data?.data?.errors?.reference_or_barcode?.[0]) {
          showToast(
            errors.response.data.data.errors.reference_or_barcode[0],
            'error'
          );
        }
      });
  }, [
    referenceOrBarcode,
    setFormData,
    setShowForm,
    setGivenAmount,
    setIsSelectedReference,
    setLoading
  ]);

  const handleBankAccount = (valueObject, action) => {
    let bankAccountId =
      valueObject && valueObject.value ? valueObject.value : '';
    if (bankAccountId && action?.action === 'select-option') {
      setFormData(prev => ({
        ...prev,
        deposit_to: bankAccountId,
        deposit_to_ref: valueObject
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        deposit_to: '',
        deposit_to_ref: ''
      }));
    }
  };

  const cancelModal = () => {
    setFormData({});
    setShowForm(false);
    setReferenceOrBarcode('');
    setChange(0);
    onHide();
  };

  const onKeyDown = e => {
    if (e.key === 'Tab') {
      setReferenceOrBarcode(e.target.value);
      searchingForInvoice();
    }
  };

  const changeReferenceOrBarcode = e => {
    setReferenceOrBarcode(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (
      CheckZeroOrLessThanZeroValidator(
        formData.amount,
        'Received Amount',
        setFormError
      )
    ) {
      setFormError({});
      setSaving(true);
      if (formData.payment_mode == 'cheque') {
        if (formData.cheque_number) {
          if (formData.cheque_number == '') {
            setFormError({
              cheque_number: [Translate('Cheque number is required')]
            });
            setSaving(false);
          } else {
            postData();
          }
        } else {
          setFormError({
            cheque_number: [Translate('Cheque number is required')]
          });
          setSaving(false);
        }
      } else {
        postData();
      }
    }
  };

  const postData = () => {
    url = 'finance/customer-receipt';
    axios({
      method: 'post',
      url: url,
      data: formData
    })
      .then(response => {
        if (response.data.success === true) {
          setShowForm(false);
          setReferenceOrBarcode('');
          setChange(0);
          showToast(response.data.message, 'success');
          onHide();
          navigate('/sales/sales-invoice/info/' + formData.id);
          setFormData({});
        } else {
          showToast(
            'Something went wrong, please refresh the page and try again.',
            'error'
          );
        }
        setSaving(false);
      })
      .catch(error => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : '';
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            'Something went wrong, please refresh the page and try again.',
            'error'
          );
        }
        setSaving(false);
      });
  };

  const handleFieldChange = e => {
    let value = '';
    if (e.target.name == 'amount') {
      if (parseFloat(e.target.value) > parseFloat(formData.balance)) {
        showToast(
          'Amount should be less than or equal to balance amount',
          'error'
        );
        value = formData.balance;
      } else {
        value = e.target.value;
      }
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    } else {
      value = e.target.value;
      setFormData({
        ...formData,
        [e.target.name]: value
      });
    }
  };

  const handleChange = e => {
    setGivenAmount(e.target.value);
  };

  useEffect(() => {
    const calculateChange = () => {
      if (givenAmount === '' || givenAmount === '0') {
        setChange('');
      } else {
        let totalDiscount = formData.discount_amount
          ? formData.discount_amount
          : 0;
        let totalAmount = formData.amount - totalDiscount;
        let changeAmount = givenAmount - totalAmount;
        setChange(changeAmount);
      }
    };
    calculateChange();
  }, [givenAmount, formData.discount_amount, formData.amount]);

  useEffect(() => {
    if (myRef && myRef.current) {
      myRef.current.focus();
    }
  }, [show]);

  useEffect(() => {
    setFormData(prevData => ({
      ...prevData,
      deposit_to: '',
      deposit_to_ref: ''
    }));
  }, [formData.payment_mode]);

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      trans_date: formData.trans_date,
      amount: formData.amount,
      discount_amount: formData.discount_amount ? formData.discount_amount : 0,
      bank_charge: formData.bank_charge ? formData.bank_charge : 0,
      allocs: [
        {
          voucher_id: formData.voucher_id,
          customer_id: formData.customer_id,
          amount: formData.amount,
          trans_type: 'SI',
          trans_date: formData.trans_date
        }
      ],
      payment_mode: formData.payment_mode ? formData.payment_mode : 'cash'
    }));
  }, [
    formData.amount,
    formData.bank_charge,
    formData.customer_id,
    formData.discount_amount,
    formData.payment_mode,
    formData.trans_date,
    formData.voucher_id
  ]);

  useEffect(() => {
    if (referenceOrBarcode == '') {
      setShowForm(false);
      setFormData({});
    }
  }, [referenceOrBarcode]);

  useEffect(() => {
    if (isSelectedReference) {
      searchingForInvoice();
    }
  }, [isSelectedReference, searchingForInvoice]);

  return (
    <>
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-with-overlay"
      >
        <Card>
          <Card.Header
            className={
              'border-bottom text-uppercase fs-1 text-dark d-flex align-items-center gap-1'
            }
          >
            <>
              <FaHandHoldingUsd size={22} className="text-dark" />
              {Translate('Receive payment')}
              <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center dropdown-menu-style">
                <CloseButton onHide={cancelModal} />
              </ButtonGroup>
            </>
          </Card.Header>
          <Form>
            <Card.Body className="pt-1">
              <Form.Group as={Row} className="mt-2">
                <Col md={5} sm={12}>
                  <Form.Label>{Translate('Reference Or Barcode')}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter the reference or barcode"
                    name="reference_or_barcode"
                    value={referenceOrBarcode}
                    ref={myRef}
                    onChange={changeReferenceOrBarcode}
                    onKeyDown={onKeyDown}
                  />
                </Col>
                <Col md={1} sm={12}>
                  <Button
                    className="mt-4"
                    size="sm"
                    variant="danger"
                    onClick={() => setShowUnpaidInvoices(true)}
                  >
                    {Translate('Choose')}
                  </Button>
                </Col>
                {referenceOrBarcode.length > 0 && (
                  <Col md={4} sm={12}>
                    <Button
                      className="ms-3 mt-4"
                      size="sm"
                      onClick={() => {
                        searchingForInvoice();
                      }}
                    >
                      {loading ? Translate('Searching') : Translate('Search')}
                    </Button>
                  </Col>
                )}
              </Form.Group>
            </Card.Body>
          </Form>
          <>
            <Form>
              <Card.Body
                className={!showForm ? 'disabled-input pt-1' : 'pt-1'}
                style={{
                  backgroundColor: showForm ? '' : '#EBEBEB'
                }}
              >
                <div>
                  {!Object.keys(formError).length == 0 ? (
                    <ErrorAlert
                      setFormError={setFormError}
                      formError={formError}
                    />
                  ) : (
                    ''
                  )}
                </div>
                <>
                  <Col md={8} sm={12} className={'m-0'}>
                    <div className="p-2 ps-0 d-flex align-items-center">
                      {Translate('Total Balance Amount')} &nbsp;: &nbsp;
                      <span className="fs-1 fw-bold text-warning">
                        {formData.balance_formatted}
                      </span>
                    </div>
                  </Col>
                  <Form.Group as={Row} className="mt-2">
                    <Col md={8} sm={12}>
                      <Form.Label>{Translate('Customer Name')}</Form.Label>
                      <Form.Control
                        disabled
                        value={formData.customer_name}
                        className="disabled-input"
                      />
                    </Col>
                    <Col md={4} sm={12}>
                      <Form.Label>{Translate('Reference')}</Form.Label>
                      <Form.Control
                        disabled
                        value={formData.reference}
                        className="disabled-input"
                      />
                    </Col>
                  </Form.Group>
                  <Card
                    className="mt-3 mb-3"
                    style={{ borderLeft: '1px solid green' }}
                  >
                    <Card.Body>
                      <Form.Group as={Row}>
                        <Col md={4} sm={12}>
                          <Form.Label className="m-0 require-data">
                            {Translate('Amount Received')}({baseCurrency})
                          </Form.Label>
                          <InputGroup>
                            <InputGroup.Text className="fs--1">
                              {baseCurrency}
                            </InputGroup.Text>
                            <Form.Control
                              type="number"
                              name="amount"
                              value={formData.amount}
                              onChange={handleFieldChange}
                              disabled={!showForm}
                            />
                          </InputGroup>
                        </Col>
                        <Col md={4} sm={12}>
                          <Form.Label className="m-0">
                            {Translate('Discount Amount')}
                          </Form.Label>
                          <Form.Control
                            type="number"
                            min={0}
                            name="discount_amount"
                            value={formData.discount_amount}
                            onChange={handleFieldChange}
                            disabled={!showForm}
                          />
                        </Col>
                        <Col md={4} sm={12}>
                          <Form.Label className="m-0">
                            {Translate('Payment Mode')}
                          </Form.Label>
                          <Form.Select
                            name="payment_mode"
                            value={formData.payment_mode}
                            onChange={handleFieldChange}
                            isInvalid={!!formError.payment_mode}
                            disabled={!showForm}
                          >
                            <option value="cash">{Translate('Cash')}</option>
                            <option value="card">{Translate('Card')}</option>
                            <option value="bank_transfer">
                              {Translate('Bank Transfer')}
                            </option>
                            <option value="cheque">
                              {Translate('Cheque')}
                            </option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </Card.Body>
                  </Card>
                  <Form.Group as={Row}>
                    <Col md={4} sm={12}>
                      <Form.Label>{Translate('Transaction Ref')}</Form.Label>
                      <Form.Control
                        type="text"
                        name="trans_ref"
                        value={formData.trans_ref}
                        onChange={handleFieldChange}
                        disabled={!showForm}
                      />
                    </Col>
                    {formData.payment_mode === 'bank' ? (
                      <Col md={4} sm={12}>
                        <Form.Label>
                          {Translate('Bank charges')}(If any)
                        </Form.Label>
                        <Form.Control
                          type="number"
                          min={0}
                          step=".01"
                          name="bank_charge"
                          value={formData.bank_charge}
                          onChange={handleFieldChange}
                          disabled={!showForm}
                        />
                      </Col>
                    ) : formData.payment_mode === 'cheque' ? (
                      <Col md={4} sm={12}>
                        <Form.Label className="require-data">
                          {Translate('Cheque Number')}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="cheque_number"
                          value={formData.cheque_number}
                          onChange={handleFieldChange}
                          disabled={!showForm}
                        />
                      </Col>
                    ) : (
                      ''
                    )}
                    <Col md={4} sm={12}>
                      <Form.Label className="require-data">
                        {Translate('Deposit To')}
                      </Form.Label>
                      <div className="d-flex w-100">
                        <SelectBankAccount
                          name="deposit_to"
                          value={formData.deposit_to_ref}
                          handleFieldChange={handleBankAccount}
                        />
                        {formError && formError.deposit_to ? (
                          <FormErrorPopover
                            id="deposit_to"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.deposit_to}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </Col>
                    <Col md={4} sm={12}>
                      <Form.Label className="require-data">
                        {Translate('Payment Date')}
                      </Form.Label>
                      <AppDatePicker
                        name="trans_date"
                        value={formData.trans_date}
                        yearPlaceholder="yyyy"
                        monthPlaceholder="mm"
                        dayPlaceholder="dd"
                        onChange={handleFieldChange}
                        disabled={!showForm}
                        showDefaultDate
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mt-3 d-flex flex-row"
                    style={{
                      backgroundColor: '#EBEBEB',
                      paddingTop: '15px',
                      paddingBottom: '15px'
                    }}
                  >
                    <Col md={4} sm={12} className="flex-2">
                      <Form.Label>{Translate('Given Amount')}</Form.Label>
                      <Form.Control
                        type="number"
                        name="given_amount"
                        value={givenAmount.given_amount}
                        onChange={handleChange}
                        disabled={!showForm}
                      />
                    </Col>
                    {change != '' || change != 0 ? (
                      <div className="flex-1 text-center">
                        <h5 className="m-0 mt-4 text-dark fs-1">
                          {Translate('Change')} &nbsp;:&nbsp;
                          <span className="text-danger fw-bold fs-1">
                            {baseCurrency}&nbsp;
                            {change.toLocaleString(navigator.language, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}
                          </span>
                        </h5>
                      </div>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  <Form.Group as={Row} className="mt-3">
                    <Col sm={12}>
                      <Form.Label>{Translate('Notes')}</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        name="memo"
                        value={formData.memo}
                        onChange={handleFieldChange}
                        disabled={!showForm}
                      />
                    </Col>
                  </Form.Group>
                </>
              </Card.Body>
              <div
                className={!showForm ? 'receive-payment-form-style' : ''}
              ></div>
              <Card.Footer className="d-flex gap-2">
                {formData.balance != '0' && showForm && (
                  <Button
                    variant="success"
                    size="sm"
                    type={'button'}
                    disabled={saving}
                    onClick={handleSubmit}
                  >
                    {!saving
                      ? Translate('Record Payment')
                      : Translate('Saving...')}
                  </Button>
                )}
              </Card.Footer>
            </Form>
          </>
        </Card>
      </Modal>
      <UnpaidTransactionsPopUp
        show={showUnpaidInvoices}
        onHide={() => setShowUnpaidInvoices(false)}
        setReferenceOrBarcode={setReferenceOrBarcode}
        setIsSelectedReference={setIsSelectedReference}
      />
    </>
  );
}

ReceivePayment.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func
};

export default ReceivePayment;

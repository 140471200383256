import { React, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Col } from 'react-bootstrap';
import WeeklySales from './Sales/WeeklySales';
import ChequeSummary from './Finance/ChequeSummary';
import GrossProfitShare from './Finance/GrossProfitShare';
import WelcomeTile from './WelcomeTile';
import BestSellingProducts from './Sales/BestSellingProducts';
import UnPaidInvoices from './Sales/UnPaidInvoices';
import Weather from './Weather';
import DailyTurnOver from './Finance/DailyTurnOver';
import ContractDetails from './Sales/ContractDetails';
import TotalSales from './Sales/TotalSale/TotalSales';
import GridLayout from 'react-grid-layout';
import { weather } from 'data/dashboard/default';
// import useDriverHook from 'hooks/useDriverHook';
import { MdClose, MdOutlineProductionQuantityLimits } from 'react-icons/md';
import { FaFileInvoice, FaMinusCircle } from 'react-icons/fa';
import { ImUserPlus } from 'react-icons/im';
import { FaUserSecret } from 'react-icons/fa';
import TotalCount from './TotalCountTile/TotalCount';
import corner1 from 'assets/img/icons/spot-illustrations/corner-2.png';
import corner2 from 'assets/img/icons/spot-illustrations/corner-1.png';
import corner3 from 'assets/img/icons/spot-illustrations/corner-3.png';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import AppContext, { StoreContext } from 'context/Context';
import { IoMdCheckmark } from 'react-icons/io';
import TotalReceivables from './Finance/TotalReceivables';
import TotalPayables from './Finance/TotalPayables';
import SalesOrderProgress from './Finance/MonthlyTarget/SalesOrderProgress';
import PaymentReceivedProgress from './Finance/MonthlyTarget/PaymentReceivedProgress';
import useBootstrapBreakpoints from './DashboardResponsiveData';
import { FaRedo, FaUndo } from 'react-icons/fa';
import SettingsToggle from 'components/settings-panel/SettingsToggle';
import SettingsPanel from 'components/settings-panel/SettingsPanel';
import TotalPurchase from './Sales/TotalPurchase/TotalPurchase';
import ChequeNotificatioAlert from './DashboadNotificationAlert/ChequeNotificatioAlert';
import IncomeVsExpense from './IncomeVsExpense';
import GrossProfitGraph from 'module/Dashboard/Finance/GrossProfitGraph';

const Dashboard = () => {
  const permission = {
    show_cheque: getAcodaxPermissionSlug('CHEQUE', 'show')
  };
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const { store } = useContext(StoreContext);
  const [draggable, setDraggable] = useState();
  const [width, setWidth] = useState();
  const [customModal, setCustomModal] = useState(false);
  const [receivablesAndPayData, setReceivablesAndPayData] = useState();
  const [receivablesAndPayablesLoading, setReceivablesAndPayLoading] =
    useState(false);
  const {
    CloseDragOption,
    layout,
    handleRemoveItem,
    onDragStart,
    onLayoutChange,
    isDragging,
    handleRedo,
    handleUndo,
    onDragStop,
    preLayout,
    count,
    handleSubmit,
    setLayout,
    setSelectedLayout,
    selectLayout,
    deletedLayout,
    setDeletedLayout,
    size
  } = useBootstrapBreakpoints();
  const [datas, setDatas] = useState([
    {
      color: 'success',
      id: 1,
      name: 'Gross Profit',
      value: ''
    },
    {
      color: 'info',
      id: 2,
      name: 'Total Sales',
      value: ''
    },
    {
      color: 'danger',
      id: 3,
      name: 'Total Purchase',
      value: ''
    }
  ]);
  const {
    config: { isNavbarVerticalCollapsed }
  } = useContext(AppContext);

  const contentDiv = document.querySelector('.content');

  // useDriverHook({
  //   stepKey: 'welcome'
  // });
  useEffect(() => {
    setDraggable(store.dashboard_draggable);
  }, [store.dashboard_draggable]);

  const fetchData = () => {
    setIsLoading(true);
    axios
      .get('sales/get-common-counts')
      .then(res => {
        if (res.data.success === true) {
          setData(res.data.data);
          setIsLoading(false);
        }
      })
      .catch(error => console.log(error));
  };
  const fetchReceivablesAndPaybles = () => {
    setReceivablesAndPayLoading(true);
    axios
      .get('finance/total-receivables-and-payables')
      .then(res => {
        if (res.data.success === true) {
          setReceivablesAndPayData(res.data.data);
          setReceivablesAndPayLoading(false);
        }
      })
      .catch(error => console.log(error));
  };

  useEffect(() => {
    fetchReceivablesAndPaybles();
    fetchData();
  }, []);

  useEffect(() => {
    function updateWidth() {
      setWidth(contentDiv?.offsetWidth);
    }
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [contentDiv, isNavbarVerticalCollapsed]);

  const handleModal = () => {
    setCustomModal(!customModal);
  };
  const dashboardData = {
    a: (
      <Col
        lg={12}
        xl={12}
        xxl={4}
        key="a"
        className={`  ${draggable && 'cursor-grabbing'}`}
      >
        {draggable && (
          <FaMinusCircle
            onClick={() => handleRemoveItem('a')}
            style={{ cursor: 'pointer', zIndex: 100 }}
            title="Delete"
            size={19}
            className=" position-absolute top-0 end-0 text-warning  cursor-pointer "
          />
        )}
        <WelcomeTile
          draggable={draggable}
          handleRemoveItem={handleRemoveItem}
        />
      </Col>
    ),
    c: (
      <Col
        md={3}
        xxl={3}
        key={'c'}
        className={`  ${draggable && 'cursor-grabbing'}`}
      >
        {draggable && (
          <FaMinusCircle
            onClick={() => handleRemoveItem('c')}
            style={{ cursor: 'pointer', zIndex: 100 }}
            title="Delete"
            size={19}
            className="position-absolute top-0 end-0 text-warning   cursor-pointer "
          />
        )}
        <TotalCount
          draggable={draggable}
          isLoading={isLoading}
          data={data}
          corner={corner1}
          title={'Total Items'}
          to={'/inventory/items'}
          name={'Item'}
          description={'Item List!'}
          Icon={MdOutlineProductionQuantityLimits}
          bgColor={'text-success'}
          permission={getAcodaxPermissionSlug('IT', 'create')}
          totalCount={data.items_count}
        />
      </Col>
    ),
    d: (
      <Col
        md={3}
        xxl={3}
        key={'d'}
        className={`  ${draggable && 'cursor-grabbing'}`}
      >
        {draggable && (
          <FaMinusCircle
            onClick={() => handleRemoveItem('d')}
            style={{ cursor: 'pointer', zIndex: 100 }}
            title="Delete"
            size={19}
            className="position-absolute top-0 end-0 text-warning  cursor-pointer "
          />
        )}
        <TotalCount
          draggable={draggable}
          isLoading={isLoading}
          data={data}
          corner={corner2}
          title={'Total Order'}
          to={'/sales/sales-order'}
          name={'Sales Order'}
          description={'Sales Order List!'}
          Icon={FaFileInvoice}
          bgColor={'text-primary'}
          permission={getAcodaxPermissionSlug('SO', 'create')}
          totalCount={data.so_count}
        />
      </Col>
    ),
    e: (
      <Col
        md={3}
        xxl={3}
        key={'e'}
        className={`  ${draggable && 'cursor-grabbing'}`}
      >
        {draggable && (
          <FaMinusCircle
            onClick={() => handleRemoveItem('e')}
            style={{ cursor: 'pointer', zIndex: 100 }}
            title="Delete"
            size={19}
            className="position-absolute top-0 end-0 text-warning  cursor-pointer "
          />
        )}
        <TotalCount
          draggable={draggable}
          isLoading={isLoading}
          data={data}
          corner={corner3}
          title={'Total Customer'}
          to={'/sales/customer'}
          name={'Customer'}
          description={'Customer List!'}
          Icon={ImUserPlus}
          bgColor={'text-info'}
          permission={getAcodaxPermissionSlug('CS', 'create')}
          totalCount={data.customer_count}
        />
      </Col>
    ),
    f: (
      <Col
        md={3}
        xxl={3}
        key={'f'}
        className={`  ${draggable && 'cursor-grabbing'}`}
      >
        {draggable && (
          <FaMinusCircle
            onClick={() => handleRemoveItem('f')}
            style={{ cursor: 'pointer', zIndex: 100 }}
            title="Delete"
            size={19}
            className="position-absolute top-0 end-0 text-warning  cursor-pointer "
          />
        )}
        <TotalCount
          draggable={draggable}
          isLoading={isLoading}
          data={data}
          corner={corner1}
          title={'Total Supplier'}
          to={'/purchase/supplier'}
          name={'Suppliers'}
          description={'Supplier List!'}
          Icon={FaUserSecret}
          bgColor={'text-warning'}
          permission={getAcodaxPermissionSlug('SP', 'create')}
          totalCount={data.supplier_count}
        />
      </Col>
    ),
    g: (
      <Col
        md={6}
        xxl={3}
        key={'g'}
        className={`  ${draggable && 'cursor-grabbing'}`}
      >
        {draggable && (
          <FaMinusCircle
            onClick={() => handleRemoveItem('g')}
            style={{ cursor: 'pointer', zIndex: 100 }}
            title="Delete"
            size={19}
            className="position-absolute top-0 end-0 text-warning  cursor-pointer "
          />
        )}
        <GrossProfitShare
          draggable={draggable}
          data={datas}
          setData={setDatas}
          radius={['100%', '87%']}
        />
      </Col>
    ),
    h: (
      <Col
        md={6}
        xxl={3}
        key={'h'}
        className={`  ${draggable && 'cursor-grabbing'}`}
      >
        {draggable && (
          <FaMinusCircle
            onClick={() => handleRemoveItem('h')}
            style={{ cursor: 'pointer', zIndex: 100 }}
            title="Delete"
            size={19}
            className="position-absolute top-0 end-0 text-warning  cursor-pointer "
          />
        )}
        <ChequeSummary draggable={draggable} />
      </Col>
    ),
    i: (
      <Col
        md={6}
        xxl={3}
        key={'i'}
        className={`  ${draggable && 'cursor-grabbing'}`}
      >
        {draggable && (
          <FaMinusCircle
            onClick={() => handleRemoveItem('i')}
            style={{ cursor: 'pointer', zIndex: 100 }}
            title="Delete"
            size={19}
            className="position-absolute top-0 end-0 text-warning  cursor-pointer "
          />
        )}
        <WeeklySales draggable={draggable} />
      </Col>
    ),
    j: (
      <Col
        md={6}
        xxl={3}
        key={'j'}
        className={`  ${draggable && 'cursor-grabbing'}`}
      >
        {draggable && (
          <FaMinusCircle
            onClick={() => handleRemoveItem('j')}
            style={{ cursor: 'pointer', zIndex: 100 }}
            title="Delete"
            size={19}
            className="position-absolute top-0 end-0 text-warning  cursor-pointer "
          />
        )}
        <Weather draggable={draggable} data={weather} />
      </Col>
    ),
    k: (
      <Col
        md={6}
        xxl={2}
        key={'k'}
        className={`  ${draggable && 'cursor-grabbing'}`}
      >
        {draggable && (
          <FaMinusCircle
            onClick={() => handleRemoveItem('k')}
            style={{ cursor: 'pointer', zIndex: 100 }}
            title="Delete"
            size={19}
            className="position-absolute top-0 end-0 text-warning  cursor-pointer "
          />
        )}
        <ContractDetails draggable={draggable} />
      </Col>
    ),
    l: (
      <Col
        md={6}
        xxl={2}
        key={'l'}
        className={`pb-1  ${draggable && 'cursor-grabbing'}`}
      >
        {draggable && (
          <FaMinusCircle
            onClick={() => handleRemoveItem('l')}
            style={{ cursor: 'pointer', zIndex: 100 }}
            title="Delete"
            size={19}
            className="position-absolute top-0 end-0 text-warning  cursor-pointer "
          />
        )}
        <TotalReceivables
          draggable={draggable}
          data={receivablesAndPayData}
          setData={setReceivablesAndPayData}
          loading={receivablesAndPayablesLoading}
          setLoading={setReceivablesAndPayLoading}
        />
      </Col>
    ),
    z: (
      <Col
        md={6}
        xxl={2}
        key={'z'}
        className={`pb-1  ${draggable && 'cursor-grabbing'}`}
      >
        {draggable && (
          <FaMinusCircle
            onClick={() => handleRemoveItem('z')}
            style={{ cursor: 'pointer', zIndex: 100 }}
            title="Delete"
            size={19}
            className="position-absolute top-0 end-0 text-warning  cursor-pointer"
          />
        )}
        <TotalPayables
          draggable={draggable}
          data={receivablesAndPayData}
          setData={setReceivablesAndPayData}
          loading={receivablesAndPayablesLoading}
          setLoading={setReceivablesAndPayLoading}
        />
      </Col>
    ),
    m: (
      <Col
        md={8}
        xxl={5}
        key={'m'}
        className={`  ${draggable && 'cursor-grabbing'}`}
      >
        {draggable && (
          <FaMinusCircle
            onClick={() => handleRemoveItem('m')}
            style={{ cursor: 'pointer', zIndex: 100 }}
            title="Delete"
            size={19}
            className="position-absolute top-0 end-0 text-warning  cursor-pointer "
          />
        )}
        <DailyTurnOver draggable={draggable} />
      </Col>
    ),
    n: (
      <Col
        md={8}
        xxl={5}
        key={'n'}
        className={`  ${draggable && 'cursor-grabbing'}`}
      >
        {draggable && (
          <FaMinusCircle
            onClick={() => handleRemoveItem('n')}
            style={{ cursor: 'pointer', zIndex: 100 }}
            title="Delete"
            size={19}
            className="position-absolute top-0 end-0 text-warning  cursor-pointer "
          />
        )}
        <SalesOrderProgress draggable={draggable} />
      </Col>
    ),
    y: (
      <Col
        md={8}
        xxl={5}
        key={'y'}
        className={`  ${draggable && 'cursor-grabbing'}`}
      >
        {draggable && (
          <FaMinusCircle
            onClick={() => handleRemoveItem('y')}
            style={{ cursor: 'pointer', zIndex: 100 }}
            title="Delete"
            size={19}
            className="position-absolute top-0 end-0 text-warning  cursor-pointer"
          />
        )}
        <PaymentReceivedProgress draggable={draggable} />
      </Col>
    ),
    o: (
      <Col
        md={8}
        xxl={5}
        key={'o'}
        className={`  ${draggable && 'cursor-grabbing'}`}
      >
        {draggable && (
          <FaMinusCircle
            onClick={() => handleRemoveItem('o')}
            style={{ cursor: 'pointer', zIndex: 100 }}
            title="Delete"
            size={19}
            className="position-absolute top-0 end-0 text-warning  cursor-pointer "
          />
        )}
        <TotalSales draggable={draggable} />
      </Col>
    ),
    p: (
      <Col lg={6} key={'p'} className={`  ${draggable && 'cursor-grabbing'}`}>
        {draggable && (
          <FaMinusCircle
            onClick={() => handleRemoveItem('p')}
            style={{ cursor: 'pointer', zIndex: 100 }}
            title="Delete"
            size={19}
            className="position-absolute top-0 end-0 text-warning  cursor-pointer "
          />
        )}
        <TotalPurchase draggable={draggable} />
      </Col>
    ),
    q: (
      <Col
        lg={4}
        xl={4}
        key={'q'}
        className={`  ${draggable && 'cursor-grabbing'}`}
      >
        {draggable && (
          <FaMinusCircle
            onClick={() => handleRemoveItem('q')}
            style={{ cursor: 'pointer', zIndex: 100 }}
            title="Delete"
            size={19}
            className="position-absolute top-0 end-0 text-warning  cursor-pointer "
          />
        )}
        <BestSellingProducts draggable={draggable} />
      </Col>
    ),
    r: (
      <Col
        lg={5}
        xl={5}
        key={'r'}
        className={`  ${draggable && 'cursor-grabbing'}`}
      >
        {draggable && (
          <FaMinusCircle
            onClick={() => handleRemoveItem('r')}
            style={{ cursor: 'pointer', zIndex: 100 }}
            title="Delete"
            size={19}
            className="position-absolute top-0 end-0 text-warning  cursor-pointer "
          />
        )}
        <UnPaidInvoices draggable={draggable} />
      </Col>
    ),
    s: (
      <Col
        lg={3}
        xl={3}
        className={`order-xxl-1  ${draggable && 'cursor-grabbing'}`}
        key={'s'}
      >
        {draggable && (
          <FaMinusCircle
            onClick={() => handleRemoveItem('s')}
            style={{ cursor: 'pointer', zIndex: 100 }}
            title="Delete"
            size={19}
            className="position-absolute top-0 end-0 text-warning  cursor-pointer "
          />
        )}
        <IncomeVsExpense draggable={draggable} isFromDashboard />
      </Col>
    ),
    t: (
      <Col lg={6} key={'t'} className={`  ${draggable && 'cursor-grabbing'}`}>
        {draggable && (
          <FaMinusCircle
            onClick={() => handleRemoveItem('t')}
            style={{ cursor: 'pointer', zIndex: 100 }}
            title="Delete"
            size={19}
            className="position-absolute top-0 end-0 text-warning  cursor-pointer "
          />
        )}
        <GrossProfitGraph draggable={draggable} isFromDashboard />
      </Col>
    )
  };
  return (
    <>
      {permission.show_cheque && <ChequeNotificatioAlert />}
      <div style={{ marginTop: '-10px', marginLeft: '-10px' }}>
        <GridLayout
          className={`layout  ${isDragging ? 'react-grid-item-dragging' : ''}`}
          cols={12}
          layout={layout}
          rowHeight={30}
          width={width + 10}
          isDraggable={draggable}
          isResizable={draggable}
          resizeHandles={['se', 'nw']}
          onDragStop={onDragStop}
          onDragStart={onDragStart}
          onLayoutChange={onLayoutChange}
        >
          {layout?.map(item => dashboardData[item.i])}
        </GridLayout>
      </div>

      {draggable && (
        <>
          <SettingsToggle handleModal={handleModal} />
          <div className="fixed-top  text-center mb-6 mt-4 ">
            <Button
              style={{ backgroundColor: 'rgba(0,0,0,0.5)', boxShadow: 'none' }}
              variant="transparent"
              className="text-align-center rounded-circle  p-1 outline-0 box-shadow-0"
              onClick={handleSubmit}
            >
              <IoMdCheckmark size={25} className="text-light" />
            </Button>

            <Button
              onClick={CloseDragOption}
              style={{ backgroundColor: 'rgba(0,0,0,0.5)', boxShadow: 'none' }}
              variant="transparent"
              className="text-align-center border-circle ms-2 rounded-circle p-1 outline-0 box-shadow-0"
            >
              <MdClose size={25} className="text-light" />
            </Button>
            <Button
              disabled={
                count >= 0 && preLayout.length - count >= 0 ? false : true
              }
              style={{ backgroundColor: 'rgba(0,0,0,0.5)', boxShadow: 'none' }}
              variant="transparent"
              onClick={handleUndo}
              className="text-align-center border-circle ms-2 rounded-circle p-1 outline-0 box-shadow-0"
            >
              <FaUndo size={20} className="text-light" />
            </Button>
            <Button
              disabled={
                preLayout.length + 1 - count < preLayout.length ? false : true
              }
              onClick={handleRedo}
              style={{ backgroundColor: 'rgba(0,0,0,0.5)', boxShadow: 'none' }}
              variant="transparent"
              className="text-align-center border-circle ms-2 rounded-circle p-1 outline-0 box-shadow-0"
            >
              <FaRedo size={20} className="text-light" />
            </Button>
          </div>
        </>
      )}
      <SettingsPanel
        handleModal={handleModal}
        customModal={customModal}
        layout={layout}
        setLayout={setLayout}
        setSelectedLayout={setSelectedLayout}
        selectLayout={selectLayout}
        deletedLayout={deletedLayout}
        setDeletedLayout={setDeletedLayout}
        size={size}
      />
    </>
  );
};

export default Dashboard;

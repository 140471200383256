import React, { useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import SalesmanTabView from 'module/Common/ViewPages/Salesman/SalesmanTabView';
import { StoreContext } from 'context/Context';
function SalesmanInfo({ salesmanId, rendering, firstId, offcanvas }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { store, addIitemToStore } = useContext(StoreContext);

  const fetchData = useCallback(
    async (enableLoading = true) => {
      const idToFetch = rendering ? salesmanId : firstId;

      if (idToFetch !== undefined) {
        try {
          enableLoading && setLoading(true);
          const response = await axios.get(`sales/salesman/${idToFetch}`);
          if (response?.data?.success) {
            setData(response.data.data);
          }
        } catch (error) {
          console.error('Error fetching salesman data:', error);
        } finally {
          enableLoading && setLoading(false);
        }
      }
    },
    [firstId, salesmanId, rendering]
  );

  useEffect(() => {
    // Set a timeout to call fetchData after 200 milliseconds
    const timeoutId = setTimeout(() => {
      fetchData();
    }, 200);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [salesmanId, firstId, fetchData]);

  useEffect(() => {
    if (store.isSalesmanInfoNeedRefresh) {
      addIitemToStore('isSalesmanInfoNeedRefresh', false);
      fetchData();
    }
  }, [store.isSalesmanInfoNeedRefresh, addIitemToStore, fetchData]);

  return (
    <>
      {loading && data ? (
        <LoadingScreenCover>
          <LoadingScreen message="Looking for details" />
        </LoadingScreenCover>
      ) : (
        <SalesmanTabView
          salesmanData={data}
          loading={loading}
          fetchData={fetchData}
          offcanvas={offcanvas}
        />
      )}
    </>
  );
}

SalesmanInfo.propTypes = {
  salesmanId: PropTypes.string,
  firstId: PropTypes.string,
  rendering: PropTypes.bool,
  offcanvas: PropTypes.bool
};

export default SalesmanInfo;

import { React, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import WebFxScanService from 'webfx-scan-service';
import PropTypes from 'prop-types';

import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

function DocumentScannerButton({
  variant,
  className,
  setFormData,
  setPassportReader
}) {
  const Translate = useAxisproTranslate();
  const [scanService, setScanService] = useState(null);
  let aiCredentials = {
    aiKey: process.env.REACT_APP_ACODAX_AI_API_KEY,
    aiUrl: `${process.env.REACT_APP_ACODAX_AI_API_HOST}kyc-extractor`
  };

  useEffect(() => {
    const service = new WebFxScanService(aiCredentials);
    setScanService(service);
    service.setOnScanCompleteCallback(messageData => {
      if (messageData && !messageData?.initialSetup && !messageData?.error) {
        const name = [
          messageData?.first_name,
          messageData?.middle_name,
          messageData?.last_name
        ]
          .filter(Boolean)
          .join(' ');
        const removeHyphens = str => str.replace(/-/g, '');
        setFormData(prev => ({
          ...prev,
          name: name,
          phone: messageData?.phone,
          email: messageData?.email,
          emirates_id:
            messageData?.document_type === 'EMIRATES_ID'
              ? removeHyphens(messageData?.document_number)
              : ''
        }));
        setPassportReader(false);
      } else if (messageData?.error) {
        showToast(messageData?.message, 'error');
        setPassportReader(false);
      }
    });

    return () => {
      if (service.socket) {
        service.socket.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScanClick = () => {
    if (scanService) {
      setPassportReader(true);
      scanService.eventScan();
    }
  };

  return (
    <Button
      size="sm"
      variant={variant ?? 'dark'}
      className={`${className}`}
      style={{ fontSize: '12px' }}
      onClick={handleScanClick}
      title={Translate('Click here to scan document')}
    >
      {Translate('Scan Document')}
    </Button>
  );
}

DocumentScannerButton.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  setPassportReader: PropTypes.func,
  setFormData: PropTypes.func
};

export default DocumentScannerButton;

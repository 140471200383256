import { React, useEffect, useState } from 'react';
import moment from 'moment/moment';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';

import { FaCalendar } from 'react-icons/fa';

import 'react-datepicker/dist/react-datepicker.css';

function AppDateTimePicker({
  onChange,
  isInvalid,
  name,
  value,
  showDefaultDate,
  className,
  filter,
  dateTimePickerName,
  setDateTimePickerName,
  ...rest
}) {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (!value && showDefaultDate) {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
      const day = ('0' + currentDate.getDate()).slice(-2);
      const hours = ('0' + currentDate.getHours()).slice(-2);
      const minutes = ('0' + currentDate.getMinutes()).slice(-2);
      const seconds = ('0' + currentDate.getSeconds()).slice(-2);
      const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      onChange({
        target: {
          name: name,
          value: formattedDateTime
        }
      });
    }
  }, [name, onChange, showDefaultDate, value]);

  const autoCompleteYear = shortYear => {
    const currentYear = new Date().getFullYear();
    const century = Math.floor(currentYear / 100) * 100;
    const possibleYear1 = century + parseInt(shortYear, 10);
    const possibleYear2 = century - 100 + parseInt(shortYear, 10);

    const closestYear =
      Math.abs(possibleYear1 - currentYear) <
      Math.abs(possibleYear2 - currentYear)
        ? possibleYear1
        : possibleYear2;

    return closestYear?.toString();
  };

  const isValidDate = dateString => {
    const format = 'MM-DD-YYYY hh:mm A';
    return moment(dateString, format, true).isValid();
  };

  const reformShortendDate = requestedDate => {
    const now = new Date();
    const transformedValue = {
      day: '01',
      month: (now.getMonth() + 1).toString(),
      year: now.getFullYear().toString(),
      hour: now.getHours().toString(),
      minute: now.getMinutes().toString(),
      meridiem: now.getHours() >= 12 ? 'PM' : 'AM'
    };

    const filteredString = requestedDate?.replace(/[-\s:]/g, '');
    const length = filteredString?.length || 0;

    if (requestedDate && !requestedDate.includes('-')) {
      if (length >= 1) {
        transformedValue.day =
          parseInt(filteredString.slice(0, 2)) > 0
            ? filteredString.slice(0, 2)
            : '01';
      }
      if (length >= 3) {
        transformedValue.month = filteredString.slice(2, 4);
      }
      if (length >= 6) {
        transformedValue.year = autoCompleteYear(filteredString.slice(4, 6));
      }
      if (length >= 8) {
        if (requestedDate.length === 8) {
          transformedValue.year = filteredString.slice(4, 8);
        } else {
          transformedValue.hour = filteredString.slice(6, 8);
        }
      }
      if (length >= 10) {
        if (requestedDate.length === 10) {
          transformedValue.hour = filteredString.slice(8, 10);
        } else {
          transformedValue.minute = filteredString.slice(8, 10);
        }
      }
      if (length >= 12) {
        if (requestedDate.length === 12) {
          transformedValue.meridiem = filteredString
            .slice(10, 12)
            ?.toUpperCase();
        }
      }
      if (length >= 14) {
        if (requestedDate.length === 14) {
          transformedValue.meridiem = filteredString
            .slice(12, 14)
            ?.toUpperCase();
        }
      }
      transformedValue.hour = Math.abs(transformedValue.hour) % 12 || 12;
      transformedValue.minute = Math.abs(transformedValue.minute) % 60;

      const reformedDate = `${transformedValue.month}-${transformedValue.day}-${transformedValue.year} ${transformedValue.hour}:${transformedValue.minute} ${transformedValue.meridiem}`;
      return reformedDate;
    } else if (filteredString) {
      const pad = num => (parseInt(num) < 10 ? '0' + num : num);
      transformedValue.day = filteredString.slice(0, 2);
      transformedValue.month = filteredString.slice(2, 4);
      transformedValue.year = filteredString.slice(4, 8);
      transformedValue.hour = filteredString.slice(8, 10);
      transformedValue.minute = filteredString.slice(10, 12);
      transformedValue.meridiem = filteredString.slice(12, 14)?.toUpperCase();

      transformedValue.hour = pad(
        String(Math.abs(transformedValue.hour) % 12 || 12)
      );

      transformedValue.minute = pad(
        String(Math.abs(transformedValue.minute) % 60)
      );

      if (transformedValue.meridiem.length === 1) {
        transformedValue.meridiem =
          transformedValue.meridiem === 'A'
            ? 'AM'
            : transformedValue.meridiem === 'P'
            ? 'PM'
            : 'AM';
      }
      const reformedDate = `${transformedValue.month}-${transformedValue.day}-${transformedValue.year} ${transformedValue.hour}:${transformedValue.minute} ${transformedValue.meridiem}`;

      return reformedDate;
    }
    return '';
  };

  const getFormatedDate = date => {
    let formatedDate;
    if (date) {
      const options = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      };
      const formattedTime = date.toLocaleTimeString([], options);
      formatedDate =
        ('0' + date.getDate()).slice(-2) +
        '-' +
        ('0' + (date.getMonth() + 1)).slice(-2) +
        '-' +
        date.getFullYear() +
        ' ' +
        formattedTime;
    }
    return formatedDate;
  };

  const getDateSpecificFormat = (targetFormat, dateString) => {
    const newConstructedDate = new Date(dateString);
    const pad = num => (num < 10 ? '0' + num : num);
    const formatMap = {
      yyyy: newConstructedDate.getFullYear(),
      MM: pad(newConstructedDate.getMonth() + 1),
      dd: pad(newConstructedDate.getDate()),
      HH: pad(newConstructedDate.getHours()),
      mm: pad(newConstructedDate.getMinutes()),
      ss: pad(newConstructedDate.getSeconds()),
      aa: pad(newConstructedDate.getHours() >= 12 ? 'PM' : 'AM')
    };

    let formattedDate = targetFormat;
    for (const key in formatMap) {
      formattedDate = formattedDate.replace(key, formatMap[key]);
    }
    return formattedDate;
  };

  const handleFieldChange = date => {
    if (date === null) {
      onChange({
        target: {
          name,
          value: ''
        }
      });
    } else {
      const constructedDate = reformShortendDate(getFormatedDate(date));
      if (isValidDate(constructedDate)) {
        onChange({
          target: {
            name,
            value: constructedDate
              ? getDateSpecificFormat('yyyy-MM-dd HH:mm:ss', constructedDate)
              : ''
          }
        });
      }
    }
    setIsOpen(false);
    setDateTimePickerName &&
      typeof setDateTimePickerName === 'function' &&
      setDateTimePickerName(null);
  };

  useEffect(() => {
    if (dateTimePickerName && dateTimePickerName === name) {
      setIsOpen(true);
    }
  }, [dateTimePickerName, name]);

  return (
    <div className="app-date-time-picker-wrapper w-100">
      <DatePicker
        className={`custom-date-time-picker form-control ${
          className ? ' ' + className : ''
        }${isInvalid ? ' is-invalid ' : ''}`}
        popperPlacement="bottom-start"
        selected={value ? new Date(value) : showDefaultDate ? new Date() : null}
        formatWeekDay={day => day.slice(0, 3)}
        timeIntervals={5}
        dateFormat="dd-MM-yyyy hh:mm aa"
        calendarClassName={'custom-date-time-picker-calendar'}
        showTimeSelect
        onChange={date => handleFieldChange(date, name)}
        isClearable={showDefaultDate ? false : true}
        showIcon={!value && !filter ? true : false}
        icon={
          <FaCalendar
            size={10}
            color="#344050"
            onClick={() => setIsOpen(true)}
          />
        }
        onInputClick={() => setIsOpen(true)}
        open={isOpen}
        onClickOutside={() => {
          setIsOpen(false);
          setDateTimePickerName &&
            typeof setDateTimePickerName === 'function' &&
            setDateTimePickerName(null);
        }}
        {...rest}
      />
    </div>
  );
}

AppDateTimePicker.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  isInvalid: PropTypes.bool,
  format: PropTypes.string,
  showDefaultDate: PropTypes.bool,
  filter: PropTypes.bool,
  dateTimePickerName: PropTypes.any,
  setDateTimePickerName: PropTypes.func
};

export default AppDateTimePicker;

import React from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';

const AdvanceTable = ({
  getTableProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  rowClassName,
  tableProps,
  view,
  to,
  allQueryParams,
  pageNumber,
  threeColumnLayout,
  entry_params,
  vouchers_table,
  onClick,
  interBranch,
  status,
  stickyHeaderAndActions
}) => {
  const navigate = useNavigate();

  const handleNavigation = route => {
    navigate(route);
  };

  return (
    <Table
      {...getTableProps(tableProps)}
      hover
      className="advance-table-styles"
    >
      <thead
        className={`${headerClassName || ''} ${
          stickyHeaderAndActions ? 'position-sticky top-0 z-index-1' : ''
        }`}
      >
        <tr>
          {headers.map((column, index) => (
            <th
              key={index}
              {...column.getHeaderProps(
                column.getSortByToggleProps(column.headerProps)
              )}
              className={`${column?.headerProps?.className || ''} ${
                stickyHeaderAndActions && index === headers?.length - 1
                  ? 'sticky-column-header'
                  : ''
              }`}
            >
              {column.render('Header')}
              {column.canSort ? (
                column.isSorted ? (
                  column.isSortedDesc ? (
                    <span className="sort desc" />
                  ) : (
                    <span className="sort asc" />
                  )
                ) : (
                  <span className="sort" />
                )
              ) : (
                ''
              )}
            </th>
          ))}
        </tr>
      </thead>
      {page?.length > 0 ? (
        <tbody className={`${stickyHeaderAndActions ? 'position-sticky' : ''}`}>
          {page.map((row, i) => {
            prepareRow(row);
            if (vouchers_table) {
              return getAcodaxPermissionSlug(
                row.original.trans_type,
                'show'
              ) ? (
                <tr
                  key={i}
                  className={rowClassName}
                  {...row.getRowProps()}
                  style={{ cursor: 'pointer' }}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        {...cell.getCellProps(cell.column.cellProps)}
                        onClick={() =>
                          cell?.column?.Header === '' ||
                          cell?.column?.blockRedirection
                            ? ''
                            : handleNavigation(
                                threeColumnLayout
                                  ? `${to}${row.original.id}&layout=column${
                                      pageNumber ? `&page=${pageNumber}` : ''
                                    }`
                                  : interBranch
                                  ? `${to}${
                                      !entry_params ? row.original.id : ''
                                    }?${createSearchParams({
                                      ...allQueryParams,
                                      ...(entry_params
                                        ? {
                                            entry: row.original.id,
                                            layout: 'column',
                                            type: row.original.trans_type,
                                            ...allQueryParams
                                          }
                                        : {})
                                    })}`
                                  : `${to}${
                                      !entry_params ? row.original.id : ''
                                    }?${createSearchParams({
                                      ...allQueryParams,
                                      ...(entry_params
                                        ? {
                                            entry: row.original.id,
                                            layout: 'column',
                                            ...allQueryParams
                                          }
                                        : {})
                                    })}`
                              )
                        }
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              ) : (
                <tr
                  key={i}
                  className={rowClassName}
                  {...row.getRowProps()}
                  style={{ cursor: 'pointer' }}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        {...cell.getCellProps(cell.column.cellProps)}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            } else {
              return view ? (
                <tr
                  key={i}
                  className={rowClassName}
                  {...row.getRowProps()}
                  style={{ cursor: 'pointer' }}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        {...cell.getCellProps(cell.column.cellProps)}
                        className={`${
                          cell?.column?.cellProps?.className || ''
                        } ${
                          stickyHeaderAndActions &&
                          index === row?.cells?.length - 1
                            ? 'sticky-column'
                            : ''
                        }`}
                        onClick={() =>
                          cell?.column?.Header === '' ||
                          cell?.column?.blockRedirection
                            ? ''
                            : typeof onClick === 'function'
                            ? onClick(cell)
                            : handleNavigation(
                                threeColumnLayout
                                  ? `${to}${row.original.id}&layout=column${
                                      (pageNumber ? `&page=${pageNumber}` : '',
                                      interBranch &&
                                      status &&
                                      (status === 'pending' ||
                                        status === 'rejected')
                                        ? `type=${'PD'}`
                                        : '')
                                    }`
                                  : interBranch
                                  ? `${to}${
                                      !entry_params ? row.original.id : ''
                                    }?${createSearchParams({
                                      ...allQueryParams,
                                      ...(status &&
                                      (status === 'pending' ||
                                        status === 'rejected')
                                        ? { type: row.original.trans_type }
                                        : {}),
                                      ...(entry_params
                                        ? {
                                            entry: row.original.id,
                                            layout: 'column'
                                          }
                                        : {})
                                    })}`
                                  : `${to}${
                                      !entry_params ? row.original.id : ''
                                    }?${createSearchParams({
                                      ...allQueryParams,
                                      ...(entry_params
                                        ? {
                                            entry: row.original.id,
                                            layout: 'column'
                                          }
                                        : {})
                                    })}`
                              )
                        }
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              ) : (
                <tr
                  key={i}
                  className={rowClassName}
                  {...row.getRowProps()}
                  style={{ cursor: 'pointer' }}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        {...cell.getCellProps(cell.column.cellProps)}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            }
          })}
        </tbody>
      ) : (
        <tbody>
          <td
            style={{ backgroundColor: '#e307272e' }}
            className="text-center  p-2 fs--1 "
            rowSpan={headers?.length ?? 12}
            colSpan={headers?.length ?? 12}
          >
            No Data Available Let's Add It 🙂{' '}
          </td>
        </tbody>
      )}
    </Table>
  );
};
AdvanceTable.propTypes = {
  getTableProps: PropTypes.func,
  onClick: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object,
  allQueryParams: PropTypes.any,
  view: PropTypes.bool,
  to: PropTypes.any,
  pageNumber: PropTypes.any,
  threeColumnLayout: PropTypes.bool,
  entry_params: PropTypes.bool,
  vouchers_table: PropTypes.bool,
  interBranch: PropTypes.bool,
  status: PropTypes.any,
  stickyHeaderAndActions: PropTypes.bool
};

export default AdvanceTable;

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { MdOutlineDashboardCustomize } from 'react-icons/md';

import useAxisproTranslate from 'hooks/useAxisproTranslate';

function ShowCustomFieldValues({ data, className }) {
  const Translate = useAxisproTranslate();

  return (
    data?.value !== null && (
      <Col lg={6} md={6} sm={12} className={`p-0 ${className || ''}`}>
        <Row>
          <Col xs={4} className="d-flex">
            <MdOutlineDashboardCustomize
              className={`me-1 text-dark`}
              size={14}
            />
            <h6 className="mb-2 text-capitalize">
              {Translate(data?.custom_field?.label)}
            </h6>
          </Col>
          <Col xs={8}>
            <h6 className="mb-2">
              {data?.custom_field?.column_type === 'date'
                ? data?.value_formatted
                : data?.value}
            </h6>
          </Col>
        </Row>
      </Col>
    )
  );
}

ShowCustomFieldValues.propTypes = {
  data: PropTypes.any,
  className: PropTypes.string
};

export default ShowCustomFieldValues;

import { React, useState } from 'react';
import {
  createSearchParams,
  Link,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Container,
  Tab,
  Tabs
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';
import { BsCoin, BsReceipt } from 'react-icons/bs';
import { TiTick } from 'react-icons/ti';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { CloseButton, MoreButton } from 'module/Common/Buttons/ViewPageButtons';
import CustomerReceiptView from './CustomerReceiptView';
import VoidTransactionTab from '../Common/VoidTransactionTab';
import ReFundPopup from '../Common/ReFundPopup';
import CustomerDetails from 'components/customer-details/CustomerDetails';
import ViewPagePrintButton from '../Common/ViewPagePrintButton';
import AttachementsView from '../Common/AttachementsView';
import FileUpload from '../Common/FileUpload';
import SendEmail from '../Common/SendEmail';
import RecurringRules from '../Common/RecurringRules';
import AllocationsTable from '../Common/Tables/AllocationsTable';
import PDFView from 'components/PDFViewerButton/PDFView';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import PersonName from '../Common/ViewPageDetails/PersonName';
import MainDetails from '../Common/ViewPageDetails/MainDetails';
import Reference from '../Common/ViewPageDetails/Reference';
import ActivityLog from 'components/activity-log/ActivityLog';

function CustomerReceiptTabView({
  customerReceiptData,
  loading,
  voidScreen,
  fetchData,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  getData
}) {
  const Translate = useAxisproTranslate();
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const { breakpoints } = useBreakpoints();
  const [key, setKey] = useState(voidScreen ? 'voidForm' : 'overview');
  const [reFund, setReFund] = useState(false);
  const [showCustomerDetailsArea, setCustomerDetailsArea] = useState(false);
  const [tabApiCallCompleted, setTabApiCallCompleted] = useState({
    allocations: false
  });
  const permission = {
    show_cs: getAcodaxPermissionSlug('CR', 'show'),
    print_cr: getAcodaxPermissionSlug('CR', 'show'),
    customer_alloc_create: getAcodaxPermissionSlug('CRA', 'create'),
    send_mail_cr: getAcodaxPermissionSlug('CR', 'send-mail'),
    void_cr: getAcodaxPermissionSlug('VOID', 'TRANSACTION')
  };
  let costCenterID =
    customerReceiptData?.gl_transactions?.length > 0
      ? customerReceiptData?.gl_transactions[0]?.cost_center
      : '';
  const [popupData] = useState({
    personId: customerReceiptData.person_id,
    personType: customerReceiptData.person_type_id,
    personName: customerReceiptData.person_name,
    refundAmount: customerReceiptData.balance,
    reference: customerReceiptData.reference,
    refundAmountFormatted: customerReceiptData.balance_formatted,
    transType: customerReceiptData.trans_type,
    transNo: customerReceiptData.id,
    value: customerReceiptData.person_id,
    costCenterID: costCenterID
  });
  const [upload, setUpload] = useState(false);
  const [recurring, setRecurring] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  let navigateTo = useNavigate();
  const handleCustomerDetailsArea = () => {
    setCustomerDetailsArea(!showCustomerDetailsArea);
  };

  const getEmail = () => {
    switch (customerReceiptData.person_type_id) {
      case 'CUSTOMER':
        return customerReceiptData.person
          ? customerReceiptData.person.email
          : '';
      case 'SUPPLIER':
        return customerReceiptData.person
          ? customerReceiptData.person.email_address
          : '';
      default:
        return '';
    }
  };

  const sendEmail = () => {
    const email = getEmail();
    setEmailAddress(email);
    setShowEmailModal(true);
  };

  const handleCancel = () => {
    setShowEmailModal(false);
  };

  return (
    <>
      <Card className="flex-fill view-page-content-wrapper">
        <Card.Header
          className={
            breakpoints.up('sm')
              ? 'd-flex m-0 align-items-center border-bottom'
              : 'border-bottom'
          }
        >
          <>
            <div className="d-flex m-0 align-items-center">
              <BsReceipt size={28} className="me-2 text-dark" />
              <h5 className="mb-0 d-flex text-uppercase view-page-header">
                {Translate('Customer Receipt')}
              </h5>
            </div>
            {customerReceiptData && customerReceiptData.is_recurring ? (
              <Badge bg="success" className="ms-2 fs--1">
                {Translate('Recurring')} <TiTick />
              </Badge>
            ) : (
              ''
            )}
            {Math.round(customerReceiptData.amount) ===
            Math.round(customerReceiptData.alloc) ? (
              <Badge bg="success" className="ms-2 fs--1">
                {Translate('Fully Allocated')}
                <TiTick />
              </Badge>
            ) : Math.round(customerReceiptData.alloc) -
                Math.round(customerReceiptData.amount) !==
              0 ? (
              <Badge bg="info" className="ms-2 fs--1">
                {Translate('Advance Receipt')}
                <TiTick />
              </Badge>
            ) : (
              ''
            )}
            {voidTransactionReport && (
              <Badge bg="danger" className="ms-2 fs--1">
                {Translate('Voided')}
              </Badge>
            )}
            <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center">
              {voidScreen || offCanvas ? (
                ''
              ) : (
                <MoreButton
                  sendEmail={sendEmail}
                  setUpload={setUpload}
                  setReFund={setReFund}
                  setRecurring={setRecurring}
                  recurring
                  disableRecurring={customerReceiptData.is_recurring === 1}
                  disableRefundButton={
                    Math.round(customerReceiptData.balance) === 0
                  }
                  refundValiadtions={true}
                  emailPermission={permission.send_mail_cr}
                  voidTransactionData={
                    permission?.void_cr
                      ? {
                          id: customerReceiptData?.id,
                          trans_type: 'CR',
                          callBack: () => {
                            const newParams = { ...allQueryParams };
                            delete newParams?.entry;
                            delete newParams?.layout;
                            const queryString = Object.keys(newParams).length
                              ? `&${createSearchParams(newParams)}`
                              : '';
                            navigateTo(
                              `/finance/voucher-inquiry?layout=column${queryString}`
                            );
                            typeof getData === 'function' && getData();
                          }
                        }
                      : undefined
                  }
                />
              )}

              {!voidTransactionReport && permission.print_cr && (
                <ViewPagePrintButton
                  variant="danger"
                  className="text-white"
                  style={{ boxShadow: 'none' }}
                  url={`finance/customer_receipt/print/${customerReceiptData.id}`}
                  pageTitle="Customer Receipt Print View"
                />
              )}
              {offCanvas ? (
                <CloseButton
                  setShowVoucherDetailArea={setShowVoucherDetailArea}
                />
              ) : (
                ''
              )}
            </ButtonGroup>
          </>
        </Card.Header>
        <Card.Body className={loading && 'd-flex'}>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div>
              {customerReceiptData && customerReceiptData.person_name ? (
                <PersonName
                  label={Translate('Customer Name')}
                  value={customerReceiptData.person_name}
                  view={true}
                  permission={permission.show_cs}
                  setCustomerDetailsArea={setCustomerDetailsArea}
                />
              ) : null}
              {customerReceiptData.trans_date_formatted ? (
                <MainDetails
                  label={'Transaction Date'}
                  value={customerReceiptData.trans_date_formatted}
                />
              ) : null}
            </div>
            <div className=" view-page-contents  d-flex flex-column">
              {customerReceiptData?.reference ? (
                <Reference
                  offCanvas={offCanvas}
                  voidTransactionReport={voidTransactionReport}
                  to={`/finance/voucher-inquiry?entry=${customerReceiptData?.id}&layout=column`}
                  value={customerReceiptData?.reference}
                />
              ) : null}
            </div>
          </div>
          <Container className="p-0 view-page-tab-style-wrapper">
            {Math.round(customerReceiptData.balance) === 0 ||
            voidScreen ||
            offCanvas ||
            customerReceiptData.person.total_outstanding === 0 ? (
              ''
            ) : (
              <div className="view-page-payment-message-wrapper">
                <Card className="p-3">
                  <>
                    <div className="d-flex align-items-center gap-2">
                      <BsCoin />
                      <p className="m-0 text-center">
                        {Translate('Un Allocated Amount')}: &nbsp;
                        <span className="fw-bold text-dark">
                          {customerReceiptData.balance_formatted
                            ? customerReceiptData.balance_formatted
                            : '0.00'}
                        </span>
                      </p>
                    </div>
                  </>
                  {permission.customer_alloc_create ? (
                    <div>
                      <hr className="mt-2 mb-2" />
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex gap-2 align-items-center">
                          <BsCoin size={22} className="text-dark" />
                          <h4 className="m-0 tab-view-heading">
                            {Translate('Make payment for this transaction!')}
                          </h4>
                        </div>
                        <div>
                          <Button
                            variant="warning"
                            style={{ boxShadow: 'none' }}
                            className="fs--2 text-uppercase"
                            as={Link}
                            to={`/sales/customer-allocation/allocate/${customerReceiptData.id}?cost_center=${costCenterID}`}
                          >
                            {Translate('Make allocation')}
                          </Button>
                        </div>
                      </div>
                      <div>
                        <p className="m-0">
                          {Translate(
                            'Make allocations for this transaction if outstanding transaction available!'
                          )}
                        </p>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </Card>
              </div>
            )}
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              color="red"
            >
              {voidScreen ? (
                <Tab eventKey="voidForm" title={Translate('Voiding Form')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <VoidTransactionTab
                      transType={customerReceiptData.trans_type}
                      transId={customerReceiptData.id}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              <Tab eventKey="overview" title={Translate('Overview')}>
                <SimpleBar
                  className={
                    Math.round(customerReceiptData.balance) === 0 ||
                    voidScreen ||
                    offCanvas ||
                    customerReceiptData.person.total_outstanding === 0
                      ? 'simplebar-view-page-style'
                      : 'simplebar-view-page-style-with-container'
                  }
                >
                  <CustomerReceiptView
                    customerReceiptData={customerReceiptData}
                  />
                </SimpleBar>
              </Tab>
              <Tab eventKey="allocations" title={Translate('Allocations')}>
                <SimpleBar
                  className={
                    Math.round(customerReceiptData.balance) === 0 ||
                    voidScreen ||
                    offCanvas ||
                    customerReceiptData.person.total_outstanding === 0
                      ? 'simplebar-view-page-style'
                      : 'simplebar-view-page-style-with-container'
                  }
                >
                  <AllocationsTable
                    id={customerReceiptData?.id}
                    t_type={'CR'}
                    activeTabKey={key}
                    tabApiCallCompleted={tabApiCallCompleted?.allocations}
                    setTabApiCallCompleted={setTabApiCallCompleted}
                  />
                </SimpleBar>
              </Tab>
              {customerReceiptData.media &&
              customerReceiptData.media.length > 0 ? (
                <Tab eventKey="attachments" title={Translate('Attachments')}>
                  <SimpleBar
                    className={
                      Math.round(customerReceiptData.balance) === 0 ||
                      voidScreen ||
                      offCanvas ||
                      customerReceiptData.person.total_outstanding === 0
                        ? 'simplebar-view-page-style'
                        : 'simplebar-view-page-style-with-container'
                    }
                  >
                    <AttachementsView
                      data={customerReceiptData}
                      fetchData={fetchData}
                      setKey={setKey}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              {customerReceiptData?.id ? (
                <Tab eventKey="pdfView" title={Translate('PDF View')}>
                  <SimpleBar className="simplebar-view-page-style pdf-tab-view-simplebar-style">
                    {key === 'pdfView' && (
                      <PDFView
                        viewType="tab"
                        url={`finance/customer_receipt/print/${customerReceiptData.id}`}
                      />
                    )}
                  </SimpleBar>
                </Tab>
              ) : null}
              {customerReceiptData?.id ? (
                <Tab eventKey="activityLog" title={Translate('Activity Log')}>
                  <SimpleBar
                    className={
                      voidScreen || offCanvas
                        ? 'simplebar-view-page-style'
                        : 'simplebar-view-page-style-with-container'
                    }
                  >
                    {key === 'activityLog' && (
                      <ActivityLog subjectID={customerReceiptData?.id} />
                    )}
                  </SimpleBar>
                </Tab>
              ) : null}
            </Tabs>
          </Container>
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created By')}:&nbsp;</h6>
            <h6 className="m-0">
              {customerReceiptData && customerReceiptData.created_user_full_name
                ? customerReceiptData.created_user_full_name
                : 'null'}
            </h6>
          </div>
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created At')}:&nbsp;</h6>
            <h6 className="m-0">
              {customerReceiptData && customerReceiptData.created_at
                ? customerReceiptData.created_at
                : 'null'}
            </h6>
          </div>
        </Card.Footer>
      </Card>
      <ReFundPopup
        show={reFund}
        popupData={popupData}
        fetchData={() => {
          fetchData();
          setTabApiCallCompleted(prev => ({
            ...prev,
            allocations: false
          }));
        }}
        onHide={() => setReFund(false)}
      />
      <CustomerDetails
        show={showCustomerDetailsArea}
        onHide={handleCustomerDetailsArea}
        value={popupData}
        showCustomerDetailsArea={showCustomerDetailsArea}
      />
      <FileUpload
        show={upload}
        onHide={() => setUpload(false)}
        fetchData={fetchData}
        type={'CR'}
        id={customerReceiptData.id}
        setKey={setKey}
      />
      <SendEmail
        url={'finance/customer_receipt/send-email/' + customerReceiptData.id}
        showEmailModal={showEmailModal}
        handleCancel={handleCancel}
        data={emailAddress}
      />
      <RecurringRules
        show={recurring}
        type="CR"
        id={customerReceiptData.id}
        onHide={() => setRecurring(false)}
        fetchData={fetchData}
      />
    </>
  );
}

CustomerReceiptTabView.propTypes = {
  customerReceiptData: PropTypes.any,
  loading: PropTypes.bool,
  voidScreen: PropTypes.bool,
  fetchData: PropTypes.func,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  getData: PropTypes.func
};

export default CustomerReceiptTabView;

import React from 'react';
import { Alert, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import WorkOrderMaterialRequestsModalMaterialsEntryTableForm from './WorkOrderMaterialRequestsModalMaterialsEntryTableForm';
import WorkOrderMaterialRequestsModalMaterialsEntryTableItem from './WorkOrderMaterialRequestsModalMaterialsEntryTableItem';

export default function WorkOrderMaterialRequestsModalMaterialsEntryTable({
  setFormData,
  materials,
  estimationMaterialCost
}) {
  const editItemsCount = Array.isArray(materials)
    ? materials.filter(item => item?.edit_ref === true)?.length
    : 0;
  return (
    <div className="estimation-entries-section-cover mb-3">
      <Table responsive className="simple-table-animation">
        <thead>
          <tr className="head-tr">
            <th colSpan={7} className="p-0">
              <p className="title text-uppercase">MATERIALS</p>
            </th>
          </tr>

          <tr className="title-items">
            <th>#</th>
            <th>ITEM CODE</th>
            <th>ITEM NAME</th>
            <th>QUANTITY</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(materials) && materials.length > 0 ? (
            materials.map((item, index) =>
              item?.edit_ref ? (
                <WorkOrderMaterialRequestsModalMaterialsEntryTableForm
                  key={index}
                  setFormData={setFormData}
                  editItemData={item}
                  ItemIndex={index}
                  estimationMaterialCost={estimationMaterialCost}
                />
              ) : (
                <WorkOrderMaterialRequestsModalMaterialsEntryTableItem
                  key={index}
                  data={item}
                  ItemIndex={index}
                  setFormData={setFormData}
                />
              )
            )
          ) : (
            <tr>
              <td colSpan={5} className="p-0">
                <Alert
                  variant="danger"
                  className="m-0 rounded-0 border p-2 text-center border-0"
                >
                  Entries are required!
                </Alert>
              </td>
            </tr>
          )}

          {!editItemsCount ? (
            <WorkOrderMaterialRequestsModalMaterialsEntryTableForm
              setFormData={setFormData}
              estimationMaterialCost={estimationMaterialCost}
            />
          ) : null}
        </tbody>
      </Table>
    </div>
  );
}

WorkOrderMaterialRequestsModalMaterialsEntryTable.propTypes = {
  setFormData: PropTypes.func,
  materials: PropTypes.array,
  estimationMaterialCost: PropTypes.array
};

import { React, useState } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { ButtonGroup, Card, Container, Tab, Tabs } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';
import { FaUserCog } from 'react-icons/fa';
import { useBreakpoints } from 'hooks/useBreakpoints';
import {
  CloseButton,
  DeleteButtonView,
  EditButton,
  MoreButton
} from 'module/Common/Buttons/ViewPageButtons';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import AttachementsView from '../Common/AttachementsView';
import FileUpload from '../Common/FileUpload';
import CustomerView from './CustomerView';
import ActivityLog from '../Common/ActivityLog';
import PersonBasedTransactions from '../Common/PersonBasedComponents/PersonBasedTransactions';
import CustomerShippingAddressTab from './CustomerShippingAddressTab';
import CommissionSettings from './CommissionSettings';
import CustomerDocuments from './CustomerDocuments';
import axios from 'axios';
import Badges from '../Common/ViewPageDetails/Badges';
import { showToast } from 'module/Common/Toast/toast';
import LoadingOverlay from 'module/Common/Layer/LoadingOverlay';
import DeleteWarning from 'module/Common/Warnings/DeleteWarning';

function CustomerTabView({
  customerData,
  loading,
  fetchData,
  Offcanvas,
  onHide,
  fetchCustomers
}) {
  const Translate = useAxisproTranslate();
  const [loadingState, setLoadingState] = useState(false);
  const { breakpoints } = useBreakpoints();
  const [key, setKey] = useState('overview');
  const [upload, setUpload] = useState(false);
  const [isKYC, setIsKYC] = useState(false);
  const [tabApiCallCompleted, setTabApiCallCompleted] = useState({
    activity_log: false,
    transaction: false
  });
  const [deleteItemInfo, setDeleteItemInfo] = useState({
    id: '',
    show: false
  });
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const permission = {
    update_cs: getAcodaxPermissionSlug('CS', 'update'),
    history_lg: getAcodaxPermissionSlug('LG', 'show-all'),
    show_csa: getAcodaxPermissionSlug('CSA', 'show'),
    delete_cs: getAcodaxPermissionSlug('CS', 'delete')
  };

  const handleCustomerStatus = () => {
    setLoadingState(true);
    axios
      .put(`sales/customer/set-active-inactive/${customerData.id}`, {
        status: customerData?.inactive === 1 ? 0 : 1
      })
      .then(res => {
        if (res.data.success) {
          showToast(res.data.message, 'success');
          fetchData();
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
      })
      .catch(error => {
        if (error.response.data && error.response.data.message) {
          showToast(error.response.data.message, 'error');
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
      })
      .finally(() => {
        setLoadingState(false);
      });
  };

  const uploadKYC = () => {
    setIsKYC(true);
    setUpload(true);
  };

  const handleDelete = () => {
    setDeleteItemInfo({
      id: customerData.id,
      show: true
    });
  };

  return (
    <>
      {loadingState && <LoadingOverlay />}
      <Card className="flex-fill view-page-content-wrapper">
        {!Offcanvas && (
          <Card.Header
            className={
              breakpoints.up('sm')
                ? 'd-flex m-0 align-items-center border-bottom'
                : 'border-bottom'
            }
          >
            <>
              <div className="d-flex m-0 align-items-center">
                <FaUserCog size={28} className="me-2 text-dark" />
                <h5
                  className="mb-0 d-flex text-uppercase view-page-header"
                  style={{ fontSize: '25px' }}
                >
                  {customerData && customerData.name ? customerData.name : ''}
                </h5>
              </div>
              {customerData && customerData?.inactive === 1 ? (
                <Badges
                  label={'Inactive'}
                  className="ms-3 fs--1"
                  bg={'danger'}
                />
              ) : (
                ''
              )}
              <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center">
                <MoreButton
                  setUpload={setUpload}
                  type="CUSTOMER"
                  fileUploadButtonTitle="Upload Documents"
                  handleCustomerStatus={handleCustomerStatus}
                  customerStatus={customerData?.inactive}
                />
                {permission?.update_cs ? (
                  <EditButton
                    style={{ boxShadow: 'none' }}
                    to={`/sales/customer/edit/${
                      customerData.id
                    }?${createSearchParams({
                      ...allQueryParams
                    })}`}
                  />
                ) : (
                  ''
                )}
                {permission?.delete_cs ? (
                  <DeleteButtonView
                    style={{ boxShadow: 'none' }}
                    onClick={handleDelete}
                  />
                ) : (
                  ''
                )}
              </ButtonGroup>
            </>
            {onHide ? (
              <div className="ms-2">
                <CloseButton onHide={onHide} />
              </div>
            ) : (
              ''
            )}
          </Card.Header>
        )}
        <Card.Body
          className={loading && 'd-flex '}
          style={{ height: Offcanvas ? '90vh' : '' }}
        >
          <div className="d-flex flex-wrap">
            <div className="w-100 mb-2 view-page-contents">
              <div className="d-flex gap-3">
                <h6 className="mb-2 fw-bold">
                  {Translate('Customer Name')}:&nbsp;
                </h6>
                <h6 className="mb-2 text-uppercase">
                  {customerData && customerData.name
                    ? customerData.name
                    : 'null'}
                </h6>
              </div>
              <div className="d-flex gap-3">
                <h6 className="mb-2 fw-bold">
                  {Translate('Unused Credits')}:&nbsp;
                </h6>
                <h6
                  className={
                    customerData.unused_credits === 0
                      ? 'mb-2 text-primary fs-1'
                      : 'mb-2 text-success fs-1'
                  }
                >
                  {customerData && customerData.unused_credits_formatted
                    ? customerData.unused_credits_formatted
                    : 'null'}
                </h6>
              </div>
            </div>
            <div className="w-100 mb-2 view-page-contents">
              <div className="d-flex gap-3">
                <h6 className="mb-2 fw-bold">
                  {Translate('Customer ID')}:&nbsp;
                </h6>
                <h6 className="mb-2">
                  {customerData && customerData.customer_id
                    ? customerData.customer_id
                    : 'update'}
                </h6>
              </div>
              <div className="d-flex gap-3">
                <h6 className="mb-2 fw-bold">
                  {Translate('Outstanding Receivables')}
                  :&nbsp;
                </h6>
                <h6
                  className={
                    customerData.unused_credits === 0
                      ? 'mb-2 text-warning fs-1'
                      : 'mb-2 text-danger fs-1'
                  }
                >
                  {customerData && customerData.total_outstanding_formatted
                    ? customerData.total_outstanding_formatted
                    : 'null'}
                </h6>
              </div>
            </div>
          </div>
          <Container className="p-0 view-page-tab-style-wrapper">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              color="red"
              className="custom-offcanvas-header-tabs"
            >
              <Tab eventKey="overview" title={Translate('Overview')}>
                <SimpleBar className="simplebar-overview-page-style">
                  <CustomerView customerData={customerData} />
                </SimpleBar>
              </Tab>

              <Tab eventKey="transaction" title={Translate('Transactions')}>
                <SimpleBar className="simplebar-overview-page-style">
                  <PersonBasedTransactions
                    person_id={customerData?.id}
                    person_name="customer_id"
                    url={'sales/customer-balance-statements'}
                    activeTabKey={key}
                    tabApiCallCompleted={tabApiCallCompleted?.transaction}
                    setTabApiCallCompleted={setTabApiCallCompleted}
                  />
                </SimpleBar>
              </Tab>
              {permission?.history_lg ? (
                <Tab eventKey="activity" title={Translate('Activity Log')}>
                  <SimpleBar className="simplebar-overview-page-style">
                    <ActivityLog
                      id={customerData.id}
                      type={'CUSTOMER'}
                      activeTabKey={key}
                      tabApiCallCompleted={tabApiCallCompleted?.activity_log}
                      setTabApiCallCompleted={setTabApiCallCompleted}
                    />
                  </SimpleBar>
                </Tab>
              ) : null}
              {customerData.media && customerData.media.length > 0 ? (
                <Tab eventKey="attachments" title={Translate('Attachments')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <AttachementsView
                      data={customerData}
                      fetchData={fetchData}
                      setKey={setKey}
                      deletePermsission={permission?.update}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              {permission?.show_csa ? (
                <Tab
                  eventKey="shipping-address"
                  title={Translate('Shipping Address')}
                >
                  {key === 'shipping-address' && (
                    <CustomerShippingAddressTab itemData={customerData} />
                  )}
                </Tab>
              ) : (
                ''
              )}
              <Tab
                eventKey="commission_settings"
                title={Translate('Commission Settings')}
              >
                <SimpleBar className="simplebar-view-page-style">
                  <CommissionSettings customerData={customerData} />
                </SimpleBar>
              </Tab>
              <Tab eventKey="kyc" title={Translate('KYC')}>
                <SimpleBar className="simplebar-view-page-style">
                  <CustomerDocuments
                    documents={customerData?.document}
                    fetchData={fetchData}
                    setKey={setKey}
                    uploadKYC={uploadKYC}
                  />
                </SimpleBar>
              </Tab>
            </Tabs>
          </Container>
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created By')}:&nbsp;</h6>
            <h6 className="m-0">
              {customerData && customerData.created_user_full_name
                ? customerData.created_user_full_name
                : 'null'}
            </h6>
          </div>
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created At')}:&nbsp;</h6>
            <h6 className="m-0">
              {customerData && customerData.created_at
                ? customerData.created_at
                : 'null'}
            </h6>
          </div>
        </Card.Footer>
      </Card>
      <FileUpload
        show={upload}
        onHide={() => {
          setIsKYC(false);
          setUpload(false);
        }}
        fetchData={fetchData}
        type={'CUSTOMER'}
        id={customerData.id}
        setKey={setKey}
        documents={isKYC}
      />
      <DeleteWarning
        show={deleteItemInfo.show}
        id={deleteItemInfo.id}
        onHide={() => {
          setDeleteItemInfo({ id: '', show: false });
          fetchCustomers(true);
        }}
        heading={'Delete customer'}
        message={'Are you sure? You want to delete this Customer!'}
        type={'CUSTOMER'}
        url="sales/customers"
      />
    </>
  );
}

CustomerTabView.propTypes = {
  customerData: PropTypes.any,
  loading: PropTypes.bool,
  fetchData: PropTypes.func,
  Offcanvas: PropTypes.bool,
  onHide: PropTypes.func,
  fetchCustomers: PropTypes.func
};

export default CustomerTabView;

import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { Alert } from 'react-bootstrap';
import { FaBell } from 'react-icons/fa';
import { IoCloseCircle } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

const ChequeNotificationAlert = () => {
  const Translate = useAxisproTranslate();
  const [show, setShow] = useState(false);
  const [chequeCount, setChequeCount] = useState(0);
  const navigate = useNavigate();

  const dismissAlertData = JSON.parse(
    localStorage.getItem('ERP_ALERT_DISMISS_DATA') || '{}'
  );

  const today = new Date().toISOString().split('T')[0];

  const getChequeDetails = useCallback(async () => {
    if (dismissAlertData?.chequeAlert?.date === today) return;

    try {
      const { data } = await axios.get(`finance/cheques/`, {
        params: { cheque_date: today, status: 'pending' }
      });

      const chequeData = data?.data?.data || [];
      setChequeCount(chequeData.length);
      setShow(chequeData.length > 0);
    } catch (error) {
      console.error('Error fetching cheque details:', error);
    }
  }, [dismissAlertData?.chequeAlert?.date, today]);

  const handleViewDetails = (navigateToPage = true) => {
    localStorage.setItem(
      'ERP_ALERT_DISMISS_DATA',
      JSON.stringify({
        ...dismissAlertData,
        chequeAlert: { date: today, dismissed: true }
      })
    );
    setShow(false);

    if (navigateToPage) {
      navigate(
        `/finance/cheques?filters=${encodeURIComponent(
          JSON.stringify({ cheque_date: today })
        )}`
      );
    }
  };

  useEffect(() => {
    getChequeDetails();
  }, [getChequeDetails]);

  return (
    <div className="me-2">
      {show && (
        <Alert
          className="position-relative rounded dashboard-alert pb-0 mb-2"
          variant=""
          onClick={() => handleViewDetails(false)}
        >
          <div className="d-flex align-items-center mb-2">
            <FaBell className="me-2" color="#b34220" />
            <h5 className="mb-0 fw-bold">
              {Translate('Cheque Clearance Alert')}
            </h5>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <p>
              {Translate(
                `Reminder: You have ${chequeCount} cheque(s) due for clearance today. Please check the details to ensure timely processing and avoid any delays.`
              )}
            </p>
            <div
              className="text-primary view-details text-decoration-underline cursor-pointer"
              onClick={() => handleViewDetails(true)}
            >
              {Translate('View Details')}
            </div>
          </div>
          <IoCloseCircle
            className="close-icon cursor-pointer"
            color="#b34220"
            onClick={() => handleViewDetails(false)}
          />
        </Alert>
      )}
    </div>
  );
};

export default ChequeNotificationAlert;

import { React, useState } from 'react';

import AsyncSelect from 'react-select/async';

import PropTypes from 'prop-types';
import ItemAdvanceSearch from 'components/item-advance-search/ItemAdvanceSearch';
import { BsHandbagFill } from 'react-icons/bs';
import ReactSelectAddButton from 'components/add-button/ReactSelectAddButton';
import ItemPopupForm from './popup-forms/ItemPopupForm';
import useAutoCompleteApiCallHandler from 'hooks/useAutoCompleteApiCallHandler';

const SelectItem = ({
  handleFieldChange,
  name,
  error,
  value,
  stock_id,
  keyName,
  advanceSearch,
  advanceSearchType,
  filter_keys,
  style,
  placeholder,
  withAddButton,
  classNamePrefix,
  formDefaultProductType,
  onFocus,
  showFilterIcon,
  fixedAssets,
  onKeyDown
}) => {
  const [showFormWindow, setShowFormWindow] = useState(false);

  const {
    fetchData,
    setDefaultResult,
    loading,
    defaultOptions,
    setDefaultOptions
  } = useAutoCompleteApiCallHandler({
    url: 'inventory/items-auto',
    setParams: searchKey => ({
      ...{
        item_name: searchKey?.length > 0 ? searchKey : stock_id ?? ''
      },
      ...filter_keys
    }),
    setOptions: option => setOptionData(option),
    onSuccess: ({ options, action }) => {
      if (stock_id) {
        const stockIdRelatedItem = options?.find(
          item => item.stock_id?.toLowerCase() === stock_id?.toLowerCase()
        );

        handleFieldChange(stockIdRelatedItem ?? null, {
          name: name
        });
      } else if (action !== 'setDefaultData' && !stock_id && value) {
        handleFieldChange(null, {
          name: name
        });
      }
    },
    onFocus: onFocus,
    dependencyKeyToResetDefaultOptions: JSON.stringify(filter_keys)
  });

  const setOptionData = option => ({
    label: option.item_name,
    value: keyName ? option[keyName] : option.id,
    item: option,
    type: option.type,
    stock_id: option.stock_id
  });

  const getOptionLabel = option => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <span>{option.label}</span>
        {option.type === 'kit' ? (
          <BsHandbagFill style={{ fill: 'red' }} size={11} />
        ) : null}
      </div>
    );
  };
  return (
    <div className="d-flex flrex-row align-items-center">
      <AsyncSelect
        cacheOptions
        key={stock_id}
        loadOptions={fetchData}
        defaultOptions={defaultOptions}
        onFocus={setDefaultResult}
        isLoading={loading}
        isClearable
        name={name ?? 'item'}
        value={value}
        placeholder={placeholder ?? ''}
        onChange={handleFieldChange}
        onKeyDown={onKeyDown}
        getOptionLabel={getOptionLabel}
        className={`w-100  ${
          error ? ' form-control ps-0 py-0 is-invalid' : ''
        }`}
        classNamePrefix={classNamePrefix ?? 'item-select-autocomplete'}
        styles={{
          menuPortal: provided => ({ ...provided, zIndex: 9999 }),
          menu: provided => ({ ...provided, zIndex: 9999 }),
          control: provided => ({
            ...provided,
            height: 32,
            minHeight: 32,
            ...style
          })
        }}
        components={
          withAddButton
            ? {
                Menu: props => (
                  <>
                    <ReactSelectAddButton
                      props={props}
                      setShowFormWindow={() => setShowFormWindow(true)}
                      title="Add Item"
                    />
                  </>
                )
              }
            : null
        }
        menuPortalTarget={document.body}
      />

      {advanceSearch && (
        <ItemAdvanceSearch
          setItem={handleFieldChange}
          name={name ?? 'item'}
          keyName={keyName}
          advanceSearchType={advanceSearchType}
          showFilterIcon={showFilterIcon}
          fixedAssets={fixedAssets}
          filter_keys={filter_keys}
        />
      )}

      {withAddButton ? (
        <ItemPopupForm
          name={name ?? 'item'}
          labelName={keyName ?? 'item_name'}
          keyName={keyName ?? 'id'}
          show={showFormWindow}
          onHide={() => setShowFormWindow(false)}
          setData={handleFieldChange}
          formDefaultProductType={formDefaultProductType}
          callBack={newItem => {
            if (newItem?.id) {
              setDefaultOptions(prev => [setOptionData(newItem), ...prev]);
            }
          }}
        />
      ) : null}
    </div>
  );
};

SelectItem.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.object,
  stock_id: PropTypes.string,
  keyName: PropTypes.string,
  advanceSearch: PropTypes.bool,
  filter_keys: PropTypes.object,
  advanceSearchType: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  withAddButton: PropTypes.bool,
  classNamePrefix: PropTypes.string,
  formDefaultProductType: PropTypes.string,
  onFocus: PropTypes.func,
  showFilterIcon: PropTypes.bool,
  fixedAssets: PropTypes.bool,
  onKeyDown: PropTypes.func
};

export default SelectItem;

import { React, useContext } from 'react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { BsCalendar2Date, BsCoin } from 'react-icons/bs';

import LineItemTable from '../Common/Tables/LineItemTable';
import ShowCustomFieldValues from '../Common/ViewPageDetails/ShowCustomFieldValues';
import PersonName from '../Common/ViewPageDetails/PersonName';
import Badges from '../Common/ViewPageDetails/Badges';
import Details from '../Common/ViewPageDetails/Details';
import ShowMemo from '../Common/ViewPageDetails/ShowMemo';
import { FaIdCardClip } from 'react-icons/fa6';
import { MdOutlineBackspace } from 'react-icons/md';
import { FaUser } from 'react-icons/fa';
import { GrStatusGoodSmall } from 'react-icons/gr';
import { TbNotes } from 'react-icons/tb';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { StoreContext } from 'context/Context';

function RentalContractView({ rentalContractData }) {
  const Translate = useAxisproTranslate();
  const { store } = useContext(StoreContext);
  const taxLabel = store?.globalConfiguration?.tax_label || 'Tax';
  return (
    <Container className="mt-3 ps-3 pe-3 voucher-inquiry-view-wrapper">
      {rentalContractData?.rental_status === 'returned' && (
        <Row className="mb-3" style={{ backgroundColor: 'rgb(215 250 234)' }}>
          <Col md={12} className="p-3">
            <div className="fw-bold pb-1 text-dark">
              {Translate('Returned Information')}
            </div>
            <div className="mt-2">
              {rentalContractData?.rental_returned_date_formatted && (
                <Details
                  label={'Returned Date :'}
                  value={rentalContractData?.rental_returned_date_formatted}
                  icon={
                    <MdOutlineBackspace
                      className="me-1 text-danger"
                      size={15}
                    />
                  }
                  className={'mb-2 fs--1'}
                />
              )}
              {rentalContractData?.return_acknowledged_by_user && (
                <Details
                  label={'Acknowledged By:'}
                  value={rentalContractData?.return_acknowledged_by_user?.name}
                  icon={<FaUser className="me-1 text-success" size={15} />}
                  className={'mb-2 fs--1'}
                />
              )}
              {rentalContractData?.return_asset_status && (
                <Details
                  label={'Asset Status :'}
                  value={
                    <Badge bg={'warning'} className="text-uppercase">
                      {rentalContractData?.return_asset_status.replace(
                        /_/g,
                        ' '
                      )}
                    </Badge>
                  }
                  icon={
                    <GrStatusGoodSmall className="me-1 text-info" size={15} />
                  }
                  className={'mb-2 fs--1 text-capitalize'}
                />
              )}
              {rentalContractData?.return_note && (
                <Details
                  label={'Notes :'}
                  value={rentalContractData?.return_note}
                  icon={<TbNotes className="me-1 text-dark" size={15} />}
                  className={'mb-2 fs--1 text-capitalize'}
                />
              )}
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col lg={6} md={6} sm={12} className="p-0">
          {rentalContractData?.customer_name ? (
            <PersonName value={rentalContractData?.customer_name} />
          ) : null}
          {rentalContractData?.reference && (
            <Badges label={rentalContractData?.reference} bg={'danger'} />
          )}
          <div className="mt-2">
            {rentalContractData?.title && (
              <Details
                label={'Title'}
                value={rentalContractData?.title}
                className={'mb-2 fs--1'}
                iconColor={'text-info'}
              />
            )}
            {rentalContractData?.contract_type && (
              <Details
                label={'Contract Type'}
                value={rentalContractData?.contract_type}
                className={'mb-2 fs--1 text-capitalize'}
                iconColor={'text-danger'}
              />
            )}
            {rentalContractData?.contract_type && (
              <Details
                label={'Contract Tenure'}
                value={rentalContractData?.contract_tenure}
                className={'mb-2 fs--1 text-capitalize'}
                iconColor={'text-success'}
                icon={<FaIdCardClip className="me-1 text-success" size={15} />}
              />
            )}
            {rentalContractData?.contract_from_date_formatted && (
              <Details
                label={'Start Date'}
                value={rentalContractData?.contract_from_date_formatted}
                icon={<BsCalendar2Date className="me-1 text-dark" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
            {rentalContractData?.contract_to_date_formatted && (
              <Details
                label={'End Date'}
                value={rentalContractData?.contract_to_date_formatted}
                icon={<BsCalendar2Date className="me-1 text-dark" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
            {rentalContractData?.sales_type && (
              <Details
                label={'Price List'}
                value={rentalContractData?.sales_type}
                className={'mb-2 fs--1'}
                iconColor={'text-warning'}
              />
            )}
          </div>
        </Col>
        <Col lg={6} md={6} sm={12} className="p-0">
          <div className="mt-sm-2 mt-md-5">
            <Details
              label={
                rentalContractData?.tax_included &&
                rentalContractData?.tax_included === '1'
                  ? `Amount (Inc.${taxLabel})`
                  : 'Amount'
              }
              value={rentalContractData?.amount_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={`Total ${taxLabel}`}
              value={rentalContractData?.total_tax_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={'Total Discount'}
              value={
                rentalContractData?.total_discount_amount_formatted ?? '0.00'
              }
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
          </div>
        </Col>
      </Row>
      {rentalContractData?.custom_field_values &&
        rentalContractData?.custom_field_values.length > 0 && (
          <Row className="mt-3">
            {rentalContractData?.custom_field_values.map((item, index) => (
              <ShowCustomFieldValues key={index} data={item} />
            ))}
          </Row>
        )}
      <Row>
        <Col xl={12} md={12} sm={12} className="p-0 mt-2">
          <LineItemTable data={rentalContractData} assetView />
        </Col>
      </Row>
      {rentalContractData?.description && (
        <ShowMemo
          memo={rentalContractData?.description}
          label={'Description'}
        />
      )}
    </Container>
  );
}

RentalContractView.propTypes = {
  rentalContractData: PropTypes.any
};

export default RentalContractView;

import React from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FaCirclePlus } from 'react-icons/fa6';

import useAcodaxNumberFormat from 'hooks/useAcodaxNumberFormat';
import SearchAndSelectItem from 'module/Common/Search/SearchAndSelectItem';
import { handleEnterButton } from 'module/Sales/common/functions/enterKeyFunctionality';
import { IoIosCheckmarkCircle, IoMdCloseCircle } from 'react-icons/io';

function BOMForm({
  itemFromData,
  handleFieldChange,
  handleAddLineItem,
  handleItem,
  newItemFieldRef,
  isUpdate = false,
  handleCloseLineItem
}) {
  const acodaxNumberFormat = useAcodaxNumberFormat();

  return (
    <tr>
      <td className="text-center p-1">#</td>
      <td className="text-center p-1">{itemFromData?.stock_id ?? '-'}</td>
      <td className="text-center text-dark p-1">
        <SearchAndSelectItem
          value={itemFromData?.item_display_name_ref}
          handleFieldChange={handleItem}
          name={itemFromData?.item_display_name_ref}
          keyName="item_name"
          advanceSearchType="sales"
          disableAddForm
          ref={newItemFieldRef}
        />
      </td>
      <td className="p-1">
        <InputGroup>
          <Form.Control
            type="number"
            name="quantity"
            onChange={handleFieldChange}
            value={itemFromData?.quantity}
            className="form-tab-switch-control"
            style={{ width: '60%' }}
          />
          <Form.Control
            type="text"
            name="unit_name"
            readOnly
            disabled
            value={itemFromData?.unit_name}
            className="form-tab-switch-control px-2"
            style={{ width: '40%' }}
          />
        </InputGroup>
      </td>
      <td className="p-1">
        <Form.Control
          type="number"
          name="amount"
          onChange={handleFieldChange}
          value={itemFromData?.amount}
          className="form-tab-switch-control"
        />
      </td>
      <td className="p-1 text-end text-dark">
        {Number(itemFromData?.quantity * itemFromData?.amount)
          ? acodaxNumberFormat(itemFromData?.quantity * itemFromData?.amount)
          : ''}
      </td>
      <td
        className="d-flex align-items-center justify-content-center p-1"
        style={{ height: '45px' }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <Button
            id="submitButton"
            size="sm"
            variant="transparent"
            className="ps-1 pe-1 pt-0 pb-0 form-tab-switch-control"
            onClick={handleAddLineItem}
            disabled={!itemFromData?.item_display_name_ref}
            onKeyDown={e =>
              handleEnterButton(
                e,
                handleAddLineItem,
                itemFromData,
                'submitButton'
              )
            }
            title={isUpdate ? 'Update Existing Item' : 'Add New Item'}
          >
            {isUpdate ? (
              <IoIosCheckmarkCircle size={20} color="green" />
            ) : (
              <FaCirclePlus size={16} color="green" />
            )}
          </Button>

          {isUpdate && (
            <Button
              id="cancelButton"
              size="sm"
              variant="transparent"
              className="ps-1 pe-1 pt-0 pb-0 form-tab-switch-control"
              onClick={handleCloseLineItem}
              title="Cancel Edit"
            >
              <IoMdCloseCircle size={20} className="text-danger" />
            </Button>
          )}
        </div>
      </td>
    </tr>
  );
}

BOMForm.propTypes = {
  itemFromData: PropTypes.object,
  handleFieldChange: PropTypes.func,
  handleAddLineItem: PropTypes.func,
  handleItem: PropTypes.func,
  newItemFieldRef: PropTypes.any,
  isUpdate: PropTypes.bool,
  handleCloseLineItem: PropTypes.func
};

export default BOMForm;

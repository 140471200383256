import { React, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import CommonSelect from 'components/form/CommonSelect';

function CustomInputFields({
  item,
  customFields,
  formError,
  enableDraft,
  handleInputBoxOutofFocus,
  handleInputBoxFocus,
  rowColumnAutoAdjust = false
}) {
  const Translate = useAxisproTranslate();
  const [data, setData] = useState({});
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState([]);
  const [multiSelectArray, setMultiSelectArray] = useState([]);
  const [multiSelectId, setMultiSelectId] = useState('');

  const handleFieldChange = (e, id) => {
    const updatedData = {
      ...data,
      [id]: e?.target?.value
    };
    setData(updatedData);
    if (e?.target?.value === '' || e?.target?.value === 'select') {
      delete customFields?.[id];
    } else {
      customFields[id] = e?.target?.value;
    }
    return customFields;
  };

  const handleMultiSelect = (id, value) => {
    setMultiSelectId(id);
    setValue(value);
  };

  const createOption = label => ({
    label,
    value: label
  });

  //You can select values in multi select using enter key
  const handleKeyDown = e => {
    if (!inputValue) return;
    switch (e?.key) {
      case 'Enter':
      case 'Tab':
        setValue(prev => [...prev, createOption(inputValue)]);
        setInputValue('');
        e?.preventDefault();
    }
  };

  //This constant is used to create an array of options form multi select and single select
  const optionsArray =
    item?.option_custom_value &&
    item?.option_custom_value?.split(',')?.map(option => option?.trim());

  //This function is used to create multiselect options
  useEffect(() => {
    if (optionsArray !== null && optionsArray?.length > 0) {
      const data = optionsArray?.map(value => ({
        value: value?.toLowerCase(),
        label: value
      }));
      setMultiSelectArray(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //This function is used to covert multiselect values to desired custom field values for submitting
  useEffect(() => {
    if (value?.length > 0) {
      const valuesString = value?.map(obj => obj?.label)?.join(',');
      customFields[multiSelectId] = [valuesString];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value && value?.length]);

  //This function is used to set multiselect values from edit case - Backend is not done with multiselect updates so i cant test this
  useEffect(() => {
    if (
      item?.is_multiselect == 1 &&
      customFields != {} &&
      customFields?.[item?.id]
    ) {
      if (
        customFields?.[item?.id] !== null &&
        customFields?.[item?.id]?.length > 0
      ) {
        const valuesString = customFields?.[item?.id]?.[0];
        const data = valuesString?.split(',')?.map(value => ({
          value: value?.trim()?.toLowerCase(),
          label: value?.trim()
        }));
        if (value.length > 0) {
          const newArray = data?.reduce((result, obj1) => {
            const existInValueArray = value?.some(
              obj2 => obj2?.value === obj1?.value
            );
            if (!existInValueArray) {
              result?.push(obj1);
            }
            return result;
          });
          setValue(newArray);
        } else {
          setValue(data);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.id && customFields?.[item?.id]]);

  return (
    item && (
      <Row className="custom-input-wrapper">
        <Col
          xl={4}
          md={4}
          sm={12}
          className={`${rowColumnAutoAdjust ? '' : 'pe-0'}`}
        >
          <Form.Label
            className={
              item?.is_required == 1
                ? `require-data d-flex ${rowColumnAutoAdjust ? '' : 'm-0'} ${
                    item?.column_type === 'longText'
                      ? 'align-items-start'
                      : 'align-items-center'
                  } justify-content-start h-100`
                : `d-flex ${rowColumnAutoAdjust ? '' : 'm-0'} ${
                    item?.column_type === 'longText'
                      ? 'align-items-start'
                      : 'align-items-center'
                  } justify-content-start h-100`
            }
          >
            {Translate(item?.label)}
          </Form.Label>
        </Col>
        <Col
          xl={8}
          md={8}
          sm={12}
          className={`${rowColumnAutoAdjust ? '' : 'ps-0'}`}
        >
          <Row className={`${rowColumnAutoAdjust ? '' : 'd-flex flex-row'}`}>
            <Col
              xl={11}
              md={11}
              sm={11}
              className={`${rowColumnAutoAdjust ? '' : 'pe-sm-0 flex-2'}`}
            >
              {item?.column_type === 'text' && (
                <Form.Control
                  type="text"
                  className="form-tab-switch-control"
                  name={`${item?.id}`}
                  value={data?.id ? data?.id : customFields?.[item?.id]}
                  placeholder={item?.place_holder}
                  onChange={e => handleFieldChange(e, item?.id)}
                  onBlur={() => {
                    enableDraft ? handleInputBoxOutofFocus() : '';
                  }}
                  onFocus={() => {
                    enableDraft ? handleInputBoxFocus() : '';
                  }}
                />
              )}
              {item?.column_type === 'number' && (
                <Form.Control
                  type="text"
                  className="form-tab-switch-control"
                  name={`${item?.id}`}
                  value={data?.id ? data?.id : customFields?.[item?.id]}
                  placeholder={item?.place_holder}
                  onChange={e => handleFieldChange(e, item?.id)}
                  onBlur={() => {
                    enableDraft ? handleInputBoxOutofFocus() : '';
                  }}
                  onFocus={() => {
                    enableDraft ? handleInputBoxFocus() : '';
                  }}
                />
              )}
              {item?.column_type === 'email' && (
                <Form.Control
                  type="email"
                  className="form-tab-switch-control"
                  name={`${item?.id}`}
                  value={data?.id ? data?.id : customFields?.[item?.id]}
                  placeholder={item?.place_holder}
                  onChange={e => handleFieldChange(e, item?.id)}
                  onBlur={() => {
                    enableDraft ? handleInputBoxOutofFocus() : '';
                  }}
                  onFocus={() => {
                    enableDraft ? handleInputBoxFocus() : '';
                  }}
                />
              )}
              {item?.column_type === 'date' && (
                <AppDatePicker
                  name={`${item?.id}`}
                  value={data?.id ? data?.id : customFields?.[item?.id]}
                  yearPlaceholder="yyyy"
                  monthPlaceholder="mm"
                  dayPlaceholder="dd"
                  onChange={e => handleFieldChange(e, item?.id)}
                  onBlur={() => {
                    enableDraft ? handleInputBoxOutofFocus() : '';
                  }}
                  onFocus={() => {
                    enableDraft ? handleInputBoxFocus() : '';
                  }}
                />
              )}
              {item?.column_type === 'select' && item?.is_multiselect == 0 && (
                <CommonSelect
                  name={`${item?.id}`}
                  value={
                    data?.id
                      ? { value: data?.id, label: data?.id }
                      : customFields?.[item?.id]
                      ? {
                          value: customFields?.[item?.id],
                          label: customFields?.[item?.id]
                        }
                      : ''
                  }
                  onBlur={() => {
                    enableDraft ? handleInputBoxOutofFocus() : '';
                  }}
                  onChange={e =>
                    handleFieldChange({ target: { value: e?.value } }, item?.id)
                  }
                  onFocus={() => {
                    enableDraft ? handleInputBoxFocus() : '';
                  }}
                  Options={optionsArray}
                />
              )}
              {item?.column_type === 'select' && item?.is_multiselect == 1 && (
                <CreatableSelect
                  inputValue={inputValue}
                  isClearable={false}
                  isMulti
                  onChange={newValue => handleMultiSelect(item?.id, newValue)}
                  onInputChange={newValue => setInputValue(newValue)}
                  onKeyDown={handleKeyDown}
                  placeholder=""
                  value={value}
                  options={multiSelectArray}
                  className="creatable-select"
                  onBlur={() => {
                    enableDraft ? handleInputBoxOutofFocus() : '';
                  }}
                  onFocus={() => {
                    enableDraft ? handleInputBoxFocus() : '';
                  }}
                />
              )}
              {/* Check box and Radio button option is now hold - Need to set additional functions to handle this case */}
              {item?.column_type === 'radio' && (
                <Form.Check
                  type="radio"
                  name={`${item?.id}`}
                  className="form-tab-switch-control"
                  value={data?.id ? data?.id : customFields?.[item?.id]}
                  checked={
                    data?.id
                      ? data?.id
                        ? true
                        : false
                      : customFields?.[item?.id]
                      ? true
                      : false
                  }
                  onChange={e => handleFieldChange(e, item?.id)}
                  onBlur={() => {
                    enableDraft ? handleInputBoxOutofFocus() : '';
                  }}
                  onFocus={() => {
                    enableDraft ? handleInputBoxFocus() : '';
                  }}
                />
              )}
              {item?.column_type === 'checkbox' && (
                <Form.Check
                  type="radio"
                  className="form-tab-switch-control"
                  name={`${item?.id}`}
                  value={data?.id ? data?.id : customFields?.[item?.id]}
                  checked={
                    data?.id
                      ? data?.id
                        ? true
                        : false
                      : customFields?.[item?.id]
                      ? true
                      : false
                  }
                  onChange={e => handleFieldChange(e, item?.id)}
                  onBlur={() => {
                    enableDraft ? handleInputBoxOutofFocus() : '';
                  }}
                  onFocus={() => {
                    enableDraft ? handleInputBoxFocus() : '';
                  }}
                />
              )}
              {item?.column_type === 'longText' && (
                <Form.Control
                  type="text"
                  as="textarea"
                  className="form-tab-switch-control"
                  rows={5}
                  name={`${item?.id}`}
                  value={data?.id ? data?.id : customFields?.[item?.id]}
                  placeholder={item?.place_holder}
                  onChange={e => handleFieldChange(e, item?.id)}
                  onBlur={() => {
                    enableDraft ? handleInputBoxOutofFocus() : '';
                  }}
                  onFocus={() => {
                    enableDraft ? handleInputBoxFocus() : '';
                  }}
                />
              )}
            </Col>
            <Col
              xl={1}
              md={1}
              sm={1}
              className="text-danger d-flex align-items-center m-0 p-sm-0 flex-1 ms-1"
            >
              {formError?.item?.id ? (
                <FormErrorPopover
                  id={item?.id}
                  totalErrorCount={Object.keys(formError).length}
                  errorMessage={formError?.item?.id}
                />
              ) : (
                ''
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    )
  );
}

CustomInputFields.propTypes = {
  customFields: PropTypes.any,
  formError: PropTypes.any,
  item: PropTypes.any,
  enableDraft: PropTypes.bool,
  handleInputBoxFocus: PropTypes.func,
  handleInputBoxOutofFocus: PropTypes.func,
  rowColumnAutoAdjust: PropTypes.bool
};

export default CustomInputFields;

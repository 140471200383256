import generateFormDataHelper from 'helpers/generateFormDataHelper';
import isObject from 'helpers/isObject';
import { showToast } from 'module/Common/Toast/toast';
import { useCallback, useEffect, useState } from 'react';
import useFormModuleDefaultFromData from './useFormModuleDefaultFromData';

const showCommonErrorToast = error => {
  const errorData = error?.response?.data;
  showToast(
    errorData?.message ??
      error?.message ??
      'Something went wrong, please refresh the page and try again.',
    'error'
  );
};

export default function useAcodaxForm(config = {}) {
  const {
    module,
    useDefaultFormData,
    defaultValues,
    defaultValueModifier,
    modifier,
    action,
    clearValidationError = true
  } = config;
  const [formData, setFormData] = useState({});
  const [isValidating, setIsValidating] = useState(false);
  const [formStaus, setFormStaus] = useState('init');
  const [formError, setFormError] = useState({});

  const defaultValuesString = isObject(defaultValues)
    ? JSON.stringify(defaultValues)
    : null;
  const setDefaultFormData = useCallback(() => {
    return new Promise(resolve => {
      (async () => {
        try {
          let defaultFormData = {};
          if (module && useDefaultFormData) {
            const moduleDefaultFormData =
              useFormModuleDefaultFromData?.[module];
            if (isObject(moduleDefaultFormData)) {
              defaultFormData = { ...moduleDefaultFormData };
            }
          }

          if (defaultValuesString) {
            defaultFormData = {
              ...defaultFormData,
              ...JSON.parse(defaultValuesString)
            };
          }

          const defaultValueModifierConstructorName =
            defaultValueModifier?.constructor?.name?.toLowerCase();

          if (defaultValueModifierConstructorName === 'function') {
            defaultFormData = defaultValueModifier(defaultFormData);
          }

          if (defaultValueModifierConstructorName === 'asyncfunction') {
            defaultFormData = await defaultValueModifier(defaultFormData);
          }

          setFormData(defaultFormData);
          resolve();
        } catch (error) {
          showCommonErrorToast(error);
        }
      })();
    });
  }, [module, useDefaultFormData, defaultValuesString, defaultValueModifier]);

  const onChange = useCallback(
    async (event, action) => {
      let formDataBackup;
      try {
        if (isValidating) {
          throw new Error('Pending validation found. Please wait.');
        }

        formDataBackup = isObject(formData) ? { ...formData } : {};
        const keyName = action?.name || event?.target?.name;
        let thisModifier = modifier;
        let modifierConstructorName =
          thisModifier?.constructor?.name?.toLowerCase();

        let newFormData = generateFormDataHelper(event, action, formData);

        if (modifierConstructorName === 'function') {
          const modifiedResult = thisModifier(event, action, newFormData);
          if (typeof modifiedResult === 'function') {
            thisModifier = modifiedResult;
            modifierConstructorName =
              thisModifier?.constructor?.name?.toLowerCase();
          } else if (!isObject(modifiedResult)) {
            newFormData = formDataBackup;
          }
        }

        if (isObject(newFormData)) {
          setFormData({ ...newFormData });
        }

        if (modifierConstructorName === 'asyncfunction') {
          setIsValidating(true);
          const modifiedResult = await thisModifier(event, action, newFormData);
          if (!isObject(modifiedResult)) {
            newFormData = formDataBackup;
          }

          setFormData({ ...newFormData });
        }

        if (clearValidationError && formError?.[keyName]) {
          setFormError(prev => {
            delete formError[keyName];
            return prev;
          });
        }
      } catch (error) {
        showCommonErrorToast(error);
        if (!isValidating) {
          setFormData(formDataBackup);
        }
      } finally {
        setIsValidating(false);
      }
    },
    [formData, isValidating, modifier, clearValidationError, formError]
  );

  const onSubmit = useCallback(
    async event => {
      event?.preventDefault();
      try {
        setFormStaus('pending');
        if (typeof action === 'function') {
          await action(formData);
        }
        setFormStaus('success');
      } catch (error) {
        const errorData = error?.response?.data;
        showCommonErrorToast(error);
        setFormError({ ...(errorData?.data?.errors ?? null) });
        setFormStaus('error');
      }
    },
    [formData, action]
  );

  useEffect(() => {
    const init = async () => {
      try {
        await setDefaultFormData();
        setFormStaus('ready');
      } catch (error) {
        console.error('useAcodaxForm Error', error);
      }
    };

    init();

    return () => {
      setFormData(null);
      setFormStaus('init');
      setFormError({});
    };
  }, [setDefaultFormData]);

  return {
    formData,
    setFormData,
    onChange,
    onSubmit,
    formStaus,
    setFormStaus,
    formError,
    setFormError,
    isValidating,
    setIsValidating
  };
}

import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { StoreContext } from 'context/Context';

function TableHeader({ headers }) {
  const Translate = useAxisproTranslate();
  const { store } = useContext(StoreContext);
  const taxLabel = store?.globalConfiguration?.tax_label || 'Tax';

  const updatedHeaders = headers?.map(header =>
    header?.toLowerCase()?.includes('tax')
      ? header?.replace(/tax/gi, taxLabel)
      : header
  );

  return (
    <thead
      className="text-light bg-dark position-sticky top-0"
      style={{ backgroundColor: 'rgb(237,243,248)' }}
    >
      <tr>
        {updatedHeaders.map((header, index) => (
          <th
            style={{ minWidth: header !== 'S/N' ? '120px' : undefined }}
            key={index}
            className={
              header === 'Customer Name' ||
              header === 'Category Name' ||
              header === 'Supplier Name' ||
              header === 'Item Name' ||
              header === 'Person Name' ||
              header === 'Description' ||
              header === 'Stock ID' ||
              header === 'Stock Id' ||
              header === 'Asset Name'
                ? // header === 'Stock on hand' ||
                  // header === 'Asset value'
                  'name-column ledger-report-table-heading'
                : 'ledger-report-table-heading'
            }
          >
            {Translate(header)}
          </th>
        ))}
      </tr>
    </thead>
  );
}

TableHeader.propTypes = {
  headers: PropTypes.any
};

export default TableHeader;

import { React } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import JournalTable from '../Common/Tables/JournalTable';
import PersonName from '../Common/ViewPageDetails/PersonName';
import Badges from '../Common/ViewPageDetails/Badges';
import { BsBank2, BsCoin } from 'react-icons/bs';
import Details from '../Common/ViewPageDetails/Details';
import ShowMemo from '../Common/ViewPageDetails/ShowMemo';

function PaymentVoucherView({ paymentVoucherData }) {
  return (
    <Container fluid className="mt-3 pe-0 ps-0 voucher-inquiry-view-wrapper">
      <Row>
        <Col lg={6} md={6} sm={12}>
          {paymentVoucherData?.person_name ? (
            <PersonName value={paymentVoucherData.person_name} />
          ) : null}
          {paymentVoucherData?.reference && (
            <Badges label={paymentVoucherData?.reference} bg={'danger'} />
          )}
          <div className="mt-2">
            {paymentVoucherData?.bank_account_name && (
              <Details
                label={'Bank Account'}
                value={paymentVoucherData.bank_account_name}
                icon={<BsBank2 className="me-1 text-danger" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
          </div>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <div
            className={
              paymentVoucherData &&
              paymentVoucherData.person_type_id === 'MISCELLANEOUS'
                ? 'mt-sm-2 mt-md-5'
                : 'mt-sm-2 mt-md-3'
            }
          >
            <Details
              label={'Amount'}
              value={paymentVoucherData?.amount_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            {paymentVoucherData &&
            paymentVoucherData.person_type_id === 'MISCELLANEOUS' ? (
              ''
            ) : (
              <>
                <Details
                  label={'Paid Amount'}
                  value={paymentVoucherData?.alloc_formatted ?? '0.00'}
                  className={'mb-2 fs--1'}
                  icon={<BsCoin className="me-1 text-dark" size={15} />}
                />
                <Details
                  label={'Balance Amount'}
                  value={
                    paymentVoucherData?.balance_formatted
                      ? paymentVoucherData.balance_formatted
                      : '0.00'
                  }
                  className={'mb-2 fs--1'}
                  icon={<BsCoin className="me-1 text-dark" size={15} />}
                />
              </>
            )}
          </div>
        </Col>
      </Row>
      {paymentVoucherData && (
        <JournalTable journalData={paymentVoucherData} voucherData />
      )}
      <div className="p-3">
        {paymentVoucherData?.memo && (
          <ShowMemo memo={paymentVoucherData.memo} />
        )}
      </div>
    </Container>
  );
}

PaymentVoucherView.propTypes = {
  paymentVoucherData: PropTypes.any
};

export default PaymentVoucherView;

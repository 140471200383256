import { React, Fragment, useContext, useEffect, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { CloseButton } from 'components/common/Toast';
import AppContext from 'context/Context';

import LandingPage from 'LandingPage';
import InstallationScreen from 'module/Installation/InstallationScreen';
import InstallationDetailsPreview from 'module/Installation/InstallationDetailsPreview';

import RequireGuest from 'helpers/RequireGuest';
import AuthOutlet from 'module/Authentication/AuthOutlet';
import LoginForm from 'module/Authentication/Page/Login/LoginForm';
import AccountRecoveryForm from 'module/Authentication/Page/AccountRecovery/AccountRecoveryForm';
import ResetPasswordForm from 'module/Authentication/Page/ResetPassword/ResetPasswordForm';

import RequireAuth from 'helpers/RequireAuth';
import StoreProvider from 'providers/StoreProvider';
import DeleteContextProvider from 'providers/DeleteContextProvider';
import ExportContextProvider from 'providers/ExportContextProvider';
import MainLayout from './MainLayout';
import Dashboard from 'module/Dashboard';
import ChatWindow from 'components/chat/chatWindow/ChatWindow';
import NotificationList from 'components/notification/NotificationList';

// finance components lazy import start
const Finance = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Finance/Finance')
);
const BankAccountTableView = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Finance/BankAccount/BankAccountTableView'
  )
);
const BankAccountForm = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Finance/BankAccount/BankAccountForm'
  )
);
const CostCenter = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Finance/CostCenter/CostCenter')
);
const CostCenterForm = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Finance/CostCenter/CostCenterForm')
);
const CostCenterGroup = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Finance/CostCenter/CostCenterGroup/CostCenterGroup'
  )
);
const CostCenterGroupForm = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Finance/CostCenter/CostCenterGroup/CostCenterGroupForm'
  )
);
const ChartOfAccounts = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Finance/ChartOfAccounts/ChartOfAccounts'
  )
);
const ChartOfAccountsForm = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Finance/ChartOfAccounts/ChartOfAccountsForm'
  )
);
const ChartOfAccountsChangeParentForm = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Finance/ChartOfAccounts/ChartOfAccountsChangeParentForm'
  )
);
const Reconcile = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Finance/ReconcileWindow/Reconcile')
);
const VoucherInquiry = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Finance/voucherInquiry/VoucherInquiry'
  )
);
const VoucherEntryView = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Finance/voucherInquiry/VoucherEntryView'
  )
);
const JornalVoucherForm = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Finance/voucherInquiry/VoucherEntryForm/JornalVoucherForm'
  )
);
const PaymentVoucherForm = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Finance/voucherInquiry/VoucherEntryForm/PaymentVoucherForm'
  )
);
const ReceiptVoucherForm = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Finance/voucherInquiry/VoucherEntryForm/ReceiptVoucherForm'
  )
);
const BankTransferForm = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Finance/voucherInquiry/VoucherEntryForm/BankTransferForm'
  )
);
const TransactionLocking = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Finance/TransactionLocking/TransactionLocking'
  )
);
const ChequeList = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Finance/Cheques/ChequeList')
);
const CashHandover = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Finance/CashHandover/CashHandover')
);
const RecurrentTransactionOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Finance/RecurrentTransaction/RecurrentTransactionOutlet'
  )
);
const RecurrentTransaction = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Finance/RecurrentTransaction/RecurrentTransaction'
  )
);
const RecurrentProfileView = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Common/ViewPages/RecurrentProfiles/RecurrentProfileView'
  )
);
const AccrualTransactionForm = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Finance/AccrualTransactions/AccrualTransactionForm'
  )
);
// finance components lazy import end

// sales components lazy import start
const Sales = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Sales/Sales')
);
const GovTransactionsInvoiceOutlet = lazy(() =>
  import('module/Common/GovernmentTransactions/GovTransactionsInvoiceOutlet')
);
const PreInvoiceOutlet = lazy(() =>
  import('module/Sales/GovernmentTransactions/Pre-Invoice/PreInvoiceOutlet')
);
const PreInvoice = lazy(() =>
  import('module/Sales/GovernmentTransactions/Pre-Invoice/PreInvoice')
);
const PreInvoiceForm = lazy(() =>
  import('module/Sales/GovernmentTransactions/Pre-Invoice/PreInvoiceForm')
);
const GovInvoiceOutlet = lazy(() =>
  import('module/Sales/GovernmentTransactions/Gov-Invoice/GovInvoiceOutlet')
);
const GovInvoice = lazy(() =>
  import('module/Sales/GovernmentTransactions/Gov-Invoice/GovInvoice')
);
const SalesQuotationOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Sales/SalesQuotation/SalesQuotationOutlet'
  )
);
const SalesQuotation = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Sales/SalesQuotation/SalesQuotation'
  )
);
const SalesQuotationForm = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Sales/SalesQuotation/SalesQuotationForm'
  )
);
const SalesQuotationInfoWrapper = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Sales/SalesQuotation/SalesQuotationPage/SalesQuotationInfoWrapper'
  )
);
const SalesOrderOutlet = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Sales/SalesOrder/SalesOrderOutlet')
);
const SalesOrder = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Sales/SalesOrder/SalesOrder')
);
const SalesOrderForm = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Sales/SalesOrder/SalesOrderForm')
);
const SalesOrderInfoWrapper = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Sales/SalesOrder/SalesOrderPage/SalesOrderInfoWrapper'
  )
);
const SalesDeliveryOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Sales/SalesDelivery/SalesDeliveryOutlet'
  )
);
const SalesDelivery = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Sales/SalesDelivery/SalesDelivery')
);
const SalesDeliveryForm = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Sales/SalesDelivery/SalesDeliveryForm'
  )
);
const SalesDeliveryInfoWrapper = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Sales/SalesDelivery/SalesDeliveryPage/SalesDeliveryInfoWrapper'
  )
);
const SalesInvoiceOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Sales/SalesInvoice/SalesInvoiceOutlet'
  )
);
const SalesInvoice = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Sales/SalesInvoice/SalesInvoice')
);
const SalesInvoiceForm = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Sales/SalesInvoice/SalesInvoiceForm'
  )
);
const SalesInvoiceInfoWrapper = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Sales/SalesInvoice/SalesInvoicePage/SalesInvoiceInfoWrapper'
  )
);
const CreditNoteOutlet = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Sales/CreditNote/CreditNoteOutlet')
);
const CreditNote = lazy(() => import('module/Sales/CreditNote/CreditNote'));
const CreditNoteForm = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Sales/CreditNote/CreditNoteForm')
);
const CreditNoteInfoWrapper = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Sales/CreditNote/CreditNotePage/CreditNoteInfoWrapper'
  )
);
const ContractOutlet = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Sales/Contract/ContractOutlet')
);
const Contract = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Sales/Contract/Contract')
);
const ContractForm = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Sales/Contract/ContractForm')
);
const ContractInfoWrapper = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Sales/Contract/ContractPage/ContractInfoWrapper'
  )
);
const CashierScreen = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Finance/CashierScreen/CashierScreen'
  )
);
const CustomerAllocationOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Finance/CustomerAllocation/CustomerAllocationOutlet'
  )
);
const CustomerAllocation = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Finance/CustomerAllocation/CustomerAllocation'
  )
);
const CustomerAllocationScreen = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Finance/CustomerAllocation/AllocationScreen/AllocationScreen'
  )
);
const CustomerOutlet = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Sales/Customer/CustomerOutlet')
);
const Customer = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Sales/Customer/Customer')
);
const CustomerForm = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Sales/Customer/CustomerForm')
);
const CustomerInfoWrapper = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Sales/Customer/CustomerPage/CustomerInfoWrapper'
  )
);
const Salesman = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Sales/Salesman/Salesman')
);
const SalesmanForm = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Sales/Salesman/SalesmanForm')
);
const SalesmanOutlet = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Sales/Salesman/SalesmanOutlet')
);
const SalesmanInfoWrapper = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Sales/Salesman/SalesmanPage/SalesmanInfoWrapper'
  )
);
const Appointments = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Sales/Appointments/Appointments')
);
const AppointmentOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Sales/Appointments/AppointmentOutlet'
  )
);
const EnquiryOutlet = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Sales/Enquiry/EnquiryOutlet')
);
const EnquiryView = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Sales/Enquiry/EnquiryView')
);
const AddEnquiry = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Sales/Enquiry/page/AddEnquiry')
);
const EditEnquiry = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Sales/Enquiry/page/EditEnquiry')
);
const InterBranchSalesTransactionOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Sales/InterBranchTransaction/InterBranchSalesTransactionOutlet'
  )
);
const InterBranchSalesTransaction = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Sales/InterBranchTransaction/InterBranchSalesTransaction'
  )
);
// sales components lazy import end

// purchase components lazy import start
const Purchase = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Purchase/Purchase')
);

const PurchaseRequestOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Purchase/Purchase Request/PurchaseRequestOutlet'
  )
);
const PurchaseRequestView = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Purchase/Purchase Request/PurchaseRequestView'
  )
);
const AddPurchaseRequest = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Purchase/Purchase Request/Page/AddPurchaseRequest'
  )
);
const EditPurchaseRequest = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Purchase/Purchase Request/Page/EditPurchaseRequest'
  )
);
const PurchaseOrderOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Purchase/Purchase Order/PurchaseOrderOutlet'
  )
);
const PurchaseOrderView = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Purchase/Purchase Order/PurchaseOrderView'
  )
);
const AddPurchaseOrder = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Purchase/Purchase Order/Page/AddPurchaseOrder'
  )
);
const EditPurchaseOrder = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Purchase/Purchase Order/Page/EditPurchaseOrder'
  )
);

const PurchaseDeliveryOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Purchase/Purchase Delivery/PurchaseDeliveryOutlet'
  )
);
const PurchaseDeliveryView = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Purchase/Purchase Delivery/PurchaseDeliveryView'
  )
);
const AddPurchaseDelivery = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Purchase/Purchase Delivery/Page/AddPurchaseDelivery'
  )
);
const EditPurchaseDelivery = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Purchase/Purchase Delivery/Page/EditPurchaseDelivery'
  )
);

const PurchaseInvoiceOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Purchase/Purchase Invoice/PurchaseInvoiceOutlet'
  )
);
const PurchaseInvoiceView = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Purchase/Purchase Invoice/PurchaseInvoiceView'
  )
);
const AddPurchaseInvoice = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Purchase/Purchase Invoice/Page/AddPurchaseInvoice'
  )
);
const EditPurchaseInvoice = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Purchase/Purchase Invoice/Page/EditPurchaseInvoice'
  )
);

const SupplierPayment = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Purchase/SupplierPayment/SupplierPayment'
  )
);

const SupplierAllocationOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Finance/SupplierAllocation/SupplierAllocationOutlet'
  )
);
const SupplierAllocation = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Finance/SupplierAllocation/SupplierAllocation'
  )
);
const SupplierAllocationScreen = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Finance/SupplierAllocation/AllocationScreen/AllocationScreen'
  )
);

const SupplierOutlet = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Purchase/Supplier/SupplierOutlet')
);
const SupplierView = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Purchase/Supplier/SupplierView')
);
const SupplierForm = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Purchase/Supplier/Form/SupplierForm'
  )
);
const InterBranchPurchaseTransactionOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Purchase/InterBranchTransaction/InterBranchPurchaseTransactionOutlet'
  )
);
const InterBranchPurchaseTransaction = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Purchase/InterBranchTransaction/InterBranchPurchaseTransaction'
  )
);
// purchase components lazy import end

// production components lazy import start
const Production = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Production/Production')
);

const WorkOrdersOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Production/WorkOrder/WorkOrdersOutlet'
  )
);
const WorkOrders = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Production/WorkOrder/WorkOrders')
);
const AddWorkOrder = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Production/WorkOrder/WorkOrderPage/AddWorkOrder'
  )
);
const EditWorkOrder = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Production/WorkOrder/WorkOrderPage/EditWorkOrder'
  )
);
const WorkOrderInfoWrapper = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Production/WorkOrder/WorkOrderPage/WorkOrderInfoWrapper'
  )
);

const EstimationOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Production/Estimation/EstimationOutlet'
  )
);
const EstimationView = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Production/Estimation/EstimationView'
  )
);
const AddEstimation = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Production/Estimation/Page/AddEstimation'
  )
);
const EditEstimation = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Production/Estimation/Page/EditEstimation'
  )
);

const MaterialRequestOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Production/MaterialRequest/MaterialRequestOutlet'
  )
);

const MaterialRequestView = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Production/MaterialRequest/MaterialRequestView'
  )
);

const AddMaterialRequest = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Production/MaterialRequest/Page/AddMaterialRequest'
  )
);

const EditMaterialRequest = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Production/MaterialRequest/Page/EditMaterialRequest'
  )
);
// production components lazy import end

//report components lazy import start
const Report = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Report/Report')
);
//report components lazy import end

// production report components lazy import start
const EnquiryReport = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Report/Production/EnquiryReport')
);
const EstimationReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Production/EstimationReport'
  )
);
const WorkInProgressReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Production/WorkInProgressReport'
  )
);
const FininshedWorkOrdersReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Production/FininshedWorkOrdersReport'
  )
);
const WorkOrdersDetailsReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Production/WorkOrdersDetailsReport'
  )
);
const OverDueWorkOrdersReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Production/OverDueWorkOrdersReport'
  )
);
const WorkOrdersIssuedItemsReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Production/WorkOrdersIssuedItemsReport'
  )
);
const WorkOrdersReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Production/WorkOrdersReport'
  )
);
const WorkOrdersCamparisonReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Production/WorkOrdersCamparisonReport'
  )
);
// production report components lazy import end

// purchase report components lazy import start
const PurchaseInvoiceReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Purchase/PurchaseInvoiceReport'
  )
);
const PurchaseOrderReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Purchase/PurchaseOrderReport'
  )
);
const PurchaseRequestReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Purchase/PurchaseRequestReport'
  )
);
const PurchaseDeliveryReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Purchase/PurchaseDeliveryReport'
  )
);
const PurchaseRequestDetailsReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Purchase/PurchaseRequestDetailsReport'
  )
);
const PurchaseOrderDetailsReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Purchase/PurchaseOrderDetailsReport'
  )
);
const DebitNoteReport = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Report/Purchase/DebitNoteReport')
);
const PurchaseDeliveryDetailsReports = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Purchase/PurchaseDeliveryDetailsReports'
  )
);
const PurchaseInvoiceDetailsReports = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Purchase/PurchaseInvoiceDetailsReports'
  )
);
const DebitNoteDetailReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Purchase/DebitNoteDetailReport'
  )
);
const SupplierBalanceReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Purchase/SupplierBalanceReport'
  )
);
const AgedSupplierAnalysisReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Purchase/AgedSupplierAnalysisReport'
  )
);
const SupplierBalanceStatementReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Purchase/SupplierBalanceStatementReport'
  )
);
const TopSupplierPurchaseReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Purchase/TopSupplierPurchaseReport'
  )
);
const CategoryWisePurchaseReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Purchase/CategoryWisePurchaseReport'
  )
);
// purchase report components lazy import end

// purchase report components lazy import start
const ScheduleMaintenanceReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/FixedAssets/ScheduleMaintenanceReport'
  )
);
const AssetWiseProfitSummaryReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/FixedAssets/AssetWiseProfitSummaryReport'
  )
);
const AssetTransactionReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/FixedAssets/AssetTransactionReport'
  )
);
const AssetStockReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/FixedAssets/AssetStockReport'
  )
);
const RentalContractReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/FixedAssets/RentalContractReport'
  )
);
const AssetPurchaseReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/FixedAssets/AssetPurchaseReport'
  )
);
// purchase report components lazy import end

// system report components lazy import start
const EmailLogs = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Report/System/EmailLogs')
);
const ActivityLogOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Sales/ActivityLog/ActivityLogOutlet'
  )
);
const ActivityLog = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Sales/ActivityLog/ActivityLog')
);
// system report components lazy import end

// financial report components lazy import start
const ProfitAndLoss = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Report/Ledger/ProfitAndLoss')
);
const TrailBalance = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Ledger/CostCenterWise/TrailBalance'
  )
);
const MonthlyTrailBalance = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Ledger/MonthlyComparison/MonthlyTrailBalance'
  )
);
const YearlyTrailBalance = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Report/Ledger/YearlyTrailBalance')
);
const MonthlyProfitAndLoss = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Ledger/MonthlyComparison/MonthlyProfitAndLoss'
  )
);
const YearlyProfitAndLoss = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Report/Ledger/YearlyProfitAndLoss')
);
const CostWiseProfitAndLoss = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Ledger/CostCenterWise/ProfitAndLoss'
  )
);
const LayoutVAT = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Report/VAT/LayoutVAT')
);
const BalanceSheet = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Report/Ledger/BalanceSheet')
);
const TrialBalance = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Report/Ledger/TrialBalance')
);
const LegderReport = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Report/LedgerReport/LegderReport')
);
const DrillDownReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Ledger/DrillDown/DrillDownReport'
  )
);
// financial report components lazy import end

// finance report components lazy import start
const JournalReport = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Report/Finance/JournalReport')
);
const CustomerPaymentReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Finance/CustomerPaymentReport'
  )
);
const SupplierPaymentReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Finance/SupplierPaymentReport'
  )
);
const PaymentVoucherReports = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Finance/PaymentVoucherReports'
  )
);
const ReceiptVoucherReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Finance/ReceiptVoucherReport'
  )
);
const BankTransferReport = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Report/Finance/BankTransferReport')
);
const RefundReport = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Report/Finance/RefundReport')
);
const BankBalanceReport = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Report/Finance/BankBalanceReport')
);
const BankTransactionReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Finance/BankTransactionReport'
  )
);
const VoidTransactionReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Finance/VoidTransactionReport'
  )
);
const RecurrentProfileReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Finance/RecurrentProfileReport'
  )
);
const CashInOutStatementReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Finance/CashInOutStatementReport'
  )
);
// finance report components lazy import end

// sales report components lazy import start
const SalesInvoiceReport = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Report/Sales/SalesInvoiceReport')
);
const SalesOrderReport = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Report/Sales/SalesOrderReport')
);
const SalesDeliveryReport = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Report/Sales/SalesDeliveryReport')
);
const SalesQuotationReport = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Report/Sales/SalesQuotationReport')
);
const CreditNoteReport = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Report/Sales/CreditNoteReport')
);
const UnpaidPurchaseInvoiceReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Purchase/UnpaidPurchaseInvoiceReport'
  )
);
const VoucherInquiryReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Finance/VoucherInquiryReport'
  )
);
const CreditNoteDetailsReports = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Sales/CreditNoteDetailsReports'
  )
);
const SalesInvoiceDetailReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Sales/SalesInvoiceDetailReport'
  )
);
const ContractsReports = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Report/Sales/ContractsReports')
);
const ContractsDetailsReports = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Sales/ContractsDetailsReports'
  )
);
const SalesDeliveryDetailsReports = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Sales/SalesDeliveryDetailsReports'
  )
);
const SalesOrderDetailsReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Sales/SalesOrderDetailsReport'
  )
);
const SalesQuotationDetailsReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Sales/SalesQuotationDetailsReport'
  )
);
const SalesReportByCustomer = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Sales/SalesReportByCustomer'
  )
);
const CategoryWiseSalesReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Sales/CategoryWiseSaleReport'
  )
);
const TopCustomerSalesReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Sales/TopCustomerSalesReport'
  )
);
const CustomerBalanceReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Sales/CustomerBalanceReport'
  )
);
const AgedCustomerReport = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Report/Sales/AgedCustomerReport')
);
const CustomerBalanceStatement = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Sales/CustomerBalanceStatement'
  )
);
const UnpaidSalesInvoiceReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Sales/UnpaidSalesInvoiceReport'
  )
);
const SalesmanWiseReport = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Report/Sales/SalesmanWiseReport')
);
const SalesmanBySalesInvoiceReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Sales/SalesmanBySalesInvoiceReport'
  )
);
// sales report components lazy import end

// inventory report components lazy import start
const InventoryValuationReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Inventory/InventoryValuationReport'
  )
);
const InventoryStatusReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Inventory/InventoryStatusReport'
  )
);
const InventorySummaryReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Inventory/InventorySummaryReport'
  )
);
const InventoryPurchaseReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Inventory/InventoryPurchaseReport'
  )
);
const ItemReorderReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Inventory/ItemReorderReport'
  )
);
const ItemWiseProfitSummaryReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Inventory/ItemWiseProfitSummaryReport'
  )
);
const CategoryWiseProfitSummaryReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Inventory/CategoryWiseProfitSummaryReport'
  )
);
const InventoryMovementReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Inventory/InventoryMovementReport'
  )
);
const BatchReport = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Report/Inventory/BatchReport')
);
const WarehouseWiseAverageCostReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Inventory/WarehouseWiseAverageCostReport'
  )
);
const InvoiceWiseItemProfitReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Report/Inventory/InvoiceWiseItemProfitReport'
  )
);
// inventory report components lazy import end

// inventory modules components lazy import start
const Inventory = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Inventory/Inventory')
);
const GovItemOutlet = lazy(() =>
  import('module/Inventory/GovernmentTransactions/Item/GovItemOutlet')
);
const GovItem = lazy(() =>
  import('module/Inventory/GovernmentTransactions/Item/GovItem')
);
const GovItemForm = lazy(() =>
  import('module/Inventory/GovernmentTransactions/Item/Forms/GovItemForm')
);
const GovCategoryOutlet = lazy(() =>
  import('module/Inventory/GovernmentTransactions/Category/GovCategoryOutlet')
);
const GovCategory = lazy(() =>
  import('module/Inventory/GovernmentTransactions/Category/GovCategory')
);
const GovCategoryForm = lazy(() =>
  import(
    'module/Inventory/GovernmentTransactions/Category/Form/GovCategoryForm'
  )
);
const ItemOutlet = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Inventory/Item/ItemOutlet')
);
const ItemForm = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Inventory/Item/Form/ItemForm')
);
const ItemView = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Inventory/Item/ItemView')
);
const Category = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Inventory/Category/Category')
);
const CategoryTableView = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Inventory/Category/CategoryTableView'
  )
);
const CategoryForm = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Inventory/Category/CategoryForm')
);
const SubCategoryForm = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Inventory/Category/SubCategory/SubCategoryForm'
  )
);
const StockKitOutlet = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Inventory/StockKit/StockKitOutlet')
);
const StockKit = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Inventory/StockKit/StockKit')
);
const StockKitForm = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Inventory/StockKit/StockKitForm')
);
const StockKitInfoWrapper = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Inventory/StockKit/StockKitInfoWrapper'
  )
);
const BatchNumberTableView = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Inventory/BatchNumber/BatchNumberTableView'
  )
);
const BatchNumberForm = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Inventory/BatchNumber/BatchNumberForm'
  )
);
const BatchNumberOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Inventory/BatchNumber/BatchNumberOutlet'
  )
);
const BatchNumberInfoWrapper = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Inventory/BatchNumber/BatchNumberPage/BatchNumberInfoWrapper'
  )
);
const PriceTableView = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Inventory/Price/PriceTableView')
);
const PriceForm = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Inventory/Price/PriceForm')
);
const StockTransferOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Inventory/StockTransfer/StockTransferOutlet'
  )
);
const StockTransferTable = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Inventory/StockTransfer/StockTransferTable'
  )
);
const StockTransferForm = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Inventory/StockTransfer/StockTransferForm'
  )
);
const StockTransferWrapper = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Inventory/StockTransfer/StockTransferWrapper'
  )
);
const InventoryAdjustment = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Inventory/InventoryAdjustment/InventoryAdjustment'
  )
);
const IssueItems = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Inventory/IssueItems/IssueItems')
);
// inventory modules components lazy import end

//settings modules component lazy import start
const Settings = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Settings/Sattings')
);
const UnitTableView = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Settings/Unit/UnitTableView')
);
const UnitForm = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Settings/Unit/UnitForm')
);
const ApprovalStageOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Settings/ApprovalStages/ApprovalStageOutlet'
  )
);
const ApprovalStageList = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Settings/ApprovalStages/page/ApprovalStageList'
  )
);
const BrandTableView = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Inventory/Brand/BrandTableView')
);
const BrandForm = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Inventory/Brand/BrandForm')
);
const ManufacturerTableView = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Inventory/Manufacturer/ManufacturerTableView'
  )
);
const ManufacturerForm = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Inventory/Manufacturer/ManufacturerForm'
  )
);
const ExchangeRateTableView = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Settings/ExchangeRate/ExchangeRateTableView'
  )
);
const ExchangeRateForm = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Settings/ExchangeRate/ExchangeRateForm'
  )
);
const FiscalYearTableView = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Settings/FiscalYear/FiscalYearTableView'
  )
);
const FiscalYearForm = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Settings/FiscalYear/FiscalYearForm'
  )
);
const ModuleTreeList = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Settings/Module/ModuleTrees/ModuleTreeList'
  )
);
const ModulesTableView = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Settings/Module/ModulesTableView')
);
const ModulesForm = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Settings/Module/ModulesForm')
);
const PaymentTermsTableView = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Settings/PaymentTerms/PaymentTermsTableView'
  )
);
const PaymentTermsForm = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Settings/PaymentTerms/PaymentTermsForm'
  )
);
const ShippingCompanyTableView = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Settings/ShippingCompany/ShippingCompanyTableView'
  )
);
const ShippingCompanyForm = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Settings/ShippingCompany/ShippingCompanyForm'
  )
);
const DocumentTypeTableView = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Settings/DocumentType/DocumentTypeTableView'
  )
);
const DocumentTypeForm = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Settings/DocumentType/DocumentTypeForm'
  )
);
const CustomFieldOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/System Admin/CustomFields/CustomFieldOutlet'
  )
);
const CustomFields = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/System Admin/CustomFields/CustomFields'
  )
);
const CustomFieldsWrapper = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/System Admin/CustomFields/CustomFieldsWrapper'
  )
);
const TaxTableView = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Settings/Tax/TaxTableView')
);
const TaxForm = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Settings/Tax/TaxForm')
);
const TaxGroupTableView = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Settings/TaxGroup/TaxGroupTableView'
  )
);
const TaxGroupForm = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Settings/TaxGroup/TaxGroupForm')
);
const TaxItemTableView = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Settings/TaxItem/TaxItemTableView')
);
const TaxItemForm = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Settings/TaxItem/TaxItemForm')
);
const SalesAreaTableView = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Settings/SalesArea/SalesAreaTableView'
  )
);
const SalesAreaForm = lazy(() =>
  import('module/Settings/SalesArea/SalesAreaForm')
);
const SalesTypeTableView = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Settings/SalesType/SalesTypeTableView'
  )
);
const SalesTypeForm = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Settings/SalesType/SalesTypeForm')
);
const RolesTableView = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Settings/Roles/RolesTableView')
);
const RolesForm = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Settings/Roles/RolesForm')
);
const WarehouseOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Settings/Warehouse/WarehouseOutlet'
  )
);
const Warehouse = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Settings/Warehouse/Warehouse')
);
const WarehouseForm = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/Settings/Warehouse/WarehouseForm')
);
const TransactionReferenceTableView = lazy(() =>
  import('module/Settings/TransactionReference/TransactionReferenceTableView')
);
const TransactionReferenceForm = lazy(() =>
  import('module/Settings/TransactionReference/TransactionReferenceForm')
);
const TermsAndConditionsTableView = lazy(() =>
  import('module/Settings/TermsAndConditions/TermsAndConditionsTableView')
);
//settings modules component lazy import end

//system admin modules component lazy import start
const SystemAdmin = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/System Admin/SystemAdmin')
);
const UserRegistrationOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/System Admin/UserRegistration/UserRegistrationOutlet'
  )
);
const UserList = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/System Admin/UserRegistration/UserList'
  )
);
const UserProfile = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/System Admin/UserRegistration/UserProfile.js/UserProfile'
  )
);
const NewUserProfile = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/System Admin/UserRegistration/UserProfile/UserProfile'
  )
);
const UsersPermissions = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/System Admin/UsersPermissions/UsersPermissions'
  )
);
const RolesPermissions = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/System Admin/RolesPermissions/RolesPermissions'
  )
);
const VoidTransactionWrapper = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/System Admin/VoidTransaction/VoidTransactionWrapper'
  )
);
const VoidTransactionInfo = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/System Admin/VoidTransaction/VoidTransactionInfo'
  )
);
//system admin modules component lazy import end

//fixed assets modules component lazy import start
const FixedAssets = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/FixedAssets/FixedAssets')
);
const AssetOutlet = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/FixedAssets/Assets/AssetOutlet')
);
const Asset = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/FixedAssets/Assets/Asset')
);
const AssetForm = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/FixedAssets/Assets/FixedAssetForm')
);
const AssetPageInfoWrapper = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/FixedAssets/Assets/AssetPages/AssetPageInfoWrapper'
  )
);
const RentalContractOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/FixedAssets/RentalContract/RentalContractOutlet'
  )
);
const RentalContractList = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/FixedAssets/RentalContract/pages/RentalContractList'
  )
);
const RentalContractAdd = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/FixedAssets/RentalContract/pages/RentalContractAdd'
  )
);
const EmployeeAllocationOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/FixedAssets/EmployeeAllocation/EmployeeAllocationOutlet'
  )
);
const EmployeeAllocationList = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/FixedAssets/EmployeeAllocation/pages/EmployeeAllocationList'
  )
);
const EmployeeAllocationInfo = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/FixedAssets/EmployeeAllocation/pages/EmployeeAllocationInfo'
  )
);

//zatca modules component lazy import start
const ZatcaRecordsOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Zatca/ZatcaRecords/ZatcaRecordsOutlet'
  )
);
const ZatcaRecordsList = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Zatca/ZatcaRecords/pages/ZatcaRecordsList'
  )
);
//zatca modules component lazy import end
//fixed assets modules component lazy import end

//company settings modules component lazy import start
const SystemSettings = lazy(() =>
  import(/* webpackPrefetch: true */ 'module/SystemSettings/SystemSettings')
);
const SystemSettingsFile = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/SystemSettings/Components/SystemSettings/SystemSettings'
  )
);
const GeneralPage = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/SystemSettings/Components/General/GeneralPage'
  )
);
const CompanySettings = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/SystemSettings/Components/CompanySettings/CompanySettings'
  )
);
const BranchLists = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/SystemSettings/Components/Branches/BranchLists'
  )
);
const BranchView = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/Common/ViewPages/Branches/BranchView'
  )
);
const BranchForm = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/SystemSettings/Components/Branches/BranchForm'
  )
);
const OpeningBalanceOutlet = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/SystemSettings/Components/OpeningBalance/OpeningBalanceOutlet'
  )
);
const OpeningBalance = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/SystemSettings/Components/OpeningBalance/OpeningBalance'
  )
);
const OpeningBalanceForm = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/SystemSettings/Components/OpeningBalance/OpeningBalanceForm'
  )
);
const FinanceSettings = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/SystemSettings/Components/FinanceAccounts/Finance'
  )
);
const SalesSettings = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/SystemSettings/Components/SalesSettings/SalesSettings'
  )
);
const PurchaseSettings = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/SystemSettings/Components/PurchaseSettings/PurchaseSettings'
  )
);
const ProductionSettings = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/SystemSettings/Components/ProductionSettings/ProductionSettings'
  )
);
const InventorySettings = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/SystemSettings/Components/InventorySettings/InventorySettings'
  )
);
const TaxSettings = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/SystemSettings/Components/TaxSettings/TaxSettings'
  )
);
const PrintSettings = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/SystemSettings/Components/PrintSettings/PrintSettings'
  )
);
const NotificationSettings = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/SystemSettings/Components/NotificationsSettings/NotificationSettings'
  )
);

const AssetSettings = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'module/SystemSettings/Components/AssetSettings/AssetSettings'
  )
);
//company settings modules component lazy import end

import Error403 from 'components/errors/Error403';
import Error404 from 'components/errors/Error404';
import TitleWrappingComponent from 'module/Common/TitleWrappingComponent';
import TermsAndConditionsForm from 'module/Settings/TermsAndConditions/TermsAndConditionsForm';
import LedgerInquiry from 'module/Finance/LedgerInquiry/LedgerInquiry';
import UserPermissions from 'module/System Admin/UsersPermissions/newLayout/UserPermissions';
import RolePermissions from 'module/System Admin/RolesPermissions/newLayout/RolePermissions';
import VersionNotification from 'module/Common/AppNotifications/VersionNotification';
import DebitNoteView from 'module/Purchase/DebitNote/DebitNoteView';
import DebitNoteOutlet from 'module/Purchase/DebitNote/DebitNoteOutlet';
import DebitNoteForm from 'module/Purchase/DebitNote/Form/DebitNoteForm';
import BankTransferFormEditFrom from 'module/Finance/voucherInquiry/VoucherEntryForm/BankTransferFormEditFrom';
import InventoryAdjustmentOutlet from 'module/Inventory/InventoryAdjustment/InventoryAdjustmentOutlet';
import SuspensePageLoaderUI from 'components/module-loader/SuspensePageLoaderUI';
import CustomerGroupTable from '../module/Sales/CustomerGroup/CustomerGroupTable';
import CustomerGroupForm from '../module/Sales/CustomerGroup/CustomerGroupForm';
import CustomerGroupView from '../module/Sales/CustomerGroup/CustomerGroupView';
import ActiveTaskContextProvider from 'providers/ActiveTaskContextProvider';
import PasswordExpiryScreen from 'components/passwordExpiryModal/PasswordExpiryScreen';
import RentalContractView from 'module/FixedAssets/RentalContract/pages/RentalContractView';
import FixedAssetPurchaseInvoiceOutlet from 'module/FixedAssets/FixedAssetPurchaseInvoice/FixedAssetPurchaseInvoiceOutlet';
import FixedAssetPurchaseInvoiceView from 'module/FixedAssets/FixedAssetPurchaseInvoice/FixedAssetPurchaseInvoiceView';
import AddFixedAssetPurchaseInvoice from 'module/FixedAssets/FixedAssetPurchaseInvoice/Page/AddFixedAssetPurchaseInvoice';
import EditFixedAssetPurchaseInvoice from 'module/FixedAssets/FixedAssetPurchaseInvoice/Page/EditFixedAssetPurchaseInvoice';
import SalesQuotationFormForCRM from 'module/Sales/SalesQuotation/SalesQuotationForm';
import EmployeeAllocationReport from 'module/Report/FixedAssets/EmployeeAllocationReport';
import MaterialRequestReport from 'module/Report/Purchase/MaterialRequestReport';
import ChequeReport from 'module/Report/Finance/ChequeReport';
import MaterialRequestDetailsReport from 'module/Report/Purchase/MaterialRequestDetailsReport';
import RequireFormSettings from 'helpers/RequireFormSettings';
import MultiTenancyEmailForm from 'module/Authentication/Page/MultiTenancyLogin/MultiTenancyEmailForm';
import MultiTenancyPassword from 'module/Authentication/Page/MultiTenancyLogin/MultiTenancyPassword';
import { getMultiTenancyStatus } from 'helpers/getMultiTenancyStatus';
import platform from 'platform';
import DeferredTransaction from 'module/Finance/DeferredTransaction/DeferredTransaction';
import DeferredTransactionReport from 'module/Report/Finance/DeferredTransactionReport';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const multiTenancy = getMultiTenancyStatus();
  useContext(AppContext);

  useEffect(() => {
    if (platform.os.family === 'Windows') {
      HTMLClassList.add('windows');
    }
    if (platform.name === 'Chrome') {
      HTMLClassList.add('chrome');
    }
    if (platform.name === 'Firefox') {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="install" element={<InstallationScreen />}>
          <Route path="preview" element={<InstallationDetailsPreview />} />
        </Route>

        {/*- ------------- Authentication ---------------------------  */}

        <Route
          path="authentication"
          element={
            <RequireGuest>
              <AuthOutlet />
            </RequireGuest>
          }
        >
          <Route
            index
            element={multiTenancy ? <MultiTenancyEmailForm /> : <LoginForm />}
          />
          <Route
            path="login"
            element={multiTenancy ? <MultiTenancyEmailForm /> : <LoginForm />}
          />
          <Route path="tenant-login" element={<MultiTenancyPassword />} />
          <Route path="account-recovery" element={<AccountRecoveryForm />} />
          <Route path="reset-password" element={<ResetPasswordForm />} />
        </Route>

        {/*- ------------- Authentication ---------------------------  */}

        <Route
          path="/"
          element={
            <RequireAuth>
              <RequireFormSettings>
                <StoreProvider>
                  <DeleteContextProvider>
                    <ExportContextProvider>
                      <ActiveTaskContextProvider>
                        <MainLayout />
                      </ActiveTaskContextProvider>
                      <VersionNotification />
                    </ExportContextProvider>
                  </DeleteContextProvider>
                </StoreProvider>
              </RequireFormSettings>
            </RequireAuth>
          }
        >
          {/* Dashboard Start */}

          <Route
            path="dashboard"
            element={
              <TitleWrappingComponent title={'Dashboard'}>
                <Dashboard />
              </TitleWrappingComponent>
            }
          />

          {/* Dashboard End */}
          {/* Chat Start */}

          <Route path="chat" element={<ChatWindow />} />

          {/* Chat End */}
          {/* Notifications Start */}

          <Route path="notifications" element={<SuspensePageLoaderUI />}>
            <Route
              index
              element={
                <TitleWrappingComponent title={'Notification'}>
                  <NotificationList />
                </TitleWrappingComponent>
              }
            />
            <Route path=":pageNumber" element={<NotificationList />} />
          </Route>

          {/* Notifications End */}
          {/* Finance Start */}

          <Route path="finance" element={<SuspensePageLoaderUI />}>
            <Route
              index
              element={
                <TitleWrappingComponent title={'Finance'}>
                  <Finance />
                </TitleWrappingComponent>
              }
            />

            <Route
              path="bank-accounts"
              element={
                <TitleWrappingComponent title={'Bank Account'}>
                  <BankAccountTableView />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<BankAccountForm />} />
              <Route path="edit/:accountId" element={<BankAccountForm />} />
            </Route>

            <Route
              path="cost-center"
              element={
                <TitleWrappingComponent title={'Cost Center'}>
                  <CostCenter />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<CostCenterForm />} />
              <Route path="edit/:centerId" element={<CostCenterForm />} />
            </Route>

            <Route
              path="cost-center-group"
              element={
                <TitleWrappingComponent title={'Cost Center Group'}>
                  <CostCenterGroup />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<CostCenterGroupForm />} />
              <Route path="edit/:groupId" element={<CostCenterGroupForm />} />
            </Route>

            <Route
              path="chart-of-accounts"
              element={
                <TitleWrappingComponent title={'Chart Of Accounts'}>
                  <ChartOfAccounts />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<ChartOfAccountsForm />} />
              <Route path="edit/:accountId" element={<ChartOfAccountsForm />} />
              <Route
                path="change-parent/:accountId"
                element={<ChartOfAccountsChangeParentForm />}
              />
            </Route>

            <Route
              path="reconcile"
              element={
                <TitleWrappingComponent title={'Reconcile'}>
                  <Reconcile />
                </TitleWrappingComponent>
              }
            />

            <Route
              path="ledger-inquiry"
              element={
                <TitleWrappingComponent title={'Ledger Inquiry'}>
                  <LedgerInquiry />
                </TitleWrappingComponent>
              }
            />

            {/* Voucher Start */}
            <Route
              path="voucher-inquiry"
              element={
                <TitleWrappingComponent title={'voucher Inquiry'}>
                  <VoucherInquiry />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<VoucherEntryView />} />
            </Route>
            <Route
              path="journal-voucher/add"
              element={
                <TitleWrappingComponent title={'Journal Voucher'}>
                  <JornalVoucherForm />
                </TitleWrappingComponent>
              }
            />
            <Route
              path="journal-voucher/edit/:voucherId"
              element={
                <TitleWrappingComponent title={'Journal Voucher'}>
                  <JornalVoucherForm />
                </TitleWrappingComponent>
              }
            />
            <Route
              path="payment-voucher/add"
              element={
                <TitleWrappingComponent title={'Payment Voucher'}>
                  <PaymentVoucherForm />
                </TitleWrappingComponent>
              }
            />
            <Route
              path="payment-voucher/edit/:voucherId"
              element={
                <TitleWrappingComponent title={'Payment Voucher'}>
                  <PaymentVoucherForm />
                </TitleWrappingComponent>
              }
            />
            <Route
              path="receipt-voucher/add"
              element={
                <TitleWrappingComponent title={'Receipt Voucher'}>
                  <ReceiptVoucherForm />
                </TitleWrappingComponent>
              }
            />
            <Route
              path="receipt-voucher/edit/:voucherId"
              element={
                <TitleWrappingComponent title={'Receipt Voucher'}>
                  <ReceiptVoucherForm />
                </TitleWrappingComponent>
              }
            />
            <Route
              path="bank-transfer/add"
              element={
                <TitleWrappingComponent title={'Bank Transfer'}>
                  <BankTransferForm />
                </TitleWrappingComponent>
              }
            />
            <Route
              path="bank-transfer/edit/:voucherId"
              element={
                <TitleWrappingComponent title={'Bank Transfer'}>
                  <BankTransferFormEditFrom />
                </TitleWrappingComponent>
              }
            />
            {/* Voucher End */}

            <Route
              path="transaction-locking"
              element={
                <TitleWrappingComponent title={'Transaction Locking'}>
                  <TransactionLocking />
                </TitleWrappingComponent>
              }
            />

            <Route
              path="cheques"
              element={
                <TitleWrappingComponent title={'Cheque'}>
                  <ChequeList />
                </TitleWrappingComponent>
              }
            ></Route>
            <Route
              path="deferred-transactions"
              element={
                <TitleWrappingComponent title={'Deferred Transaction'}>
                  <DeferredTransaction />
                </TitleWrappingComponent>
              }
            ></Route>

            <Route
              path="cash-handover"
              element={
                <TitleWrappingComponent title={'Cash Handover'}>
                  <CashHandover />
                </TitleWrappingComponent>
              }
            ></Route>

            <Route
              path="recurrent-profiles"
              element={
                <TitleWrappingComponent title={'Recurrent Profile'}>
                  <RecurrentTransactionOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<RecurrentTransaction />} />
              <Route path="info" element={<RecurrentProfileView />} />
              <Route
                path="info/:RecurrentId"
                element={<RecurrentProfileView />}
              />
            </Route>

            <Route
              path="accrual-transactions/add"
              element={
                <TitleWrappingComponent title={'Accrual Transaction'}>
                  <AccrualTransactionForm />
                </TitleWrappingComponent>
              }
            />
          </Route>

          {/* Finance End */}
          {/* Sales Start */}

          <Route path="sales" element={<SuspensePageLoaderUI />}>
            <Route
              index
              element={
                <TitleWrappingComponent title={'Sales'}>
                  <Sales />
                </TitleWrappingComponent>
              }
            />

            {/*Sales Government Transactions Start */}

            <Route
              path="gov-transactions"
              element={<GovTransactionsInvoiceOutlet />}
            >
              <Route
                path="pre-invoice"
                element={
                  <TitleWrappingComponent title={'Pre Invoice'}>
                    <PreInvoiceOutlet />
                  </TitleWrappingComponent>
                }
              >
                <Route index element={<PreInvoice />} />
                <Route path="add" element={<PreInvoiceForm />} />
              </Route>
              <Route
                path="gov-invoice"
                element={
                  <TitleWrappingComponent title={'Gov Invoice'}>
                    <GovInvoiceOutlet />
                  </TitleWrappingComponent>
                }
              >
                <Route index element={<GovInvoice />} />
              </Route>
            </Route>

            {/* Government Transactions End */}

            <Route
              path="sales-quotation"
              element={
                <TitleWrappingComponent title={'Sales Quotation'}>
                  <SalesQuotationOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<SalesQuotation />} />
              <Route path="add" element={<SalesQuotationForm />} />
              <Route
                path="edit/:salesQuotationId"
                element={<SalesQuotationForm />}
              />
              <Route
                path=":salesQuotationId"
                element={<SalesQuotationForm />}
              />
              <Route path="info" element={<SalesQuotationInfoWrapper />} />
              <Route
                path="info/:salesQuotationId"
                element={<SalesQuotationInfoWrapper />}
              />
            </Route>

            <Route
              path="sales-order"
              element={
                <TitleWrappingComponent title={'Sales Order'}>
                  <SalesOrderOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<SalesOrder />} />
              <Route path="add" element={<SalesOrderForm />} />
              <Route path="edit/:salesOrderId" element={<SalesOrderForm />} />
              <Route
                path="add/:salesQuotationId"
                element={<SalesOrderForm />}
              />
              <Route path="info" element={<SalesOrderInfoWrapper />} />
              <Route
                path="info/:salesOrderId"
                element={<SalesOrderInfoWrapper />}
              />
            </Route>

            <Route
              path="sales-deliveries"
              element={
                <TitleWrappingComponent title={'Sales Delivery'}>
                  <SalesDeliveryOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<SalesDelivery />} />
              <Route path="add" element={<SalesDeliveryForm />} />
              <Route
                path="edit/:salesDeliveryId"
                element={<SalesDeliveryForm />}
              />
              <Route
                path="add/so/:salesOrderId"
                element={<SalesDeliveryForm />}
              />
              <Route
                path="add/si/:salesInvoiceId"
                element={<SalesDeliveryForm />}
              />
              <Route path="info" element={<SalesDeliveryInfoWrapper />} />
              <Route
                path="info/:salesDeliveryId"
                element={<SalesDeliveryInfoWrapper />}
              />
            </Route>

            <Route
              path="sales-invoice"
              element={
                <TitleWrappingComponent title={'Sales Invoice'}>
                  <SalesInvoiceOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<SalesInvoice />} />
              <Route path="add" element={<SalesInvoiceForm />} />
              <Route
                path="edit/:salesInvoiceId"
                element={<SalesInvoiceForm />}
              />
              <Route
                path="add/sd/:salesDeliveryId"
                element={<SalesInvoiceForm />}
              />
              <Route
                path="add/so/:salesOrderId"
                element={<SalesInvoiceForm />}
              />
              <Route path="add/ct/:contractId" element={<SalesInvoiceForm />} />
              <Route
                path="add/pi/:purchaseInvoiceId"
                element={<SalesInvoiceForm />}
              />
              <Route path="info" element={<SalesInvoiceInfoWrapper />} />
              <Route
                path="info/:salesInvoiceId"
                element={<SalesInvoiceInfoWrapper />}
              />
            </Route>

            <Route
              path="cash-invoice"
              element={
                <TitleWrappingComponent title={'Cash Invoice'}>
                  <SalesInvoiceOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<SalesInvoiceForm cashInvoice />} />
            </Route>

            <Route
              path="export-invoice"
              element={
                <TitleWrappingComponent title={'Export Invoice'}>
                  <SalesInvoiceOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<SalesInvoiceForm exportInvoice />} />
              <Route
                path="edit/:salesInvoiceId"
                element={<SalesInvoiceForm exportInvoice />}
              />
            </Route>

            <Route
              path="credit-note"
              element={
                <TitleWrappingComponent title={'Credit Note'}>
                  <CreditNoteOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<CreditNote />} />
              <Route path="add" element={<CreditNoteForm />} />
              <Route path="add/:salesInvoiceId" element={<CreditNoteForm />} />
              <Route path="edit/:creditNoteId" element={<CreditNoteForm />} />
              <Route path="info" element={<CreditNoteInfoWrapper />} />
              <Route
                path="info/:creditNoteId"
                element={<CreditNoteInfoWrapper />}
              />
            </Route>

            <Route
              path="contract"
              element={
                <TitleWrappingComponent title={'Contract'}>
                  <ContractOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<Contract />} />
              <Route path="add" element={<ContractForm />} />
              <Route path="edit/:contractId" element={<ContractForm />} />
              <Route path="info" element={<ContractInfoWrapper />} />
              <Route
                path="info/:contractId"
                element={<ContractInfoWrapper />}
              />
            </Route>

            <Route
              path="customer-receipt"
              element={
                <TitleWrappingComponent title={'Customer Receipt'}>
                  <CashierScreen />
                </TitleWrappingComponent>
              }
            />

            <Route
              path="customer-allocation"
              element={
                <TitleWrappingComponent title={'Customer Allocation'}>
                  <CustomerAllocationOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<CustomerAllocation />} />
              <Route
                path="allocate/:voucherId"
                element={<CustomerAllocationScreen />}
              />
            </Route>

            <Route
              path="customer"
              element={
                <TitleWrappingComponent title={'Customer'}>
                  <CustomerOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<Customer />} />
              <Route path="add" element={<CustomerForm />} />
              <Route path="edit/:customerId" element={<CustomerForm />} />
              <Route path="info" element={<CustomerInfoWrapper />} />
              <Route
                path="info/:customerId"
                element={<CustomerInfoWrapper />}
              />
            </Route>
            <Route
              path="customer-groups"
              element={
                <TitleWrappingComponent title={'Customer Groups'}>
                  <CustomerGroupTable />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<CustomerGroupForm />} />
              <Route path=":id" element={<CustomerGroupView />} />
              <Route path="edit/:id" element={<CustomerGroupForm />} />
            </Route>

            <Route
              path="salesman"
              element={
                <TitleWrappingComponent title={'Salesman'}>
                  <Salesman />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<SalesmanForm />} />
              <Route path="edit/:salesmanId" element={<SalesmanForm />} />
            </Route>
            <Route
              path="salesman"
              element={
                <TitleWrappingComponent title={'Salesman'}>
                  <SalesmanOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route
                path="info/:salesmanId"
                element={<SalesmanInfoWrapper />}
              />
              <Route path="info" element={<SalesmanInfoWrapper />}>
                <Route path="add" element={<SalesmanForm />} />
                <Route path="edit/:salesmanId" element={<SalesmanForm />} />
              </Route>
            </Route>

            <Route
              path="enquiry"
              element={
                <TitleWrappingComponent title={'Enquiry'}>
                  <EnquiryOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<EnquiryView />} />
              <Route path="add" element={<AddEnquiry />} />
              <Route path="edit/:enquiryId" element={<EditEnquiry />} />
            </Route>
            <Route
              path="appointment"
              element={
                <TitleWrappingComponent title={'Appointment'}>
                  <AppointmentOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<Appointments />} />
            </Route>

            <Route path="inter-branch-transactions">
              <Route
                path="sales-order"
                element={
                  <TitleWrappingComponent title={'Sales Order'}>
                    <SalesOrderOutlet />
                  </TitleWrappingComponent>
                }
              >
                <Route index element={<SalesOrder interBranch />} />
                <Route path="add" element={<SalesOrderForm interBranch />} />
                <Route
                  path="edit/:salesOrderId"
                  element={<SalesOrderForm interBranch />}
                />
                <Route
                  path="info"
                  element={<SalesOrderInfoWrapper interBranch />}
                />
                <Route
                  path="info/:salesOrderId"
                  element={<SalesOrderInfoWrapper interBranch />}
                />
              </Route>

              <Route
                path="sales-deliveries"
                element={
                  <TitleWrappingComponent title={'Sales Delivery'}>
                    <SalesDeliveryOutlet />
                  </TitleWrappingComponent>
                }
              >
                <Route index element={<SalesDelivery interBranch />} />
                <Route
                  path="edit/:salesDeliveryId"
                  element={<SalesDeliveryForm interBranch />}
                />
                <Route
                  path="add/so/:salesOrderId"
                  element={<SalesDeliveryForm interBranch />}
                />
                <Route
                  path="info"
                  element={<SalesDeliveryInfoWrapper interBranch />}
                />
                <Route
                  path="info/:salesDeliveryId"
                  element={<SalesDeliveryInfoWrapper interBranch />}
                />
              </Route>

              <Route
                path="sales-invoice"
                element={
                  <TitleWrappingComponent title={'Sales Invoice'}>
                    <SalesInvoiceOutlet />
                  </TitleWrappingComponent>
                }
              >
                <Route index element={<SalesInvoice interBranch />} />
                <Route
                  path="edit/:salesInvoiceId"
                  element={<SalesInvoiceForm interBranch />}
                />
                <Route
                  path="add/sd/:salesDeliveryId"
                  element={<SalesInvoiceForm interBranch />}
                />
                <Route
                  path="info"
                  element={<SalesInvoiceInfoWrapper interBranch />}
                />
                <Route
                  path="info/:salesInvoiceId"
                  element={<SalesInvoiceInfoWrapper interBranch />}
                />
              </Route>
            </Route>

            <Route
              path="inter-branch-sales"
              element={
                <TitleWrappingComponent title={'Inter Company Sales'}>
                  <InterBranchSalesTransactionOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<InterBranchSalesTransaction />} />
              <Route
                path="sales-order/add"
                element={<SalesOrderForm interBranch />}
              />
              <Route
                path="sales-order/edit/:salesOrderId"
                element={<SalesOrderForm interBranch />}
              />
              <Route
                path="transactions/info"
                element={<SalesOrderInfoWrapper interBranch />}
              />
              <Route
                path="transactions/info/:salesOrderId"
                element={<SalesOrderInfoWrapper interBranch />}
              />
              <Route
                path="sales-invoice/add/so/:salesOrderId"
                element={<SalesInvoiceForm interBranch />}
              />
              <Route
                path="sales-deliveries/add/so/:salesOrderId"
                element={<SalesDeliveryForm interBranch />}
              />
            </Route>
          </Route>

          {/* Sales End */}
          {/* Production Start */}

          <Route path="production" element={<SuspensePageLoaderUI />}>
            <Route
              index
              element={
                <TitleWrappingComponent title={'Production'}>
                  <Production />
                </TitleWrappingComponent>
              }
            />

            <Route
              path="work-orders"
              element={
                <TitleWrappingComponent title={'Work Order'}>
                  <WorkOrdersOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<WorkOrders />} />
              <Route path="add" element={<AddWorkOrder />} />
              <Route path="add/:salesOrderId" element={<AddWorkOrder />} />
              <Route path="edit/:workOrderId" element={<EditWorkOrder />} />
              <Route path="info" element={<WorkOrderInfoWrapper />} />
              <Route
                path="info/:workOrderId"
                element={<WorkOrderInfoWrapper />}
              />
            </Route>

            <Route
              path="material-request"
              element={
                <TitleWrappingComponent title={'Material Request'}>
                  <MaterialRequestOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<MaterialRequestView />} />
              <Route path="add" element={<AddMaterialRequest />} />
              <Route path="edit/:itemId" element={<EditMaterialRequest />} />
            </Route>

            <Route
              path="estimation"
              element={
                <TitleWrappingComponent title={'Estimation'}>
                  <EstimationOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<EstimationView />} />
              <Route path="add" element={<AddEstimation />} />
              <Route path="edit/:estimationId" element={<EditEstimation />} />
              <Route path="revise/:estimationId" element={<EditEstimation />} />
            </Route>
          </Route>

          {/* Production End */}
          {/* Purchase Start */}

          <Route path="purchase" element={<SuspensePageLoaderUI />}>
            <Route
              index
              element={
                <TitleWrappingComponent title={'Purchase'}>
                  <Purchase />
                </TitleWrappingComponent>
              }
            />

            <Route
              path="purchase-request"
              element={
                <TitleWrappingComponent title={'Purchase Request'}>
                  <PurchaseRequestOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<PurchaseRequestView />} />
              <Route path="add" element={<AddPurchaseRequest />} />
              {/* <Route path="add" element={<PurchaseRequestForm />} /> */}
              <Route path="edit/:itemId" element={<EditPurchaseRequest />} />
              {/* <Route path="edit/:itemId" element={<PurchaseRequestEdit />} /> */}
            </Route>

            <Route
              path="purchase-order"
              element={
                <TitleWrappingComponent title={'Purchase Order'}>
                  <PurchaseOrderOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<PurchaseOrderView />} />
              <Route path="add" element={<AddPurchaseOrder />} />
              <Route path="edit/:itemId" element={<EditPurchaseOrder />} />
            </Route>

            <Route
              path="purchase-delivery"
              element={
                <TitleWrappingComponent title={'Purchase Delivery'}>
                  <PurchaseDeliveryOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<PurchaseDeliveryView />} />
              <Route path="add" element={<AddPurchaseDelivery />} />
              <Route path="edit/:itemId" element={<EditPurchaseDelivery />} />
            </Route>

            <Route
              path="purchase-invoice"
              element={
                <TitleWrappingComponent title={'Purchase Invoice'}>
                  <PurchaseInvoiceOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<PurchaseInvoiceView />} />
              <Route path="add" element={<AddPurchaseInvoice />} />
              <Route path="edit/:itemId" element={<EditPurchaseInvoice />} />
            </Route>

            <Route
              path="debit-note"
              element={
                <TitleWrappingComponent title={'Debit Note'}>
                  <DebitNoteOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<DebitNoteView />} />
              <Route path="add" element={<DebitNoteForm />} />
            </Route>

            <Route
              path="supplier-payment"
              element={
                <TitleWrappingComponent title={'Supplier Payment'}>
                  <SupplierPayment />
                </TitleWrappingComponent>
              }
            />

            <Route
              path="supplier-allocation"
              element={
                <TitleWrappingComponent title={'Supplier Allocation'}>
                  <SupplierAllocationOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<SupplierAllocation />} />
              <Route
                path="allocate/:voucherId"
                element={<SupplierAllocationScreen />}
              />
            </Route>

            <Route
              path="supplier"
              element={
                <TitleWrappingComponent title={'Supplier'}>
                  <SupplierOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<SupplierView />} />
              <Route path="add" element={<SupplierForm />} />
              <Route path="edit/:itemId" element={<SupplierForm />} />
            </Route>
            <Route path="inter-branch-transactions">
              <Route
                path="purchase-delivery"
                element={
                  <TitleWrappingComponent title={'Purchase Delivery'}>
                    <PurchaseDeliveryOutlet />
                  </TitleWrappingComponent>
                }
              >
                <Route index element={<PurchaseDeliveryView interBranch />} />
                <Route
                  path="add"
                  element={<AddPurchaseDelivery interBranch />}
                />
                <Route
                  path="edit/:itemId"
                  element={<EditPurchaseDelivery interBranch />}
                />
              </Route>
              <Route
                path="purchase-order"
                element={
                  <TitleWrappingComponent title={'Purchase Order'}>
                    <PurchaseOrderOutlet />
                  </TitleWrappingComponent>
                }
              >
                <Route index element={<PurchaseOrderView interBranch />} />
                <Route path="add" element={<AddPurchaseOrder interBranch />} />
                <Route
                  path="edit/:itemId"
                  element={<EditPurchaseOrder interBranch />}
                />
              </Route>
              <Route
                path="purchase-invoice"
                element={
                  <TitleWrappingComponent title={'Purchase Invoice'}>
                    <PurchaseInvoiceOutlet />
                  </TitleWrappingComponent>
                }
              >
                <Route index element={<PurchaseInvoiceView interBranch />} />
                <Route
                  path="add"
                  element={<AddPurchaseInvoice interBranch />}
                />
                <Route
                  path="edit/:itemId"
                  element={<EditPurchaseInvoice interBranch />}
                />
              </Route>
            </Route>

            <Route
              path="inter-branch-purchase"
              element={
                <TitleWrappingComponent title={'Inter Company Purchase'}>
                  <InterBranchPurchaseTransactionOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<InterBranchPurchaseTransaction />} />
              <Route
                path="purchase-order/add"
                element={<AddPurchaseOrder interBranch />}
              />
              <Route
                path="purchase-order/edit/:itemId"
                element={<EditPurchaseOrder interBranch />}
              />
              <Route
                path="purchase-delivery/add"
                element={<AddPurchaseDelivery interBranch />}
              />
              <Route
                path="purchase-invoice/add"
                element={<AddPurchaseInvoice interBranch />}
              />
              <Route
                path="transactions/info"
                element={<SalesOrderInfoWrapper interBranch />}
              />
              <Route
                path="transactions/info/:salesOrderId"
                element={<SalesOrderInfoWrapper interBranch />}
              />
              <Route
                path="sales-invoice/add/so/:salesOrderId"
                element={<SalesInvoiceForm interBranch />}
              />
              <Route
                path="sales-deliveries/add/so/:salesOrderId"
                element={<SalesDeliveryForm interBranch />}
              />
            </Route>
          </Route>

          {/* Purchase End */}
          {/* Inventory Start */}

          <Route path="inventory" element={<SuspensePageLoaderUI />}>
            <Route
              index
              element={
                <TitleWrappingComponent title={'Inventory'}>
                  <Inventory />
                </TitleWrappingComponent>
              }
            />

            {/* Government Inventory start */}

            <Route
              path="gov-transactions"
              element={<GovTransactionsInvoiceOutlet />}
            >
              <Route
                path="item"
                element={
                  <TitleWrappingComponent title={'Gov Item'}>
                    <GovItemOutlet />
                  </TitleWrappingComponent>
                }
              >
                <Route index element={<GovItem />} />
                <Route path="add" element={<GovItemForm />} />
                <Route path="edit/:itemId" element={<GovItemForm />} />
              </Route>
              <Route
                path="categories"
                element={
                  <TitleWrappingComponent title={'Gov Category'}>
                    <GovCategoryOutlet />
                  </TitleWrappingComponent>
                }
              >
                <Route index element={<GovCategory />} />
                <Route path="add" element={<GovCategoryForm />} />
                <Route path="edit/:categoryId" element={<GovCategoryForm />} />
              </Route>
            </Route>

            {/* Government Inventory End */}

            <Route
              path="items"
              element={
                <TitleWrappingComponent title={'Item'}>
                  <ItemOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<ItemView />} />
              <Route path="add" element={<ItemForm />} />
              <Route path="edit/:itemId" element={<ItemForm />} />
            </Route>

            <Route
              path="categories"
              element={
                <TitleWrappingComponent title={'Category'}>
                  <Category />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<CategoryTableView />} />
              <Route path="add" element={<CategoryForm />} />
              <Route path="edit/:categoryId" element={<CategoryForm />} />
              <Route path="add/:categoryId" element={<SubCategoryForm />} />
              <Route
                path="subcategory/edit/:subCategoryId"
                element={<SubCategoryForm />}
              />
            </Route>

            <Route
              path="stock-kit"
              element={
                <TitleWrappingComponent title={'Stock Kit'}>
                  <StockKitOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<StockKit />} />
              <Route path=":pageNumber" element={<StockKit />} />
              <Route path="add" element={<StockKitForm />} />
              <Route path="edit/:stockKitId" element={<StockKitForm />} />
              <Route path="info" element={<StockKitInfoWrapper />} />
              <Route
                path="info/:stockKitId"
                element={<StockKitInfoWrapper />}
              />
            </Route>

            <Route
              path="batch-number"
              element={
                <TitleWrappingComponent title={'Batch Number'}>
                  <BatchNumberTableView />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<BatchNumberForm />} />
              <Route path="edit/:batchNumberId" element={<BatchNumberForm />} />
            </Route>

            <Route
              path="batch-number"
              element={
                <TitleWrappingComponent title={'Batch Number'}>
                  <BatchNumberOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route path="info" element={<BatchNumberInfoWrapper />} />
              <Route
                path="info/:batchId"
                element={<BatchNumberInfoWrapper />}
              />
            </Route>

            <Route
              path="price"
              element={
                <TitleWrappingComponent title={'Price'}>
                  <PriceTableView />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<PriceForm />} />
              <Route path="edit/:itemId" element={<PriceForm />} />
            </Route>

            <Route
              path="stock-transfers"
              element={
                <TitleWrappingComponent title={'Stock Transfers'}>
                  <StockTransferOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<StockTransferTable />} />
              <Route path=":pageNumber" element={<StockTransferTable />} />
              <Route path="add" element={<StockTransferForm />} />
              <Route path="edit/:itemId" element={<StockTransferForm />} />
              <Route path="info" element={<StockTransferWrapper />} />
              <Route path="info/:itemId" element={<StockTransferWrapper />} />
            </Route>

            <Route
              path="inventory-adjustment"
              element={
                <TitleWrappingComponent title={'Inventory Adjustment'}>
                  <InventoryAdjustmentOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<InventoryAdjustment />} />
              <Route path="edit/:editId" element={<InventoryAdjustment />} />
            </Route>

            <Route
              path="issue-items"
              element={
                <TitleWrappingComponent title={'Issue Items'}>
                  <IssueItems />
                </TitleWrappingComponent>
              }
            />
          </Route>

          {/* Inventory End */}
          {/* Settings Start */}

          <Route path="settings" element={<SuspensePageLoaderUI />}>
            <Route
              index
              element={
                <TitleWrappingComponent title={'Settings'}>
                  <Settings />
                </TitleWrappingComponent>
              }
            />

            <Route
              path="units"
              element={
                <TitleWrappingComponent title={'Units'}>
                  <UnitTableView />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<UnitForm />} />
              <Route path="edit/:itemId" element={<UnitForm />} />
            </Route>
            <Route
              path="approval-stages"
              element={
                <TitleWrappingComponent title={'Approval Stages'}>
                  <ApprovalStageOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<ApprovalStageList />} />
            </Route>

            <Route
              path="brands"
              element={
                <TitleWrappingComponent title={'Brands'}>
                  <BrandTableView />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<BrandForm />} />
              <Route path="edit/:itemId" element={<BrandForm />} />
            </Route>

            <Route
              path="manufacturer"
              element={
                <TitleWrappingComponent title={'Manufacturer'}>
                  <ManufacturerTableView />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<ManufacturerForm />} />
              <Route path="edit/:itemId" element={<ManufacturerForm />} />
            </Route>

            <Route
              path="currency-rate"
              element={
                <TitleWrappingComponent title={'Currency Rate'}>
                  <ExchangeRateTableView />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<ExchangeRateForm />} />
              <Route path="edit/:itemId" element={<ExchangeRateForm />} />
            </Route>

            <Route
              path="fiscal-year"
              element={
                <TitleWrappingComponent title={'Fiscal Year'}>
                  <FiscalYearTableView />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<FiscalYearForm />} />
              <Route path="edit/:itemId" element={<FiscalYearForm />} />
            </Route>

            <Route
              path="modules-tree"
              element={
                <TitleWrappingComponent title={'Modules Tree'}>
                  <ModuleTreeList />
                </TitleWrappingComponent>
              }
            />

            <Route
              path="modules"
              element={
                <TitleWrappingComponent title={'Modules'}>
                  <ModulesTableView />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<ModulesForm />} />
              <Route path="edit/:moduleId" element={<ModulesForm />} />
            </Route>

            <Route
              path="payment-terms"
              element={
                <TitleWrappingComponent title={'Payment Terms'}>
                  <PaymentTermsTableView />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<PaymentTermsForm />} />
              <Route path="edit/:itemId" element={<PaymentTermsForm />} />
            </Route>

            <Route
              path="shipping-company"
              element={
                <TitleWrappingComponent title={'Shipping Company'}>
                  <ShippingCompanyTableView />{' '}
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<ShippingCompanyForm />} />
              <Route path="edit/:itemId" element={<ShippingCompanyForm />} />
            </Route>

            <Route
              path="document-types"
              element={
                <TitleWrappingComponent title={'Document Types'}>
                  <DocumentTypeTableView />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<DocumentTypeForm />} />
              <Route path="edit/:itemId" element={<DocumentTypeForm />} />
            </Route>

            <Route
              path="custom-fields"
              element={
                <TitleWrappingComponent title={'Custom Fields'}>
                  <CustomFieldOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<CustomFields />} />
              <Route path="add" element={<CustomFieldsWrapper />} />
              <Route
                path="edit/:customFieldId"
                element={<CustomFieldsWrapper />}
              />
            </Route>

            {/* Tax Start */}

            <Route
              path="tax"
              element={
                <TitleWrappingComponent title={'Tax'}>
                  <TaxTableView />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<TaxForm />} />
              <Route path="edit/:taxId" element={<TaxForm />} />
            </Route>

            <Route
              path="tax-group"
              element={
                <TitleWrappingComponent title={'Tax Group'}>
                  <TaxGroupTableView />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<TaxGroupForm />} />
              <Route path="edit/:taxGroupId" element={<TaxGroupForm />} />
            </Route>

            <Route
              path="tax-item"
              element={
                <TitleWrappingComponent title={'Tax Item'}>
                  <TaxItemTableView />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<TaxItemForm show />} />
              <Route path="edit/:taxItemId" element={<TaxItemForm show />} />
            </Route>

            {/* Tax End */}
            {/* Sales Start */}

            <Route
              path="sales-area"
              element={
                <TitleWrappingComponent title={'Sales Area'}>
                  <SalesAreaTableView />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<SalesAreaForm />} />
              <Route path="edit/:itemId" element={<SalesAreaForm />} />
            </Route>

            <Route
              path="sales-type"
              element={
                <TitleWrappingComponent title={'Sales Type'}>
                  <SalesTypeTableView />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<SalesTypeForm />} />
              <Route path="edit/:itemId" element={<SalesTypeForm />} />
            </Route>

            {/* Sales End */}

            <Route
              path="warehouse"
              element={
                <TitleWrappingComponent title={'Warehouse'}>
                  <WarehouseOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<Warehouse />} />
              <Route path="add" element={<WarehouseForm />} />
              <Route path="edit/:itemId" element={<WarehouseForm />} />
            </Route>

            <Route
              path="transaction-reference"
              element={
                <TitleWrappingComponent title={'Transaction Reference'}>
                  <TransactionReferenceTableView />
                </TitleWrappingComponent>
              }
            >
              <Route
                path="edit/:itemId"
                element={<TransactionReferenceForm />}
              />
            </Route>

            <Route
              path="terms-and-conditions"
              element={
                <TitleWrappingComponent title={'Terms And Conditions'}>
                  <TermsAndConditionsTableView />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<TermsAndConditionsForm />} />
              <Route path="edit/:itemId" element={<TermsAndConditionsForm />} />
            </Route>
          </Route>

          {/* Settings End */}

          {/* System Admin Start */}
          <Route path="system-admin" element={<SuspensePageLoaderUI />}>
            <Route
              index
              element={
                <TitleWrappingComponent title={'System Admin'}>
                  <SystemAdmin />
                </TitleWrappingComponent>
              }
            />

            <Route
              path="users"
              element={
                <TitleWrappingComponent title={'Users'}>
                  <UserRegistrationOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<UserList />} />
              <Route path=":pageNumber" element={<UserList />} />
              <Route path="info/:userId" element={<UserProfile />} />
            </Route>

            <Route
              path="users-permissions"
              element={
                <TitleWrappingComponent title={'User Permission'}>
                  <UserPermissions />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<UsersPermissions />} />
            </Route>
            <Route
              path="roles"
              element={
                <TitleWrappingComponent title={'Roles'}>
                  <RolesTableView />
                </TitleWrappingComponent>
              }
            >
              <Route path="add" element={<RolesForm />} />
              <Route path="edit/:roleId" element={<RolesForm />} />
            </Route>
            <Route
              path="roles-permissions"
              element={
                <TitleWrappingComponent title={'Roles Permission'}>
                  <RolePermissions />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<RolesPermissions />} />
            </Route>

            <Route
              path="void-transactions"
              element={
                <TitleWrappingComponent title={'Void Transactions'}>
                  <VoidTransactionWrapper />
                </TitleWrappingComponent>
              }
            >
              <Route path="info/:transId" element={<VoidTransactionInfo />} />
            </Route>
          </Route>

          {/* System Admin End */}

          {/* Fixed Assets Start */}
          <Route path="fixed-assets" element={<SuspensePageLoaderUI />}>
            <Route
              index
              element={
                <TitleWrappingComponent title={'Fixed Assets'}>
                  <FixedAssets />
                </TitleWrappingComponent>
              }
            />
            <Route
              path="assets"
              element={
                <TitleWrappingComponent title={'Assets'}>
                  <AssetOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<Asset />} />
              <Route path="add" element={<AssetForm />} />
              <Route path="edit/:assetId" element={<AssetForm />} />
              <Route path="info" element={<AssetPageInfoWrapper />} />
              <Route path="info/:assetId" element={<AssetPageInfoWrapper />} />
            </Route>
            <Route
              path="rental-contracts"
              element={
                <TitleWrappingComponent title={'Rental Contract'}>
                  <RentalContractOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<RentalContractList />} />
              <Route path="add" element={<RentalContractAdd />} />
              <Route path="edit/:rentalId" element={<RentalContractAdd />} />
              <Route path="info/:rentalId" element={<RentalContractView />} />
              <Route path="info" element={<RentalContractView />} />
            </Route>
            <Route
              path="employee-allocation"
              element={
                <TitleWrappingComponent title={'Employee Allocation'}>
                  <EmployeeAllocationOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<EmployeeAllocationList />} />
              <Route path="info" element={<EmployeeAllocationInfo />} />
              <Route
                path="info/:employeeAllocationId"
                element={<EmployeeAllocationInfo />}
              />
            </Route>
            <Route
              path="purchase-invoice"
              element={
                <TitleWrappingComponent title={'Fixed Asset Purchase Invoice'}>
                  <FixedAssetPurchaseInvoiceOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<FixedAssetPurchaseInvoiceView />} />
              <Route path="add" element={<AddFixedAssetPurchaseInvoice />} />
              <Route
                path="edit/:itemId"
                element={<EditFixedAssetPurchaseInvoice />}
              />
            </Route>
          </Route>
          {/* Fixed Assets End */}

          {/* Zatca Start */}
          <Route path="zatca" element={<SuspensePageLoaderUI />}>
            <Route
              path="records"
              element={
                <TitleWrappingComponent title={'Zatca Records'}>
                  <ZatcaRecordsOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route index element={<ZatcaRecordsList />} />
            </Route>
          </Route>
          {/* Zatca End */}

          {/* Reports Start */}
          <Route path="reports" element={<SuspensePageLoaderUI />}>
            <Route
              index
              element={
                <TitleWrappingComponent title={'Report'}>
                  <Report />
                </TitleWrappingComponent>
              }
            />

            {/* Financial Reports Start */}
            <Route path="profit-and-loss" element={<ProfitAndLoss />} />
            <Route
              path="cost-center-wise-trial-balance"
              element={<TrailBalance />}
            />
            <Route
              path="monthly-trail-balance"
              element={<MonthlyTrailBalance />}
            />
            <Route
              path="yearly-trial-balance"
              element={<YearlyTrailBalance />}
            />
            <Route
              path="monthly-profit-and-loss"
              element={<MonthlyProfitAndLoss />}
            />
            <Route
              path="yearly-profit-and-loss"
              element={<YearlyProfitAndLoss />}
            />
            <Route
              path="cost-center-wise-profit-loss"
              element={<CostWiseProfitAndLoss />}
            />
            <Route path="vat-report" element={<LayoutVAT />} />
            <Route path="balance-sheet" element={<BalanceSheet />} />
            <Route path="trial-balance" element={<TrialBalance />} />
            <Route
              path="trial-balance-drill-down"
              element={<DrillDownReport uiFor={'trial-balance'} />}
            />
            <Route
              path="profit-and-loss-drill-down"
              element={<DrillDownReport uiFor={'profit-and-loss'} />}
            />
            <Route path="ledger-report" element={<LegderReport />} />
            <Route path="cheque-report" element={<ChequeReport />} />
            <Route
              path="deferred-transaction-report"
              element={<DeferredTransactionReport />}
            />
            {/* Financial Reports End */}

            {/* Sales Report Start */}
            <Route path="sales-invoice" element={<SalesInvoiceReport />} />
            <Route
              path="inter-branch-sales-invoice"
              element={<SalesInvoiceReport interBranch />}
            />
            <Route path="sales-order" element={<SalesOrderReport />} />
            <Route
              path="inter-branch-sales-order"
              element={<SalesOrderReport interBranch />}
            />
            <Route path="sales-delivery" element={<SalesDeliveryReport />} />
            <Route
              path="inter-branch-sales-delivery"
              element={<SalesDeliveryReport interBranch />}
            />
            <Route path="sales-quotation" element={<SalesQuotationReport />} />
            <Route path="credit-note-reports" element={<CreditNoteReport />} />
            <Route
              path="unpaid-purchase-invoice"
              element={<UnpaidPurchaseInvoiceReport />}
            />
            <Route path="voucher-inquiry" element={<VoucherInquiryReport />} />
            <Route
              path="credit-note-details"
              element={<CreditNoteDetailsReports />}
            />
            <Route
              path="sales-invoice-details-reports"
              element={<SalesInvoiceDetailReport />}
            />
            <Route
              path="inter-branch-sales-invoice-details-reports"
              element={<SalesInvoiceDetailReport interBranch />}
            />
            <Route path="contract" element={<ContractsReports />} />
            <Route
              path="contract-details"
              element={<ContractsDetailsReports />}
            />
            <Route
              path="sales-delivery-details-reports"
              element={<SalesDeliveryDetailsReports />}
            />
            <Route
              path="inter-branch-sales-delivery-details-reports"
              element={<SalesDeliveryDetailsReports interBranch />}
            />
            <Route
              path="sales-order-details-reports"
              element={<SalesOrderDetailsReport />}
            />
            <Route
              path="inter-branch-sales-order-details-reports"
              element={<SalesOrderDetailsReport interBranch />}
            />
            <Route
              path="sales-quotation-details-reports"
              element={<SalesQuotationDetailsReport />}
            />
            <Route
              path="sales-reports-by-customers"
              element={<SalesReportByCustomer />}
            />
            <Route
              path="category-wise-sales-reports"
              element={<CategoryWiseSalesReport />}
            />
            <Route
              path="top-customers-sales-reports"
              element={<TopCustomerSalesReport />}
            />
            <Route
              path="customer-balance-report"
              element={<CustomerBalanceReport />}
            />
            <Route
              path="aged-customer-reports"
              element={<AgedCustomerReport />}
            />
            <Route
              path="customer-balance-statement-report"
              element={<CustomerBalanceStatement />}
            />
            <Route
              path="unpaid-sales-invoice-reports"
              element={<UnpaidSalesInvoiceReport />}
            />
            <Route
              path="salesman-wise-report"
              element={<SalesmanWiseReport />}
            />
            <Route
              path="salesman-by-sales-invoice-report"
              element={<SalesmanBySalesInvoiceReport />}
            />
            {/* Sales Report End */}

            {/* Purchase Reports Start */}
            <Route
              path="purchase-invoice"
              element={<PurchaseInvoiceReport />}
            />
            <Route
              path="inter-branch-purchase-invoice"
              element={<PurchaseInvoiceReport interBranch />}
            />
            <Route path="purchase-order" element={<PurchaseOrderReport />} />
            <Route
              path="inter-branch-purchase-order"
              element={<PurchaseOrderReport interBranch />}
            />
            <Route
              path="purchase-request"
              element={<PurchaseRequestReport />}
            />
            <Route
              path="purchase-delivery"
              element={<PurchaseDeliveryReport />}
            />
            <Route
              path="inter-branch-purchase-delivery"
              element={<PurchaseDeliveryReport interBranch />}
            />
            <Route
              path="purchase-request-details-reports"
              element={<PurchaseRequestDetailsReport />}
            />
            <Route
              path="purchase-order-details-reports"
              element={<PurchaseOrderDetailsReport />}
            />
            <Route
              path="inter-branch-purchase-order-details-reports"
              element={<PurchaseOrderDetailsReport interBranch />}
            />
            <Route path="debit-note" element={<DebitNoteReport />} />
            <Route
              path="purchase-delivery-details-reports"
              element={<PurchaseDeliveryDetailsReports />}
            />
            <Route
              path="inter-branch-purchase-delivery-details-reports"
              element={<PurchaseDeliveryDetailsReports interBranch />}
            />
            <Route
              path="purchase-invoice-details-reports"
              element={<PurchaseInvoiceDetailsReports />}
            />
            <Route
              path="inter-branch-purchase-invoice-details-reports"
              element={<PurchaseInvoiceDetailsReports interBranch />}
            />
            <Route
              path="debit-note-details-reports"
              element={<DebitNoteDetailReport />}
            />
            <Route
              path="supplier-balance-report"
              element={<SupplierBalanceReport />}
            />
            <Route
              path="aged-supplier-analysis-report"
              element={<AgedSupplierAnalysisReport />}
            />
            <Route
              path="supplier-balance-statement-report"
              element={<SupplierBalanceStatementReport />}
            />
            <Route
              path="top-suppliers-purchase-reports"
              element={<TopSupplierPurchaseReport />}
            />
            <Route
              path="category-wise-purchase-report"
              element={<CategoryWisePurchaseReport />}
            />
            <Route
              path="material-request"
              element={<MaterialRequestReport />}
            />
            {/* Purchase Reports End */}

            {/* Production Reports Start */}
            <Route path="enquiry-report" element={<EnquiryReport />} />
            <Route path="estimation-report" element={<EstimationReport />} />
            <Route
              path="work-in-progress-report"
              element={<WorkInProgressReport />}
            />
            <Route
              path="fininshed-work-orders-report"
              element={<FininshedWorkOrdersReport />}
            />
            <Route
              path="work-orders-details-report"
              element={<WorkOrdersDetailsReport />}
            />
            <Route
              path="over-due-work-orders-report"
              element={<OverDueWorkOrdersReport />}
            />
            <Route
              path="work-orders-issued-items-report"
              element={<WorkOrdersIssuedItemsReport />}
            />
            <Route path="work-orders-report" element={<WorkOrdersReport />} />
            <Route
              path="work-orders-camparison-report"
              element={<WorkOrdersCamparisonReport />}
            />
            {/* Production Reports End */}

            {/* Inventory Reports Start */}
            <Route
              path="inventory-valuation-reports"
              element={<InventoryValuationReport />}
            />
            <Route
              path="inventory-status-reports"
              element={<InventoryStatusReport />}
            />
            <Route
              path="inventory-summary-reports"
              element={<InventorySummaryReport />}
            />
            <Route
              path="inventory-purchase-reports"
              element={<InventoryPurchaseReport />}
            />
            <Route
              path="item-reorder-reports"
              element={<ItemReorderReport />}
            />
            <Route
              path="item-wise-profit-summary-reports"
              element={<ItemWiseProfitSummaryReport />}
            />
            <Route
              path="category-wise-profit-reports"
              element={<CategoryWiseProfitSummaryReport />}
            />
            <Route
              path="inventory-movements-reports"
              element={<InventoryMovementReport />}
            />
            <Route path="batch-report" element={<BatchReport />} />
            <Route
              path="warehouse-wise-average-cost-report"
              element={<WarehouseWiseAverageCostReport />}
            />
            <Route
              path="invoice-wise-item-profit-report"
              element={<InvoiceWiseItemProfitReport />}
            />
            {/* Inventory Reports End */}

            {/* Finance Reports Start */}
            <Route path="journal-report" element={<JournalReport />} />
            <Route
              path="customer-payment-reports"
              element={<CustomerPaymentReport />}
            />
            <Route
              path="supplier-payment-reports"
              element={<SupplierPaymentReport />}
            />
            <Route
              path="payment-voucher-report"
              element={<PaymentVoucherReports />}
            />
            <Route
              path="receipt-voucher-report"
              element={<ReceiptVoucherReport />}
            />
            <Route
              path="bank-transfer-report"
              element={<BankTransferReport />}
            />
            <Route path="refund-report" element={<RefundReport />} />

            <Route
              path="bank-balance-reports"
              element={<BankBalanceReport />}
            />
            <Route
              path="bank-transaction-report"
              element={<BankTransactionReport />}
            />
            <Route
              path="void-transaction-report"
              element={<VoidTransactionReport />}
            />

            <Route
              path="recurrent-profile-reports"
              element={<RecurrentProfileReport />}
            />
            <Route
              path="cash-in-out-statement-reports"
              element={<CashInOutStatementReport />}
            />
            {/* Finance Reports End */}

            {/* System Reports Start */}
            <Route path="email-logs" element={<EmailLogs />} />

            <Route
              path="activity-log"
              element={
                <TitleWrappingComponent title={'Activity Log'}>
                  <ActivityLogOutlet />
                </TitleWrappingComponent>
              }
            >
              <Route
                index
                element={<Navigate to="/reports/activity-log/1" replace />}
              />
              <Route path=":pageNumber" element={<ActivityLog />} />
            </Route>
            {/* System Reports End */}

            {/* Fixed Assets Reports Start */}
            <Route
              path="schedule-maintenance-report"
              element={<ScheduleMaintenanceReport />}
            />
            <Route
              path="asset-wise-profit-summary"
              element={<AssetWiseProfitSummaryReport />}
            />
            <Route
              path="asset-transaction-report"
              element={<AssetTransactionReport />}
            />
            <Route path="asset-stock-report" element={<AssetStockReport />} />
            <Route
              path="asset-purchase-report"
              element={<AssetPurchaseReport />}
            />
            <Route
              path="rental-contract-report"
              element={<RentalContractReport />}
            />
            <Route
              path="employee-allocation-report"
              element={<EmployeeAllocationReport />}
            />
            <Route
              path="material-request-details-reports"
              element={<MaterialRequestDetailsReport />}
            />
            {/* Fixed Assets Reports End */}
          </Route>
          {/* Reports End */}

          {/* System Settings Start */}
          <Route path="system-settings" element={<SuspensePageLoaderUI />}>
            <Route
              element={
                <TitleWrappingComponent title={'System Settings'}>
                  <SystemSettings />
                </TitleWrappingComponent>
              }
            >
              <Route path="system-settings" element={<SystemSettingsFile />} />
              <Route path="preferences" element={<GeneralPage />} />
              <Route path="company-preference">
                <Route index element={<CompanySettings />} />
                <Route path="branches">
                  <Route path="add/:companyId" element={<BranchForm />} />
                  <Route path=":pageNumber" element={<BranchLists />} />
                  <Route path="info/:branchId" element={<BranchView />} />
                  <Route
                    path="edit/:companyId/:branchId"
                    element={<BranchForm />}
                  />
                </Route>
              </Route>
              <Route path="opening-balance" element={<OpeningBalanceOutlet />}>
                <Route index element={<OpeningBalance />} />
                <Route path="edit" element={<OpeningBalanceForm />} />
              </Route>
              <Route path="finance" element={<FinanceSettings />} />

              <Route path="sales-settings" element={<SalesSettings />} />
              <Route path="purchase-settings" element={<PurchaseSettings />} />
              <Route
                path="production-settings"
                element={<ProductionSettings />}
              />
              <Route
                path="inventory-settings"
                element={<InventorySettings />}
              />
              <Route path="asset-settings" element={<AssetSettings />} />
              <Route path="tax-settings" element={<TaxSettings />} />
              <Route path="print-settings" element={<PrintSettings />} />
              <Route
                path="notification-settings"
                element={<NotificationSettings />}
              />
            </Route>
          </Route>
          <Route path="user-profile" element={<SuspensePageLoaderUI />}>
            <Route index element={<NewUserProfile />} />
          </Route>
          {/* System Settings End */}
        </Route>
        <Route path="/password-expiry" element={<PasswordExpiryScreen />} />
        <Route path="errors/403" element={<Error403 />} />
        <Route path="errors/404" element={<Error404 />} />
        <Route path="*" element={<Error404 />} />
        <Route path="sales-quotation" element={<SuspensePageLoaderUI />}>
          <Route path="add" element={<SalesQuotationFormForCRM />} />
        </Route>
      </Routes>

      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </Fragment>
  );
};

export default Layout;

import axios from 'axios';
import moment from 'moment';

export default function handlePurchaseModuleFormDynamicValues(
  event,
  action,
  formData,
  user,
  methods,
  module,
  configs = {
    setShowLoadingScreen: undefined
  }
) {
  return new Promise((resolve, reject) => {
    let showLoadingScreen;

    (async () => {
      try {
        const { setShowLoadingScreen } = configs;
        showLoadingScreen = boolean => {
          if (typeof setShowLoadingScreen === 'function') {
            setShowLoadingScreen(boolean);
          }
        };

        if (!Array.isArray(methods)) {
          throw new Error(
            'handlePurchaseModuleFormDynamicValues failed to execute, Please provide a valid methods'
          );
        }

        showLoadingScreen(true);

        let keyName = !action ? event.target.name : action?.name;

        if (
          methods.includes(
            'setSuppliersDefaultPaymentTermAsDefaultPaymentTermValue'
          ) &&
          (keyName === 'supplier_id' ||
            (module === 'DebitNote' && keyName === 'person_id'))
        ) {
          formData.payment_term_id = null;
          formData.payment_term_id_ref = null;
          if (methods.includes('setDueDateRelatedToPaymentTermDays')) {
            formData.due_date = '';
          }

          const paymentTermId =
            event?.supplier_details?.payment_term_id ||
            user?.branch?.purchase_settings?.payment_term_id ||
            undefined;

          if (action?.action !== 'clear' && paymentTermId) {
            const paymentTermDetails = await axios.get(
              `settings/payment-terms/${paymentTermId}`
            );

            const paymentTermDetailsData = paymentTermDetails?.data?.data;

            if (paymentTermDetailsData?.id) {
              formData.payment_term_id = paymentTermDetailsData.id;
              formData.payment_term_id_ref = {
                label: paymentTermDetailsData?.terms,
                value: paymentTermDetailsData.id,
                days: paymentTermDetailsData?.days
              };

              if (event) {
                event.days = paymentTermDetailsData?.days;
              }
            }
          }
        }

        if (methods.includes('applyTransdateAndTaxIncludedChanges')) {
          if (['tax_included', 'trans_date'].includes(keyName)) {
            formData.details = formData.details.map(item => {
              return {
                ...item,
                trans_date: formData.trans_date,
                tax_included: formData.tax_included,
                add_to_cart_ref: true,
                edit: true
              };
            });
          }
        }

        if (methods.includes('setDueDateRelatedToPaymentTermDays')) {
          if (
            (['payment_term_id', 'supplier_id'].includes(keyName) ||
              (module === 'DebitNote' && keyName === 'person_id')) &&
            typeof event?.days === 'number' &&
            formData?.trans_date?.length > 0
          ) {
            let format = 'YYYY-MM-DD';
            formData.due_date = moment(formData?.trans_date, format)
              .clone()
              .add(event.days, 'days')
              .format(format);
          }
        }

        if (
          methods.includes('setPhoneAndEmailFromSupplierDetails') &&
          (keyName === 'supplier_id' ||
            (module === 'DebitNote' && keyName === 'person_id')) &&
          formData?.supplierDetails_ref
        ) {
          const supplierDetails = formData.supplierDetails_ref;
          formData.phone = supplierDetails?.contact_number || '';
          formData.email = supplierDetails?.email_address || '';
        }

        if (
          methods.includes('setCostCenterRelatedToWarehouse') &&
          keyName === 'warehouse_id'
        ) {
          formData = await setCostCenterRelatedToWarehouse(formData);
        }

        resolve(formData);
      } catch (error) {
        reject(error);
      } finally {
        showLoadingScreen(false);
      }
    })();
  });
}

export const setCostCenterRelatedToWarehouse = async formData => {
  formData.cost_center_id = null;
  formData.cost_center_id_ref = null;

  const warehouseID = formData?.warehouse_id;
  if (warehouseID) {
    const warehouseDetails = await axios.get(
      `settings/warehouses/${formData.warehouse_id}`
    );
    const warehouseDetailsData = warehouseDetails?.data?.data;
    if (warehouseDetailsData?.cost_center_id) {
      formData.cost_center_id = warehouseDetailsData.cost_center_id;
      formData.cost_center_id_ref = {
        label: warehouseDetailsData?.cost_center_name,
        value: warehouseDetailsData.cost_center_id
      };
    }
  }
  return formData;
};

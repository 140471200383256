import React, { useState, useEffect, useCallback, useContext } from 'react';
import { AuthWizardContext, StoreContext } from 'context/Context';
import eventEmitter from './eventEmiter';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import { Container } from 'react-bootstrap';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import axios from 'axios';
import PropTypes from 'prop-types';

const InitStore = ({ children }) => {
  const { user } = useContext(AuthWizardContext);
  const { addIitemToStore } = useContext(StoreContext);
  const [config, setConfig] = useState(null);

  const showMultiBranch = getAcodaxPermissionSlug('BRANCH', 'switch');
  const showUserBranch = getAcodaxPermissionSlug('BRANCH', 'enable-filter');

  const getLatestVersion = async () => {
    try {
      const res = await axios.get('app-versions/latest/react');
      return res?.data?.success ? res.data.data : null;
    } catch (error) {
      console.error('Error fetching latest version:', error);
      return null;
    }
  };

  const getCurrentDate = () => {
    const current = new Date();
    return `${current.getFullYear()}-${String(current.getMonth() + 1).padStart(
      2,
      '0'
    )}-${String(current.getDate()).padStart(2, '0')}`;
  };

  const loadConfig = useCallback(async () => {
    if (!user) return;

    const latestVersion = await getLatestVersion();

    const currentDate = getCurrentDate();
    addIitemToStore('currentDate', currentDate);

    const {
      branch = {},
      whatsapp_web_api_enabled = false,
      enable_crm_link_in_navbar = false,
      branch_id
    } = user;

    const {
      finance_settings = {},
      sales_settings = {},
      system_settings = {},
      purchase_settings = {},
      inventory_settings = {},
      base_currency = 'AED'
    } = branch || {};

    const newConfig = {
      currentDate,
      latestVersion,
      show_multi_branch_for_users: showUserBranch,
      enable_inter_branch_transaction: true,
      show_multi_branch_for_admins: showMultiBranch,
      show_nav_bar_crm_link: enable_crm_link_in_navbar,
      enable_whatsapp_bot: whatsapp_web_api_enabled,
      tax_label: finance_settings?.tax_label ?? 'TRN',
      round_off_max_limit: finance_settings?.round_off_max_limit ?? '0',
      round_off_precision: finance_settings?.round_off_precision ?? '0.00',
      round_off_algorithm: finance_settings?.round_off_algorithm ?? 'auto',
      branch_id,
      base_currency,
      purchase_settings,
      system_settings,
      inventory_settings,
      finance_settings,
      sales_settings
    };

    addIitemToStore('globalConfiguration', newConfig);
    setConfig(newConfig);
  }, [user, addIitemToStore, showMultiBranch, showUserBranch]);

  useEffect(() => {
    loadConfig();

    const handleUserUpdate = data => {
      if (data?.message === 'User updated') {
        loadConfig();
      }
    };

    eventEmitter.on('user.updated', handleUserUpdate);
    return () => {
      eventEmitter.off('user.updated', handleUserUpdate);
    };
  }, [loadConfig]);

  return !config ? (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ height: '100vh', width: '100vw' }}
    >
      <LoadingScreen />
    </Container>
  ) : (
    children
  );
};

InitStore.propTypes = {
  children: PropTypes.element.isRequired
};

export default InitStore;

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  Badge,
  Button,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip
} from 'react-bootstrap';
import { FaChevronLeft, FaFilter, FaSearch } from 'react-icons/fa';
import PropTypes from 'prop-types';
import ReportinLineItemLoading from 'components/report-components/ReportinLineItemLoading';
import LoadingItem from 'components/loading-screen/LoadingItem';
import { apiCall } from 'helpers/apiCalls';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import AppPagination from 'components/common/app-pagination/AppPagination';
import { BiCategory } from 'react-icons/bi';
import Search from 'components/search/Search';
import { useLocation } from 'react-router-dom';
import BackButton from 'components/common/BackButton';
import generatePaginationData from 'helpers/generatePaginationData';
import DownloadCenterBacktoTopButton from 'components/download-center/DownloadCenterBacktoTopButton';
import { AuthWizardContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import SelectWarehouse from 'components/form/SelectWarehouse';
import SelectCategory from 'components/form/SelectCategory';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import SubCategoryFilter from 'module/Common/TableFilter/Components/SubCategoryFilter';

const ItemAdvanceSearch = ({
  setItem,
  name,
  keyName,
  advanceSearchType,
  showFilterIcon,
  fixedAssets,
  filter_keys
}) => {
  const navigate = useNavigate();
  const Translate = useAxisproTranslate();
  const { user } = useContext(AuthWizardContext);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({});
  const [categoryData, setCategoryData] = useState({});
  const [categoriesArray, setCategoriesArray] = useState({});
  const [searchKey, setSearchKey] = useState(null);
  const [items, setItems] = useState([]);
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [loadingItem, setLoadingItem] = useState(false);
  const [itemPage, setItemPage] = useState(0);
  const [onChangeLoading, setOnChangeLoading] = useState(false);
  const [loadMoreItems, setLoadMoreItems] = useState(false);
  const [showBacktoTop, setShowBacktoTop] = useState(false);
  const [excludeRentedOut, setExcludeRentedOut] = useState(true);
  const [queryParams] = useSearchParams();
  const location = useLocation();
  const categoryId = queryParams.get('category');
  const subcategoryId = queryParams.get('subcategory');
  const category_page = queryParams.get('category_page');
  const categorLimit = 5;
  const element = useRef(null);

  const handleFieldChange = async (e, action) => {
    const newFormData = generateFormDataHelper(e, action, formData);
    setFormData(newFormData);
    setItemPage(1);
    setOnChangeLoading(true);
    setLoadMoreItems(false);
    try {
      const Itemsdata = await apiCall({
        url: fixedAssets ? 'fixedasset/fa-items' : 'inventory/items',
        params: {
          query: searchKey,
          category_id: newFormData?.category_id
            ? newFormData?.category_id
            : categoryId,
          sub_category_id: newFormData?.sub_category_id
            ? newFormData?.sub_category_id
            : subcategoryId,
          page: 1,
          ...(fixedAssets && {
            product_type: 'fixed_asset',
            include_fixed_asset: '1',
            exclude_kits: '1',
            exclude_rented_out: excludeRentedOut ? '1' : '0',
            ...newFormData,
            fixed_asset_type: filter_keys?.fixed_asset_type ?? ''
          })
        }
      });

      if (Itemsdata && typeof Itemsdata === 'object') {
        Itemsdata.data && setItems(Itemsdata.data);
        setOnChangeLoading(false);
        let page = 1;
        setLoadMoreItems(
          Itemsdata.meta &&
            Itemsdata.meta.last_page &&
            page < Itemsdata.meta.last_page
            ? true
            : false
        );
      }
    } catch (error) {
      setOnChangeLoading(false);
    }
  };

  const handleBacktoTop = () => {
    if (element && element.current) {
      element.current.scrollTop = 0;
    }
  };

  const handleWindowScroll = e => {
    if (e.target.scrollTop > 300 && !showBacktoTop) {
      setShowBacktoTop(true);
    } else if (e.target.scrollTop < 301 && showBacktoTop) {
      setShowBacktoTop(false);
    }
  };

  const handleScreen = () => {
    if (show) {
      setSearchKey(null);
      navigate(location.pathname);
    }
    setShow(!show);
  };

  const handleSelectItem = item => {
    let event = {
      item: item,
      label: item.item_name,
      value: item[keyName ?? 'id']
    };

    let action = {
      name: name,
      action: 'select-option'
    };

    setItem(event, action);
    setSearchKey(null);
    navigate(location.pathname);
    setShow(false);
  };

  const getItems = useCallback(
    async page => {
      setLoadingItem(true);
      setLoadMoreItems(false);
      setItemPage(page);
      console.log('aks', formData);

      try {
        const Itemsdata = await apiCall({
          url: fixedAssets ? 'fixedasset/fa-items' : 'inventory/items',
          params: {
            query: searchKey,
            category_id: formData?.category_id
              ? formData?.category_id
              : categoryId,
            sub_category_id: formData?.sub_category_id
              ? formData?.sub_category_id
              : subcategoryId,
            page: page ?? 1,
            ...(fixedAssets && {
              product_type: 'fixed_asset',
              include_fixed_asset: '1',
              exclude_kits: '1',
              exclude_rented_out: excludeRentedOut ? '1' : '0',
              warehouse_id: filter_keys?.warehouse_id ?? '',
              fixed_asset_type: filter_keys?.fixed_asset_type ?? ''
            }),
            ...formData
          }
        });

        if (Itemsdata && typeof Itemsdata === 'object') {
          Itemsdata.data &&
            setItems(prev =>
              page > 1 ? [...prev, ...Itemsdata.data] : Itemsdata.data
            );
          setLoadingItem(false);
          setLoadMoreItems(
            Itemsdata.meta &&
              Itemsdata.meta.last_page &&
              page < Itemsdata.meta.last_page
              ? true
              : false
          );
        }
      } catch (error) {
        setLoadingItem(false);
      }
    },
    [
      categoryId,
      subcategoryId,
      searchKey,
      fixedAssets,
      excludeRentedOut,
      filter_keys?.warehouse_id,
      filter_keys?.fixed_asset_type,
      formData
    ]
  );

  const handleExcludeRentedOut = () => {
    setExcludeRentedOut(!excludeRentedOut);
  };

  useEffect(() => {
    const getCategories = async () => {
      setLoadingCategory(true);
      const Categorydata = await apiCall({
        url: 'settings/category/list-category-and-subcategory',
        params: { page: category_page }
      });

      setCategoryData(Categorydata);

      setLoadingCategory(false);
    };

    show && getCategories();
  }, [show, category_page]);

  useEffect(() => {
    setCategoriesArray(
      categoryData && categoryData.categories
        ? generatePaginationData(
            categoryData.categories,
            categorLimit,
            category_page ? parseInt(category_page) : 1
          )
        : {}
    );
  }, [category_page, categoryData]);

  useEffect(() => {
    if (
      (show && !loadingItem && itemPage < 1) ||
      (show && !loadingItem && loadMoreItems)
    ) {
      getItems(itemPage + 1);
    }
  }, [show, loadMoreItems, getItems, itemPage, loadingItem]);

  useEffect(() => {
    if (show) {
      setItems([]);
      const debouncedGetItems = setTimeout(() => {
        getItems(1);
      }, 500);
      return () => clearTimeout(debouncedGetItems);
    }
  }, [searchKey, categoryId, subcategoryId, excludeRentedOut, getItems, show]);

  useEffect(() => {
    let id = subcategoryId ? subcategoryId : categoryId ? categoryId : null;
    if (
      id &&
      categoryData &&
      categoryData.sub_categories &&
      Array.isArray(categoryData.sub_categories)
    ) {
      setCategoriesArray(old => ({
        ...old,
        data: categoryData.sub_categories.filter(
          item =>
            item[!subcategoryId ? 'category_id' : 'sub_category_id'] === id
        )
      }));
    } else if (
      categoryData.categories &&
      Array.isArray(categoryData.categories)
    ) {
      setCategoriesArray(
        generatePaginationData(
          categoryData.categories,
          categorLimit,
          category_page ? parseInt(category_page) : 1
        )
      );
    }
  }, [categoryId, subcategoryId, category_page, categoryData]);
  useEffect(() => {
    setFormData({
      warehouse_id: filter_keys?.warehouse_id ?? '',
      warehouse_id_ref: {
        label: filter_keys?.warehouse_name ?? '',
        value: filter_keys?.warehouse_id ?? ''
      }
    });
  }, [filter_keys]);
  return (
    <>
      {showFilterIcon ? (
        <OverlayTrigger
          overlay={<Tooltip>{Translate('Advance Filter')}</Tooltip>}
        >
          <Button
            variant="secondary"
            size="sm"
            className="px-2 align-self-stretch d-lex justify-content-center align-items-center"
            onClick={handleScreen}
          >
            <FaFilter size={12} />
          </Button>
        </OverlayTrigger>
      ) : (
        <OverlayTrigger overlay={<Tooltip>Advance search</Tooltip>}>
          <Button
            variant="primary"
            size="sm"
            className="px-2 align-self-stretch d-lex justify-content-center align-items-center"
            onClick={handleScreen}
          >
            <FaSearch size={12} />
          </Button>
        </OverlayTrigger>
      )}

      <Modal
        show={show}
        onHide={handleScreen}
        backdrop="static"
        size="xl"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>ITEM LIST</Modal.Title>
        </Modal.Header>
        {showBacktoTop ? (
          <DownloadCenterBacktoTopButton onClick={handleBacktoTop} />
        ) : null}
        <Modal.Body ref={element} onScroll={handleWindowScroll}>
          {categoryId && (
            <BackButton size="sm" className="mb-3 fs--1 px-2">
              <FaChevronLeft />
            </BackButton>
          )}
          {!fixedAssets && (
            <>
              <Row md={3} lg={5}>
                {loadingCategory
                  ? [1, 2, 3, 4, 5].map(index => (
                      <Col key={index} className="mb-3">
                        <LoadingItem />
                      </Col>
                    ))
                  : categoriesArray && categoriesArray.data
                  ? categoriesArray.data.map((category, index) => (
                      <Col key={index} className="mb-3">
                        <Link
                          to={`${location.pathname}?${
                            category_page
                              ? `category_page=${category_page}&`
                              : ''
                          }${
                            categoryId ? `subcategory=${category.id}&` : ''
                          }category=${categoryId ?? category.id}`}
                        >
                          <div className="border p-3 d-flex flex-column align-items-center">
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{
                                width: 80,
                                height: 60,
                                ...(category.images &&
                                Array.isArray(category.images) &&
                                category.images.length > 0
                                  ? {
                                      backgroundImage: `url(${category.images[0]})`,
                                      backgroundPosition: 'center center',
                                      backgroundSize: 'cover',
                                      backgroundRepeat: 'no-repeat'
                                    }
                                  : null)
                              }}
                            >
                              {!category.images ||
                              !Array.isArray(category.images) ||
                              category.images.length < 1 ? (
                                <BiCategory size={20} />
                              ) : null}
                            </div>
                            <p className="h6 mb-0 text-center pt-2">
                              {category.name}
                            </p>
                          </div>
                        </Link>
                      </Col>
                    ))
                  : null}
              </Row>

              <div className="my-3">
                {!loadingCategory &&
                  !categoryId &&
                  categoriesArray &&
                  categoriesArray.meta && (
                    <AppPagination
                      data={categoriesArray}
                      url={`${location.pathname}?category_page=`}
                    />
                  )}
              </div>
            </>
          )}
          <Form className="m-0 ">
            {fixedAssets && (
              <Row className="w-100 pe-0">
                <Col md={3}>
                  <Form.Group className="mb-3 flex-fill ">
                    <Form.Label className="d-flex align-items-center mb-0">
                      {'Warehouse '}
                    </Form.Label>
                    <SelectWarehouse
                      name={'warehouse_id'}
                      value={formData?.warehouse_id_ref}
                      handleFieldChange={handleFieldChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group className="mb-3 flex-fill ">
                    <Form.Label className="d-flex align-items-center mb-0">
                      {'Category '}
                    </Form.Label>
                    <SelectCategory
                      name={'category_id'}
                      value={formData?.category_id_ref}
                      handleFieldChange={handleFieldChange}
                      fixedAsset={fixedAssets}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3 flex-fill ">
                    <Form.Label className="d-flex align-items-center mb-0">
                      {'Sub Category '}
                    </Form.Label>
                    <SubCategoryFilter
                      setFormData={setFormData}
                      formData={formData}
                      item={{ name: 'sub_category_id' }}
                      filterForm={{
                        subCategory: [{ name: 'sub_category_id' }]
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group className="mb-3 flex-fill ">
                    <Form.Label className="d-flex align-items-center mb-0">
                      {'Serial Number '}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="machine_serial_number"
                      onChange={handleFieldChange}
                      value={formData?.machine_serial_number}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
          </Form>

          <div
            className={`d-flex align-items-center  ${
              fixedAssets ? 'justify-content-between' : 'justify-content-end'
            } py-3 `}
          >
            <div>
              {fixedAssets && (
                <div className="d-flex ">
                  <Form.Check
                    type="checkbox"
                    id="exclude_rented_out"
                    name="exclude_rented_out"
                    className="me-2"
                    checked={excludeRentedOut}
                    onChange={handleExcludeRentedOut}
                  />
                  <Form.Label>{Translate('Exclude Rented Out')}</Form.Label>
                </div>
              )}
            </div>
            <div style={{ width: '100%', maxWidth: 300 }}>
              <Search
                placeholder="Search item"
                className="fs--1 w-100"
                value={searchKey}
                onChange={e => setSearchKey(e.target.value)}
              />
            </div>
          </div>

          <Table striped bordered responsive>
            <thead
              className="text-dark text-uppercase"
              style={{ backgroundColor: 'rgb(237,243,248)' }}
            >
              <tr>
                <th className="table-header-size">Item Code</th>
                <th style={{ minWidth: '250px' }} className="table-header-size">
                  Item Name
                </th>
                {fixedAssets && (
                  <th className="table-header-size">Serial Number</th>
                )}
                <th className="table-header-size">Category</th>
                {fixedAssets && (
                  <th className="table-header-size">Warehouse</th>
                )}
                <th className="table-header-size">Price</th>
              </tr>
            </thead>
            <tbody>
              {items.length > 0 ? (
                items.map((item, index) => (
                  <tr key={index}>
                    <th className="fs--1 py-1">
                      <Button
                        variant="transparant"
                        className="fs--1 btn-link py-0"
                        onClick={() => handleSelectItem(item)}
                      >
                        {item.stock_id}
                      </Button>
                    </th>
                    <td className="fs--1 py-1">{item.item_name}</td>
                    {fixedAssets && (
                      <td className="fs--1 py-1">
                        {item.machine_details?.serial_number}
                      </td>
                    )}
                    <td className="fs--1 py-1">{item.category_name}</td>
                    {fixedAssets && (
                      <td className="fs--1 py-1">
                        {item?.warehouse?.length > 0
                          ? item?.warehouse?.[0]?.location_name
                          : '-'}
                      </td>
                    )}
                    <td className="fs--1 py-1">
                      {item[
                        advanceSearchType === 'sales'
                          ? 'sale_rate_formatted'
                          : 'purchase_rate_formatted'
                      ] ?? `${user?.branch?.base_currency} 0.00`}
                    </td>
                  </tr>
                ))
              ) : !loadingItem && items.length < 1 ? (
                <tr>
                  <td colSpan={5}>
                    <Badge bg="danger">No results found</Badge>
                  </td>
                </tr>
              ) : null}

              {loadingItem || onChangeLoading ? (
                <ReportinLineItemLoading colSpan={5} />
              ) : !loadingItem && !loadMoreItems ? (
                <tr>
                  <td colSpan={5}>
                    <div className="d-flex flex-column align-items-center">
                      <span>End reached! &#128578;</span>
                    </div>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};

ItemAdvanceSearch.propTypes = {
  setItem: PropTypes.func,
  name: PropTypes.string,
  keyName: PropTypes.string,
  advanceSearchType: PropTypes.string.isRequired,
  showFilterIcon: PropTypes.bool,
  fixedAssets: PropTypes.bool,
  filter_keys: PropTypes.any
};

export default ItemAdvanceSearch;

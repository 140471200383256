import { React } from 'react';
import { Col, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';

function NoteInputField({ formData, formError, handleFieldChange, size }) {
  const Translate = useAxisproTranslate();

  return (
    <Col sm={size?.col1 ?? ''}>
      <Form.Group className="mb-3" controlId="memo">
        <Form.Label column md>
          {Translate('Notes')}
        </Form.Label>
        <div className="d-flex flex-row align-items-center">
          <div className="flex-fill">
            <Form.Control
              as="textarea"
              rows={size?.rows ?? 5}
              name="memo"
              onChange={handleFieldChange}
              value={formData.memo}
              className="form-tab-switch-control"
            />
          </div>
          <FormErrorPopover
            id="memo"
            totalErrorCount={Object.keys(formError).length}
            errorMessage={formError.memo}
          />
        </div>
      </Form.Group>
    </Col>
  );
}

NoteInputField.propTypes = {
  formData: PropTypes.any,
  formError: PropTypes.any,
  handleFieldChange: PropTypes.func,
  size: PropTypes.any
};

export default NoteInputField;

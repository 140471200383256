import { React, useContext, useLayoutEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Container,
  Tab,
  Tabs
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';

import { BsCoin } from 'react-icons/bs';
import { AiOutlineFileText } from 'react-icons/ai';
import { TiTick } from 'react-icons/ti';
import { FaExternalLinkAlt } from 'react-icons/fa';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { StoreContext } from 'context/Context';
import {
  MoreButton,
  CloseButton,
  EditButton
} from 'module/Common/Buttons/ViewPageButtons';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import PurchaseInvoiceView from './PurchaseInvoiceView';
import PaymentScreenPopup from '../Common/PaymentScreenPopup';
import TransactionsData from 'module/Common/Timeline/TransactionsData';
import UnUsedCreditsPopup from '../Common/UnUSedCredits/UnUsedCreditsPopup';
import VoidTransactionTab from '../Common/VoidTransactionTab';
import SupplierDetails from 'components/supplier-details/SupplierDetails';
import ViewPagePrintButton from '../Common/ViewPagePrintButton';
import AttachementsView from '../Common/AttachementsView';
import FileUpload from '../Common/FileUpload';
import SendEmail from '../Common/SendEmail';
import RecurringRules from '../Common/RecurringRules';
import JournalTable from '../Common/Tables/JournalTable';
import AllocationsTable from '../Common/Tables/AllocationsTable';
import PDFView from 'components/PDFViewerButton/PDFView';
import ActivityLog from 'components/activity-log/ActivityLog';
import useTabViewContentReloadHandler from 'hooks/useTabViewContentReloadHandler';
import TabViewGeneralDetailsSectionCover from 'components/tab-view-general-details-section-cover/TabViewGeneralDetailsSectionCover';
import ShowReference from 'components/show-reference/ShowReference';
import Badges from '../Common/ViewPageDetails/Badges';
import AdditionalCostTab from 'components/additional-cost-tab-view/AdditionalCostTab';
import BranchToBranch from 'components/inter-branch-transaction/components/BranchToBranch';
import PaymentStatus from 'components/payment-status/PaymentStatus';
import DeferredTransactionTable from '../Common/Tables/DeferredTransactionTable';
// import FormSettingsOffcanvas from 'components/form-settings-offcanvas/FormSettingsOffcanvas';
import { isFromSource } from 'helpers/utils';

const tabList = ['PI-PDF-VIEW-TAB', 'PI-ACTIVITY-LOG-VIEW-TAB'];
function PurchaseInvoiceTabView({
  purchaseInvoiceData,
  loading,
  fetchData,
  voidScreen,
  infoPage,
  setShowVoucherDetailArea,
  onHide,
  offCanvas,
  voidTransactionReport,
  getData,
  isFixedAsset,
  interBranch,
  handlePaymentSuccess
}) {
  const { addTab } = useTabViewContentReloadHandler({
    activeTabList: tabList
  });
  const Translate = useAxisproTranslate();
  const { store } = useContext(StoreContext);
  const taxLabel = store?.globalConfiguration?.tax_label || 'Tax';
  let purchase_settings = store?.globalConfiguration?.purchase_settings;
  let navigateTo = useNavigate();
  let [permissions] = useOutletContext() ?? [];
  if (!permissions) {
    permissions = {
      sendMail: getAcodaxPermissionSlug('PI', 'send-mail'),
      print: getAcodaxPermissionSlug('PI', 'print'),
      create: getAcodaxPermissionSlug('PI', 'create'),
      show_sp: getAcodaxPermissionSlug('supplier', 'show'),
      voidTransaction: getAcodaxPermissionSlug('VOID', 'TRANSACTION'),
      createDebitNote: getAcodaxPermissionSlug('DN', 'create'),
      additionalCosts: getAcodaxPermissionSlug('PI_ADDITIONAL_COST', 'save')
    };
  }
  permissions.createSP = getAcodaxPermissionSlug('SP', 'create');
  permissions.createSI = getAcodaxPermissionSlug('SI', 'create');
  const { breakpoints } = useBreakpoints();
  const [key, setKey] = useState(voidScreen ? 'voidForm' : 'overview');
  const [unUsedCredits, setUnUsedCredits] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [payment, setPayment] = useState(false);
  const [showSupplierDetailsArea, setSupplierDetailsArea] = useState(false);
  const [tabApiCallCompleted, setTabApiCallCompleted] = useState({
    journal: false,
    additionalCosts: false,
    allocations: false,
    pdfView: false,
    activityLog: false,
    deferred_transactions: false
  });
  const [person] = useState({
    name: purchaseInvoiceData.supplier_name,
    personId: purchaseInvoiceData.supplier_id,
    personType: 'SUPPLIER',
    value: purchaseInvoiceData.supplier_id,
    costCenterId: purchaseInvoiceData?.cost_center_id ?? ''
  });
  const [upload, setUpload] = useState(false);
  const [recurring, setRecurring] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const pathPreFix = isFixedAsset ? 'fixed-assets' : 'purchase';
  const unusedCreditsFound = purchaseInvoiceData?.supplier?.unused_credits > 0;
  const isPending =
    purchaseInvoiceData?.payment_status?.toLowerCase() !== 'fully paid';
  const allocatedAmount = Math.round(purchaseInvoiceData.alloc);
  const invoicedAmount = Math.round(purchaseInvoiceData.amount);
  const isPartiallyPaid =
    allocatedAmount > 0 &&
    invoicedAmount !== allocatedAmount &&
    allocatedAmount < invoicedAmount;

  const handleSupplierDetailsArea = () => {
    setSupplierDetailsArea(!showSupplierDetailsArea);
  };

  const sendEmail = () => {
    setShowEmailModal(true);
  };

  const handleCancel = () => {
    setShowEmailModal(false);
  };

  const handleVoidTransactionDataAndAdditionalCostCallBack = () => {
    navigateTo(`/${pathPreFix}/purchase-invoice?layout=column`);
    typeof getData === 'function' && getData(1);
  };

  useLayoutEffect(() => {
    if (
      purchaseInvoiceData &&
      purchaseInvoiceData.connected_order &&
      purchaseInvoiceData.connected_deliveries
    ) {
      let connected = [
        ...purchaseInvoiceData.connected_order,
        ...purchaseInvoiceData.connected_deliveries
      ];
      let sorted = connected.sort((a, b) =>
        a.timestamp.localeCompare(b.timestamp)
      );
      setTransactions(sorted);
    }
  }, [purchaseInvoiceData]);

  return (
    <>
      <Card className="flex-fill view-page-content-wrapper">
        <Card.Header
          className={
            breakpoints.up('sm')
              ? 'd-flex m-0 align-items-center border-bottom'
              : 'border-bottom'
          }
        >
          <>
            <div className="d-flex m-0 align-items-center">
              <AiOutlineFileText size={28} className="me-2 text-dark" />
              <h5
                className="mb-0 d-flex text-uppercase view-page-header"
                style={{ fontSize: '25px' }}
              >
                {interBranch
                  ? 'Inter Branch Purchase Invoice'
                  : isFixedAsset
                  ? Translate('Fixed Asset Purchase')
                  : Translate('Purchase invoice')}
              </h5>
            </div>

            <PaymentStatus
              paymentStatus={purchaseInvoiceData?.payment_status}
              className="ms-2 fs--1 d-flex flex-row gap-1"
            />

            {purchaseInvoiceData.total_qty_sent > 0 &&
            !(
              purchaseInvoiceData.total_qty_sent ===
              purchaseInvoiceData.total_qty_invoiced
            ) ? (
              <Badges
                label={`Partially Delivered`}
                className="ms-2 fs--1"
                bg={'dark'}
              />
            ) : purchaseInvoiceData.total_qty_sent ===
              purchaseInvoiceData.total_qty_invoiced ? (
              <Badges
                label={'Fully Delivered'}
                className="ms-2 fs--1"
                bg={'warning'}
                icon={<TiTick className="ms-1" />}
              />
            ) : (
              ''
            )}
            {voidTransactionReport && (
              <Badge bg="danger" className="ms-2 fs--1">
                {Translate('Voided')}
              </Badge>
            )}
            {!interBranch &&
            purchaseInvoiceData?.is_inter_branch_transaction ? (
              <BranchToBranch className={'ms-2'} badgeColor="info" size={15} />
            ) : null}
            {purchaseInvoiceData &&
            purchaseInvoiceData.total_qty_debited > 0 &&
            purchaseInvoiceData.total_qty_debited <
              purchaseInvoiceData.total_qty_invoiced ? (
              <Badge bg="light" text="dark" className="ms-2 fs--1">
                {Translate('Partially Debited')}
                <TiTick />
              </Badge>
            ) : purchaseInvoiceData && purchaseInvoiceData.is_fully_debited ? (
              <Badge bg="dark" className="ms-2 fs--1">
                {Translate('Fully Debited')} <TiTick />
              </Badge>
            ) : (
              ''
            )}
            {purchaseInvoiceData && purchaseInvoiceData.is_recurring ? (
              <Badge bg="success" className="ms-2 fs--1">
                {Translate('Recurring')} <TiTick />
              </Badge>
            ) : (
              ''
            )}
            <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center">
              {voidScreen || offCanvas ? (
                ''
              ) : (
                <>
                  <MoreButton
                    sendEmail={sendEmail}
                    setUpload={setUpload}
                    setPayment={setPayment}
                    setRecurring={setRecurring}
                    recurring={
                      !isFixedAsset ||
                      interBranch ||
                      purchaseInvoiceData?.is_inter_branch_transaction
                        ? false
                        : true
                    }
                    disableRecurring={
                      purchaseInvoiceData.is_recurring === 1 ||
                      purchaseInvoiceData.total_qty_debited > 0
                    }
                    disablePaymentButton={invoicedAmount === allocatedAmount}
                    paymentValidations={isPending}
                    emailPermission={permissions?.sendMail}
                    debitNoteValidations={
                      permissions?.createDebitNote &&
                      !interBranch &&
                      !purchaseInvoiceData?.is_inter_branch_transaction &&
                      purchaseInvoiceData?.details?.length > 0
                    }
                    disableDebitNote={
                      purchaseInvoiceData.is_fully_debited ||
                      !!(purchaseInvoiceData?.total_qty_debited > 0)
                    }
                    debitNoteTo={`/purchase/debit-note/add?invoice_id=${purchaseInvoiceData?.id}`}
                    debitNoteButtonText={
                      purchaseInvoiceData?.is_fully_debited
                        ? 'Fully Debited'
                        : purchaseInvoiceData?.total_qty_debited > 0
                        ? 'Debit note exists'
                        : 'Create Debit Note'
                    }
                    clonePath={
                      permissions?.create &&
                      !interBranch &&
                      !purchaseInvoiceData?.is_inter_branch_transaction
                        ? `/${pathPreFix}/purchase-invoice/add?clone_item=` +
                          purchaseInvoiceData?.id
                        : undefined
                    }
                    voidTransactionData={
                      permissions?.voidTransaction &&
                      !isFromSource(purchaseInvoiceData?.props, 'pms')
                        ? {
                            id: purchaseInvoiceData?.id,
                            trans_type: 'PI',
                            callBack: () =>
                              handleVoidTransactionDataAndAdditionalCostCallBack()
                          }
                        : undefined
                    }
                    additionalCost={
                      purchase_settings?.enable_pi_additional_cost === '1' &&
                      purchaseInvoiceData?.details?.length && {
                        id: purchaseInvoiceData?.id,
                        trans_type: 'PI',
                        orderDetailsArray: purchaseInvoiceData?.details,
                        callBack: () =>
                          handleVoidTransactionDataAndAdditionalCostCallBack()
                      }
                    }
                    firstConversionValidation={
                      purchaseInvoiceData?.customer_id &&
                      purchaseInvoiceData?.is_fully_sent &&
                      permissions?.createSI
                    }
                    disableFirstConversion={
                      purchaseInvoiceData?.is_converted_to_si
                    }
                    firstConversionTo={`/sales/sales-invoice/add/pi/${purchaseInvoiceData?.id}`}
                    afterFirstConversion="Fully Invoiced!"
                    beforeFirstConversion="Convert to Sales Invoice"
                  />

                  {!infoPage && permissions?.edit ? (
                    <EditButton
                      to={`/${pathPreFix}/${
                        interBranch ? 'inter-branch-transactions/' : ''
                      }purchase-invoice/edit/${purchaseInvoiceData.id}`}
                      disable={
                        isPartiallyPaid ||
                        purchaseInvoiceData?.is_converted_to_si ||
                        purchaseInvoiceData?.is_deferred ||
                        isFromSource(purchaseInvoiceData?.props, 'pms')
                      }
                      message={
                        purchaseInvoiceData?.is_converted_to_si
                          ? 'Editing this invoice is not possible because it has already been converted to a Sales Invoice.'
                          : purchaseInvoiceData?.is_deferred
                          ? 'This invoice cannot be edited as it is a deferred transaction.'
                          : isFromSource(purchaseInvoiceData?.props, 'pms')
                          ? 'This invoice is linked to a purchase contract, so it cannot be edited.'
                          : null
                      }
                    />
                  ) : null}
                </>
              )}
              {permissions?.print && !voidTransactionReport && (
                <ViewPagePrintButton
                  variant="danger"
                  className="text-white"
                  style={{ boxShadow: 'none' }}
                  url={`purchase/purchase-invoices/print/${purchaseInvoiceData.id}`}
                  pageTitle="Purchase Invoice print view"
                />
              )}
              {/* <FormSettingsOffcanvas type="PI" /> */}
              {infoPage || offCanvas ? (
                <CloseButton
                  setShowVoucherDetailArea={setShowVoucherDetailArea}
                  onHide={onHide}
                />
              ) : (
                ''
              )}
            </ButtonGroup>
          </>
        </Card.Header>
        <Card.Body className={loading && 'd-flex'}>
          <TabViewGeneralDetailsSectionCover mainTitle="General Details">
            <TabViewGeneralDetailsSectionCover.Section position="start">
              <TabViewGeneralDetailsSectionCover.Item
                title={interBranch ? 'Branch Name' : 'Supplier Name'}
                value={purchaseInvoiceData?.supplier_name}
                EndElement={
                  <Button
                    variant="transparent"
                    className="p-0 mb-2 text-primary outline-none"
                    onClick={() => setSupplierDetailsArea(true)}
                    size="sm"
                  >
                    <FaExternalLinkAlt size={10} />
                  </Button>
                }
              />

              <TabViewGeneralDetailsSectionCover.Item
                title="Transaction Date"
                value={purchaseInvoiceData?.trans_date_formatted}
              />

              <TabViewGeneralDetailsSectionCover.Item
                title="Due Date"
                value={purchaseInvoiceData?.due_date_formatted}
                showIfValueExist
              />
            </TabViewGeneralDetailsSectionCover.Section>

            <TabViewGeneralDetailsSectionCover.Section position="end">
              <TabViewGeneralDetailsSectionCover.Item
                title="Reference"
                contentSectionClassName="mb-2"
              >
                <ShowReference
                  data={purchaseInvoiceData}
                  to={`/purchase/${
                    interBranch ? 'inter-branch-transactions/' : ''
                  }purchase-invoice?entry=${
                    purchaseInvoiceData?.id
                  }&layout=column`}
                  offCanvas={offCanvas}
                  readOnly={voidTransactionReport}
                />
              </TabViewGeneralDetailsSectionCover.Item>

              <TabViewGeneralDetailsSectionCover.Item
                title={`${taxLabel} Included`}
                value={
                  [true, 1, '1'].includes(purchaseInvoiceData?.tax_included)
                    ? 'Included'
                    : 'Excluded'
                }
              />

              <TabViewGeneralDetailsSectionCover.Item
                title="Payment Term"
                value={purchaseInvoiceData?.payment_term}
                showIfValueExist
              />
            </TabViewGeneralDetailsSectionCover.Section>

            <TabViewGeneralDetailsSectionCover.CustomColumnResultSection
              customColumnItems={purchaseInvoiceData?.custom_field_values}
            />
          </TabViewGeneralDetailsSectionCover>

          <Container className="p-0 view-page-tab-style-wrapper">
            {permissions.createSP ? (
              invoicedAmount === allocatedAmount || voidScreen || offCanvas ? (
                ''
              ) : unusedCreditsFound || isPending ? (
                <div className="view-page-payment-message-wrapper">
                  <Card className="p-3">
                    {!unusedCreditsFound ? (
                      ''
                    ) : (
                      <>
                        <div className="d-flex align-items-center gap-2">
                          <BsCoin />
                          <p className="m-0 text-center">
                            {Translate('Unused Credits')}: &nbsp;
                            <span className="fw-bold text-dark">
                              {purchaseInvoiceData &&
                              purchaseInvoiceData.supplier &&
                              purchaseInvoiceData.supplier
                                .unused_credits_formatted
                                ? purchaseInvoiceData.supplier
                                    .unused_credits_formatted
                                : '0.00'}
                            </span>
                          </p>
                          <Button
                            variant="transparent"
                            style={{ boxShadow: 'none' }}
                            className="fs--2 p-0 text-primary"
                            onClick={() => setUnUsedCredits(true)}
                          >
                            {Translate('Apply now')}
                          </Button>
                        </div>
                        <hr className="mt-2 mb-2" />
                      </>
                    )}

                    {isPending ? (
                      <div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex gap-2 align-items-center">
                            <BsCoin size={22} className="text-dark" />
                            <h4 className="m-0 tab-view-heading">
                              {Translate('Make payment for the invoice!')}
                            </h4>
                          </div>
                          <div>
                            <Button
                              variant="warning"
                              style={{ boxShadow: 'none' }}
                              className="fs--2 text-uppercase"
                              onClick={() => setPayment(true)}
                            >
                              {Translate('Make payment')}
                            </Button>
                          </div>
                        </div>
                        <div>
                          <p className="m-0">
                            {Translate(
                              'Make payments for this invoice if adavance allocation available or you can directly pay into this invoice!'
                            )}
                          </p>
                        </div>
                      </div>
                    ) : null}
                  </Card>
                </div>
              ) : null
            ) : (
              ''
            )}
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              color="red"
              className="custom-offcanvas-header-tabs"
            >
              {voidScreen ? (
                <Tab eventKey="voidForm" title={Translate('Voiding Form')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <VoidTransactionTab
                      transType={purchaseInvoiceData.trans_type}
                      transId={purchaseInvoiceData.id}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              <Tab eventKey="overview" title={Translate('Overview')}>
                <SimpleBar
                  className={
                    invoicedAmount === allocatedAmount ||
                    voidScreen ||
                    offCanvas
                      ? 'simplebar-view-page-style'
                      : 'simplebar-view-page-style-with-container'
                  }
                >
                  <PurchaseInvoiceView
                    purchaseInvoiceData={purchaseInvoiceData}
                    isFixedAsset={isFixedAsset}
                  />
                </SimpleBar>
              </Tab>
              <Tab eventKey="journal" title={Translate('Journal')}>
                <SimpleBar
                  className={
                    invoicedAmount === allocatedAmount ||
                    voidScreen ||
                    offCanvas
                      ? 'simplebar-view-page-style'
                      : 'simplebar-view-page-style-with-container'
                  }
                >
                  <JournalTable
                    id={purchaseInvoiceData?.id}
                    transType={'PI'}
                    activeTabKey={key}
                    tabApiCallCompleted={tabApiCallCompleted?.journal}
                    setTabApiCallCompleted={setTabApiCallCompleted}
                  />
                </SimpleBar>
              </Tab>
              <Tab eventKey="allocations" title={Translate('Allocations')}>
                <SimpleBar
                  className={
                    invoicedAmount === allocatedAmount ||
                    voidScreen ||
                    offCanvas
                      ? 'simplebar-view-page-style'
                      : 'simplebar-view-page-style-with-container'
                  }
                >
                  <AllocationsTable
                    id={purchaseInvoiceData?.id}
                    t_type={'PI'}
                    activeTabKey={key}
                    tabApiCallCompleted={tabApiCallCompleted?.allocations}
                    setTabApiCallCompleted={setTabApiCallCompleted}
                  />
                </SimpleBar>
              </Tab>
              {purchaseInvoiceData?.is_deferred === 1 && (
                <Tab
                  eventKey="deferred_transactions"
                  title={Translate('Deferred Transactions')}
                >
                  <SimpleBar
                    className={
                      Math.round(purchaseInvoiceData?.amount) ===
                        Math.round(purchaseInvoiceData?.alloc) ||
                      voidScreen ||
                      offCanvas
                        ? 'simplebar-view-page-style'
                        : 'simplebar-view-page-style-with-container'
                    }
                  >
                    <DeferredTransactionTable
                      id={purchaseInvoiceData?.id}
                      activeTabKey={key}
                      tabApiCallCompleted={
                        tabApiCallCompleted?.deferred_transactions
                      }
                      setTabApiCallCompleted={setTabApiCallCompleted}
                      transactionType={'PI'}
                    />
                  </SimpleBar>
                </Tab>
              )}
              {parseFloat(purchaseInvoiceData?.additional_cost_amount) > 0 && (
                <Tab eventKey="additional_costs" title="Additional Costs">
                  <SimpleBar className="simplebar-custom-height-work-order">
                    <AdditionalCostTab
                      activeTabKey={key}
                      tabApiCallCompleted={tabApiCallCompleted?.additionalCosts}
                      setTabApiCallCompleted={setTabApiCallCompleted}
                      moduleID={purchaseInvoiceData?.id}
                      detailsArray={purchaseInvoiceData?.details}
                    />
                  </SimpleBar>
                </Tab>
              )}
              {transactions && transactions.length > 0 && (
                <Tab eventKey="transaction" title={Translate('Transactions')}>
                  <SimpleBar
                    className={
                      invoicedAmount === allocatedAmount ||
                      voidScreen ||
                      offCanvas
                        ? 'simplebar-view-page-style'
                        : 'simplebar-view-page-style-with-container'
                    }
                  >
                    <TransactionsData
                      transaction={transactions}
                      interBranch={interBranch}
                    />
                  </SimpleBar>
                </Tab>
              )}
              {purchaseInvoiceData.media &&
              purchaseInvoiceData.media.length > 0 ? (
                <Tab eventKey="attachments" title={Translate('Attachments')}>
                  <SimpleBar
                    className={
                      invoicedAmount === allocatedAmount ||
                      voidScreen ||
                      offCanvas
                        ? 'simplebar-view-page-style'
                        : 'simplebar-view-page-style-with-container'
                    }
                  >
                    <AttachementsView
                      data={purchaseInvoiceData}
                      fetchData={fetchData}
                      setKey={setKey}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}

              {purchaseInvoiceData?.id ? (
                <Tab eventKey="pdfView" title={Translate('PDF View')}>
                  <SimpleBar className="simplebar-view-page-style pdf-tab-view-simplebar-style">
                    <PDFView
                      tabViewReloadHandlerID="PI-PDF-VIEW-TAB"
                      viewType="tab"
                      url={`purchase/purchase-invoices/print/${purchaseInvoiceData.id}`}
                      activeTabKey={key}
                      tabApiCallCompleted={tabApiCallCompleted?.pdfView}
                      setTabApiCallCompleted={setTabApiCallCompleted}
                    />
                  </SimpleBar>
                </Tab>
              ) : null}

              <Tab eventKey="activityLog" title={Translate('Activity Log')}>
                <SimpleBar className="simplebar-view-page-style pdf-tab-view-simplebar-style">
                  <ActivityLog
                    subjectID={purchaseInvoiceData.id}
                    tabViewReloadHandlerID="PI-ACTIVITY-LOG-VIEW-TAB"
                    activeTabKey={key}
                    tabApiCallCompleted={tabApiCallCompleted?.activityLog}
                    setTabApiCallCompleted={setTabApiCallCompleted}
                  />
                </SimpleBar>
              </Tab>
            </Tabs>
          </Container>
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created By')}:&nbsp;</h6>
            <h6 className="m-0">
              {purchaseInvoiceData && purchaseInvoiceData.created_user_full_name
                ? purchaseInvoiceData.created_user_full_name
                : 'null'}
            </h6>
          </div>
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created At')}:&nbsp;</h6>
            <h6 className="m-0">
              {purchaseInvoiceData && purchaseInvoiceData.created_at
                ? purchaseInvoiceData.created_at
                : 'null'}
            </h6>
          </div>
        </Card.Footer>
      </Card>
      <SupplierDetails
        show={showSupplierDetailsArea}
        onHide={handleSupplierDetailsArea}
        value={person}
        showSupplierDetailsArea={showSupplierDetailsArea}
      />
      <UnUsedCreditsPopup
        show={unUsedCredits}
        onHide={() => setUnUsedCredits(false)}
        person={person}
        transType={purchaseInvoiceData.trans_type}
        transId={purchaseInvoiceData.voucher_id}
        reference={purchaseInvoiceData.reference}
        dueAmount={purchaseInvoiceData?.balance}
        reLoad={() => {
          fetchData();
          setTabApiCallCompleted(prev => ({
            ...prev,
            allocations: false
          }));
        }}
      />

      <PaymentScreenPopup
        show={payment}
        person={person}
        invoiceData={purchaseInvoiceData}
        onHide={() => setPayment(false)}
        fetchData={() => {
          fetchData({
            updateListItemData: true
          });
          setTabApiCallCompleted(prev => ({
            ...prev,
            allocations: false
          }));
          addTab(tabList);
        }}
        dueAmount={purchaseInvoiceData?.balance}
        onPaymentSuccess={handlePaymentSuccess}
      />
      <FileUpload
        show={upload}
        onHide={() => setUpload(false)}
        fetchData={fetchData}
        type={'PI'}
        id={purchaseInvoiceData.id}
        setKey={setKey}
      />
      <SendEmail
        url={'purchase/purchase-invoice/send-mail/' + purchaseInvoiceData.id}
        showEmailModal={showEmailModal}
        handleCancel={handleCancel}
        data={purchaseInvoiceData.supplier_email}
      />
      <RecurringRules
        show={recurring}
        type="PI"
        id={purchaseInvoiceData.id}
        fetchData={fetchData}
        onHide={() => setRecurring(false)}
      />
    </>
  );
}

PurchaseInvoiceTabView.propTypes = {
  purchaseInvoiceData: PropTypes.any,
  loading: PropTypes.bool,
  fetchData: PropTypes.func,
  voidScreen: PropTypes.bool,
  infoPage: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  onHide: PropTypes.func,
  offCanvas: PropTypes.bool,
  voidTransactionReport: PropTypes.bool,
  getData: PropTypes.func,
  isFixedAsset: PropTypes.bool,
  interBranch: PropTypes.bool,
  handlePaymentSuccess: PropTypes.any
};

export default PurchaseInvoiceTabView;

import { React, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Dropdown } from 'react-bootstrap';

import { FcNoIdea } from 'react-icons/fc';
import PropTypes from 'prop-types';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor } from 'helpers/utils';
import { BarChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { tooltipFormatter } from 'helpers/echart-utils';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import Loader from './Common/Loader';
import {
  grossProfit,
  IncomeVsExpense as IncomeVsExpenseLoader
} from './Common/LoaderData';
import useAcodaxNumberRoundFormat from 'hooks/useAcodaxNumberRoundFormat';
import FalconCardHeader from 'components/common/FalconCardHeader';
import CardDropdown from 'components/common/CardDropdown';
import CalendarRangePickerModal from 'components/CalendarRangePickerModal/CalendarRangePickerModal';

echarts.use([
  BarChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  CanvasRenderer,
  LegendComponent
]);

function IncomeVsExpense({ draggable, isFromDashboard = false }) {
  const Translate = useAxisproTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const acodaxNumberRoundFormat = useAcodaxNumberRoundFormat();
  const [chartData, setChartData] = useState({
    incomeData: [],
    expenseData: [],
    months: []
  });
  const [dateRange, setDateRange] = useState(null);
  const isDateRangeActive = Array.isArray(dateRange) && dateRange.length > 0;
  const [showDateRangePicker, setshowDateRangePicker] = useState(false);
  const loaderArray = isFromDashboard
    ? [...IncomeVsExpenseLoader, ...grossProfit]
    : IncomeVsExpenseLoader;

  const fetchData = useCallback(
    async (reset = false) => {
      setIsLoading(true);
      let params = { from_dashboard: true };

      if (reset) {
        setDateRange(null);
      } else {
        params = isDateRangeActive
          ? { ...params, date_from: dateRange[0], date_to: dateRange[1] }
          : params;
      }

      try {
        const { data } = await axios.get(
          'finance/reports/income-vs-expense-report',
          {
            params: params
          }
        );

        if (data.success) {
          const { monthly_income, monthly_expense } = data.data;
          const years = Object.keys(monthly_income);

          if (years.length === 0) {
            console.error('No data available for any year.');
            setIsLoading(false);
            return;
          }

          const selectedYear = years[0];
          const months = Object.keys(monthly_income[selectedYear] || {});

          if (months.length === 0) {
            console.error(
              `No monthly data available for the year ${selectedYear}.`
            );
            setIsLoading(false);
            return;
          }

          const incomeData = months.map(month =>
            acodaxNumberRoundFormat(
              monthly_income[selectedYear][month]?.amount || 0
            )
          );
          const expenseData = months.map(month =>
            acodaxNumberRoundFormat(
              monthly_expense[selectedYear][month]?.amount || 0
            )
          );

          setChartData({
            incomeData,
            expenseData,
            months
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
        setshowDateRangePicker(false);
      }
    },
    [acodaxNumberRoundFormat, dateRange, isDateRangeActive]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getOption = () => ({
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: tooltipFormatter,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    },
    legend: {
      data: ['Income', 'Expenses'],
      top: -5,
      right: 0,
      textStyle: {
        fontSize: 14
      },
      itemWidth: 20,
      itemHeight: 10
    },
    xAxis: {
      type: 'category',
      data: chartData?.months,
      axisLine: {
        lineStyle: {
          color: getColor('gray-300'),
          type: 'solid'
        }
      },
      axisTick: { show: false },
      axisLabel: {
        color: getColor('600'),
        formatter: value => value.substring(0, 3),
        margin: 15
      },
      splitLine: {
        show: false
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        show: true,
        formatter: value => value / 1000 + 'k',
        color: getColor('600'),
        margin: 15
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: getColor('gray-200')
        }
      },
      axisTick: { show: false },
      axisLine: { show: false },
      min: 0
    },
    series: [
      {
        name: 'Income',
        type: 'bar',
        label: {
          show: false
        },
        data: chartData?.incomeData || [],
        lineStyle: { color: getColor('success') },
        itemStyle: {
          color: getColor('success'),
          borderRadius: [3, 3, 0, 0]
        },
        showSymbol: false,
        symbol: 'circle',
        smooth: false,
        emphasis: {
          scale: true
        },
        color: '#4caf50',
        barWidth: '20%'
      },
      {
        name: 'Expenses',
        type: 'bar',
        data: chartData?.expenseData || [],
        color: '#f44336',
        barWidth: '20%',
        lineStyle: { color: getColor('danger') },
        itemStyle: {
          color: getColor('danger'),
          borderRadius: [3, 3, 0, 0]
        },
        showSymbol: false,

        symbol: 'circle',
        smooth: false,
        emphasis: {
          scale: true
        },
        label: {
          show: false
        }
      }
    ],
    grid: { right: '3%', left: '10%', bottom: '10%', top: '5%' }
  });

  const handleSetDateRange = event => {
    setDateRange(event?.target?.value ?? null);
  };

  return (
    <>
      {showDateRangePicker ? (
        <CalendarRangePickerModal
          show={showDateRangePicker}
          value={dateRange}
          onChange={handleSetDateRange}
          onBack={() => setshowDateRangePicker(false)}
          onReset={() => fetchData(true)}
        />
      ) : null}
      <Card className={`h-100 ${draggable && 'dashboard_shake'}`}>
        <FalconCardHeader
          title={`${Translate('Income vs Expenses')} ${
            isDateRangeActive ? `(${dateRange.join(' - ')})` : ''
          }`}
          light={false}
          titleTag="h6"
          className="pb-0"
          endEl={
            <CardDropdown>
              <div className="py-2">
                <Dropdown.Item onClick={() => setshowDateRangePicker(true)}>
                  {Translate('Change Date')}
                </Dropdown.Item>
              </div>
            </CardDropdown>
          }
        />
        {!isLoading ? (
          Object.keys(chartData).length > 0 ? (
            <>
              <Card.Body className="pt-0">
                <ReactEChartsCore
                  echarts={echarts}
                  option={getOption()}
                  style={{ width: '100%', height: '100%', minHeight: '300px' }}
                />
              </Card.Body>
            </>
          ) : (
            <Card.Body className="d-flex flex-column justify-content-center align-items-center">
              <FcNoIdea size={30} />
              {Translate('No Data Available')}
            </Card.Body>
          )
        ) : (
          <Loader loader={loaderArray} className={'ps-4'} />
        )}
      </Card>
    </>
  );
}

IncomeVsExpense.propTypes = {
  draggable: PropTypes.bool,
  isFromDashboard: PropTypes.bool
};
export default IncomeVsExpense;

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React, { useState } from 'react';
import { Alert, Button, Form, Offcanvas } from 'react-bootstrap';
import { FaCog } from 'react-icons/fa';
import PropTypes from 'prop-types';
import FormSettingsOffcanvasConfigs from './components/FormSettingsOffcanvasConfigs';
import FormSettingsOffcanvasAddSection from './components/FormSettingsOffcanvasAddSection';
import useFormSettingsHook from 'hooks/useFormSettingsHook';
import { formSettingsPropTypesAllowdTypeItems } from 'data/form-settings-data/formSettingsData';

export default function FormSettingsOffcanvas({ type }) {
  const Translate = useAxisproTranslate();
  const { activeTemplate, handleToggle } = useFormSettingsHook(type);

  const [show, setShow] = useState(false);
  const data = activeTemplate?.value;
  const excludedTypes = [
    'DN',
    'SQ',
    'SO',
    'SD',
    'SI',
    'CI',
    'EI',
    'CN',
    'CT',
    'RCT',
    'FAP',
    'SE',
    'JV',
    'PV',
    'RV',
    'BT'
  ];

  const handleClose = event => {
    event?.preventDefault();
    setShow(!show);
  };

  return data ? (
    <>
      <Button
        type="button"
        size="sm"
        variant="secondary"
        title={Translate('Settings')}
        onClick={handleClose}
        className="svg-spin-on-hover"
      >
        <FaCog />
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title
            as={'p'}
            className="fw-bold text-dark"
            style={{
              fontSize: '1rem'
            }}
          >
            {Translate(
              `${excludedTypes.includes(type) ? '' : 'Purchase '}${
                data?.title
              } Settings`
            )}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0 position-relative form-layer-container">
          <Alert variant="warning" className="rounded-0 m-0">
            {Translate(
              'Hi, you can hide or show the input fields related to this form by toggling the switch on this settings screen.'
            )}
            <br />
            <br />
            <b>{Translate('Note: ')}</b>
            {Translate(
              'For the mandatory fields, you should set a default value in the main settings before hiding them.'
            )}
          </Alert>

          <div className="sticky-top shadow-sm bg-white">
            <FormSettingsOffcanvasAddSection type={type} />
          </div>

          <Form>
            <div className="p-3">
              <FormSettingsOffcanvasConfigs
                data={data}
                handleToggle={handleToggle}
              />
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  ) : null;
}

FormSettingsOffcanvas.propTypes = {
  type: PropTypes.oneOf(formSettingsPropTypesAllowdTypeItems).isRequired
};

import React, { useState } from 'react';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { IoMdCheckmark } from 'react-icons/io';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import ConfirmWarning from 'module/Common/Warnings/ConfirmWarning';
import setNewFormData from 'helpers/setNewFormData';
import axios from 'axios';
import { showToast } from 'module/Common/Toast/toast';
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { removeParamsFunc } from 'module/Common/Hooks/useRemoveParams';

const ApproveButton = ({ name, Id, info, approveRequestTab = true }) => {
  const Translate = useAxisproTranslate();
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const navigate = useNavigate();
  const [onSave, setOnSave] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);
  const handleSubmit = () => {
    setOnSave(true);
    const newData = {
      approval_stage_event_id: Id
    };
    let newFormData = setNewFormData(newData);
    axios({
      method: 'post',
      url: `settings/approve-transaction`,
      data: newFormData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        if (response.data.success === true) {
          navigate(
            `?${createSearchParams(
              removeParamsFunc(
                {
                  ...allQueryParams,
                  status: approveRequestTab ? 'approved-request' : 'active'
                },
                ['entry']
              )
            )}`
          );
          handleHide();
          showToast(response.data.message, 'success');
          setOnSave(false);
        }
      })
      .catch(error => {
        console.log(error);
        handleHide();
        showToast('Something went wrong', 'error');
        setOnSave(false);
      });
  };
  return (
    <div>
      <ConfirmWarning
        content="Are you sure you want to approve this request ?"
        onHide={handleHide}
        show={show}
        handleSubmit={handleSubmit}
        onSave={onSave}
        buttonTitle="Approve"
      />
      <Button
        className={`${
          info
            ? 'approval-request-info-button rounded'
            : 'approval-request-table-button rounded-pill me-1'
        }  text-capitalize `}
        onClick={handleShow}
      >
        <IoMdCheckmark className="p-0 m-0" size={15} /> {Translate(name)}
      </Button>
    </div>
  );
};

ApproveButton.propTypes = {
  name: PropTypes.string,
  Id: PropTypes.string,
  approveRequestTab: PropTypes.bool,
  info: PropTypes.bool
};
export default ApproveButton;

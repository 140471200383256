import React from 'react';
import UseAnimations from 'react-useanimations';
import infinity from 'react-useanimations/lib/infinity';
import PropTypes from 'prop-types';

function FormLayer({ caption }) {
  return (
    <div className="form-layer-wrapper d-flex align-items-center flex-column justify-content-center">
      <UseAnimations animation={infinity} size={40} />
      {caption && (
        <p className="mb-0" style={{ fontSize: '12px' }}>
          {caption}
        </p>
      )}
    </div>
  );
}

FormLayer.propTypes = {
  caption: PropTypes.string
};

export default FormLayer;

import React from 'react';
import PropTypes from 'prop-types';

import useAcodaxNumberFormat from 'hooks/useAcodaxNumberFormat';

const ReportLineItem = ({
  mainTitle,
  subTitle,
  totalTitle,
  netTotalTitle,
  total,
  className,
  itemsArray,
  getData,
  accountCode,
  handleExpand,
  collapsedTotal,
  ledgerReport
}) => {
  const acodaxNumberFormat = useAcodaxNumberFormat();

  return (
    <tr
      className={`${
        mainTitle || totalTitle || netTotalTitle ? 'border-top' : null
      } ${className}`}
    >
      {!itemsArray ? (
        <>
          <td className={`border-0 ${mainTitle ? 'pb-0' : null}`}>
            {mainTitle || netTotalTitle ? (
              <div
                onClick={() => {
                  if (mainTitle) {
                    handleExpand(mainTitle);
                  }
                }}
                className={
                  netTotalTitle
                    ? 'mb-0 mt-1 report-class-style fw-bold'
                    : 'mb-0 report-class-style'
                }
              >
                {mainTitle ?? netTotalTitle}
              </div>
            ) : subTitle ? (
              subTitle != 'Calculated Return' ? (
                <p
                  className={`mb-0 report-ledger-style ${
                    total !== 0 && 'report-ledger-hover-style'
                  } `}
                  onClick={() => {
                    if (total !== 0) getData(accountCode);
                  }}
                >
                  &nbsp;&nbsp;-&nbsp;&nbsp;{subTitle}
                </p>
              ) : (
                <p className="mb-0 report-ledger-style">
                  &nbsp;&nbsp;-&nbsp;&nbsp;{subTitle}
                </p>
              )
            ) : totalTitle ? (
              <p className="mb-0 report-total-title">{totalTitle}</p>
            ) : null}
          </td>
          <td
            className={`border-0 ${mainTitle ? 'pb-0' : null}`}
            style={{ width: '50px' }}
          >
            {total !== undefined ? (
              totalTitle ? (
                <div className="text-dark fw-bold d-flex justify-content-end align-items-end fs--1">
                  {acodaxNumberFormat(total)}
                </div>
              ) : netTotalTitle ? (
                <div className="text-dark d-flex justify-content-end align-items-end fs--1 report-class-style fw-bold">
                  {acodaxNumberFormat(total)}
                </div>
              ) : collapsedTotal ? (
                <div className="text-dark d-flex justify-content-end align-items-end fs--1 fw-bold">
                  {acodaxNumberFormat(total)}
                </div>
              ) : (
                <div className="text-dark d-flex justify-content-end align-items-end fs--1">
                  {acodaxNumberFormat(total)}
                </div>
              )
            ) : null}
          </td>
        </>
      ) : !ledgerReport ? (
        <>
          <td className={`${mainTitle ? 'pb-0' : null}`}>
            {mainTitle || netTotalTitle ? (
              <div
                onClick={() => {
                  if (mainTitle) {
                    handleExpand(mainTitle);
                  }
                }}
                className={
                  netTotalTitle
                    ? 'mb-0 mt-1 report-class-style fw-bold'
                    : 'mb-0 report-class-style'
                }
              >
                {mainTitle ?? netTotalTitle}
              </div>
            ) : subTitle ? (
              subTitle != 'Calculated Return' ? (
                <p
                  className={`mb-0 report-ledger-style ${
                    total !== 0 && 'report-ledger-hover-style'
                  } `}
                  onClick={() => {
                    if (total !== 0) getData(accountCode);
                  }}
                >
                  &nbsp;&nbsp;-&nbsp;&nbsp;{subTitle}
                </p>
              ) : (
                <p className="mb-0 report-ledger-style">
                  &nbsp;&nbsp;-&nbsp;&nbsp;{subTitle}
                </p>
              )
            ) : totalTitle ? (
              <p className="mb-0 report-total-title">{totalTitle}</p>
            ) : null}
          </td>
          <td
            className={`${mainTitle ? 'pb-0' : null}`}
            style={{ width: '100px' }}
          >
            {total !== undefined && parseFloat(total) >= 0 ? (
              totalTitle && parseFloat(total) >= 0 ? (
                <div className="text-dark fw-bold d-flex justify-content-start align-items-start fs--1">
                  {acodaxNumberFormat(total)}
                </div>
              ) : netTotalTitle ? (
                <div className="text-dark d-flex justify-content-end align-items-end fs--1 report-class-style fw-bold">
                  {acodaxNumberFormat(total)}
                </div>
              ) : collapsedTotal ? (
                <div className="text-dark d-flex justify-content-end align-items-end fs--1 fw-bold">
                  {acodaxNumberFormat(total)}
                </div>
              ) : (
                <div className="text-dark d-flex justify-content-end align-items-end fs--1">
                  {acodaxNumberFormat(total)}
                </div>
              )
            ) : total?.debit || total?.debit === 0 ? (
              <div className="text-dark fw-bold d-flex justify-content-end align-items-end fs--1">
                {acodaxNumberFormat(Math.abs(total?.debit ?? 0))}
              </div>
            ) : null}
          </td>
          <td
            className={`border-0 ${mainTitle ? 'pb-0' : null}`}
            style={{ width: '100px' }}
          >
            {total !== undefined && parseFloat(total) < 0 ? (
              totalTitle && parseFloat(total) < 0 ? (
                <div className="text-dark fw-bold d-flex justify-content-start align-items-start fs--1">
                  {acodaxNumberFormat(Math.abs(total))}
                </div>
              ) : netTotalTitle ? (
                <div className="text-dark d-flex justify-content-end align-items-end fs--1 report-class-style fw-bold">
                  {acodaxNumberFormat(Math.abs(total))}
                </div>
              ) : collapsedTotal ? (
                <div className="text-dark d-flex justify-content-end align-items-end fs--1 fw-bold">
                  {acodaxNumberFormat(Math.abs(total))}
                </div>
              ) : (
                <div className="text-dark d-flex justify-content-end align-items-end fs--1">
                  {acodaxNumberFormat(Math.abs(total))}
                </div>
              )
            ) : total?.credit || total?.credit === 0 ? (
              <div className="text-dark fw-bold d-flex justify-content-end align-items-end fs--1">
                {acodaxNumberFormat(Math.abs(total?.credit ?? 0))}
              </div>
            ) : null}
          </td>
        </>
      ) : (
        itemsArray.map(({ value, ...rest }, index) => (
          <td {...rest} key={index}>
            {value}
          </td>
        ))
      )}
    </tr>
  );
};

ReportLineItem.propTypes = {
  mainTitle: PropTypes.string,
  subTitle: PropTypes.string,
  totalTitle: PropTypes.string,
  netTotalTitle: PropTypes.string,
  total: PropTypes.any,
  className: PropTypes.string,
  itemsArray: PropTypes.array,
  getData: PropTypes.func,
  accountCode: PropTypes.any,
  handleExpand: PropTypes.func,
  collapsedTotal: PropTypes.bool,
  ledgerReport: PropTypes.bool
};

export default ReportLineItem;

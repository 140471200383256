import { React, useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Alert, Container, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { StoreContext } from 'context/Context';
import { showToast } from 'module/Common/Toast/toast';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import VoucherDetail from 'components/voucher-details/VoucherDetail';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import useAcodaxNumberFormat from 'hooks/useAcodaxNumberFormat';
import MinimalReportDataFetching from 'module/Common/Loading/MinimalReportDataFetching';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';

function SalesmanTransactions({
  salesman_id,
  tabApiCallCompleted,
  setTabApiCallCompleted,
  activeTabKey
}) {
  const Translate = useAxisproTranslate();
  const { store } = useContext(StoreContext);
  const taxLabel = store?.globalConfiguration?.tax_label || 'Tax';
  const [isLoading, setIsLoading] = useState(false);
  const branch_id = store?.globalConfiguration?.branch_id;
  const acodaxNumberFormat = useAcodaxNumberFormat();
  const [trans_type, setTransType] = useState('');
  const [showVoucherDetailArea, setShowVoucherDetailArea] = useState(false);
  const [voucherId, setVoucherId] = useState();
  const [data, setData] = useState([]);
  const [nextLink, setNextLink] = useState(null);
  const [total, setTotal] = useState({
    totalAmount: '',
    totalTaxAmount: '',
    totalBalanceAmount: ''
  });

  const handleVoucherDetailsArea = () => {
    setShowVoucherDetailArea(!showVoucherDetailArea);
  };

  const clickOnVoucher = (voucher_id, trans_type) => {
    setVoucherId(voucher_id);
    setTransType(trans_type);
    setShowVoucherDetailArea(true);
  };

  const loadNextPage = useCallback(async () => {
    if (nextLink && nextLink !== '') {
      try {
        const res = await axios.get(nextLink);
        if (res.data.success) {
          setData(prevData => [...prevData, ...res.data.data.data]);
          setNextLink(res?.data?.data?.links?.next || null);
        }
      } catch (error) {
        console.error(error);
        showToast('Something went wrong. Please contact admin.', 'error');
      }
    }
  }, [nextLink]);

  useEffect(() => {
    let totalAmount = 0;
    let totalTaxAmount = 0;
    let totalBalanceAmount = 0;
    if (data && data.length > 0) {
      let amount = data.map(item => parseFloat(item.amount));
      let taxAmount = data.map(item => parseFloat(item.total_tax));
      let balanceAmount = data.map(item => parseFloat(item.balance_amount));

      if (amount.length > 0) {
        totalAmount = amount.reduce((a, b) => a + b);
      }
      if (taxAmount.length > 0) {
        totalTaxAmount = taxAmount.reduce((a, b) => a + b);
      }
      if (balanceAmount.length > 0) {
        totalBalanceAmount = balanceAmount.reduce((a, b) => a + b);
      }

      setTotal({
        totalAmount: totalAmount,
        totalTaxAmount: totalTaxAmount,
        totalBalanceAmount: totalBalanceAmount
      });
    }
  }, [data]);

  useEffect(() => {
    const getData = () => {
      if (salesman_id) {
        setIsLoading(true);
        axios
          .get('sales/salesman-transactions', {
            params: {
              branch_id: branch_id,
              salesman_id: salesman_id
            }
          })
          .then(response => {
            if (response.data.success) {
              setData(response.data.data.data);
              if (response?.data?.data?.links?.next !== null) {
                setNextLink(response.data.data.links.next);
              } else {
                setNextLink(null);
              }
            }
          })
          .catch(error => {
            if (error) {
              showToast('Something went wrong! Please contact admin', 'error');
            }
          })
          .finally(() => {
            setIsLoading(false);
            setTabApiCallCompleted(prev => ({
              ...prev,
              transaction: true
            }));
          });
      }
    };
    if (salesman_id) {
      if (activeTabKey !== 'transaction' || tabApiCallCompleted) return;
      getData();
    }
  }, [
    activeTabKey,
    branch_id,
    salesman_id,
    setTabApiCallCompleted,
    tabApiCallCompleted
  ]);

  return (
    <Container fluid className="p-0 reports-container-wrapper">
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center mt-5">
          <LoadingScreen message="Looking for transaction" />
        </div>
      ) : data && data.length > 0 ? (
        <Table striped bordered hover responsive className="mt-2">
          <thead className="bg-dark text-light text-uppercase">
            <tr>
              <th className="ledger-report-table-heading">#</th>
              <th className="ledger-report-table-heading">
                {Translate('DATE')}
              </th>
              <th className="ledger-report-table-heading">
                {Translate('REFERENCE')}
              </th>
              <th className="ledger-report-table-heading">
                {Translate('AMOUNT')}
              </th>
              <th className="ledger-report-table-heading">
                {Translate(`${taxLabel} AMOUNT`)}
              </th>
              <th className="ledger-report-table-heading">
                {Translate('BALANCE AMOUNT')}
              </th>
            </tr>
          </thead>

          <tbody>
            {data.map((item, key) => (
              <tr className="report-column-custom-style" key={key}>
                <td className="p-2 text-center">{key + 1}</td>
                <td>{item?.trans_date_formatted}</td>
                <td className="p-2">
                  {getAcodaxPermissionSlug(item?.trans_type, 'show') ? (
                    <a
                      href="#"
                      onClick={() =>
                        clickOnVoucher(item?.reference_id, item?.trans_type)
                      }
                    >
                      {item?.reference}
                    </a>
                  ) : (
                    <a>{item?.reference}</a>
                  )}
                </td>
                <td className="p-2">
                  {' '}
                  <span className="report-column-content-custom-style">
                    {acodaxNumberFormat(item?.amount ?? 0)}
                  </span>
                </td>
                <td className="p-2">
                  {' '}
                  <span className="report-column-content-custom-style">
                    {acodaxNumberFormat(item?.total_tax ?? 0)}
                  </span>
                </td>
                <td className="p-2">
                  {' '}
                  <span className="report-column-content-custom-style">
                    {acodaxNumberFormat(item?.balance_amount ?? 0)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
          {nextLink && nextLink !== null && (
            <MinimalReportDataFetching
              row={3}
              column={8}
              nextLink={nextLink}
              loadNextPage={loadNextPage}
            />
          )}
          <tr className="total-wrapper report-column-custom-style">
            <td></td>
            <td></td>
            <td className="text-field">
              <span className="report-column-content-custom-style">
                {Translate('Total')}
              </span>
            </td>
            <td>
              <span className="report-column-content-custom-style">
                {acodaxNumberFormat(total?.totalAmount ?? 0)}
              </span>
            </td>
            <td>
              <span className="report-column-content-custom-style">
                {acodaxNumberFormat(total?.totalTaxAmount ?? 0)}
              </span>
            </td>
            <td>
              <span className="report-column-content-custom-style">
                {acodaxNumberFormat(total?.totalBalanceAmount ?? 0)}
              </span>
            </td>
          </tr>
        </Table>
      ) : (
        <Alert className="my-auto alert-danger  mt-2">
          <h5 className="text-center text-secondary">
            {Translate('No Transaction Found')} &#128578;
          </h5>
        </Alert>
      )}
      <VoucherDetail
        show={showVoucherDetailArea}
        onHide={handleVoucherDetailsArea}
        setShowVoucherDetailArea={setShowVoucherDetailArea}
        type={trans_type}
        voucherId={voucherId}
        infoPage={true}
        offCanvas={true}
      />
    </Container>
  );
}

SalesmanTransactions.propTypes = {
  salesman_id: PropTypes.string,
  activeTabKey: PropTypes.string,
  tabApiCallCompleted: PropTypes.bool,
  setTabApiCallCompleted: PropTypes.func
};

export default SalesmanTransactions;

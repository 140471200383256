import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { MdBatchPrediction } from 'react-icons/md';

import { consumerFormKeys } from 'helpers/formKeys';
import { showToast } from 'module/Common/Toast/toast';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import PopupFormButton from 'components/popup-form-button/PopupFormButton';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';

const ConsumerPopupForm = ({
  callBack,
  setData,
  labelName,
  keyName,
  name,
  showAddButton,
  onHide,
  show
}) => {
  const Translate = useAxisproTranslate();
  const [showWindow, setShowWindow] = useState(show ?? false);
  const [formData, setFormData] = useState(consumerFormKeys);
  const [formError, setFormError] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const handleShow = () => {
    setShowWindow(!showWindow);
    if (show) {
      setFormData(consumerFormKeys);
      setFormError({});
      setIsSaving(false);
      if (onHide && typeof onHide === 'function') onHide();
    }
  };

  const handleFieldChange = (e, action) => {
    setFormData(generateFormDataHelper(e, action, formData));
  };

  const handleSubmit = e => {
    e.preventDefault();
    setFormError({});
    setIsSaving(true);

    axios({
      method: 'post',
      url: 'inventory/consumer',
      data: formData
    })
      .then(response => {
        if (response.data.success === true) {
          showToast(response.data.message, 'success');
          if (callBack) {
            callBack(response.data.data);
          }

          if (response.data.data && setData) {
            setData(
              {
                label: response.data.data[labelName ?? 'name'],
                value: response.data.data[keyName ?? 'id']
              },
              {
                action: 'select-option',
                option: undefined,
                name: name ?? 'consumer_id'
              }
            );
          }

          handleShow();
        } else {
          showToast(
            Translate(
              'Something went wrong, please refresh the page and try again.'
            ),
            'error'
          );
        }
        setIsSaving(false);
      })
      .catch(error => {
        if (error.response.data && error.response.data.message) {
          showToast(error.response.data.message, 'error');

          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            Translate(
              'Something went wrong, please refresh the page and try again.'
            ),
            'error'
          );
        }
        setIsSaving(false);
      });
  };

  useEffect(() => {
    setShowWindow(show ?? false);
    return () => {
      setFormData(consumerFormKeys);
      setFormError({});
      setShowWindow(false);
      setIsSaving(false);
    };
  }, [show]);

  return (
    <>
      {showAddButton ? (
        <PopupFormButton onClick={handleShow}>
          {Translate('Add Consumer')}
        </PopupFormButton>
      ) : null}

      {showWindow ? (
        <Modal
          show={showWindow}
          onHide={handleShow}
          backdrop="static"
          keyboard={false}
        >
          <Form onSubmit={handleSubmit}>
            <Modal.Header>
              <Modal.Title className="d-flex flex-row align-items-center">
                <MdBatchPrediction size={20} className="text-success" />
                <span className="ms-1">{Translate('ADD CONSUMER')}</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {Object.keys(formError).length > 0 && (
                <ErrorAlert formError={formError} setFormError={setFormError} />
              )}
              <Form.Group className="mb-3" controlId="form.name">
                <Row>
                  <Col md={5} sm={12}>
                    <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                      {Translate('Name')}
                    </Form.Label>
                  </Col>
                  <Col
                    md={7}
                    sm={12}
                    className="d-flex flex-row align-items-center"
                  >
                    <Form.Control
                      name="name"
                      onChange={handleFieldChange}
                      value={formData.name}
                    />
                    <FormErrorPopover
                      id="name"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError.name}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3" controlId="form.address">
                <Row>
                  <Col md={5} sm={12}>
                    <Form.Label className="m-0 d-flex align-items-center justify-content-start h-100">
                      {Translate('Address')}
                    </Form.Label>
                  </Col>
                  <Col
                    md={7}
                    sm={12}
                    className="d-flex flex-row align-items-center"
                  >
                    <Form.Control
                      as="textarea"
                      rows={5}
                      name="address"
                      onChange={handleFieldChange}
                      value={formData.address}
                    />
                    <FormErrorPopover
                      id="address"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError.address}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="d-flex flex-row justify-content-start">
              <Button
                variant="outline-success"
                size="sm"
                className="d-flex align-items-center justify-content-center"
                onClick={handleSubmit}
                disabled={isSaving}
              >
                {isSaving ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="success"
                      className="me-1"
                    />
                    <span className="fs--1">{Translate('Saving...')}</span>
                  </>
                ) : (
                  <span>{Translate('Add')}</span>
                )}
              </Button>
              <Button
                variant="outline-danger"
                onClick={handleShow}
                size="sm"
                disabled={isSaving}
              >
                {Translate('Cancel')}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      ) : null}
    </>
  );
};

ConsumerPopupForm.propTypes = {
  callBack: PropTypes.func,
  setData: PropTypes.func,
  name: PropTypes.string,
  labelName: PropTypes.string,
  keyName: PropTypes.string,
  show: PropTypes.bool,
  showAddButton: PropTypes.bool,
  onHide: PropTypes.func
};

export default ConsumerPopupForm;

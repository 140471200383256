import React from 'react';
import { Alert, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import UnUsedCreditsFormTableItem from './UnUsedCreditsFormTableItem';

function UnUsedCreditsTable({
  advanceReceipt,
  handleAdvanceReceiptAmount,
  total,
  dueAmount,
  allocatedAmount,
  ClearAllocation
}) {
  const Translate = useAxisproTranslate();

  return advanceReceipt.length > 0 ? (
    <Table size="sm" striped bordered responsive>
      <thead
        className="fs--1 text-bold"
        style={{ backgroundColor: 'rgb(213,243,255)' }}
      >
        <tr>
          <th
            className="table-header-size text-center text-dark fw-900"
            style={{ width: '40px' }}
          >
            #
          </th>
          <th className="table-header-size text-center text-dark fw-900">
            {Translate('Reference')}
          </th>
          <th className="table-header-size text-center text-dark fw-900">
            {Translate('Type')}
          </th>
          <th className="table-header-size text-center text-dark fw-900">
            {Translate('Date')}
          </th>
          <th className="table-header-size text-center text-dark fw-900">
            {Translate('Cost Center')}
          </th>
          <th className="table-header-size text-center text-dark fw-900">
            {Translate('Amount')}
          </th>
          <th className="table-header-size text-center text-dark fw-900">
            {Translate('Balance Amount')}
          </th>
          <th
            className="table-header-size text-center text-dark fw-900"
            style={{ width: '200px' }}
          >
            {Translate('Amount to Allocate')}
          </th>
          <th className="table-header-size text-center text-dark fw-900"></th>
        </tr>
      </thead>
      <tbody className="fs--1 text-dark">
        {advanceReceipt.map((item, index) => (
          <UnUsedCreditsFormTableItem
            key={index}
            advance_data={item}
            index={index}
            allocatedAmount={allocatedAmount}
            dueAmount={dueAmount}
            handleAdvanceReceiptAmount={handleAdvanceReceiptAmount}
            ClearAllocation={ClearAllocation}
          />
        ))}
        <tr>
          <td className="small text-muted" colSpan={4}>
            **{Translate('List contains only Advance receipts')}
          </td>
          <td colSpan={1}>
            <div className="d-flex justify-content-center">
              <b>{Translate('TOTAL')}</b>
            </div>
          </td>
          <td>
            <div className="d-flex justify-content-center">
              <b>{parseFloat(total.totalAmount).toFixed(2)}</b>
            </div>
          </td>
          <td>
            <div className="d-flex justify-content-center">
              <b>{parseFloat(total.dueAmount).toFixed(2)}</b>
            </div>
          </td>
          <td>
            <div className="d-flex justify-content-center">
              <b>{parseFloat(total.allocatedAmount).toFixed(2)}</b>
            </div>
          </td>
        </tr>
      </tbody>
    </Table>
  ) : (
    <Alert
      variant="info fs--1"
      className="m-0 mb-2"
      style={{ borderRadius: '0px' }}
    >
      {Translate(
        'There are no unpaid Invoices for this customer, that can be applied for this payment.'
      )}
      <br />
      <b>{Translate('Note')}:</b>
      {Translate('You can add advance amount for this customer.')}
    </Alert>
  );
}

UnUsedCreditsTable.propTypes = {
  advanceReceipt: PropTypes.any,
  handleAdvanceReceiptAmount: PropTypes.func,
  total: PropTypes.object,
  dueAmount: PropTypes.number,
  allocatedAmount: PropTypes.func,
  ClearAllocation: PropTypes.func
};

export default UnUsedCreditsTable;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import ReferenceCopyToClipBoard from '../../Common/ReferenceCopyToClipBoard';

function Reference({
  offCanvas,
  voidTransactionReport,
  to,
  value,
  label,
  onReferenceClick
}) {
  const navigate = useNavigate();
  const Translate = useAxisproTranslate();

  return (
    <div className="d-flex gap-1">
      <h6 className="mb-2 fw-bold">
        {Translate(label ? label : 'Reference')}:&nbsp;
      </h6>
      <h6
        className={
          offCanvas && !voidTransactionReport ? 'mb-1 reference-style' : 'mb-2'
        }
        style={{
          cursor: offCanvas && !voidTransactionReport ? 'pointer' : ''
        }}
        onClick={() =>
          offCanvas && !voidTransactionReport
            ? onReferenceClick?.() ?? navigate(to)
            : ''
        }
      >
        {value}
      </h6>
      <ReferenceCopyToClipBoard reference={value} />
    </div>
  );
}

Reference.propTypes = {
  value: PropTypes.any,
  offCanvas: PropTypes.bool,
  voidTransactionReport: PropTypes.bool,
  to: PropTypes.any,
  label: PropTypes.any,
  onReferenceClick: PropTypes.func
};

export default Reference;

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  MdDoneAll,
  MdNewLabel,
  MdOutlineAttachEmail,
  MdOutlineClose,
  MdOutlineCloudUpload
} from 'react-icons/md';
import {
  BsArrowRepeat,
  BsCoin,
  BsFillTrashFill,
  BsPencilSquare
} from 'react-icons/bs';
import { RiFileTransferLine, RiRefund2Fill } from 'react-icons/ri';
import { AiOutlineFileText } from 'react-icons/ai';
import {
  FaBarcode,
  FaRegClone,
  FaRegFileAlt,
  FaUserPlus,
  FaWarehouse
} from 'react-icons/fa';
import { BiRevision } from 'react-icons/bi';
// import { TbReportMoney } from 'react-icons/tb';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import VoidTransactionPopUpDropdownItem from 'components/void-transaction-pop-up-dropdown-item/VoidTransactionPopUpDropdownItem';
import AdditionalCostPopUpDropdownItem from 'components/additional-cost-pop-up-dropdown-item/AdditionalCostPopUpDropdownItem';
import ProposalPdfGeneratorDropDownItem from 'components/proposal-pdf-generator/ProposalPdfGeneratorDropDownItem';
import { getAcodaxPermissionSlug } from '../Validators/permissions/PermissionSlug';
import { HiOutlineStatusOnline } from 'react-icons/hi';

export const MoreButton = ({
  renewedPath,
  sendEmail,
  setUpload,
  setRecurring,
  setMakeRentalToInvoice,
  setMakeAdditionalInvoice,
  recurring,
  disableRecurring,
  refundValiadtions,
  disableRefundButton,
  setReFund,
  paymentValidations,
  disablePaymentButton,
  setPayment,
  firstConversionValidation,
  disableFirstConversion,
  firstConversionTo,
  afterFirstConversion,
  beforeFirstConversion,
  debitNoteValidations,
  secondConversionValidation,
  disableSecondConversion,
  secondConversionTo,
  afterSecondConversion,
  beforeSecondConversion,
  type,
  creditNoteValidations,
  disableCreditNote,
  disableDebitNote,
  creditNoteTo,
  item,
  setBarcodeModel,
  issueItems,
  setIssueItem,
  emailPermission,
  convertToWorkOrder,
  changeStatus,
  setStatus,
  disableStatus,
  debitNoteTo,
  debitNoteButtonText,
  creditNoteButtonName,
  clonePath,
  revise,
  voidTransactionData,
  additionalCost,
  rentalToInvoice,
  additionalInvoice,
  proposalData,
  showOpeningStockModal,
  setOpeningStockModal,
  fileUploadButtonTitle,
  handleCustomerStatus,
  customerStatus
}) => {
  const Translate = useAxisproTranslate();
  const navigate = useNavigate();
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="light"
        className="dropdown-button-style"
        style={{
          backgroundColor: 'whitesmoke',
          border: '1px solid rgb(212, 210, 210)'
        }}
      >
        {Translate('More')}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {firstConversionValidation ? (
          <Dropdown.Item
            className="d-flex align-items-center"
            as={Link}
            to={firstConversionTo}
            disabled={disableFirstConversion}
          >
            <RiFileTransferLine className="me-1 text-dark" size={16} />
            {disableFirstConversion
              ? Translate(afterFirstConversion)
              : Translate(beforeFirstConversion)}
          </Dropdown.Item>
        ) : (
          ''
        )}
        {secondConversionValidation ? (
          <Dropdown.Item
            className="d-flex align-items-center"
            as={Link}
            to={secondConversionTo}
            disabled={disableSecondConversion}
          >
            <RiFileTransferLine className="me-1 text-dark" size={16} />
            {disableSecondConversion
              ? Translate(afterSecondConversion)
              : Translate(beforeSecondConversion)}
          </Dropdown.Item>
        ) : (
          ''
        )}
        {changeStatus ? (
          <Dropdown.Item
            className="d-flex align-items-center"
            onClick={() => setStatus(true)}
            disabled={disableStatus}
          >
            <MdDoneAll className="me-1 text-dark" size={16} />
            {Translate('Complete')}
          </Dropdown.Item>
        ) : (
          ''
        )}
        {renewedPath ? (
          <Dropdown.Item
            className="d-flex align-items-center fs--1"
            onClick={() => navigate(renewedPath)}
          >
            <MdNewLabel className="me-1 text-dark" size={16} />
            {Translate('Renew')}
          </Dropdown.Item>
        ) : (
          ''
        )}
        {convertToWorkOrder ? (
          <Dropdown.Item
            className="d-flex align-items-center"
            as={Link}
            to={convertToWorkOrder}
            disabled={disableFirstConversion}
          >
            <RiFileTransferLine className="me-1 text-dark" size={16} />
            {disableFirstConversion
              ? Translate('Fully Converted to Work Order')
              : Translate('Convert to Work Order')}
          </Dropdown.Item>
        ) : (
          ''
        )}
        {debitNoteValidations ? (
          <Dropdown.Item
            className="d-flex align-items-center"
            as={Link}
            to={debitNoteTo}
            disabled={disableDebitNote}
          >
            <AiOutlineFileText className="me-1 text-dark" size={16} />
            {Translate(debitNoteButtonText ?? 'Create Debit Note')}
          </Dropdown.Item>
        ) : (
          ''
        )}
        {creditNoteValidations ? (
          <Dropdown.Item
            className="d-flex align-items-center"
            as={Link}
            to={creditNoteTo}
            disabled={disableCreditNote}
          >
            <AiOutlineFileText className="me-1 text-dark" size={16} />
            {Translate(creditNoteButtonName)}
          </Dropdown.Item>
        ) : (
          ''
        )}
        {refundValiadtions ? (
          <Dropdown.Item
            className="d-flex align-items-center"
            onClick={() => setReFund(true)}
            disabled={disableRefundButton}
          >
            <RiRefund2Fill className="me-1 text-dark" size={16} />
            {Translate('Refund Amount')}
          </Dropdown.Item>
        ) : (
          ''
        )}
        {paymentValidations ? (
          <Dropdown.Item
            className="d-flex align-items-center"
            onClick={() => setPayment(true)}
            disabled={disablePaymentButton}
          >
            <BsCoin className="me-1 text-dark" size={16} />
            {type === 'SI' || type === 'PV'
              ? Translate('Receive Payment')
              : Translate('Make Payment')}
          </Dropdown.Item>
        ) : (
          ''
        )}
        {type === 'CUSTOMER' ||
        type === 'SALESMAN' ||
        type === 'SUPPLIER' ||
        type === 'BRANCH' ||
        type == 'BT' ||
        !emailPermission ||
        item ? (
          ''
        ) : (
          <Dropdown.Item
            className="d-flex align-items-center"
            onClick={sendEmail}
          >
            <MdOutlineAttachEmail className="me-1 text-dark" size={16} />
            {Translate('Send Email')}
          </Dropdown.Item>
        )}
        {type === 'CUSTOMER' ? (
          <Dropdown.Item
            className="d-flex align-items-center"
            onClick={handleCustomerStatus}
          >
            <HiOutlineStatusOnline className="me-1 text-dark" size={18} />
            {Translate(customerStatus === 1 ? 'Make Active' : 'Make Inactive')}
          </Dropdown.Item>
        ) : (
          ''
        )}
        {item ? (
          <>
            <Dropdown.Item
              className="d-flex align-items-center"
              onClick={() => setBarcodeModel(true)}
            >
              <FaBarcode className="me-1 text-dark" size={16} />
              {Translate('Print Barcode')}
            </Dropdown.Item>

            {showOpeningStockModal && (
              <Dropdown.Item
                className="d-flex align-items-center"
                onClick={() => setOpeningStockModal(true)}
              >
                <FaWarehouse className="me-1 text-dark" size={16} />
                {Translate('Add Opening Stock')}
              </Dropdown.Item>
            )}
          </>
        ) : (
          ''
        )}

        {setUpload ? (
          <Dropdown.Item
            className="d-flex align-items-center"
            onClick={() => setUpload(true)}
          >
            <MdOutlineCloudUpload className="me-1 text-dark" size={16} />
            {Translate(
              fileUploadButtonTitle ? fileUploadButtonTitle : 'Upload File'
            )}
          </Dropdown.Item>
        ) : null}

        {recurring ? (
          <Dropdown.Item
            className="d-flex align-items-center"
            onClick={() => setRecurring(true)}
            disabled={disableRecurring}
          >
            <BsArrowRepeat className="me-1 text-dark" size={16} />
            {Translate('Make Recurring')}
          </Dropdown.Item>
        ) : (
          ''
        )}
        {rentalToInvoice ? (
          <Dropdown.Item
            className="d-flex align-items-center"
            onClick={() => setMakeRentalToInvoice(true)}
          >
            <FaRegFileAlt className="me-1 text-dark" size={16} />
            {Translate('Make Invoice')}
          </Dropdown.Item>
        ) : (
          ''
        )}
        {additionalInvoice ? (
          <Dropdown.Item
            className="d-flex align-items-center"
            onClick={() => setMakeAdditionalInvoice(true)}
          >
            <FaRegFileAlt className="me-1 text-dark" size={16} />
            {Translate('Additional Invoice')}
          </Dropdown.Item>
        ) : (
          ''
        )}
        {issueItems ? (
          <Dropdown.Item
            className="d-flex align-items-center"
            onClick={() => setIssueItem(true)}
          >
            <BsArrowRepeat className="me-1 text-dark" size={16} />
            {Translate('Issue Items')}
          </Dropdown.Item>
        ) : (
          ''
        )}

        <AdditionalCostPopUpDropdownItem details={additionalCost} />

        {clonePath ? (
          <Dropdown.Item
            className="d-flex align-items-center"
            as={Link}
            to={clonePath}
          >
            <FaRegClone className="me-1 text-dark" size={14} />
            {Translate('Clone')}
          </Dropdown.Item>
        ) : null}

        {proposalData?.sqId &&
        getAcodaxPermissionSlug('PROPOSAL', 'dynamic') ? (
          <ProposalPdfGeneratorDropDownItem data={proposalData?.data} />
        ) : null}

        {revise?.permission ? (
          <Dropdown.Item
            className="d-flex align-items-center"
            as={Link}
            to={revise?.path}
          >
            <BiRevision className="me-1 text-dark" size={14} />
            {Translate('Revise')}
          </Dropdown.Item>
        ) : null}

        <VoidTransactionPopUpDropdownItem details={voidTransactionData} />
      </Dropdown.Menu>
    </Dropdown>
  );
};

MoreButton.propTypes = {
  renewedPath: PropTypes.string,
  voidScreen: PropTypes.bool,
  setMakeRentalToInvoice: PropTypes.func,
  setMakeAdditionalInvoice: PropTypes.func,
  sendEmail: PropTypes.func,
  setUpload: PropTypes.func,
  setReFund: PropTypes.func,
  setPayment: PropTypes.func,
  refundValiadtions: PropTypes.bool,
  paymentValidations: PropTypes.bool,
  disablePaymentButton: PropTypes.bool,
  disableRefundButton: PropTypes.bool,
  firstConversionValidation: PropTypes.bool,
  disableFirstConversion: PropTypes.bool,
  firstConversionTo: PropTypes.string,
  afterFirstConversion: PropTypes.string,
  beforeFirstConversion: PropTypes.string,
  secondConversionValidation: PropTypes.bool,
  disableSecondConversion: PropTypes.bool,
  secondConversionTo: PropTypes.string,
  afterSecondConversion: PropTypes.string,
  beforeSecondConversion: PropTypes.string,
  debitNoteValidations: PropTypes.bool,
  creditNoteValidations: PropTypes.bool,
  disableCreditNote: PropTypes.bool,
  disableDebitNote: PropTypes.bool,
  creditNoteTo: PropTypes.string,
  type: PropTypes.string,
  item: PropTypes.bool,
  setBarcodeModel: PropTypes.func,
  setRecurring: PropTypes.func,
  disableRecurring: PropTypes.bool,
  recurring: PropTypes.bool,
  issueItems: PropTypes.bool,
  setIssueItem: PropTypes.func,
  emailPermission: PropTypes.bool,
  convertToWorkOrder: PropTypes.any,
  changeStatus: PropTypes.bool,
  setStatus: PropTypes.func,
  disableStatus: PropTypes.bool,
  debitNoteTo: PropTypes.string,
  debitNoteButtonText: PropTypes.string,
  creditNoteButtonName: PropTypes.string,
  fileUploadButtonTitle: PropTypes.string,
  clonePath: PropTypes.string,
  showOpeningStockModal: PropTypes.bool,
  setOpeningStockModal: PropTypes.any,
  revise: PropTypes.any,
  rentalToInvoice: PropTypes.bool,
  additionalInvoice: PropTypes.bool,
  handleCustomerStatus: PropTypes.func,
  customerStatus: PropTypes.any,
  voidTransactionData: PropTypes.shape({
    id: PropTypes.string,
    trans_type: PropTypes.string,
    callBack: PropTypes.func
  }),
  additionalCost: PropTypes.shape({
    id: PropTypes.string,
    trans_type: PropTypes.string,
    callBack: PropTypes.func
  }),
  proposalData: PropTypes.shape({
    data: PropTypes.any,
    sqId: PropTypes.string
  })
};

export const EditButton = ({ to, disable, message }) => {
  const Translate = useAxisproTranslate();

  return (
    <OverlayTrigger
      placement={'bottom'}
      overlay={
        <Tooltip>
          <span className="text-white fs--2">
            {disable
              ? Translate(
                  message
                    ? message
                    : 'Editing this invoice is not possible because a payment has been made against it.'
                )
              : Translate('Edit')}
          </span>
        </Tooltip>
      }
    >
      <Button
        size="sm"
        variant="info"
        className="px-2"
        as={Link}
        to={to}
        disabled={disable}
      >
        <BsPencilSquare className="text-white" />
      </Button>
    </OverlayTrigger>
  );
};

EditButton.propTypes = {
  to: PropTypes.string,
  disable: PropTypes.bool,
  message: PropTypes.any
};

export const CloseButton = ({ onHide, setShowVoucherDetailArea }) => {
  const Translate = useAxisproTranslate();
  const clickOnCloseButton = () => {
    if (setShowVoucherDetailArea) {
      setShowVoucherDetailArea(false);
    }

    if (onHide) {
      onHide();
    }
  };
  return (
    <Button
      size="sm"
      variant="transaparent"
      className="px-2"
      style={{
        backgroundColor: 'whitesmoke',
        border: '1px solid rgb(212, 210, 210)',
        boxShadow: 'none'
      }}
      onClick={clickOnCloseButton}
      title={Translate('Close')}
    >
      <MdOutlineClose size={15} />
    </Button>
  );
};

CloseButton.propTypes = {
  onHide: PropTypes.func,
  setShowVoucherDetailArea: PropTypes.func
};

export const LinkPersonButton = ({ onClick, disable, title }) => {
  const Translate = useAxisproTranslate();

  return (
    <OverlayTrigger
      placement={'bottom'}
      overlay={
        <Tooltip>
          <span className="text-white fs--2">{Translate(title)}</span>
        </Tooltip>
      }
    >
      <Button
        size="sm"
        variant="success"
        className="px-2"
        onClick={onClick}
        disabled={disable}
      >
        <FaUserPlus className="text-white" />
      </Button>
    </OverlayTrigger>
  );
};

LinkPersonButton.propTypes = {
  disable: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string
};

export const DeleteButtonView = ({ onClick, disable, message }) => {
  const Translate = useAxisproTranslate();

  return (
    <OverlayTrigger
      placement={'bottom'}
      overlay={
        <Tooltip>
          <span className="text-white fs--2">
            {disable
              ? Translate(
                  message ? message : 'Deleting this is currently not possible.'
                )
              : Translate('Delete')}
          </span>
        </Tooltip>
      }
    >
      <Button
        size="sm"
        variant="danger"
        className="px-2"
        onClick={onClick}
        disabled={disable}
      >
        <BsFillTrashFill className="text-white" />
      </Button>
    </OverlayTrigger>
  );
};

DeleteButtonView.propTypes = {
  disable: PropTypes.bool,
  onClick: PropTypes.func,
  message: PropTypes.string
};

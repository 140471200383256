import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import HalfDoughnutChart from './HalfDoughnutChart';
import CountUp from 'react-countup';
import PropTypes from 'prop-types';
import Loader from 'module/Dashboard/Common/Loader';

function MonthlyRevenue({ title, dataArray, isLoading, progressPercentage }) {
  const [stat] = useState({
    target: 10000,
    reached: 9000
  });

  const parseKValue = value =>
    typeof value === 'string'
      ? parseFloat(value?.replace('K', '')) * 1000
      : value;

  const { total, received, order, delivered } = (
    Array.isArray(dataArray) ? dataArray : []
  ).reduce(
    (acc, item) => {
      acc[item?.label?.toLowerCase()] = parseKValue(item?.value);
      return acc;
    },
    { total: 0, received: 0, order: 0, delivered: 0 }
  );

  return (
    <Card className="h-100">
      <Card.Header className="pb-0">
        <h6 className="mb-0">{title ?? 'Monthly Revenue Target'}</h6>
      </Card.Header>

      <Card.Body className="pt-0" style={{ height: '9.75rem' }}>
        {isLoading ? (
          <Loader loader={[9, 7, 7, 5, 8]} className={'ps-4'} />
        ) : (
          <Row className="align-items-end h-100 mb-n1 mt-n3">
            <Col xs={5} className="pe-md-0 pe-lg-3">
              <Row className="g-0">
                {Array.isArray(dataArray) ? (
                  dataArray.map((data, index) => (
                    <Row className="g-0" key={index}>
                      <Col xs={7}>
                        <h6 className="text-600">{data?.label}: </h6>
                      </Col>
                      <Col xs={5}>
                        <h6 className="text-800">{data?.value} </h6>
                      </Col>
                    </Row>
                  ))
                ) : (
                  <>
                    <Row className="g-0">
                      <Col xs={7}>
                        <h6 className="text-600">Target: </h6>
                      </Col>
                      <Col xs={5}>
                        <h6 className="text-800">$1.2M </h6>
                      </Col>
                    </Row>

                    <Row className="g-0">
                      <Col xs={7}>
                        <h6 className="mb-0 text-600">Reached: </h6>
                      </Col>
                      <Col xs={5}>
                        <h6 className="mb-0 text-800">$823K </h6>
                      </Col>
                    </Row>
                  </>
                )}
              </Row>
            </Col>
            <Col xs={7}>
              <div
                className="ms-auto text-center"
                style={{ maxWidth: '10.625rem' }}
              >
                <div className="mb-n6">
                  <HalfDoughnutChart
                    color="dark"
                    target={total || order || stat?.target}
                    reached={received || delivered || stat?.reached}
                  />
                </div>
                <CountUp
                  start={0}
                  end={
                    progressPercentage ??
                    ((stat?.reached / stat?.target) * 100).toFixed(0)
                  }
                  duration={2.75}
                  className="d-inline-block mb-0 mt-n6 fs-1 fw-medium font-sans-serif"
                  suffix="%"
                  separator=","
                />
              </div>
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
}

MonthlyRevenue.propTypes = {
  title: PropTypes.string,
  dataArray: PropTypes.array,
  isLoading: PropTypes.bool,
  progressPercentage: PropTypes.any
};

export default MonthlyRevenue;

import React from 'react';
import './CardLoading.scss';
import PropTypes from 'prop-types';

const CardLoading = ({ width = '14.6rem' }) => {
  return (
    <div className="card-loader-container" style={{ width: width }}>
      <div className="card-loader-wrapper">
        <div className="card-loader-img loader-skeleton"></div>
        <div className="card-loader-body">
          <h2 className="card-loader-title loader-skeleton"></h2>
          <h2 className="card-loader-title loader-skeleton"></h2>
          <p className="card-loader-intro loader-skeleton"></p>
        </div>
      </div>
    </div>
  );
};

CardLoading.propTypes = {
  width: PropTypes.string
};

export default CardLoading;

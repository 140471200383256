import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

function EmiratesIDReaderButton({
  variant,
  className,
  setIsFetch,
  setFormData
}) {
  const Translate = useAxisproTranslate();

  const fetchDataFromEmiratesId = async () => {
    if (typeof setIsFetch === 'function') setIsFetch(true);
    try {
      const response = await fetch(
        'http://localhost:8080/EIDCardReader/EIDDataReader',
        {
          method: 'GET'
        }
      );
      if (!response.ok) {
        showToast(Translate(`Emirates Id reading failed!`, 'error'));
      }
      const dataFromEmiratesId = await response.json();
      const imageUrl = dataFromEmiratesId?.photo
        ? `data:image/png;base64,${dataFromEmiratesId.photo}`
        : '';
      setFormData(prev => ({
        ...prev,
        name: dataFromEmiratesId.fullNameEN,
        phone: dataFromEmiratesId.phoneNumber,
        email: dataFromEmiratesId.email,
        emirates_id: dataFromEmiratesId?.cardNumber,
        profile_image_base64: imageUrl
      }));
    } catch (error) {
      showToast(Translate(`Emirates Id reading failed!`, 'error'));
    } finally {
      if (typeof setIsFetch === 'function') setIsFetch(false);
    }
  };

  return (
    <Button
      size="sm"
      variant={variant ?? 'dark'}
      className={`${className}`}
      style={{ fontSize: '12px' }}
      onClick={fetchDataFromEmiratesId}
      title={Translate('Click here to read from Emirates ID')}
    >
      {Translate('Emirates ID Reader')}
    </Button>
  );
}

EmiratesIDReaderButton.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  setIsFetch: PropTypes.func,
  setFormData: PropTypes.func
};

export default EmiratesIDReaderButton;

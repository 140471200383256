import Avatar from 'components/common/Avatar';
import { FaExclamationCircle } from 'react-icons/fa';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Collapse,
  Form,
  OverlayTrigger,
  Popover,
  Spinner
} from 'react-bootstrap';
import { FaUserLock } from 'react-icons/fa';
import { FaCircleCheck } from 'react-icons/fa6';
import {
  _validateConfirmPassword,
  _validatePassword
} from 'module/System Admin/UserRegistration/Common/InputValidators';
import { showToast } from 'module/Common/Toast/toast';
import axios from 'axios';
import PasswordSuccessModal from './PasswordSuccessModal';
import { useNavigate } from 'react-router-dom';
import { AuthWizardContext } from 'context/Context';
import { useBroadcastChannel } from 'providers/BroadcastChannelContextProvider';

const PasswordExpiryScreen = () => {
  const broadcastChannel = useBroadcastChannel();
  const navigate = useNavigate();
  let { dispatch, user } = useContext(AuthWizardContext);
  const [isSaving, setIsSaving] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showPasswordPolicy, setShowPasswordPolicy] = useState(false);
  const [formData, setFormData] = useState({
    new_password: '',
    c_password: '',
    old_password: ''
  });
  const [valid, setValid] = useState({
    password: [],
    c_password: true
  });

  const [formError, setFormError] = useState({});
  const Translate = useAxisproTranslate();
  const handleChange = e => {
    setFormData(prev => ({
      ...prev,
      [e?.target?.name]: e?.target?.value ?? ''
    }));
  };
  const updateValid = dataWithPassword => {
    setValid(previousState => ({
      ...previousState,
      ...dataWithPassword
    }));
  };
  const handleFocus = () => {
    setShowPasswordPolicy(true);
  };

  const handleBlur = () => {
    setShowPasswordPolicy(false);
  };
  useEffect(() => {
    updateValid({
      password: _validatePassword(
        formData?.new_password,
        user?.username,
        user?.branch?.system_settings ?? ''
      )
    });
    updateValid({
      c_password: _validateConfirmPassword(
        formData?.new_password,
        formData?.c_password
      )
    });
  }, [
    formData?.new_password,
    formData?.c_password,
    user?.branch?.system_settings,
    user?.username
  ]);
  const hasErrors = valid?.password?.some(error => error.error);
  const handleSubmit = e => {
    setIsSaving(true);
    e.preventDefault();
    setFormError({});
    axios({
      method: 'post',
      url: `/change-password`,
      data: formData
    })
      .then(async response => {
        setIsSaving(false);
        if (response.data.success === true) {
          setShowSuccess(true);
          setTimeout(() => {
            localStorage.removeItem('AXIS_PRO_USER');
            dispatch({ type: 'DELETE' });
            navigate('/authentication/login');
            if (broadcastChannel) {
              broadcastChannel?.postMessage({
                action: 'LoggedOut'
              });
            }
          }, 2000);
        } else {
          setIsSaving(false);
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
      })
      .catch(error => {
        setIsSaving(false);
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
      });
  };
  useEffect(() => {
    if (!user) navigate('/authentication/login');
    if (user && !user?.password_expired) navigate('/dashboard');
  }, [user, navigate]);
  const popover = (
    <Popover id="popover-basic" className="w-100">
      <Popover.Body>
        <ul style={{ listStyle: 'none', fontSize: '11px' }}>
          {valid?.password.map((items, index) => (
            <li
              key={index}
              className={`d-flex  ${
                items?.error ? 'text-danger' : 'text-success'
              } mt-1`}
            >
              {items?.error ? (
                <FaExclamationCircle className=" mt-1" />
              ) : (
                <FaCircleCheck className="mt-1" />
              )}
              <div className="ms-2">{items?.message}</div>
            </li>
          ))}
        </ul>
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="d-flex align-items-center justify-content-center w-100 password-expiry-screen auth-screen-wrapper">
      <Card className="m-0 p-0 expiry-card zIndex-1">
        <Card.Header className=" pe-4">
          <div
            className="d-flex align-items-center text-warning"
            style={{ fontSize: '12px' }}
          >
            {' '}
            <FaUserLock size={18} className="me-1  " />
            {Translate(
              'Your Password Has Been Expired. Please Change your Password'
            )}
          </div>
        </Card.Header>
        <Card.Body className="bg-light">
          <div className="w-100 d-flex flex-column align-items-center justify-content-center mb-2 mt-3">
            <Avatar src={user?.image} size="4xl" />
            <div className="  mt-2 fw-bold">{user?.username}</div>
          </div>
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="confirmPassword">
              <Form.Label>{Translate('Old Password')}</Form.Label>
              <Form.Control
                type="Password"
                value={formData?.old_password}
                name="old_password"
                onChange={handleChange}
                isInvalid={!!formError?.old_password}
              />
              <Form.Control.Feedback type="invalid">
                {formError?.old_password}{' '}
              </Form.Control.Feedback>
            </Form.Group>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={valid?.password.length > 0 ? popover : <></>}
              show={showPasswordPolicy && hasErrors}
            >
              <Form.Group className="mb-3" controlId="newPassword">
                <Form.Label>{Translate('New Password')}</Form.Label>
                <Form.Control
                  type="password"
                  value={formData?.new_password}
                  name="new_password"
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  isInvalid={
                    !!(
                      formError?.new_password ||
                      (hasErrors && formData?.new_password)
                    )
                  }
                  autocomplete="new-password"
                />
                <Form.Control.Feedback type="invalid">
                  {formError?.new_password}{' '}
                </Form.Control.Feedback>
              </Form.Group>
            </OverlayTrigger>
            <Form.Group className="mb-3" controlId="confirmPassword">
              <Form.Label>{Translate('Confirm Password')}</Form.Label>
              <Form.Control
                type="password"
                value={formData?.c_password}
                name="c_password"
                onChange={handleChange}
                isInvalid={
                  !!(
                    formError?.c_password ||
                    (formData?.c_password !== '' && !valid.c_password)
                  )
                }
                autocomplete="new-password"
              />
              <Form.Control.Feedback type="invalid">
                {formError?.c_password}{' '}
              </Form.Control.Feedback>
              <Collapse in={formData?.c_password !== '' && !valid.c_password}>
                <p className="text-danger mb-0 fs--2">
                  {Translate('Password and Confirm Password must be same!')}
                </p>
              </Collapse>
            </Form.Group>
            <Button
              size="sm"
              className="w-100 text-small text-sm"
              type="submit"
              disabled={
                hasErrors || formData.c_password !== formData.new_password
                  ? true
                  : false
              }
            >
              {isSaving ? (
                <span className="d-flex align-items-center justify-content-center">
                  {' '}
                  <Spinner animation="border" size="sm" className="me-2" />{' '}
                  {Translate('Loading')}....
                </span>
              ) : (
                Translate('Update Password')
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
      {showSuccess && (
        <PasswordSuccessModal show={showSuccess} setShow={setShowSuccess} />
      )}
    </div>
  );
};

export default PasswordExpiryScreen;

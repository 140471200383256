import { React, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { BiLogoGmail } from 'react-icons/bi';
import { FaCentercode, FaPhoneAlt, FaWarehouse } from 'react-icons/fa';
import {
  MdOutlineLocalShipping,
  MdOutlineMan3,
  MdPayment
} from 'react-icons/md';
import { BsCoin } from 'react-icons/bs';

import { StoreContext } from 'context/Context';
import LineItemTable from '../Common/Tables/LineItemTable';
import ShowCustomFieldValues from '../Common/ViewPageDetails/ShowCustomFieldValues';
import PersonName from '../Common/ViewPageDetails/PersonName';
import Badges from '../Common/ViewPageDetails/Badges';
import Details from '../Common/ViewPageDetails/Details';
import QuillPreviewContainer from 'components/QuillPreviewContainer/QuillPreviewContainer';
import { IoDocumentTextSharp } from 'react-icons/io5';

function SalesDeliveryView({ salesDeliveryData }) {
  const { store } = useContext(StoreContext);
  const showPrice =
    store?.globalConfiguration?.sales_settings?.show_price_on_sales_delivery ===
    '1'
      ? false
      : true;
  const taxLabel = store?.globalConfiguration?.tax_label || 'Tax';

  return (
    <Container className="mt-3 ps-3 pe-3 voucher-inquiry-view-wrapper">
      <Row>
        <Col lg={6} md={6} sm={12} className="p-0">
          {salesDeliveryData?.customer_name ? (
            <PersonName value={salesDeliveryData?.customer_name} />
          ) : null}
          {salesDeliveryData?.reference && (
            <Badges label={salesDeliveryData?.reference} bg={'danger'} />
          )}
          <div className="mt-2">
            {salesDeliveryData?.customer_lpo_number && (
              <Details
                label={'Customer LPO Number'}
                value={salesDeliveryData?.customer_lpo_number}
                className={'mb-2 fs--1'}
                iconColor={'text-info'}
              />
            )}
            {salesDeliveryData?.customer_lpo_date_formatted && (
              <Details
                label={'Customer LPO Date'}
                value={salesDeliveryData?.customer_lpo_date_formatted}
                className={'mb-2 fs--1'}
                iconColor={'text-warning'}
              />
            )}
            {salesDeliveryData?.email && (
              <Details
                label={'Email Address'}
                value={salesDeliveryData?.email}
                icon={<BiLogoGmail className="me-1 text-danger" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
            {salesDeliveryData?.mobile && (
              <Details
                label={'Phone Number'}
                value={salesDeliveryData?.mobile}
                icon={<FaPhoneAlt className="me-1 text-dark" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
            {salesDeliveryData?.sales_type_name && (
              <Details
                label={'Sales Type'}
                value={salesDeliveryData?.sales_type_name}
                className={'mb-2 fs--1'}
                iconColor={'text-info'}
              />
            )}
            {salesDeliveryData?.cost_center_name && (
              <Details
                label={'Cost Center'}
                value={salesDeliveryData?.cost_center_name}
                className={'mb-2 fs--1'}
                icon={<FaCentercode className="me-1 text-warning" size={15} />}
              />
            )}
            {salesDeliveryData?.payment_term_name && (
              <Details
                label={'Payment Term'}
                value={salesDeliveryData?.payment_term_name}
                className={'mb-2 fs--1'}
                icon={<MdPayment className="me-1 text-success" size={15} />}
              />
            )}
            {salesDeliveryData?.salesman_name && (
              <Details
                label={'Salesman Name'}
                value={salesDeliveryData?.salesman_name}
                className={'mb-2 fs--1'}
                icon={<MdOutlineMan3 className="me-1 text-dark" size={15} />}
              />
            )}
            {salesDeliveryData?.warehouse_name && (
              <Details
                label={'Delivery Location'}
                value={salesDeliveryData?.warehouse_name}
                className={'mb-2 fs--1'}
                icon={<FaWarehouse className="me-1 text-dark" size={15} />}
              />
            )}
            {salesDeliveryData?.fa_stock_id && (
              <Details
                label={'Asset'}
                value={salesDeliveryData?.fa_stock_name}
                className={'mb-2 fs--1 text-capitalize'}
                icon={
                  <IoDocumentTextSharp
                    className="me-1  text-primary"
                    size={15}
                  />
                }
              />
            )}
            {salesDeliveryData?.customer_shipping_address && (
              <Details
                label={'Shipping Address'}
                value={[
                  salesDeliveryData?.customer_shipping_address?.address1,
                  salesDeliveryData?.customer_shipping_address?.city,
                  salesDeliveryData?.customer_shipping_address?.state,
                  salesDeliveryData?.customer_shipping_address?.zip_code,
                  salesDeliveryData?.customer_shipping_address?.country
                ]
                  .filter(Boolean)
                  .join(', ')}
                className={'mb-2 fs--1'}
                icon={
                  <MdOutlineLocalShipping
                    className="me-1 text-dark"
                    size={16}
                  />
                }
              />
            )}
          </div>
        </Col>
        {!showPrice ? (
          <Col lg={6} md={6} sm={12} className="p-0">
            <div className="mt-sm-2 mt-md-5">
              <Details
                label={
                  salesDeliveryData?.tax_included &&
                  salesDeliveryData?.tax_included === '1'
                    ? `Amount (Inc.${taxLabel})`
                    : 'Amount'
                }
                value={salesDeliveryData?.amount_formatted ?? '0.00'}
                className={'mb-2 fs--1'}
                icon={<BsCoin className="me-1 text-dark" size={15} />}
              />
              <Details
                label={`Total ${taxLabel}`}
                value={salesDeliveryData?.total_tax_formatted ?? '0.00'}
                className={'mb-2 fs--1'}
                icon={<BsCoin className="me-1 text-dark" size={15} />}
              />
              <Details
                label={'Total Discount'}
                value={
                  salesDeliveryData?.total_discount_amount_formatted ?? '0.00'
                }
                className={'mb-2 fs--1'}
                icon={<BsCoin className="me-1 text-dark" size={15} />}
              />
            </div>
          </Col>
        ) : null}
      </Row>
      {salesDeliveryData?.custom_field_values &&
        salesDeliveryData?.custom_field_values.length > 0 && (
          <Row className="mt-3">
            {salesDeliveryData?.custom_field_values.map((item, index) => (
              <ShowCustomFieldValues key={index} data={item} />
            ))}
          </Row>
        )}
      <Row>
        <Col xl={12} md={12} sm={12} className="p-0 mt-2">
          <LineItemTable data={salesDeliveryData} batch showPrice={showPrice} />
        </Col>
      </Row>
      {salesDeliveryData?.memo && (
        <QuillPreviewContainer
          title="Notes :"
          value={salesDeliveryData.memo}
          dashedBorder
        />
      )}
    </Container>
  );
}

SalesDeliveryView.propTypes = {
  salesDeliveryData: PropTypes.any
};

export default SalesDeliveryView;

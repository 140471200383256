import React, { Fragment, useContext } from 'react';
import { Accordion, Badge, Button, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import EstimationLineItemsAccordionViewCostItem from './EstimationLineItemsAccordionViewCostItem';
import { FaClone, FaEdit } from 'react-icons/fa';
import { FaTrash } from 'react-icons/fa6';
import { DeleteContext } from 'context/Context';
import EstimateEntryProductInformationTitleSection from '../EstimateEntryProductInformationTitleSection';

export default function EstimationLineItemsAccordionView({
  entries,
  setFormData,
  handleSaveDraftData,
  disableClone = false,
  activeSubSections = [],
  handleCloneLineItem
}) {
  const Translate = useAxisproTranslate();
  const { dispatch: configConfirm } = useContext(DeleteContext);

  function handleEdit(event, ItemIndex) {
    event?.preventDefault();
    setFormData(prev => ({
      ...prev,
      details: Array.isArray(prev?.details)
        ? prev.details.map((entry, index) => {
            delete entry?.edit;
            if (index === ItemIndex) {
              entry.edit = true;
            }
            return entry;
          })
        : prev?.details
    }));
  }

  function removeSelectedItem(dataArray, index) {
    if (Array.isArray(dataArray) && dataArray?.[index]) {
      dataArray.splice(index, 1);
    }
    return dataArray;
  }

  function handleRemove(event, ItemIndex) {
    event.preventDefault();
    configConfirm({
      type: 'CONFIG',
      payload: {
        show: true,
        title: Translate('Delete selected item'),
        message: Translate(
          'Are you sure you want to delete the selected line item?'
        ),
        submitButtonVariant: 'danger',
        buttonText: Translate('Delete'),
        cancelButtonVariant: 'secondary',
        onSubmit: event => {
          event?.preventDefault();

          setFormData(prev => {
            const newData = {
              ...prev,
              details: Array.isArray(prev?.details)
                ? removeSelectedItem(prev.details, ItemIndex)
                : prev?.details
            };

            if (typeof handleSaveDraftData === 'function') {
              handleSaveDraftData({ draftableData: newData });
            }

            return newData;
          });

          configConfirm({
            type: 'RESET'
          });
        }
      }
    });
  }

  return (
    <Accordion defaultActiveKey={activeSubSections} alwaysOpen>
      <Table
        bordered
        responsive
        size="sm"
        className="simple-table-animation mb-0"
      >
        <thead
          className="bg-light text-dark text-uppercase shadow-sm"
          style={{
            fontSize: '.76rem'
          }}
        >
          <EstimateEntryProductInformationTitleSection />
        </thead>
        <tbody
          style={{
            fontSize: '.72rem',
            backgroundColor: '#f2f5f7'
          }}
        >
          {entries?.map((entry, index) => (
            <Fragment key={index}>
              {index > 0 ? (
                <EstimateEntryProductInformationTitleSection className="bg-light text-dark text-uppercase shadow-sm" />
              ) : null}
              <tr className="text-muted fw-bold">
                <td>
                  <Badge className="rounded">{index + 1}</Badge>
                </td>
                <td>{entry?.stock_id ?? '-'}</td>
                <td>{entry?.title_ref?.label ?? '-'}</td>
                <td>
                  {entry?.dim_length
                    ? `${entry.dim_length} ${entry?.dimension_unit || ''}`
                    : '-'}
                </td>
                <td>
                  {entry?.dim_width
                    ? `${entry.dim_width} ${entry?.dimension_unit || ''}`
                    : '-'}
                </td>
                <td>
                  {entry?.dim_height
                    ? `${entry.dim_height} ${entry?.dimension_unit || ''}`
                    : '-'}
                </td>
                <td>{entry?.weight || '-'}</td>
                <td className="border-end-0">
                  {entry?.quantity
                    ? `${entry.quantity} ${entry?.unit_name_ref || ''}`
                    : '-'}
                </td>
                <td className="border-start-0">
                  <div className="d-flex flex-row align-items-center justify-content-end gap-1">
                    {typeof setFormData === 'function' ? (
                      <>
                        <Button
                          variant="transparent"
                          className="p-0 m-0 text-info outline-none shadow-none"
                          size="sm"
                          onClick={event => handleEdit(event, index)}
                        >
                          <FaEdit size={12} />
                        </Button>
                        <Button
                          variant="transparent"
                          className="p-0 m-0 text-danger outline-none shadow-none"
                          size="sm"
                          onClick={event => handleRemove(event, index)}
                        >
                          <FaTrash size={12} />
                        </Button>
                      </>
                    ) : null}
                    {!disableClone ? (
                      <Button
                        variant="transparent"
                        size="sm"
                        className="p-0 m-0 text-info outline-none shadow-none"
                        title="Clone"
                        onClick={() => handleCloneLineItem(true, entry)}
                      >
                        <FaClone size={12} />
                      </Button>
                    ) : null}
                  </div>
                </td>
              </tr>

              <EstimationLineItemsAccordionViewCostItem
                title="MATERIALS / COSTS"
                eventKey={'maCostEvent' + entry?.id}
                entries={entry?.costs}
                subTotalFormatted={entry?.costs_net_amount_formatted}
              />

              <EstimationLineItemsAccordionViewCostItem
                title="ADDITIONAL COSTS"
                eventKey={'addCostEvent' + entry?.id}
                entries={entry?.additional_costs}
                subTotalFormatted={entry?.additional_costs_net_amount_formatted}
              />

              <tr
                className="bg-white"
                style={{
                  borderTop: '3px solid #e2e6eb',
                  borderBottom: 'none'
                }}
              >
                <td className="border-0 py-3" colSpan={9}></td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </Table>
    </Accordion>
  );
}

EstimationLineItemsAccordionView.propTypes = {
  entries: PropTypes.array,
  setFormData: PropTypes.func,
  handleSaveDraftData: PropTypes.func,
  disableClone: PropTypes.bool,
  activeSubSections: PropTypes.array,
  handleCloneLineItem: PropTypes.func
};

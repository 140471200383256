import React, { useContext } from 'react';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { Badge, Tab, Tabs } from 'react-bootstrap';
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { removeParamsFunc } from 'module/Common/Hooks/useRemoveParams';
import PropTypes from 'prop-types';
import { StoreContext } from 'context/Context';
const ApprovalSectionTab = ({ data, info }) => {
  const navigate = useNavigate();
  const { store } = useContext(StoreContext);

  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const Translate = useAxisproTranslate();
  const status = queryParams.get('status');
  return (
    store?.globalConfiguration?.system_settings?.enable_approval_transaction ===
      '1' && (
      <Tabs
        id="controlled-tab-example"
        activeKey={status ?? 'approved-request'}
        onSelect={tabName =>
          navigate(
            `?${createSearchParams(
              removeParamsFunc(
                {
                  ...allQueryParams,
                  status: tabName
                },
                ['page', 'entry']
              )
            )}`
          )
        }
        color="red"
        className={`${
          info && ' d-flex align-items-center justify-content-around'
        } approval-tab`}
      >
        <Tab
          eventKey="approved-request"
          title={Translate('Approved Request')}
        ></Tab>
        <Tab
          eventKey="pending"
          title={
            <>
              {Translate('Pending')}
              {data?.pending_count > 0 && (
                <Badge pill bg="danger" className="ms-1">
                  +{data?.pending_count}
                </Badge>
              )}
            </>
          }
        ></Tab>
        <Tab
          eventKey="rejected"
          title={
            <>
              {Translate('Rejected')}
              {data?.reject_count > 0 && (
                <Badge pill bg="danger" className="ms-1">
                  +{data?.reject_count}
                </Badge>
              )}
            </>
          }
        ></Tab>
      </Tabs>
    )
  );
};
ApprovalSectionTab.propTypes = {
  data: PropTypes.any,
  info: PropTypes.bool
};
export default ApprovalSectionTab;

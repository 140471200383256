import { React, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FcNoIdea } from 'react-icons/fc';
import PropTypes from 'prop-types';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import Loader from 'module/Dashboard/Common/Loader';
import Flex from 'components/common/Flex';
import { totalSales } from 'module/Dashboard/Common/LoaderData';
import { FaMinusCircle } from 'react-icons/fa';
import TotalPurchaseChart from './TotalPurchaseChart';

const invoicesArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
const receiptsArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
function TotalPurchase({ draggable }) {
  const chartRef = useRef(null);
  const Translate = useAxisproTranslate();
  const [data, setData] = useState({
    invoice: [],
    payment: []
  });
  const [loading, setLoading] = useState(false);

  const handleLegendToggle = (event, name) => {
    chartRef.current.getEchartsInstance().dispatchAction({
      type: 'legendToggleSelect',
      name
    });
    event.target.closest('button').classList.toggle('opacity-50');
  };

  const getMonthIndex = month => {
    const monthIndex = [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC'
    ].indexOf(month);
    return monthIndex;
  };

  useEffect(() => {
    const fetchData = () => {
      axios
        .get('purchase/summary')
        .then(res => {
          if (res?.data?.success) {
            let invoioces = res?.data?.data.map(item => item.invoice);
            let payment = res?.data?.data.map(item => item.payment);
            const updatedInvoiceArray = [...invoicesArray];
            invoioces.forEach(obj => {
              const index = getMonthIndex(obj.month);
              if (index !== -1) {
                updatedInvoiceArray[index] = Math.round(obj.amount);
              }
            });
            const updatedPaymentArray = [...receiptsArray];
            payment.forEach(obj => {
              const index = getMonthIndex(obj.month);
              if (index !== -1) {
                updatedPaymentArray[index] = Math.round(obj.amount);
              }
            });
            setData({
              invoice: updatedInvoiceArray,
              payment: updatedPaymentArray
            });
            setLoading(false);
          }
        })
        .catch(error => console.log(error));
    };

    setLoading(true);
    fetchData();
  }, []);

  return (
    <Card className={`h-100 ${draggable && 'dashboard_shake'}`}>
      {draggable && (
        <FaMinusCircle
          size={19}
          className="position-absolute top-0 end-0 text-warning"
        />
      )}
      <Card.Header>
        <Row className="flex-between-center g-0">
          <Col xs="auto">
            <h6 className="mb-0">{Translate('Total Purchase')}</h6>
          </Col>
          <Col md="auto" className="order-1 order-md-0">
            <Flex className="flex-wrap gap-md-2">
              <Button
                variant="text"
                size="sm"
                className="d-flex align-items-center p-0 shadow-none"
                onClick={event => handleLegendToggle(event, 'Invoice')}
              >
                <FontAwesomeIcon
                  icon="circle"
                  className="text-primary fs--2 me-1"
                  transform="shrink-3"
                />
                <small className="text-600">{Translate('Invoice')}</small>
              </Button>
              <Button
                variant="text"
                size="sm"
                className="d-flex align-items-center p-0 shadow-none ms-2"
                onClick={event => handleLegendToggle(event, 'Payment')}
              >
                <FontAwesomeIcon
                  icon="circle"
                  className="text-warning fs--2 me-1"
                  transform="shrink-3"
                />
                <small className="text-600">{Translate('Payment')}</small>
              </Button>
            </Flex>
          </Col>
        </Row>
      </Card.Header>
      {loading ? (
        <Loader loader={totalSales} className={'ps-4'} />
      ) : data &&
        data.invoice &&
        data.invoice.length > 0 &&
        data.payment &&
        data.payment.length > 0 ? (
        <Card.Body className="pb-0 ps-1">
          <TotalPurchaseChart
            data={data}
            ref={chartRef}
            style={{ 'min-height': '18.625rem', width: '100%' }}
          />
        </Card.Body>
      ) : (
        <Card.Body className="pb-0 d-flex flex-column justify-content-center align-items-center">
          <FcNoIdea size={30} />
          {Translate('No Data Available')}
        </Card.Body>
      )}
    </Card>
  );
}
TotalPurchase.propTypes = {
  draggable: PropTypes.bool
};
export default TotalPurchase;

import axios from 'axios';
import includeCurrentDateToFormData from 'helpers/includeCurrentDateToFormData';
import removeRefData from 'helpers/removeRefData';
import setNewFormData from 'helpers/setNewFormData';
import { showToast } from 'module/Common/Toast/toast';
import checkDueDateIsValid from 'module/Purchase/common/helpers/checkDueDateIsValid';
import handlePurchaseModuleFormDynamicValues, {
  setCostCenterRelatedToWarehouse
} from 'module/Purchase/common/helpers/handlePurchaseModuleFormDynamicValues';
import handleSetPurchaseFormDetailsData from 'module/Purchase/common/helpers/handleSetPurchaseFormDetailsData';
import { setPurchaseDetailsItemsTaxGroupDataV2 } from 'module/Purchase/common/helpers/setPurchaseDetailsItemsTaxGroupData';
import setSupplierDetailsToFormData from 'module/Purchase/common/helpers/setSupplierDetailsToFormData';

export default async (formData, config = {}) => {
  const { navigate, method, targetId } = config;

  let url = 'finance/debit-notes';
  const detailsData = await handleSetPurchaseFormDetailsData({
    detailsArray: formData?.details
  });

  let formDataWithoutRefkeys = {
    ...removeRefData(formData, 'object', {
      removeValue: [null, 'null']
    }),
    details: detailsData?.validItems
  };

  if (method === 'PUT') {
    formDataWithoutRefkeys._method = 'PUT';
    url = url + `/${targetId}`;
  }

  let newFormData = setNewFormData(formDataWithoutRefkeys);

  const postResponse = await axios.postForm(url, newFormData);

  const postResponseData = postResponse?.data;
  const debitNoteDetails = postResponseData?.data;
  const lastCreatedRecoardId =
    method === 'PUT' ? targetId : debitNoteDetails?.id;
  if (!lastCreatedRecoardId) {
    throw new Error(
      'Something went wrong, please refresh the page and try again.'
    );
  }

  if (postResponseData?.message) {
    showToast(postResponseData.message, 'success');
  }

  navigate(`/purchase/debit-note?entry=${lastCreatedRecoardId}&layout=column`, {
    replace: true
  });
};

export const debitNoteFormDataModifier = (
  event,
  action,
  formData,
  options = {}
) => {
  const {
    user,
    enableTaxGroup,
    setIsFetchingDetails,
    setEntryTableShowLoading,
    roundOffMaxLimit
  } = options;
  const keyName = action?.name || event?.target?.name;

  const asyncModifier = async () => {
    try {
      if (keyName === 'person_id') {
        const supplierID = formData?.person_id;

        setEntryTableShowLoading && setEntryTableShowLoading(true);
        const { supplierDetails } = await setSupplierDetailsToFormData({
          supplierID,
          formData
        });

        if (enableTaxGroup) {
          await setPurchaseDetailsItemsTaxGroupDataV2({
            supplierDetails,
            formData
          });
        }
      }

      await handlePurchaseModuleFormDynamicValues(
        event,
        action,
        formData,
        user,
        [
          'applyTransdateAndTaxIncludedChanges',
          'setSuppliersDefaultPaymentTermAsDefaultPaymentTermValue',
          'setDueDateRelatedToPaymentTermDays',
          'setPhoneAndEmailFromSupplierDetails',
          'setCostCenterRelatedToWarehouse'
        ],
        'DebitNote',
        {
          setShowLoadingScreen: setIsFetchingDetails
        }
      );

      if (keyName === 'person_id' && 'phone' in formData) {
        formData.mobile = formData.phone;
        delete formData.phone;
      }

      if (['trans_date', 'due_date'].includes(keyName)) {
        await checkDueDateIsValid(formData?.trans_date, formData?.due_date);
      }

      return formData;
    } catch (error) {
      if (error) {
        throw error;
      }
    } finally {
      if (keyName === 'person_id' && setEntryTableShowLoading) {
        setEntryTableShowLoading(false);
      }
    }
  };

  if (
    [
      'person_id',
      'trans_date',
      'due_date',
      'tax_included',
      'payment_term_id',
      'warehouse_id'
    ].includes(keyName)
  ) {
    return asyncModifier;
  }

  if (
    keyName === 'round_off_amount' &&
    event?.target?.value > roundOffMaxLimit
  ) {
    throw new Error(
      `Rounding amount is greater than system rounding limit: ${roundOffMaxLimit}`
    );
  }

  return formData;
};

export const debitNoteFormDefaultValueModifier = async formData => {
  includeCurrentDateToFormData(formData, 'trans_date');
  formData = await setCostCenterRelatedToWarehouse(formData);
  return formData;
};

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Button, Form, Offcanvas, Spinner } from 'react-bootstrap';
import { FaMicrophone, FaPaperPlane, FaRobot } from 'react-icons/fa';
import './ai_chatbot.scss';
import axios from 'axios';
import ChatInitialHelpers from './ChatInitialHelpers';
import ChatRequiredParamsList from './ChatRequiredParamsList';
import removeRefData from 'helpers/removeRefData';

export default function AIChatBot() {
  const Translate = useAxisproTranslate();
  const messageResultArea = useRef(null);
  const messageLoadingItem = useRef(null);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lastKey, setLastKey] = useState(undefined);
  const [question, setQuestion] = useState('');
  const [dataList, setDataList] = useState([]);

  function handleShow() {
    setShow(!show);
  }
  const scrollToBottom = () => {
    if (messageResultArea.current) {
      messageResultArea.current.scrollTop =
        messageResultArea.current.scrollHeight;
    }
  };
  function handleMessageResultAreaAutoScroll() {
    if (messageResultArea.current?.lastElementChild) {
      messageResultArea.current.lastElementChild.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      });
    }
  }

  function setApiResult(data, question = '', error = false) {
    const dataId = `id${dataList.length + 1}_${Date.now()}`;

    const responseData = error
      ? {
          text: 'Something Went Wrong!'
        }
      : data?.formattedResponse;

    setDataList(prev => [
      ...prev,
      {
        id: dataId,
        type: 'answer',
        prompt: question,
        data: responseData,
        params_required: error
          ? []
          : Object.keys(data?.rawResponse?.paramsRequired || {}),
        isFunctionCall: data?.isFunctionCall || error,
        isJsonResult: data?.isJsonResult || false,
        showHelpers: error
      }
    ]);

    handleMessageResultAreaAutoScroll();
    setIsLoading(false);
  }

  async function handleQuestion(question, params = null, id = null) {
    if (typeof question !== 'string' || question.length < 1 || isLoading)
      return;
    setQuestion('');
    setIsLoading(true);
    setDataList(prev => [
      ...prev,
      {
        type: 'question',
        value: question
      }
    ]);
    if (id && params) {
      setDataList(prev =>
        prev.reduce((newResult, item) => {
          if (item.id === id) {
            item.params = params;
            item.submitted = true;
          }
          newResult.push(item);
          return newResult;
        }, [])
      );
    }

    axios({
      method: 'POST',
      url: '/ask-ai',
      header: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      data: {
        prompt: params ? JSON.stringify(removeRefData(params)) : question
      }
    })
      .then(response => {
        if (response?.data) {
          setApiResult(response.data, question);
          handleMessageResultAreaAutoScroll();
        }
      })
      .catch(error => {
        if (error?.response?.data?.rawResponse) {
          setApiResult(error.response.data);
        } else {
          setApiResult(question, error.response.data, true);
        }
      });
  }

  function handleSubmit(event, customQuestion) {
    event.preventDefault();
    handleQuestion(customQuestion ?? question);
  }

  function handleTextAreaOnEnter(event) {
    if (event.key !== 'Enter') setLastKey(event.key);
    if (lastKey !== 'Control' && event.key === 'Enter') {
      handleQuestion(question);
      setQuestion('');
    } else if (lastKey === 'Control' && event.key === 'Enter') {
      setQuestion(prev => prev + '\n');
    }
  }

  useEffect(() => {
    handleMessageResultAreaAutoScroll();
    if (messageLoadingItem.current) {
      handleMessageResultAreaAutoScroll();
    }
  }, [isLoading]);

  useEffect(() => {
    if (show) {
      setTimeout(scrollToBottom, 100);
    }
  }, [show]);
  return (
    <div className="position-relative">
      <Button
        variant="transparent"
        onClick={handleShow}
        className={`px-1 py-0 nav-link download-center-button ai-chatbot-driver-element`}
        style={{ boxShadow: 'none' }}
      >
        <FaRobot title={Translate('AI Chatbot')} size={20} />
      </Button>

      <Offcanvas
        show={show}
        onHide={handleShow}
        placement="end"
        className="ai-chatbot-cover"
      >
        <Offcanvas.Header closeButton className="border-bottom chatbot-header">
          <Offcanvas.Title className="fw-bold mb-0 d-flex flex-row align-items-center">
            <FaRobot className="me-2" style={{ color: '#c1c1c1' }} size={18} />
            <span>{Translate('AI Chatbot')}</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="d-flex flex-column content-area">
          <div className="chat-response-area flex-fill" ref={messageResultArea}>
            <div className="chat-welcome-area d-flex flex-column align-items-center">
              <div className="py-5 d-flex flex-column align-items-center">
                <div className="chat-icon">
                  <FaRobot size={20} />
                </div>
                <p className="title">{Translate('AcoBot')}</p>
              </div>

              <ChatInitialHelpers handleSubmit={handleSubmit} />

              <p className="small-message">
                {Translate(
                  'AcoBot is powered by AI, so surprises and mistakes are possible.'
                )}
              </p>

              <div className="pt-5 d-flex flex-column chat-items w-100">
                {Array.isArray(dataList) &&
                  dataList.map((data, index) => {
                    return (
                      <>
                        <div className="d-flex ">
                          <div className="me-1">
                            {data.type === 'answer' && (
                              <FaRobot color="" size={16} />
                            )}
                          </div>
                          <div
                            className={`chat-item d-flex flex-row  ${
                              data.type === 'question'
                                ? 'ms-auto question'
                                : 'me-auto answer'
                            }`}
                            key={index}
                          >
                            {/* {data.type === 'question' ? (
                            ""
                          ) : (
                            <FaRobot className="mt-1" size={15}/>
                          )} */}
                            <div>
                              {data?.type === 'question' ? (
                                <p>{data.value}</p>
                              ) : data?.isFunctionCall &&
                                !data?.isJsonResult ? (
                                <p>{data?.data?.text}</p>
                              ) : data?.isFunctionCall && data?.isJsonResult ? (
                                <div className="data-para   mt-0">
                                  {Object.entries(data.data || {}).map(
                                    ([key, value], index) => (
                                      <p key={index}>
                                        {key}: {value}
                                      </p>
                                    )
                                  )}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        {Array.isArray(data?.params_required) &&
                        data.params_required.length > 0 ? (
                          <div className="d-flex flex-column">
                            <div className="">
                              <ChatRequiredParamsList
                                details={data}
                                requiredParams={data.params_required}
                                lastQuestion={dataList?.length === index + 1}
                                onSubmit={formData =>
                                  handleQuestion(
                                    formData.confirmation,
                                    formData,
                                    data?.id
                                  )
                                }
                              />
                            </div>
                          </div>
                        ) : null}
                        {/* 
                      {data.showHelpers ? (
                        <div className="d-flex flex-column">
                          <div className="me-auto">
                            <ChatInitialHelpers handleSubmit={handleSubmit} />
                          </div>
                        </div>
                      ) : null} */}
                      </>
                    );
                  })}
                {isLoading ? (
                  <div
                    className="chat-item d-flex flex-row align-items-center me-auto answer"
                    ref={messageLoadingItem}
                  >
                    <FaRobot />
                    <p className="spinners ms-2">
                      <Spinner animation="grow" size="sm" />
                      <Spinner animation="grow" size="sm" className="mx-1" />
                      <Spinner animation="grow" size="sm" />
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="content-end"></div>
          </div>
          <div className="chatbot-footer mt-auto rounded">
            <Form className="d-flex flex-row" onSubmit={handleSubmit}>
              <Form.Control
                className="rounded border-0"
                as="textarea"
                rows={4}
                placeholder="Ask me anything..."
                value={question}
                onChange={event => setQuestion(event.target.value)}
                onKeyDown={handleTextAreaOnEnter}
                readOnly={isLoading}
              />
              <div className="div d-flex flex-column">
                <Button
                  className="rounded my-2 me-1"
                  variant="transparent"
                  size="sm"
                  title="Use microphone"
                  disabled={isLoading}
                >
                  <FaMicrophone />
                </Button>
                <Button
                  className="rounded my-2 me-1 chatbot-form-submit-button"
                  variant="transparent"
                  size="sm"
                  title="Submit"
                  type="submit"
                  disabled={isLoading}
                >
                  <FaPaperPlane />
                </Button>
              </div>
            </Form>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

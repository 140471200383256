import { React, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { BsCalendar2Date, BsCoin } from 'react-icons/bs';

import LineItemTable from '../Common/Tables/LineItemTable';
import ShowCustomFieldValues from '../Common/ViewPageDetails/ShowCustomFieldValues';
import PersonName from '../Common/ViewPageDetails/PersonName';
import Badges from '../Common/ViewPageDetails/Badges';
import Details from '../Common/ViewPageDetails/Details';
import ShowMemo from '../Common/ViewPageDetails/ShowMemo';
import { StoreContext } from 'context/Context';

function ContractView({ contractData }) {
  const { store } = useContext(StoreContext);
  const taxLabel = store?.globalConfiguration?.tax_label || 'Tax';
  return (
    <Container className="mt-3 ps-3 pe-3 voucher-inquiry-view-wrapper">
      <Row>
        <Col lg={6} md={6} sm={12} className="p-0">
          {contractData?.customer_name ? (
            <PersonName value={contractData?.customer_name} />
          ) : null}
          {contractData?.reference && (
            <Badges label={contractData?.reference} bg={'danger'} />
          )}
          <div className="mt-2">
            {contractData?.title && (
              <Details
                label={'Title'}
                value={contractData?.title}
                className={'mb-2 fs--1'}
                iconColor={'text-info'}
              />
            )}
            {contractData?.contract_type && (
              <Details
                label={'Contract Type'}
                value={contractData?.contract_type}
                className={'mb-2 fs--1 text-capitalize'}
                iconColor={'text-danger'}
              />
            )}
            {contractData?.contract_from_date_formatted && (
              <Details
                label={'Start Date'}
                value={contractData?.contract_from_date_formatted}
                icon={<BsCalendar2Date className="me-1 text-dark" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
            {contractData?.contract_to_date_formatted && (
              <Details
                label={'End Date'}
                value={contractData?.contract_to_date_formatted}
                icon={<BsCalendar2Date className="me-1 text-dark" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
            {contractData?.sales_type && (
              <Details
                label={'Price List'}
                value={contractData?.sales_type}
                className={'mb-2 fs--1'}
                iconColor={'text-warning'}
              />
            )}
          </div>
        </Col>
        <Col lg={6} md={6} sm={12} className="p-0">
          <div className="mt-sm-2 mt-md-5">
            <Details
              label={
                contractData?.tax_included && contractData?.tax_included === '1'
                  ? `Amount (Inc.${taxLabel})`
                  : 'Amount'
              }
              value={contractData?.amount_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={`Total ${taxLabel}`}
              value={contractData?.total_tax_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={'Total Discount'}
              value={contractData?.total_discount_amount_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
          </div>
        </Col>
      </Row>
      {contractData?.custom_field_values &&
        contractData?.custom_field_values.length > 0 && (
          <Row className="mt-3">
            {contractData?.custom_field_values.map((item, index) => (
              <ShowCustomFieldValues key={index} data={item} />
            ))}
          </Row>
        )}
      <Row>
        <Col xl={12} md={12} sm={12} className="p-0 mt-2">
          <LineItemTable data={contractData} />
        </Col>
      </Row>
      {contractData?.description && (
        <ShowMemo memo={contractData?.description} label={'Description'} />
      )}
    </Container>
  );
}

ContractView.propTypes = {
  contractData: PropTypes.any
};

export default ContractView;

import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Pagination, Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FcNoIdea } from 'react-icons/fc';

import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.css';

import Flex from 'components/common/Flex';
import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from 'echarts/components';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { CanvasRenderer } from 'echarts/renderers';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import Loader from '../Common/Loader';
import { weeklySales } from '../Common/LoaderData';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer
]);

const WeeklySales = ({ amountClassName, draggable }) => {
  const Translate = useAxisproTranslate();
  const [weeklyData, setWeeklyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = () => {
    setIsLoading(true);
    axios
      .get('sales/get-weekly-sales-invoice-summary')
      .then(res => {
        if (res.data.success === true) {
          if (res.data.data.length > 0) {
            const updatedArray = res.data.data.map(obj => {
              const date = new Date();
              const year = date.getFullYear();
              var simple = new Date(year, 0, 1 + (obj.week - 1) * 7);
              var dow = simple.getDay();
              var ISOweekStart = simple;
              if (dow <= 4) {
                ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
              } else {
                ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
                return ISOweekStart;
              }
              let startDate = new Date(ISOweekStart).toDateString();
              const endDate = new Date(ISOweekStart);
              endDate.setDate(endDate.getDate() + 6);
              let convertedEndData = endDate.toDateString();
              return {
                ...obj,
                from: startDate,
                to: convertedEndData
              };
            });
            setWeeklyData(updatedArray);
          }
          setIsLoading(false);
        }
      })
      .catch(error => console.log(error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card className={`h-md-100 ${draggable && 'dashboard_shake'} weekly-sales`}>
      <Card.Header className="pb-0 ps-2">
        <h6 className="mb-0 ">
          {Translate('Weekly Sales')}
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{Translate('Calculate weekly sales')}</Tooltip>}
          >
            <span>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                transform="shrink-1"
                className="ms-1 text-400"
                id="weeklySalesTooltip"
              />
            </span>
          </OverlayTrigger>
        </h6>
      </Card.Header>
      {!isLoading && weeklyData ? (
        weeklyData.length > 0 ? (
          <Swiper
            style={{ width: '100%' }}
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false
            }}
            pagination={{
              clickable: true
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper pb-4 px-1"
          >
            {weeklyData.map((item, key) => (
              <SwiperSlide key={key}>
                <Card.Body
                  as={Flex}
                  alignItems="start"
                  justifyContent="between"
                  className="mb-0 flex-row pt-1 p-2"
                >
                  <div className="d-flex flex-column">
                    <SoftBadge
                      pill
                      bg="success"
                      className="me-2 mt-2 mb-1 fs--2"
                    >
                      {item.from}
                    </SoftBadge>
                    <SoftBadge pill bg="warning" className="me-2 mb-3 fs--2">
                      {item.to}
                    </SoftBadge>
                    <h2
                      className={classNames(
                        amountClassName,
                        'mb-1 text-dark fw-bold text-dark lh-1 fs-1'
                      )}
                    >
                      {item.total_invoiced_amount}
                    </h2>
                  </div>
                  <div>
                    <div className="d-flex flex-row align-items-center content-weekly justify-content-between gap-3">
                      <p className="m-0 fs--2">
                        <FontAwesomeIcon
                          icon="circle"
                          className="text-info fs--2 me-2"
                        />
                        {Translate('Total invoice')}
                      </p>
                      <h5 className="m-0 fs--2 text-info">
                        {item.total_invoice_count}
                      </h5>
                    </div>
                    <div className="d-flex flex-row align-items-center content-weekly justify-content-between gap-3">
                      <p className="m-0 fs--2">
                        <FontAwesomeIcon
                          icon="circle"
                          className="text-warning fs--2 me-2"
                        />
                        {Translate('Invoiced Amount')}
                      </p>
                      <h5 className="m-0 fs--2 text-warning">
                        {item.total_invoiced_amount}
                      </h5>
                    </div>
                    <div className="d-flex flex-row align-items-center content-weekly justify-content-between gap-3">
                      <p className="m-0 fs--2">
                        <FontAwesomeIcon
                          icon="circle"
                          className="text-success fs--2 me-2"
                        />
                        {Translate('Amount Received')}
                      </p>
                      <h5 className="m-0 fs--2 text-success">
                        {item.total_paid_amount}
                      </h5>
                    </div>
                    <div className="d-flex flex-row align-items-center content-weekly justify-content-between gap-3">
                      <p className="m-0 fs--2">
                        <FontAwesomeIcon
                          icon="circle"
                          className="text-danger fs--2 me-2"
                        />
                        {Translate('Total Outstanding')}
                      </p>
                      <h5 className="m-0 fs--2 text-danger">{item.balance}</h5>
                    </div>
                  </div>
                </Card.Body>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <>
            <Card.Body className="d-flex flex-column justify-content-center align-items-center">
              <FcNoIdea size={30} />
              {Translate('No Data Available')}
            </Card.Body>
          </>
        )
      ) : (
        <Loader loader={weeklySales} className={'ps-4'} />
      )}
    </Card>
  );
};

WeeklySales.propTypes = {
  amountClassName: PropTypes.string,
  draggable: PropTypes.bool
};

export default WeeklySales;

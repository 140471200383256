import SupplierContactPopupForm from 'components/form/popup-forms/SupplierContactPopupForm';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Container,
  Figure,
  ListGroup
} from 'react-bootstrap';
import { BsFillTrashFill, BsPencilSquare } from 'react-icons/bs';
import { FaRegAddressCard } from 'react-icons/fa';
import { FcPlus } from 'react-icons/fc';
import { MdCall, MdOutlineEmail } from 'react-icons/md';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';
import { apiCall } from 'helpers/apiCalls';
import CardLoading from 'components/common/card-loading/CardLoading';
import { DeleteContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import ProfileDefaultImage from '../../../../assets/img/avatar/default-avatar.png';

const SalesmanContacts = ({ salesmanId }) => {
  const [contacts, setContacts] = useState([]);
  const [showPopupFormData, setShowPopupFormData] = useState({
    show: false,
    editData: null
  });
  const [isExpanded, setIsExpanded] = useState(null);
  const maxLength = 70;
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(DeleteContext);
  const Translate = useAxisproTranslate();
  const [onSave, setOnSave] = useState(false);

  const handleDelete = data => {
    if (!data?.id) return;
    dispatch({
      type: 'CONFIG',
      payload: {
        target: data.id,
        url: `sales/salesman-contacts/${data?.id}`,
        title: Translate('Delete Contact'),
        message: Translate(
          'Are you sure? You want to delete this salesman contact!'
        ),
        onSuccess: () => {
          const dataWithoutDeletedItem = contacts.filter(
            contact => contact.id !== data.id
          );
          setContacts(dataWithoutDeletedItem);
          // reset deleteContext
          dispatch({
            type: 'RESET'
          });
        }
      }
    });
  };

  const toggleExpand = id => {
    if (id === isExpanded) {
      setIsExpanded(null);
    } else {
      setIsExpanded(id);
    }
  };

  const handlePopup = (show, data = null) => {
    setShowPopupFormData(prev => ({
      ...prev,
      show: show,
      editData: data
    }));
  };

  const getContactsData = useCallback(
    async (enableLoading = true) => {
      if (!salesmanId) return;
      try {
        enableLoading && setLoading(true);
        const res = await apiCall({
          url: `sales/salesman-contacts/${salesmanId}`
        });
        setContacts(res ?? []);
      } catch (error) {
        console.log(error);
      } finally {
        enableLoading && setLoading(false);
      }
    },
    [salesmanId]
  );

  useEffect(() => {
    getContactsData();
  }, [getContactsData]);

  return (
    <Container className="ps-1 pe-1">
      <FcPlus
        size={20}
        style={{
          top: -25,
          right: -12
        }}
        title="Add Shipping Address"
        className="cursor-pointer me-3 position-absolute"
        onClick={() => handlePopup(true)}
      />
      <SimpleBar className="simplebar-overview-page-style">
        <div className="d-flex  gap-3 flex-wrap mt-3 mb-3">
          {!loading && contacts?.length > 0 ? (
            <>
              {contacts.map((item, key) => (
                <>
                  {onSave && showPopupFormData?.editData?.id === item?.id ? (
                    <CardLoading width="14.6rem" />
                  ) : (
                    <Card
                      style={{
                        width: '14rem',
                        maxWidth: '14.7rem',
                        maxHeight: isExpanded ? '100%' : '17rem',
                        minHeight: '17rem',
                        overflow: 'auto'
                      }}
                      key={key}
                      className="border-dashed d-flex flex-column flex-grow-1"
                    >
                      <Card.Body className="p-0">
                        <Card.Title className="d-flex align-items-center gap-1 m-0 pt-2">
                          <Figure className="ps-3">
                            <Figure.Image
                              width={60}
                              height={60}
                              alt="profile"
                              src={ProfileDefaultImage}
                              roundedCircle
                              lazy
                            />
                            <Figure.Caption className="fs--1 text-dark fw-bold text-center">
                              {item?.name}
                            </Figure.Caption>
                          </Figure>
                          <div className="d-flex gap-2 me-3 position-absolute top-0 end-0">
                            <Button
                              variant="transparent"
                              className="p-0 shadow-none"
                              onClick={() => handlePopup(true, item)}
                            >
                              <BsPencilSquare size={14} className="text-info" />
                            </Button>
                            <Button
                              variant="transparent"
                              className="p-0 shadow-none"
                              onClick={() => handleDelete(item)}
                            >
                              <BsFillTrashFill
                                size={14}
                                className="text-danger"
                              />
                            </Button>
                          </div>
                        </Card.Title>
                        <ListGroup className="d-flex flex-column flex-grow-1">
                          {item?.contact_number && (
                            <ListGroup.Item className="d-flex align-items-center gap-2 flex-grow-1 border-0 border-top border-bottom rounded-0">
                              <MdCall
                                size={18}
                                className="flex-shrink-0 mb-auto"
                              />
                              <span>{item.contact_number}</span>
                            </ListGroup.Item>
                          )}

                          {item?.email_address && (
                            <ListGroup.Item className="d-flex align-items-center gap-2 flex-grow-1 border-0 border-bottom rounded-0">
                              <MdOutlineEmail
                                size={18}
                                className="flex-shrink-0 mb-auto"
                              />
                              <div
                                className="text-truncate"
                                style={{ maxWidth: '12rem' }}
                                title={item.email_address}
                              >
                                {item.email_address}
                              </div>
                            </ListGroup.Item>
                          )}
                          {item?.address && (
                            <ListGroup.Item className="d-flex align-items-start gap-2 flex-grow-1 border-0">
                              <FaRegAddressCard
                                size={18}
                                className="flex-shrink-0 mt-1"
                              />
                              <div className="d-flex">
                                <span>
                                  {isExpanded === item.id
                                    ? item.address
                                    : item.address.slice(0, maxLength) +
                                      (item.address.length > maxLength
                                        ? '...'
                                        : '')}
                                  {item.address.length > maxLength && (
                                    <Button
                                      variant="transparent"
                                      size="sm"
                                      className="p-0 w-25 ms-auto fs--1 text-info shadow-none"
                                      onClick={() => toggleExpand(item.id)}
                                    >
                                      {isExpanded === item.id ? 'Less' : 'More'}
                                    </Button>
                                  )}
                                </span>
                              </div>
                            </ListGroup.Item>
                          )}
                        </ListGroup>
                      </Card.Body>
                    </Card>
                  )}
                </>
              ))}
            </>
          ) : loading ? (
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(key => (
              <CardLoading key={key} />
            ))
          ) : (
            <Alert className="my-auto alert-danger  mt-2 w-100">
              <h5 className="text-center text-secondary">
                {Translate('No Contacts Found')} &#128578;
              </h5>
            </Alert>
          )}
        </div>
      </SimpleBar>
      {showPopupFormData.show && (
        <SupplierContactPopupForm
          showPopupFormData={showPopupFormData}
          salesmanId={salesmanId}
          handleShow={handlePopup}
          getContactsData={getContactsData}
          onSave={onSave}
          setOnSave={setOnSave}
        />
      )}
    </Container>
  );
};

SalesmanContacts.propTypes = {
  salesmanId: PropTypes.string
};

export default SalesmanContacts;

import { Fragment, React, useCallback, useContext } from 'react';
import { Alert, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import handleTableItem from 'module/Purchase/common/functions/handleTableItem';
import PurchaseTableForm from './PurchaseTableForm';
import PurchaseTableFormLineItem from './PurchaseTableFormLineItem';
import { useSaveToCart } from '../hooks/usePurchaseHooks';
import DisplayLineItemErrors from './DisplayLineItemErrors';
import { useState } from 'react';
import TableLoading from 'module/Report/Common/TableLoading';
import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import SubTotalLineItem from './SubTotalLineItem';
import { AuthWizardContext, StoreContext } from 'context/Context';
import ShowInput from 'components/form-settings-offcanvas/ShowInput';
import useFormSettingsHook from 'hooks/useFormSettingsHook';

function PurchaseEntryTable({
  entries,
  setFormData,
  conversion,
  readOnly = false,
  batchIsEditable,
  handleSaveDraftData,
  taxIncluded,
  transaction_date,
  defaultKeys,
  totalAmount,
  disableEntriesRequiredMessage,
  enableBatchSection,
  transType,
  transNumber,
  enableTaxGroup,
  supplierDetails,
  showLoading,
  showPurchaseHistory,
  errors,
  entriesLimit,
  otherEntriesCount,
  setIncludeRoundOffAmount,
  supplierCurrencyDetails
}) {
  const { user } = useContext(AuthWizardContext);
  const { settings: Form_settings } = useFormSettingsHook(transType);
  const { store } = useContext(StoreContext);
  const taxLabel = store?.globalConfiguration?.tax_label || 'Tax';
  const baseCurrency =
    supplierCurrencyDetails?.currency_code ||
    user?.branch?.base_currency ||
    'AED';
  const entriesLength = entries?.length;
  const limitExceeded =
    entriesLimit &&
    (entriesLength || 0) + (otherEntriesCount || 0) >= (entriesLimit || 0);

  let colSpan = 12;
  let textAreaColSpan = 10;

  ['item_code', 'unit', 'tax_group', 'unit_tax', 'discount'].forEach(key => {
    if (Form_settings && !Form_settings?.[key]) {
      colSpan -= 1;
      textAreaColSpan -= 1;
    }
  });

  if (!enableBatchSection) {
    colSpan -= 1;
    textAreaColSpan -= 1;
  }

  if (enableTaxGroup) {
    defaultKeys = {
      ...defaultKeys,
      tax_group_id: '',
      tax_group_id_ref: null
    };
  }

  const Translate = useAxisproTranslate();
  const [itemIsAdding, setItemIsAdding] = useState(false);
  const onTableItemChange = useCallback(
    async (data, method, saveToDraft) => {
      if (!transaction_date) {
        return showToast(
          Translate('Transaction date is required, please select a valid date')
        );
      }
      setItemIsAdding(true);
      handleTableItem({
        data: data,
        method: method,
        setFormData: setFormData,
        handleSaveDraftData: handleSaveDraftData,
        saveToDraft: saveToDraft ?? false
      });
      setItemIsAdding(false);
    },
    [transaction_date, Translate, handleSaveDraftData, setFormData]
  );

  useSaveToCart({
    onTableItemChange: onTableItemChange,
    cartItems: entries,
    transType: transType,
    transNumber: transNumber
  });

  return (
    <Table
      striped
      bordered
      responsive
      hover={!readOnly ? true : false}
      className="simple-table-animation"
    >
      <thead
        className="text-dark text-uppercase"
        style={{ backgroundColor: 'rgb(237,243,248)' }}
      >
        <tr>
          <th
            className="table-header-size text-center"
            style={{ width: '40px' }}
          >
            #
          </th>
          <ShowInput show={!Form_settings || Form_settings?.item_code}>
            <th className="table-header-size" style={{ width: '90px' }}>
              {Translate('Item Code')}
            </th>
          </ShowInput>

          <th style={{ minWidth: '250px' }} className="table-header-size">
            {Translate('Item Name')}
          </th>

          <ShowInput show={Boolean(enableBatchSection)}>
            <th className="table-header-size" style={{ width: '200px' }}>
              {Translate('Batch')}
            </th>
          </ShowInput>

          <ShowInput show={!Form_settings || Form_settings?.unit}>
            <th className="table-header-size" style={{ width: '120px' }}>
              {Translate('Unit')}
            </th>
          </ShowInput>

          <ShowInput show={Boolean(enableTaxGroup)}>
            <th className="table-header-size" style={{ minWidth: '150px' }}>
              {Translate('Tax Group')}
            </th>
          </ShowInput>

          <th className="table-header-size" style={{ width: '200px' }}>
            {Translate('Quantity')}
          </th>

          <th className="table-header-size" style={{ width: '200px' }}>
            <div className="d-flex flex-wrap gap-1">
              <span>{Translate('Unit Price')}</span>
              <b>({baseCurrency})</b>
              {taxIncluded ? (
                <small className="text-danger">
                  <b> ({Translate(`Inc ${taxLabel}`)})</b>
                </small>
              ) : null}
            </div>
          </th>

          <ShowInput show={!Form_settings || Form_settings?.unit_tax}>
            <th className="table-header-size">
              <div className="d-flex flex-wrap gap-1">
                <span>{Translate(`Unit ${taxLabel}`)}</span>
                <b>({baseCurrency})</b>
              </div>
            </th>
          </ShowInput>

          <ShowInput show={!Form_settings || Form_settings?.discount}>
            <th className="table-header-size" style={{ width: '200px' }}>
              <div className="d-flex flex-wrap gap-1">
                <span>{Translate('Discount / Unit')}</span>
                <b>({baseCurrency})</b>
              </div>
            </th>
          </ShowInput>

          <th className="table-header-size">
            <div className="d-flex flex-wrap gap-1">
              <span>{Translate('Total')}</span>
              <b>({baseCurrency})</b>
            </div>
          </th>
          {!readOnly && <th style={{ width: '70px' }}></th>}
        </tr>
      </thead>
      <tbody>
        {entriesLength > 0 ? (
          <>
            {!transaction_date && (
              <tr>
                <td colSpan={colSpan} className="p-0">
                  <Alert
                    className="text-center my-2 rounded-0 p-2 fs--1"
                    variant="danger"
                  >
                    {Translate(
                      'The transaction date is invalid, please select a valid date!'
                    )}
                  </Alert>
                </td>
              </tr>
            )}
            {entries.map((entry, index) => (
              <Fragment key={index}>
                {showLoading ? (
                  <TableLoading row={1} column={colSpan} />
                ) : entry.edit ? (
                  <PurchaseTableForm
                    editItemData={entry}
                    onTableItemChange={onTableItemChange}
                    transaction_date={transaction_date}
                    taxIncluded={taxIncluded}
                    defaultKeys={defaultKeys}
                    isLoading={entry?.add_to_cart_ref ? true : false}
                    enableBatchSection={enableBatchSection}
                    conversion={conversion}
                    batchIsEditable={batchIsEditable}
                    transType={transType}
                    enableTaxGroup={enableTaxGroup}
                    supplierDetails={supplierDetails}
                    showPurchaseHistory={showPurchaseHistory}
                    setIncludeRoundOffAmount={setIncludeRoundOffAmount}
                    hasSavedEntries={entries.length > 0}
                  />
                ) : (
                  <PurchaseTableFormLineItem
                    Form_settings={Form_settings}
                    entry={entry}
                    index={index}
                    onTableItemChange={onTableItemChange}
                    readOnly={readOnly}
                    conversion={conversion}
                    enableBatchSection={enableBatchSection}
                    errors={errors}
                    enableTaxGroup={enableTaxGroup}
                  />
                )}
                {!entry.add_to_cart_ref && entry.errors ? (
                  <DisplayLineItemErrors
                    errors={entry.errors[0]}
                    colSpan={colSpan}
                  />
                ) : null}
              </Fragment>
            ))}
          </>
        ) : !disableEntriesRequiredMessage ? (
          <tr>
            <td colSpan={colSpan} className="p-0">
              <Alert
                className="text-center my-2 rounded-0 p-2 fs--1"
                variant="warning"
              >
                {Translate('Entries are required!')}
              </Alert>
            </td>
          </tr>
        ) : null}

        {!limitExceeded && !readOnly && (!conversion || transType === 'PO') ? (
          <PurchaseTableForm
            onTableItemChange={onTableItemChange}
            transaction_date={transaction_date}
            taxIncluded={taxIncluded}
            defaultKeys={defaultKeys}
            enableBatchSection={enableBatchSection}
            hide={
              showLoading ||
              itemIsAdding ||
              !!(entries?.filter(item => item.edit === true).length > 0)
            }
            enableTaxGroup={enableTaxGroup}
            supplierDetails={supplierDetails}
            showPurchaseHistory={showPurchaseHistory}
            transType={transType}
            setIncludeRoundOffAmount={setIncludeRoundOffAmount}
            hasSavedEntries={entries.length > 0}
          />
        ) : null}

        {showLoading || itemIsAdding ? (
          <TableLoading row={1} column={colSpan} />
        ) : null}

        {typeof totalAmount === 'number' ? (
          <SubTotalLineItem
            textAreaColSpan={textAreaColSpan}
            amountAreaColSpan={2}
            totalAmount={totalAmount}
          />
        ) : null}
      </tbody>
    </Table>
  );
}

PurchaseEntryTable.propTypes = {
  entries: PropTypes.array,
  setFormData: PropTypes.func,
  conversion: PropTypes.bool,
  readOnly: PropTypes.bool,
  batchIsEditable: PropTypes.bool,
  handleSaveDraftData: PropTypes.func,
  taxIncluded: PropTypes.bool,
  transaction_date: PropTypes.string,
  defaultKeys: PropTypes.object.isRequired,
  totalAmount: PropTypes.number,
  disableEntriesRequiredMessage: PropTypes.bool,
  enableBatchSection: PropTypes.bool,
  transType: PropTypes.string,
  transNumber: PropTypes.string,
  enableTaxGroup: PropTypes.bool,
  supplierDetails: PropTypes.object,
  showLoading: PropTypes.bool,
  showPurchaseHistory: PropTypes.bool,
  errors: PropTypes.object,
  entriesLimit: PropTypes.number,
  otherEntriesCount: PropTypes.number,
  setIncludeRoundOffAmount: PropTypes.func,
  supplierCurrencyDetails: PropTypes.object
};

export default PurchaseEntryTable;

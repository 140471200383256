import { React, useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Form, Card, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FaRegFileAlt } from 'react-icons/fa';
import { AuthWizardContext } from 'context/Context';
import { showToast } from 'module/Common/Toast/toast';
import { CancelButton, SaveButton } from 'module/Common/Buttons/CommonButton';
import { additionalInvoiceFormKeys } from 'helpers/formKeys';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import SelectPaymentTerms from 'components/form/SelectPaymentTerms';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import setNewFormData from 'helpers/setNewFormData';
import removeRefData from 'helpers/removeRefData';
import classNames from 'classnames';
import AdditionalInvoiceTable from './components/AdditionalInvoiceTable';
import ItemChooseModal from './components/ItemChooseModal';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import FormLayer from 'module/Common/Layer/FormLayer';

function AdditionalInvoice({ show, setShow, data, fetchData }) {
  const { user } = useContext(AuthWizardContext);
  const Translate = useAxisproTranslate();
  const [onSave, setOnSave] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(additionalInvoiceFormKeys);
  const [validationErrorMsg, setValidationErrorMsg] = useState({});
  const [shakeModal, setShakeModal] = useState(false);
  const [lineItemEditIndex, setLineItemEditIndex] = useState();
  const [openItemModal, setOpenItemModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setOnSave(true);
    let newFormData = setNewFormData(removeRefData(formData));
    axios
      .post(`sales/rental-additional-invoice`, newFormData)
      .then(response => {
        if (response?.data?.success) {
          fetchData();
          showToast(response?.data?.message, 'success');
          setFormData({});
          setShow(false);
          setOnSave(false);
        }
      })
      .catch(error => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setShakeModal(true);
          setTimeout(() => {
            setShakeModal(false);
          }, 500);
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setValidationErrorMsg({ ...validation_error });
        } else {
          showToast(
            Translate(
              'Something went wrong, please refresh the page and try again.'
            ),
            'error'
          );
        }
        setOnSave(false);
      });
  };
  const getDate = days => {
    let today = new Date();
    today.setDate(today.getDate() + days);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const handleFieldChange = (e, action) => {
    let newFormData = generateFormDataHelper(e, action, formData);
    setFormData(newFormData);
    if (e?.target?.name === 'unit_price') {
      setFormData(prev => ({
        ...prev,
        details: [
          {
            ...prev.details[0],
            unit_price: e?.target?.value,
            discount_amount: 0,
            is_kit: 0
          }
        ]
      }));
    }
    if (
      action?.name === 'payment_term_id' &&
      action.action === 'select-option'
    ) {
      let newData = getDate(e?.days);
      setFormData(prev => ({
        ...prev,
        due_date: newData
      }));
    }
  };
  const handleCancel = () => {
    setFormData(additionalInvoiceFormKeys);
    setValidationErrorMsg({});
    setShow(false);
  };

  const fetchTerms = useCallback(async id => {
    try {
      const { data } = await axios.get(`/settings/payment-terms/${id}`);
      let paymentDetails = data?.data;
      if (paymentDetails?.id) {
        setIsLoading(false);
        setFormData(prev => ({
          ...prev,
          due_date: getDate(paymentDetails?.days),
          payment_term_id: paymentDetails?.id,
          payment_term_id_ref: {
            label: paymentDetails?.terms,
            value: paymentDetails?.id
          }
        }));
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchCustomer = useCallback(
    async id => {
      try {
        const { data } = await axios.get(`/sales/customers/${id}`);
        if (data?.data?.payment_term_id) {
          fetchTerms(data?.data?.payment_term_id);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    },
    [fetchTerms]
  );
  useEffect(() => {
    const init = async () => {
      if (data?.id) {
        setIsLoading(true);
        setFormData(prev => ({
          ...prev,
          contract_id: data?.id,
          customer_id: data?.customer_id,
          customer_id_ref: {
            label: data?.customer_name,
            value: data?.customer_id
          },
          sales_type_id: data?.sales_type_id,
          sales_type_id_ref: {
            label: data?.sales_type,
            value: data?.sales_type_id
          },
          warehouse_id:
            data?.details?.length > 0
              ? data?.details[0]?.item_warehouse_id
                ? data?.details[0]?.item_warehouse_id
                : user?.branch?.inventory_settings?.default_warehouse_id ?? ''
              : user?.branch?.inventory_settings?.default_warehouse_id ?? ''
        }));
        await fetchCustomer(data?.customer_id);
      }
    };

    init();
  }, [
    data,
    fetchCustomer,
    user?.branch?.inventory_settings?.default_warehouse_id
  ]);

  return (
    <>
      <Modal
        show={show}
        className={`modal-with-overlay ${classNames({
          'modal-shake': shakeModal
        })}`}
        backdrop="static"
        size="xl"
        onHide={handleCancel}
      >
        <Card className="form-layer-container">
          {loading ? <FormLayer /> : ''}
          <Modal.Header
            className="fs--1 text-uppercase text-dark d-flex align-items-center border-bottom"
            closeButton
          >
            <FaRegFileAlt size={20} className="me-2" />
            {Translate('Additional Invoice')}
          </Modal.Header>
          <Card.Body className="pt-2">
            {Object.keys(validationErrorMsg).length > 0 && (
              <ErrorAlert
                formError={validationErrorMsg}
                setFormError={setValidationErrorMsg}
              />
            )}
            {!isLoading ? (
              <Form className="app-form">
                <div
                  className={`d-flex flex-wrap border p-3 mb-2`}
                  style={{
                    backgroundColor: 'aliceblue'
                  }}
                >
                  <div className="me-auto">
                    <div className="d-flex flex-row gap-2 mb-1">
                      <p className="fw-bold text-dark m-0">
                        {Translate('Customer Name')} :
                      </p>
                      <div className="d-flex flex-row align-items-center gap-2">
                        <p className="m-0 ">{data?.customer_name}</p>
                      </div>
                    </div>
                    <div className="d-flex flex-row gap-2 mb-1">
                      <p className="fw-bold text-dark m-0">
                        {Translate('Sales Type')} :
                      </p>
                      <div className="d-flex flex-row align-items-center gap-2">
                        <p className="m-0 ">{data?.sales_type}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <Row className="">
                  <Form.Group as={Col} md={4} className="mb-2">
                    <Form.Label className="require-data">
                      {Translate('Date')}
                    </Form.Label>
                    <AppDatePicker
                      name={'trans_date'}
                      onChange={handleFieldChange}
                      isInvalid={validationErrorMsg?.trans_date}
                      value={formData?.trans_date}
                      yearPlaceholder="yyyy"
                      monthPlaceholder="mm"
                      dayPlaceholder="dd"
                      showDefaultDate
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={4} className="mb-2">
                    <Form.Label className="require-data">
                      {Translate('Payment Term')}
                    </Form.Label>
                    <SelectPaymentTerms
                      name="payment_term_id"
                      value={formData?.payment_term_id_ref}
                      handleFieldChange={handleFieldChange}
                      error={validationErrorMsg?.payment_term_id}
                      includeThisKeys={['days']}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={4} className="mb-2">
                    <Form.Label className="require-data">
                      {Translate('Due Date')}
                    </Form.Label>
                    <AppDatePicker
                      name={'due_date'}
                      onChange={handleFieldChange}
                      isInvalid={validationErrorMsg?.due_date}
                      value={formData?.due_date}
                      yearPlaceholder="yyyy"
                      monthPlaceholder="mm"
                      dayPlaceholder="dd"
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Col md={12}>
                    <AdditionalInvoiceTable
                      data={formData?.details ?? []}
                      setFormData={setFormData}
                      formData={data}
                      setLoading={setLoading}
                    />
                  </Col>
                </Row>
                <Form.Group as={Col} md={12} sm={12} className="mb-2">
                  <Form.Label>{Translate('Notes')}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="memo"
                    className="w-100"
                    value={formData?.memo}
                    onChange={handleFieldChange}
                  />
                </Form.Group>
              </Form>
            ) : (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: '350px' }}
              >
                <LoadingScreen message="Looking for invoice details" />
              </div>
            )}
          </Card.Body>
          <Card.Footer className="d-flex gap-1 pe-3 justify-content-end border-top">
            <CancelButton handleCancel={handleCancel} onSave={onSave} />
            <SaveButton
              buttonTitle={'Make Invoice'}
              createPermission={true}
              editPermission={true}
              handleSubmit={handleSubmit}
              onSave={onSave}
            />
          </Card.Footer>
        </Card>
      </Modal>
      <ItemChooseModal
        setShow={setOpenItemModal}
        show={openItemModal}
        setFormData={setFormData}
        editIndex={lineItemEditIndex}
        formData={formData}
        setEditIndex={setLineItemEditIndex}
      />
    </>
  );
}

AdditionalInvoice.propTypes = {
  data: PropTypes.object,
  show: PropTypes.func,
  setShow: PropTypes.func,
  fetchData: PropTypes.func,
  defaultUserDetails: PropTypes.object
};

export default AdditionalInvoice;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Form, Modal, Spinner } from 'react-bootstrap';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { FaBan } from 'react-icons/fa6';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import { FaTimesCircle } from 'react-icons/fa';
import { showToast } from 'module/Common/Toast/toast';
import axios from 'axios';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import generateFormDataHelper from 'helpers/generateFormDataHelper';

export default function VoidTransactionPopUpDropdownItem({ details }) {
  const Translate = useAxisproTranslate();
  const [show, setShow] = useState(false);
  const [formError, setFormError] = useState({});
  const [formData, setFormData] = useState(undefined);
  const [isLoading, setisLoading] = useState(false);

  const handleModal = () => {
    setFormData({
      voided_date: '',
      memo: ''
    });
    setFormError({});
    setisLoading(false);
    setShow(!show);
  };

  const handleFieldChange = (event, action) =>
    setFormData(generateFormDataHelper(event, action, formData));

  const handleSubmit = async event => {
    event.preventDefault();
    setFormError({});
    setisLoading(true);
    try {
      const apiResponse = await axios.post('settings/void-transaction', {
        trans_no: details?.id,
        trans_type: details?.trans_type,
        ...formData
      });

      if (apiResponse?.status === 200 && apiResponse?.data?.success) {
        setShow(false);
        showToast(
          apiResponse?.data?.message ??
            'Transaction has been voided successfully',
          'success'
        );

        typeof details?.callBack === 'function' &&
          details.callBack(apiResponse);
      }
    } catch (error) {
      setFormError({ ...(error?.response?.data?.data?.errors ?? null) });
    } finally {
      setisLoading(false);
    }
  };

  return details?.id && details?.trans_type ? (
    <>
      <Dropdown.Item
        className="d-flex align-items-center"
        onClick={handleModal}
      >
        <FaBan className="me-1 text-dark" size={14} />
        {Translate('Void Transaction')}
      </Dropdown.Item>

      <Modal show={show} onHide={handleModal}>
        <Modal.Header
          closeButton
          className="d-flex flex-row align-items-center gap-2"
          style={{
            fontSize: '1.1rem'
          }}
        >
          <FaBan className="text-danger" />
          <p className="m-0 text-dark">{Translate('Void Transaction')}</p>
        </Modal.Header>
        <Modal.Body>
          {Object.keys(formError).length > 0 && (
            <ErrorAlert formError={formError} setFormError={setFormError} />
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="voided_date">
              <Form.Label className="require-data">
                {Translate('Void Date')}
              </Form.Label>
              <div className="d-flex flex-row align-items-center">
                <AppDatePicker
                  showDefaultDate
                  name="voided_date"
                  value={formData?.voided_date}
                  onChange={handleFieldChange}
                />
                <FormErrorPopover
                  id="voided_date"
                  totalErrorCount={Object.keys(formError).length}
                  errorMessage={formError.voided_date}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="memo">
              <Form.Label className="require-data">
                {Translate('Notes')}
              </Form.Label>
              <div className="d-flex flex-row align-items-center">
                <Form.Control
                  as="textarea"
                  rows={5}
                  name="memo"
                  value={formData?.memo}
                  onChange={handleFieldChange}
                />
                <FormErrorPopover
                  id="memo"
                  totalErrorCount={Object.keys(formError).length}
                  errorMessage={formError.memo}
                />
              </div>
            </Form.Group>
            <Form.Group className="d-flex gap-1 justify-content-end">
              <Button
                variant="danger"
                size="sm"
                className="d-flex gap-1 align-items-center"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Spinner
                      style={{
                        width: '13px',
                        height: '13px'
                      }}
                    />
                    {Translate('Voiding...')}
                  </>
                ) : (
                  <>
                    <FaBan />
                    {Translate('Void')}
                  </>
                )}
              </Button>
              <Button
                variant="warning"
                size="sm"
                className="d-flex gap-1 align-items-center"
                type="button"
                onClick={handleModal}
                disabled={isLoading}
              >
                <FaTimesCircle />
                {Translate('Cancel')}
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  ) : null;
}

VoidTransactionPopUpDropdownItem.propTypes = {
  details: PropTypes.shape({
    id: PropTypes.string,
    trans_type: PropTypes.string,
    callBack: PropTypes.func
  })
};

import { React, useState } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import WarehousePopupForm from './popup-forms/WarehousePopupForm';
import ReactSelectAddButton from 'components/add-button/ReactSelectAddButton';
import useAutoCompleteApiCallHandler from 'hooks/useAutoCompleteApiCallHandler';

const SelectWarehouse = ({
  handleFieldChange,
  name,
  error,
  value,
  keyName,
  placeholder,
  style,
  withAddButton,
  onFocus,
  ...rest
}) => {
  const [showFormWindow, setShowFormWindow] = useState(false);
  const {
    fetchData,
    setDefaultResult,
    loading,
    defaultOptions,
    setDefaultOptions
  } = useAutoCompleteApiCallHandler({
    url: 'settings/warehouses-autocomplete',
    setParams: searchKey => ({ location_name: searchKey }),
    setOptions: option => setOptionData(option),
    onFocus: onFocus
  });

  const setOptionData = option => ({
    label: option.location_name,
    value: keyName ? option[keyName] : option.id
  });

  return (
    <>
      <AsyncSelect
        cacheOptions
        loadOptions={fetchData}
        isLoading={loading}
        defaultOptions={defaultOptions}
        onFocus={setDefaultResult}
        isClearable
        name={name ?? 'warehouse'}
        value={value}
        placeholder={placeholder ?? ''}
        onChange={handleFieldChange}
        classNamePrefix="app-react-select"
        className={`${error && 'is-invalid'} w-100`}
        components={
          withAddButton
            ? {
                Menu: props => (
                  <>
                    <ReactSelectAddButton
                      props={props}
                      setShowFormWindow={() => setShowFormWindow(true)}
                      title="Add Warehouse"
                    />
                  </>
                )
              }
            : null
        }
        styles={{
          menuPortal: provided => ({ ...provided, zIndex: 9999 }),
          menu: provided => ({
            ...provided,
            zIndex: 9999,
            borderRadius: '0px',
            fontSize: '13px'
          }),
          control: provided => ({
            ...provided,
            height: 32,
            minHeight: 32,
            ...style
          })
        }}
        {...rest}
      />

      {withAddButton ? (
        <WarehousePopupForm
          name={name ?? 'warehouse_id'}
          labelName="location_name"
          keyName="id"
          show={showFormWindow}
          onHide={() => setShowFormWindow(false)}
          setData={handleFieldChange}
          callBack={newItem => {
            if (newItem?.id) {
              setDefaultOptions(prev => [setOptionData(newItem), ...prev]);
            }
          }}
        />
      ) : null}
    </>
  );
};

SelectWarehouse.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.object,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  withAddButton: PropTypes.bool,
  onFocus: PropTypes.func
};

export default SelectWarehouse;

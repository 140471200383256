import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React, { useEffect } from 'react';
import { Col, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
const CheckBoxFilter = ({ item, formData, setFormData }) => {
  const Translate = useAxisproTranslate();
  const [queryParams] = useSearchParams();
  const filterItems = queryParams.get('filters');
  const handleCheckBoxChange = ({ target }) => {
    const { name, checked, value: inputValue } = target;
    const toggleFields = [
      'is_unpaid',
      'is_expiry',
      'is_active',
      'show_opening_balance',
      'overdue',
      'suppress_zero',
      'is_recurring'
    ];
    const booleanFields = [];
    const value = booleanFields.includes(name)
      ? checked
      : toggleFields.includes(name)
      ? formData[name] === 1
        ? 0
        : 1
      : inputValue;
    setFormData(prev => ({ ...prev, [name]: value }));
  };
  useEffect(() => {
    if (
      item.defaultValue &&
      !Object.keys(JSON.parse(filterItems || '{}')).length &&
      formData[item.name] === undefined
    ) {
      setFormData(prev => ({
        ...prev,
        [item.name]: item.defaultValue
      }));
    }
  }, [setFormData, filterItems, formData, item]);
  return (
    <Col
      md={1}
      className={`d-flex align-items-center h-100 ${
        item?.hidde ? 'd-none' : ''
      }`}
    >
      <Form.Group className="d-flex gap-1">
        <Form.Check
          type="checkbox"
          name={item.name}
          className="ms-0"
          checked={!!formData[item.name]}
          onChange={handleCheckBoxChange}
        />
        <Form.Label className="m-0" style={{ width: 'max-content' }}>
          {Translate(item.Heading)}
        </Form.Label>
      </Form.Group>
    </Col>
  );
};
CheckBoxFilter.propTypes = {
  item: PropTypes.object,
  formData: PropTypes.object,
  setFormData: PropTypes.func
};
export default CheckBoxFilter;

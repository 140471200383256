import axios from 'axios';

export default async (options = {}) => {
  const { supplierID, formData, resultKeyName } = options;
  const keyName = resultKeyName || 'supplierDetails_ref';

  if (!supplierID) {
    throw new Error('Supplier is required, please choose a valid data.');
  }

  if (formData?.[keyName]) delete formData[keyName];

  const apiResult = await axios.get('purchase/supplier/' + supplierID);
  const supplierDetails = apiResult?.data?.data;

  if (!supplierDetails) {
    throw new Error("We couldn't find any results");
  }

  formData[keyName] = supplierDetails;

  return {
    formData,
    supplierDetails
  };
};
